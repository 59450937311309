import React from 'react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import Tag from '../tag';
import { getIcon } from '../utils/icon';
import { truncateString } from '../utils/truncateString';
import { useIsMobile } from '../hooks/useIsMobile';
import { format } from 'date-fns';
import { Text, RichText, Image, Link } from '@sitecore-jss/sitecore-jss-react';
import {
  SignpostComponent,
  SignpostWrap,
  CTAWrap,
  IconWrap,
  Title,
  Content,
  TextWrap,
  ComponentDate,
  ImageWrap,
} from './Signpost.styles.js';

const Signpost = props => {
  const {
    title,
    url,
    summary,
    image,
    date,
    tagTextColour,
    tagBackgroundColour,
    tagLabel,
    truncated = false,
    theme: { website },
    tagRef,
  } = props;
  const isMobile = useIsMobile();
  const truncateDescriptionLength = isMobile ? 200 : 250;

  const hasSummaryText =
    (typeof summary === 'object' && summary?.value) || (typeof summary === 'string' && summary.length > 0);

  const renderText = () => {
    let text = {};
    if (summary?.value) {
      text.value = truncated ? truncateString(summary.value, truncateDescriptionLength) : summary.value;
    } else {
      text.value = truncated ? truncateString(summary, truncateDescriptionLength) : summary;
    }
    return text?.value ? <RichText field={text} tag="p" /> : null;
  };

  // Swap the different SVG's per website
  const handleSiteSvgIcons = () => {
    if (website === 'london-marathon')
      return (
        <>
          {hasSummaryText ? getIcon('signpostMobile') : getIcon('signpostDesktop')}
          {getIcon('arrowLong')}
          {getIcon('signpostDesktop')}
        </>
      );

    return (
      <>
        {hasSummaryText ? getIcon('signpostSquareMobile') : getIcon('signpostSquareDesktop')}
        {getIcon('arrowLong')}
        {getIcon('signpostSquareDesktop')}
      </>
    );
  };

  const googleAnalyticsTitle = () => {
    if (typeof title === 'string') return title;

    if (typeof title === 'object') return title.value;

    return null;
  };

  const LinkWrapper = ({ children, onClick }) => {
    if (typeof url === 'string') {
      return (
        <a href={url} onClick={onClick}>
          {children}
        </a>
      );
    }

    return (
      <Link field={url} onClick={onClick}>
        {children}
      </Link>
    );
  };

  return (
    <SignpostComponent>
      <LinkWrapper
        onClick={() => {
          if (!isServer()) {
            window.dataLayer.push({
              event: 'signpostCTA',
              signpostType: tagLabel,
              clickText: googleAnalyticsTitle(),
              clickURL: url,
            });
          }
        }}
      >
        <SignpostWrap>
          {image && Object.keys(image.value).length ? (
            <ImageWrap>
              <Image field={image} />
            </ImageWrap>
          ) : (
            <Tag
              textColour={tagTextColour}
              backgroundColour={tagBackgroundColour}
              label={tagLabel}
              isPresentationalOnly
              ref={tagRef}
            />
          )}
          <Content pageEditing={false}>
            {title && typeof title === 'string' && <Title>{title}</Title>}
            {title && typeof title === 'object' && (
              <Title>
                <Text field={title} />
              </Title>
            )}
            {summary && <TextWrap>{renderText()}</TextWrap>}
            {date && <ComponentDate>{format(Date.parse(date), 'dd-MM-yyyy')}</ComponentDate>}
          </Content>
          <CTAWrap>
            <IconWrap
              className={`${website ? `signpost-${website}` : 'signpost-london-marathon'}`}
              hasDescription={hasSummaryText}
            >
              {handleSiteSvgIcons()}
            </IconWrap>
          </CTAWrap>
        </SignpostWrap>
      </LinkWrapper>
    </SignpostComponent>
  );
};

Signpost.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  url: PropTypes.any,
  summary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  date: PropTypes.string,
  tagTextColour: PropTypes.string,
  tagBackgroundColour: PropTypes.string,
  tagLabel: PropTypes.string,
  truncated: PropTypes.bool,
  theme: PropTypes.object,
  image: PropTypes.object,
  tagRef: PropTypes.any,
};

export default withTheme(Signpost);
