export const styleOptions = {
  hideUploadButton: true,
  suggestedActionLayout: 'stacked',
  suggestedActionHeight: 50,

  suggestedActionBackgroundColor: 'black',
  suggestedActionTextColor: 'white',
  suggestedActionBorderColor: 'black',
  suggestedActionsStackedHeight: 200,

  rootHeight: '550px',
  rootWidth: '350px',
  backgroundColor: 'white',

  bubbleBackground: 'white',
  bubbleMaxWidth: '275px',
  bubbleFromUserBackground: '#f5f5f5',
  bubbleBorderRadius: '20px',
  bubbleFromUserBorderRadius: '20px',
  bubbleTextColor: 'black',
  bubbleFromUserTextColor: 'black',
  bubbleBorderColor: 'grey',
  bubbleFromUserBorderColor: 'grey',

  botAvatarImage:
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='174' height='174' viewBox='0 0 174 174'%3E%3Cg id='Group_8' data-name='Group 8' transform='translate(-4883 1174)'%3E%3Ccircle id='Ellipse_1' data-name='Ellipse 1' cx='87' cy='87' r='87' transform='translate(4883 -1174)' fill='%23fff'/%3E%3Cpath id='Ellipse_1_-_Outline' data-name='Ellipse 1 - Outline' d='M87,1A86.642,86.642,0,0,0,69.667,2.747a85.524,85.524,0,0,0-30.75,12.94A86.254,86.254,0,0,0,7.758,53.525,85.561,85.561,0,0,0,2.747,69.667a86.849,86.849,0,0,0,0,34.666,85.523,85.523,0,0,0,12.94,30.75,86.254,86.254,0,0,0,37.838,31.159,85.561,85.561,0,0,0,16.142,5.011,86.849,86.849,0,0,0,34.666,0,85.523,85.523,0,0,0,30.75-12.94,86.254,86.254,0,0,0,31.159-37.838,85.561,85.561,0,0,0,5.011-16.142,86.849,86.849,0,0,0,0-34.666,85.524,85.524,0,0,0-12.94-30.75A86.254,86.254,0,0,0,120.475,7.758a85.561,85.561,0,0,0-16.142-5.011A86.642,86.642,0,0,0,87,1m0-1A87,87,0,1,1,0,87,87,87,0,0,1,87,0Z' transform='translate(4883 -1174)' fill='%23555'/%3E%3Cpath id='smart_toy_FILL0_wght400_GRAD0_opsz48' d='M12.825,54.987a10.455,10.455,0,0,1-7.689-3.136,10.993,10.993,0,0,1,0-15.377,10.455,10.455,0,0,1,7.689-3.136V20.895a5.823,5.823,0,0,1,1.821-4.249,5.823,5.823,0,0,1,4.249-1.821H35.688a10.455,10.455,0,0,1,3.136-7.689,10.993,10.993,0,0,1,15.377,0,10.455,10.455,0,0,1,3.136,7.689H74.13a5.823,5.823,0,0,1,4.249,1.821A5.823,5.823,0,0,1,80.2,20.895V33.338A10.709,10.709,0,0,1,91.025,44.162,10.709,10.709,0,0,1,80.2,54.987V74.815a6.223,6.223,0,0,1-6.07,6.07H18.895a5.823,5.823,0,0,1-4.249-1.821,5.823,5.823,0,0,1-1.821-4.249ZM32.653,44.871a3.974,3.974,0,1,0-2.883-1.163A3.915,3.915,0,0,0,32.653,44.871Zm27.719,0a3.974,3.974,0,1,0-2.883-1.163A3.915,3.915,0,0,0,60.372,44.871ZM29.517,64.193H63.508v-6.07H29.517ZM18.895,74.815H74.13V20.895H18.895Zm0,0v0Z' transform='translate(4923.487 -1129.443)'/%3E%3C/g%3E%3C/svg%3E",
  botAvatarInitials: '',
  botAvatarBackgroundColor: 'grey',
  userAvatarImage:
    "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='174' height='174' viewBox='0 0 174 174'%3E%3Cg id='Group_7' data-name='Group 7' transform='translate(-5098 1174)'%3E%3Ccircle id='Ellipse_2' data-name='Ellipse 2' cx='87' cy='87' r='87' transform='translate(5098 -1174)' fill='%23e1e1e1'/%3E%3Cpath id='person_FILL0_wght400_GRAD0_opsz48' d='M47.621,46.095A17.813,17.813,0,0,1,29.049,27.522,17.813,17.813,0,0,1,47.621,8.95,17.813,17.813,0,0,1,66.194,27.522,17.813,17.813,0,0,1,47.621,46.095ZM8,85.84V74.2a13.626,13.626,0,0,1,2.353-8.048,15.1,15.1,0,0,1,6.067-5.076A93.067,93.067,0,0,1,32.33,55.5a64.317,64.317,0,0,1,15.291-1.857,61.612,61.612,0,0,1,15.229,1.919A105.473,105.473,0,0,1,78.7,61.077a14.928,14.928,0,0,1,6.191,5.076A13.626,13.626,0,0,1,87.243,74.2V85.84Zm7.429-7.429H79.814V74.2a6.783,6.783,0,0,0-1.176-3.776,7.455,7.455,0,0,0-2.91-2.662A65.748,65.748,0,0,0,61.241,62.5a64.06,64.06,0,0,0-13.62-1.424A65.8,65.8,0,0,0,33.878,62.5a62.887,62.887,0,0,0-14.487,5.262A7.45,7.45,0,0,0,15.429,74.2ZM47.621,38.666A10.8,10.8,0,0,0,58.765,27.522,10.8,10.8,0,0,0,47.621,16.379,10.8,10.8,0,0,0,36.478,27.522,10.8,10.8,0,0,0,47.621,38.666ZM47.621,27.522ZM47.621,78.411Z' transform='translate(5137 -1133.95)'/%3E%3C/g%3E%3C/svg%3E",
  userAvatarInitials: '',
  userAvatarBackgroundColor: 'grey',
  avatarSize: '30px',

  sendBoxBackground: 'white',
  sendBoxBorderTop: '#f5f5f5',
  sendBoxTextColor: 'black',
  sendBoxPlaceholderColor: 'grey',
  sendBoxHeight: '50px',
};
