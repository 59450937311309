import styled, { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

const handleValuesBySite = props => {
  const { website } = props;
  const sites = {
    'london-marathon': {
      thumbsIconZIndex: '-1',
      desktopButtonPaddingLeft: '44px',
      svgThumbsLeftPosition: '15px',
      mobileThumbsIconDisplay: 'none',
      mobileThumbsIconPosition: 'absolute',
      mobileThumbsIconLeftPosition: '0',
    },
    'the-big-half': {
      thumbsIconZIndex: '0',
      desktopButtonPaddingLeft: '50px',
      svgThumbsLeftPosition: '20px',
      mobileThumbsIconDisplay: 'unset',
      mobileThumbsIconPosition: 'absolute',
      mobileThumbsIconLeftPosition: '0',
    },
    'london-10k': {
      thumbsIconZIndex: '-1',
      desktopButtonPaddingLeft: '44px',
      svgThumbsLeftPosition: '15px',
      mobileThumbsIconDisplay: 'unset',
      mobileThumbsIconPosition: 'absolute',
      mobileThumbsIconLeftPosition: '0',
    },
    'westminster-mile': {
      thumbsIconZIndex: '1',
      desktopButtonPaddingLeft: '44px',
      svgThumbsLeftPosition: '15px',
      mobileThumbsIconDisplay: 'unset',
      mobileThumbsIconPosition: 'absolute',
      mobileThumbsIconLeftPosition: '0',
    },
    'swim-serpentine': {
      thumbsIconZIndex: '0',
      desktopButtonPaddingLeft: '55px',
      svgThumbsLeftPosition: '25px',
      mobileThumbsIconDisplay: 'unset',
      mobileThumbsIconPosition: 'absolute',
      mobileThumbsIconLeftPosition: '0',
    },
    'ride-london': {
      thumbsIconZIndex: '0',
      desktopButtonPaddingLeft: '55px',
      desktopButtonPaddingRight: '30px',
      svgThumbsLeftPosition: '15px',
      mobileThumbsIconDisplay: 'unset',
      mobileThumbsIconPosition: 'absolute',
      mobileThumbsIconLeftPosition: '0',
    },
    'city-race': {
      thumbsIconZIndex: '0',
      desktopButtonPaddingLeft: '55px',
      desktopButtonPaddingRight: '30px',
      svgThumbsLeftPosition: '15px',
      mobileThumbsIconDisplay: 'unset',
      mobileThumbsIconPosition: 'absolute',
      mobileThumbsIconLeftPosition: '-10px',
    },
    'lm-foundation': {
      thumbsIconZIndex: '1',
      desktopButtonPaddingLeft: '55px',
      desktopButtonPaddingRight: '30px',
      svgThumbsLeftPosition: '30px',
      mobileThumbsIconDisplay: 'unset',
      mobileThumbsIconPosition: 'absolute',
      mobileThumbsIconLeftPosition: '10px',
    },
  };

  if (website in sites) {
    return sites[website];
  }

  return sites['london-marathon'];
};

export const FAQVotingComponent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 52px);
  margin: 0 0 ${props => props.theme.space(props.spaceMultiplierMobile)};
  padding: ${props => props.theme.px(26)};
  background-color: ${props => CM(props.theme).backgroundColor};

  ${breakpoints.tabletLandscape} {
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 122px);
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
    padding: ${props => props.theme.px(40)};
  }

  h1 {
    ${props => props.theme.setFont('faqVotingQuestion')}
    margin-bottom: ${props => props.theme.px(26)};

    ${breakpoints.tabletLandscape} {
      margin: 0;
    }
  }
`;

export const VoteWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;

  ${breakpoints.tabletLandscape} {
    align-items: center;
    width: unset;
    margin-right: ${props => props.theme.px(26)};
  }

  button {
    position: relative;
    display: flex;
    font-weight: normal;

    > span {
      border-color: transparent;
    }

    &:not(:first-child) {
      ${breakpoints.tabletLandscape} {
        margin-left: ${props => props.theme.px(85)};
      }
    }

    &:hover {
      filter: none;

      > span {
        border-color: ${props => CM(props.theme)?.optionTextBorderColor};
      }

      svg {
        &:first-of-type {
          path:not(:first-child) {
            fill: ${props => CM(props.theme).svgFillColourA};
          }
        }

        &:last-of-type {
          path:first-child {
            fill: ${props => CM(props.theme).svgFillColourC};
          }

          path:not(:first-child) {
            fill: ${props => CM(props.theme).svgFillColourB};
          }
        }
      }

      span {
        color: ${props => CM(props.theme).optionTextColor};
        background-color: ${props => CM(props.theme).optionTextBackgroundColor};
      }
    }

    svg {
      &:first-of-type {
        position: ${props => handleValuesBySite(props.theme).mobileThumbsIconPosition};
        top: ${props => (props.theme.website == 'lm-foundation' ? 5 : 17)}px;
        left: ${props => handleValuesBySite(props.theme).mobileThumbsIconLeftPosition};
        width: 22px;
        height: 20px;
        transform: scale(1);

        ${breakpoints.tabletLandscape} {
          left: ${props => handleValuesBySite(props.theme).svgThumbsLeftPosition};
        }
      }

      &:last-of-type {
        display: ${props => handleValuesBySite(props.theme).mobileThumbsIconDisplay};

        path {
          &:first-child {
            fill: transparent;
          }

          &:not(:first-child) {
            fill: ${props => CM(props.theme).svgLastOfTypeFill};
          }
        }
        ${props =>
          props.theme.website == 'lm-foundation'
            ? css`
                top: 10px;
              `
            : css`
                ${breakpoints.tabletLandscape} {
                  position: absolute;
                  right: -28px;
                  z-index: ${props => handleValuesBySite(props.theme).thumbsIconZIndex};
                  display: inline-block;
                }
              `}
      }
    }

    span {
      display: flex;
      align-items: center;
      height: 55px;
      ${props =>
        props.theme.website == 'lm-foundation'
          ? css`
              font-size: 16px;
              line-height: 24px;
              padding-top: 10px !important;
              padding-bottom: 10px !important;
            `
          : css`
              font-size: 18px;
              line-height: 29px;
              padding-left: 30px;
            `}
      background-color: transparent;
      pointer-events: none;

      ${breakpoints.tabletLandscape} {
        padding-left: ${props => handleValuesBySite(props.theme).desktopButtonPaddingLeft};
        padding-right: ${props => handleValuesBySite(props.theme).desktopButtonPaddingRight};
      }
    }

    &.inactive {
      span {
        color: ${props => CM(props.theme).inactiveTextColor};
      }

      svg:first-of-type {
        path:not(:first-child) {
          fill: ${props => CM(props.theme).inactiveSvgColor};
        }
      }
    }
  }
`;

export const VoteConfirmedWrap = styled.div`
  display: flex;
  align-items: center;

  p {
    ${props => props.theme.setFont('faqVotingConfirmedText')}
    display: flex;
    align-items: center;
    height: 55px;
    margin: ${props => `0 ${props.theme.px(26)} 0 0`};
    color: ${props => CM(props.theme).voteConfirmedColor};
    font-weight: bold;
    opacity: 0;
  }

  svg {
    width: 24px;
    height: 24px;
    transform: translateX(-200px);
  }

  &.animate-icon {
    svg {
      transform: translateX(0);
      transition: transform 0.7s ease-in;
    }
  }
  &.animate-text {
    p {
      opacity: 1;
      transition: opacity 0.3s ease-in;
    }
  }
`;
