import React from 'react';
import PropTypes from 'prop-types';
import VisualCardsComponent from 'lmel-components/core/visual-cards';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import ErrorBoundary from 'lmel-components/core/error-boundary';

const VisualCards = props => {
  return (
    <ErrorBoundary>
      <VisualCardsComponent translate={props.t} {...props} />
    </ErrorBoundary>
  );
};

VisualCards.propTypes = {
  fields: PropTypes.object,
  t: PropTypes.func,
};

export default withSitecoreContext()(withTranslation()(VisualCards));
