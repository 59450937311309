import { colors } from './colors';

const createLinearGradient = (fromColor, toColor) =>
  `linear-gradient(0deg, ${fromColor} 0%, ${toColor} 100%)`;

export const gradients = {
  pink: createLinearGradient(colors.primary[100], colors.primary[100]),
  orange: createLinearGradient(colors.primary[200], colors.primary[200]),
  green: createLinearGradient(colors.secondary[200], colors.secondary[200]),
  violet: createLinearGradient(colors.secondary[300], colors.secondary[300]),
};
