export const mapColourToSiteTheme = theme => {
  const sites = {
    'london-marathon': {
      controlsText: theme.colors.primary[200],
      controlsButtonFill: theme.colors.primary[200],
      cardHeading: theme.colors.primary[300],
      cardContentText: theme.colors.primary[300],
      cardLink: theme.colors.primary[300],
      cardLinkIconFill: theme.colors.primary[300],
      swipeText: theme.colors.grays[500],
      swipeTextIconFill: theme.colors.grays[500],
    },
    'the-big-half': {
      controlsText: theme.colors.secondary[400],
      controlsButtonFill: theme.colors.secondary[400],
      cardHeading: theme.colors.primary[400],
      cardContentText: theme.colors.primary[400],
      cardLink: theme.colors.primary[400],
      cardLinkIconFill: theme.colors.primary[400],
      swipeText: theme.colors.grays[100],
      swipeTextIconFill: theme.colors.grays[100],
    },
    'london-10k': {
      controlsText: theme.colors.primary[200],
      controlsButtonFill: theme.colors.primary[200],
      cardHeading: theme.colors.primary[300],
      cardContentText: theme.colors.primary[300],
      cardLink: theme.colors.primary[300],
      cardLinkIconFill: theme.colors.primary[300],
      swipeText: theme.colors.primary[200],
      swipeTextIconFill: theme.colors.secondary[200],
    },
    'swim-serpentine': {
      controlsText: theme.colors.secondary[400],
      controlsButtonFill: theme.colors.secondary[400],
      cardHeading: theme.colors.primary[400],
      cardContentText: theme.colors.primary[400],
      cardLink: theme.colors.primary[400],
      cardLinkIconFill: theme.colors.primary[400],
      swipeText: theme.colors.grays[100],
      swipeTextIconFill: theme.colors.primary[200],
    },
    'westminster-mile': {
      controlsText: theme.colors.primary[200],
      controlsButtonFill: theme.colors.primary[200],
      cardHeading: theme.colors.primary[300],
      cardContentText: theme.colors.primary[300],
      cardLink: theme.colors.primary[300],
      cardLinkIconFill: theme.colors.primary[300],
      swipeText: theme.colors.primary[200],
      swipeTextIconFill: theme.colors.primary[100],
    },
    'ride-london': {
      controlsText: theme.colors.primary[300],
      controlsButtonFill: theme.colors.primary[300],
      cardHeading: theme.colors.primary[400],
      cardContentText: theme.colors.primary[400],
      cardLink: theme.colors.primary[400],
      cardLinkIconFill: theme.colors.primary[400],
      swipeText: theme.colors.primary[300],
      swipeTextIconFill: theme.colors.primary[300],
    },
    'city-race': {
      controlsText: theme.colors.primary[300],
      controlsButtonFill: theme.colors.primary[300],
      cardHeading: theme.colors.primary[400],
      cardContentText: theme.colors.primary[400],
      cardLink: theme.colors.primary[500],
      cardLinkIconFill: theme.colors.primary[500],
      swipeText: theme.colors.primary[300],
      swipeTextIconFill: theme.colors.primary[300],
    },
    'lm-foundation': {
      controlsText: theme.colors.primary[200],
      controlsButtonFill: theme.colors.primary[200],
      cardHeading: theme.colors.primary[300],
      cardContentText: theme.colors.primary[300],
      cardLink: theme.colors.secondary[700],
      cardLinkIconFill: theme.colors.secondary[700],
      swipeText: theme.colors.primary[200],
      swipeTextIconFill: theme.colors.primary[200],
    },
  };
  if (theme.website in sites) return sites[theme.website];
  return sites['london-marathon'];
};
