import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  HelpListComponent,
  HelpSectionContainer,
  HelpSection,
  TitleContainer,
  Title,
  LoadMore,
  NoResults,
} from './HelpList.styles.js';
import CategoryFilter from '../category-filter';
import EditFrame from '../edit-frame';
import Signpost from '../signpost';
import CTA from '../cta/CTA';
import { useIsMobile } from '../hooks/useIsMobile';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { getIcon } from '../utils/icon';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/react-hooks';
import InfiniteScroll from 'react-infinite-scroller';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { withTheme } from 'styled-components';
import { handleCtaMappingByTheme } from '../cta/ctaMapper';

const FETCH_FAQS = loader('./FetchFAQs.graphql');

const HelpList = props => {
  const {
    fields: { filters = [], sections = [] } = {},
    translate = {},
    params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
    theme: { website },
  } = props;

  const isMobile = useIsMobile('tabletLandscape', -1);
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);
  const [currentFilterPage, setCurrentFilterPage] = useState(0);
  const [FAQList, setFAQList] = useState([]);
  const [totalFilterResults, setTotalFilterResults] = useState(0);
  const [allFilterDisplayAll, setAllFilterDisplayAll] = useState(false);
  const [noResults, setNoResults] = useState(false);
  const [filterInteractionHasOccured, setFilterInteractionHasOccured] = useState(false);
  const [fetchFAQs, { loading, error, data }] = useLazyQuery(FETCH_FAQS, { fetchPolicy: 'no-cache' });
  const allFilterSectionsToShow = 3;

  const t = key => {
    if (translate && typeof translate === 'function') {
      return translate(`HelpList_${key}`);
    }
    return key;
  };

  const filterResultsOffsetRef = useRef();
  const categoryFilterRef = useRef();

  const resultsPerRequest = 10;
  const allFilterLabel = t('AllFilter');
  const loadMoreLabel = t('LoadMore');
  const noResultsLabel = t('NoResults');

  const filterLabels = filters.map(filter => filter?.title?.value);
  filterLabels.unshift(allFilterLabel);

  const filterIds = filters.map(filter => filter?.id);
  filterIds.unshift('');

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  const handleFilterChange = index => {
    if (index === selectedFilterIndex) {
      return;
    }
    dataLayerEvent('faqFilter', {
      clickText: filterLabels[index],
    });
    setNoResults(false);
    setFilterInteractionHasOccured(true);
    setAllFilterDisplayAll(false);
    setCurrentFilterPage(0);
    setTotalFilterResults(0);
    setFAQList([]);
    setSelectedFilterIndex(index);
  };

  useEffect(() => {
    if (selectedFilterIndex > 0) {
      fetchFAQs({
        variables: {
          tags: [filterIds[selectedFilterIndex]],
          resultCount: resultsPerRequest,
          resultStartIndex: (currentFilterPage * resultsPerRequest).toString(),
        },
      });
    }
  }, [selectedFilterIndex, currentFilterPage]);

  useEffect(() => {
    if (!loading) {
      if (data?.faqs?.results?.items?.length) {
        setNoResults(false);
        const newFAQList = [...FAQList, ...data.faqs.results.items];
        setFAQList(newFAQList);
      } else {
        setNoResults(true);
        setFAQList([]);
      }
      setTotalFilterResults(data?.faqs?.results?.totalCount || 0);
    }
  }, [data]);

  useEffect(() => {
    if (currentFilterPage === 0 && filterInteractionHasOccured) {
      const height = categoryFilterRef?.current?.getBoundingClientRect().height || 0;
      if (filterResultsOffsetRef?.current) {
        filterResultsOffsetRef.current.style.top = `-${height}px`;
        filterResultsOffsetRef.current.scrollIntoView();
      }
    }
  }, [FAQList]);

  const handleLoadMore = () => {
    setCurrentFilterPage(currentFilterPage + 1);
  };

  const handleLoadMoreAllFilter = () => {
    setAllFilterDisplayAll(true);
  };

  const showLoadMore = () => {
    if (totalFilterResults < resultsPerRequest) return false;

    if (selectedFilterIndex === 0 && !allFilterDisplayAll) {
      return true;
    }

    if (Math.ceil(totalFilterResults / resultsPerRequest) - 1 > currentFilterPage) return true;
  };

  const determineLoadMoreFn = () => {
    const doCheck = () => {
      if (selectedFilterIndex === 0 && !allFilterDisplayAll) {
        handleLoadMoreAllFilter();
      } else if (showLoadMore()) {
        handleLoadMore();
      }
    };

    if (selectedFilterIndex === 0 && sections.length <= allFilterSectionsToShow) {
      doCheck();
    } else {
      setTimeout(doCheck, 1000);
    }
  };

  const renderAllFilterSection = () => {
    return (
      <>
        {selectedFilterIndex === 0 &&
          sections?.length > 0 &&
          sections.map((section, i) => {
            if ((i < allFilterSectionsToShow && !allFilterDisplayAll) || allFilterDisplayAll) {
              return (
                <EditFrame
                  itemId={section.id}
                  header="Edit Help Section"
                  commandDisplayName="Help Section"
                  fields={['title', 'cta', 'ctaTheme', 'items']}
                  key={i}
                >
                  <HelpSection>
                    <TitleContainer>
                      <Title>
                        <Text field={section.fields.title} />
                      </Title>
                      {!isMobile && section?.fields?.cta?.value?.href && (
                        <CTA
                          type="a"
                          variant={handleCtaMappingByTheme(website, section?.fields?.ctaTheme?.value)}
                          label={section?.fields?.cta?.value?.text}
                          ariaLabel={section?.fields?.cta?.value?.text}
                          link={section?.fields?.cta?.value}
                          target={section?.fields?.cta?.value?.target}
                        />
                      )}
                    </TitleContainer>
                    {section?.fields?.items?.map((item, j) => (
                      <Signpost
                        key={j}
                        url={item?.url}
                        title={item?.fields?.question?.value}
                        summary={item?.fields?.answer?.value}
                      />
                    ))}
                    {isMobile && section?.fields?.cta?.value?.href && (
                      <CTA
                        fullwidth={1}
                        type="a"
                        variant={handleCtaMappingByTheme(website, section?.fields?.ctaTheme?.value)}
                        label={section?.fields?.cta?.value?.text}
                        ariaLabel={section?.fields?.cta?.value?.text}
                        link={section?.fields?.cta?.value}
                        target={section?.fields?.cta?.value?.target}
                      />
                    )}
                  </HelpSection>
                </EditFrame>
              );
            }
          })}
      </>
    );
  };

  return (
    <HelpListComponent
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      <CategoryFilter
        className="help-list-filter"
        filterFn={handleFilterChange}
        filterLabels={filterLabels}
        t={t}
        selectedIndex={selectedFilterIndex}
        ref={categoryFilterRef}
        applyStickyBorder
      />
      <HelpSectionContainer>
        <div className="offset" ref={filterResultsOffsetRef}></div>
        <InfiniteScroll
          pageStart={0}
          loadMore={determineLoadMoreFn}
          hasMore={showLoadMore()}
          threshold={0}
          initialLoad={false}
          loader={
            <LoadMore key={0} onClick={handleLoadMore}>
              {getIcon('refresh')} {loadMoreLabel}
            </LoadMore>
          }
        >
          {renderAllFilterSection()}
          {selectedFilterIndex > 0 && !loading && (
            <HelpSection>
              {FAQList?.map((item, j) => (
                <Signpost
                  key={j}
                  url={item?.url}
                  title={item?.fields?.question}
                  summary={item?.fields?.answer}
                />
              ))}
              {noResults && <NoResults>{noResultsLabel}</NoResults>}
            </HelpSection>
          )}
        </InfiniteScroll>
      </HelpSectionContainer>
    </HelpListComponent>
  );
};

HelpList.propTypes = {
  fields: PropTypes.object,
  translate: PropTypes.any,
  params: PropTypes.object,
  theme: PropTypes.object,
};

export default withTheme(HelpList);
