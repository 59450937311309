import React from 'react';
import PropTypes from 'prop-types';
import HeroImpactComponent from 'lmel-components/core/hero-impact';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const HeroImpact = props => {
  return (
    <ErrorBoundary>
      <HeroImpactComponent translate={props.t} {...props} />
    </ErrorBoundary>
  );
};

HeroImpact.propTypes = {
  t: PropTypes.func,
};

export default withSitecoreContext()(withTranslation()(HeroImpact));
