import styled from 'styled-components';
import { breakpoints } from '../../../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const HeadingWrap = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: ${props => (props.hasTag ? 0 : props.theme.px(19))};

  ${breakpoints.tabletLandscape} {
    margin-top: 0;
  }
`;

export const ContentWrap = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${breakpoints.tabletLandscape} {
    max-width: ${props => (props.readingTimeVisibleCondition ? '70%' : 'unset')};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${props => props.theme.setFont('articleTitle')}
    margin-bottom: ${props => props.theme.px(16)};
    color: ${props => CM(props.theme, props.gradient).titleColor};

    ${breakpoints.tabletLandscape} {
      font-size: 21px;
      line-height: 29px;
    }
  }
`;

export const ReadTimeWrapper = styled.div`
  position: absolute;
  right: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: ${props => CM(props.theme, props.gradient).readTimeColor};
  line-height: 50px;
  text-align: center;
  background: rgba(0, 0, 0, 0.21);
  border: ${({ theme, gradient, hasBgImg }) => `1px solid ${CM(theme, gradient, hasBgImg).borderColor}`};
  border-radius: 50%;

  ${breakpoints.tabletLandscape} {
    right: 27px;
    bottom: 30px;
    background: ${props => (props.hasBgImg ? 'rgba(0, 0, 0, 0.21)' : 'transparent')};
  }
`;

export const EventWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.px(17)};

  p {
    margin-right: ${props => props.theme.px(8)};

    ${breakpoints.tabletPortrait} {
      margin-right: 0;
    }
  }

  > div {
    display: none;

    ${breakpoints.tabletLandscape} {
      display: flex;
    }
  }
`;

export const ReadTimeNumber = styled.span`
  ${props => props.theme.setFont('articleReadTimeNumber')}
  display: block;
  margin-bottom: 3px;
  color: ${props => CM(props.theme, props.gradient).readTimeNumberColor};
`;

export const ReadTimeText = styled.p`
  ${props => props.theme.setFont('articleReadTimeText')}
  margin-bottom: 0;
`;

export const EventDateWrapper = styled.span`
  ${props => props.theme.setFont('articleEventDate')}
  display: block;
  color: ${props => CM(props.theme, props.gradient).eventColor};
`;

export const TextContrastGradient = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: ${props => (props.pageEditing ? 0 : 1)};
  width: 100%;
  height: ${props => (props.pageEditing ? '0' : '80%')};
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
`;

export const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${props => props.theme.gradients[props.gradient]};
`;
