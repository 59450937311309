import React from 'react';
import PropTypes from 'prop-types';
import {
  ClickDimensionsFormComponent,
  FormWrap,
  FormContainer,
  FormFrame,
  Panel,
} from './ClickDimensionsForm.styles.js';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import Empty from '../empty';

const ClickDimensionsForm = props => {
  const {
    fields: { url: { value: url = '' } = {}, panelDescription = {}, panelTitle = {}, title = {} } = {},
    rendering: { uid = '' } = {},
    params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
    sitecoreContext: { pageEditing = false } = {},
  } = props;

  const formTitleId = `ClickDimensionsTitle_${uid}`;

  return (
    (url && (
      <ClickDimensionsFormComponent
        className="limitedWidth"
        aria-labelledby={formTitleId}
        spaceMultiplierMobile={spaceMultiplierMobile}
        spaceMultiplierDesktop={spaceMultiplierDesktop}
      >
        <Text field={title} tag="h1" id={formTitleId} />
        <FormWrap>
          <FormContainer>
            <FormFrame src={url} allowtransparency={true} frameBorder="0" />
          </FormContainer>
          <Panel>
            <Text field={panelTitle} tag="h2" />
            <RichText field={panelDescription} />
          </Panel>
        </FormWrap>
      </ClickDimensionsFormComponent>
    )) ||
    (pageEditing && !url && <Empty message="No url specified" />)
  );
};

ClickDimensionsForm.propTypes = {
  fields: PropTypes.object,
  rendering: PropTypes.object,
  params: PropTypes.object,
  sitecoreContext: PropTypes.object,
};

export default ClickDimensionsForm;
