import React from 'react';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import DynamicsFormComponent from 'lmel-components/core/dynamics-form';

const DynamicsForm = props => {
  return (
    <ErrorBoundary>
      <DynamicsFormComponent {...props} />
    </ErrorBoundary>
  );
};

export default DynamicsForm;
