import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { getIcon } from '../../utils/icon';
import { CardContainer, CardLink, LinkList } from './Card.styles';
import { isServer } from '@sitecore-jss/sitecore-jss';

const Card = props => {
  const { item, receiveFocus = false, closeFn = () => {} } = props;
  const cardRef = useRef(null);

  useEffect(() => {
    if (cardRef.current) {
      cardRef.current.focus();
    }
  }, []);

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  const cardClick = (text = '', url = '') => {
    dataLayerEvent('navSubMenu', {
      clickText: item?.title?.value.replace(`'`, ''),
      clickURL: url,
    });
    closeFn();
  };

  if (item.type === 'Card') {
    return (
      <CardContainer
        ref={receiveFocus ? cardRef : null}
        backgroundimage={item?.image?.value?.src}
        href={item?.link?.href}
        target={item?.link?.linktype === 'external' ? '_blank' : '_self'}
        tabIndex="0"
        onClick={() => cardClick(item?.link?.value, item?.link?.href)}
      >
        <CardLink>
          <span>{item?.title?.value}</span>
          {getIcon('arrowLong')}
        </CardLink>
      </CardContainer>
    );
  } else if (item.type === 'LinkList') {
    return (
      <LinkList>
        {item?.links?.map((link, i) => (
          <li key={i}>
            <a
              href={link?.href}
              target={link?.linktype === 'external' ? '_blank' : '_self'}
              ref={receiveFocus && i === 0 ? cardRef : null}
              tabIndex="0"
              onClick={() => cardClick(link?.text, link?.href)}
            >
              <span>{link?.text}</span>
              {getIcon('arrowLong')}
            </a>
          </li>
        ))}
      </LinkList>
    );
  }
};

Card.propTypes = {
  item: PropTypes.object,
  receiveFocus: PropTypes.bool,
  closeFn: PropTypes.func,
};

export default Card;
