import { breakpoints } from '../../global-styles/breakpoints';

const checkZero = (val, fallbackVal) => {
  if (val == '0') {
    return 0;
  } else if (!val) {
    return fallbackVal;
  } else {
    return val;
  }
};

export const setFont = props => styleName => {
  const { px, typography } = props;
  const {
    fontSize = 14,
    type = 'body',
    lineHeight = 14,
    letterSpacing = 0,
    textTransform = '',
    fontWeight,
  } = typography.styles[styleName];

  return `
    font-size: ${px(checkZero(fontSize.mobile, fontSize))};
    font-family: ${typography.typefaces[type?.mobile || type]};
    line-height: ${px(checkZero(lineHeight.mobile, lineHeight))};
    letter-spacing: ${px(checkZero(letterSpacing.mobile, letterSpacing))};
    text-transform: ${textTransform};
    ${
      fontWeight
        ? `
          font-weight: ${fontWeight?.mobile ? fontWeight.mobile : fontWeight};
        `
        : ''
    };


    ${breakpoints.tabletPortrait} {
      font-size: ${px(checkZero(fontSize.tablet, fontSize))};
      font-family: ${typography.typefaces[type?.tablet || type]};
      line-height: ${px(checkZero(lineHeight.tablet, lineHeight))};
      letter-spacing: ${px(checkZero(letterSpacing.tablet, letterSpacing))};
      text-transform: ${textTransform};
      ${
        fontWeight
          ? `
            font-weight: ${fontWeight?.tablet ? fontWeight.tablet : fontWeight};
          `
          : ''
      };
    }

    ${breakpoints.desktopSmall} {
      font-size: ${px(checkZero(fontSize.desktopSmall, fontSize))};
      font-family: ${typography.typefaces[type?.desktopSmall || type]};
      line-height: ${px(checkZero(lineHeight.desktopSmall, lineHeight))};
      letter-spacing: ${px(checkZero(letterSpacing.desktopSmall, letterSpacing))};
      text-transform: ${textTransform};
      ${
        fontWeight
          ? `
            font-weight: ${fontWeight?.desktopSmall ? fontWeight.desktopSmall : fontWeight};
          `
          : ''
      };
    }

    ${breakpoints.desktopLarge} {
      font-size: ${px(checkZero(fontSize.desktopLarge, fontSize))};
      font-family: ${typography.typefaces[type?.desktopLarge || type]};
      line-height: ${px(checkZero(lineHeight.desktopLarge, lineHeight))};
      letter-spacing: ${px(checkZero(letterSpacing.desktopLarge, letterSpacing))};
      text-transform: ${textTransform};
      ${
        fontWeight
          ? `
            font-weight: ${fontWeight?.desktopLarge ? fontWeight.desktopLarge : fontWeight};
          `
          : ''
      };
    }
  `;
};
