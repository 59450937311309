import styled from 'styled-components';
import { breakpoints } from '../../../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const HeadingWrap = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const ContentWrap = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  ${breakpoints.tabletLandscape} {
    position: relative;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${props => props.theme.setFont('articleTitleLarge')}
    margin-bottom: 0;
    color: ${({ theme, gradient }) => CM(theme, gradient).titleColor};

    ${breakpoints.tabletLandscape} {
      max-width: ${props => (props.readingTimeVisibleCondition ? '50%' : '95%')};
      font-size: 30px;
      line-height: 39px;
    }
  }
`;

export const ReadTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: ${({ theme, gradient }) => CM(theme, gradient).readTimeColor};
  line-height: 50px;
  text-align: center;
  background: ${props => (props.hasBgImg ? 'rgba(0, 0, 0, 0.47)' : 'transparent')};
  border: ${({ theme, gradient }) => `1px solid ${CM(theme, gradient).borderColor}`};
  border-radius: 50%;
`;

export const EventWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.px(17)};

  ${breakpoints.tabletLandscape} {
    position: absolute;
    right: 0px;
    bottom: -17px;
  }

  p {
    margin-right: ${props => props.theme.px(8)};

    ${breakpoints.tabletLandscape} {
      margin-right: 0;
    }
  }

  > span {
    display: block;
    margin-right: ${props => props.theme.px(20)};
  }
`;

export const ReadTimeNumber = styled.span`
  ${props => props.theme.setFont('articleReadTimeNumber')}
  display: block;
  margin-bottom: 3px;
  color: ${({ theme, gradient }) => CM(theme, gradient).readTimeNumberColor};
`;

export const ReadTimeText = styled.p`
  ${props => props.theme.setFont('articleReadTimeText')}
  margin-bottom: 0;
`;

export const EventDateWrapper = styled.span`
  ${props => props.theme.setFont('articleEventDate')}
  display: block;
  color: ${({ theme, gradient }) => CM(theme, gradient).eventColor};
`;

export const CTAWrap = styled.div`
  width: 100%;
  margin-top: ${props => props.theme.px(13)};

  > button {
    span {
      width: ${props => (props.theme.website === 'london-marathon' ? 'calc(100% - 55px)' : '100%')};
      ${breakpoints.tabletLandscape} {
        width: auto;
      }
    }
  }
`;

export const TextContrastGradient = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: ${props => (props.pageEditing ? 0 : 1)};
  width: 100%;
  height: ${props => (props.pageEditing ? '0' : '80%')};
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
`;

export const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${props => props.theme.gradients[props.gradient]};
`;
