import styled from 'styled-components';

export const MobileCarouselComponent = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const SlideContainer = styled.ul`
  display: flex;
  width: calc(
    ${props => props.slideCount} * 80% + (${props => props.slideCount + props.theme.space(1, true)}px)
  );
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style-type: none;
  transform: translateX(0px);
  transition-timing-function: ease-out;
  transition-property: transform;

  li {
    width: 80%;
    margin: 0 ${props => props.theme.space(0.5)};
  }
`;
