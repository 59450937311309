import styled, { css } from 'styled-components';
import { RichText } from '@sitecore-jss/sitecore-jss-react';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

// Make sure to look at the bottom of this file and create logo styles if necessary

export const siteSpecificStyles = theme => {
  const sites = {
    'london-marathon': {
      enterBtnMarginLeft: '0',
      enterBtnMarginRight: '0',
    },
    'the-big-half': {
      enterBtnMarginLeft: 'auto',
      enterBtnMarginRight: '15px',
    },
    'london-10k': {
      enterBtnMarginLeft: '15px',
      enterBtnMarginRight: '15px',
    },
    'westminster-mile': {
      enterBtnMarginLeft: 'auto',
      enterBtnMarginRight: '15px',
    },
    'swim-serpentine': {
      enterBtnMarginLeft: 'auto',
      enterBtnMarginRight: '15px',
    },
    'city-race': {
      enterBtnMarginLeft: '15px',
      enterBtnMarginRight: '15px',
    },
  };
  if (theme.website in sites) return sites[theme.website];
  return sites['london-marathon'];
};

const headerHeight = {
  desktop: 80,
  mobile: 60,
  mobileSmall: 44,
};

export const HeaderComponent = styled.header`
  position: relative;
  z-index: 101;
  padding: 0;
  background-color: ${props => CM(props.theme).headerBackground};
  transition: transform 0.3s ease-in-out;
  visibility: ${props => (props.isMounted ? 'visible' : 'hidden')};

  &.fixed {
    position: fixed;
    top: 0px;
  }

  ~ main > *:first-child:not(.headerComponentClass):not(.sub-navigation) {
    margin-top: ${props => props.theme.space()};

    ${breakpoints.tabletLandscape} {
      margin-top: 0;
    }
  }
`;

export const UtilityBar = styled.div`
  ${props => props.theme.setFont('utilityBar')}
  position: relative;
  display: grid;
  grid-column: 1 / -1;
  padding: ${props => props.theme.px(5)};
  color: ${props => CM(props.theme).utilityColor};
  background-color: ${props => CM(props.theme).utilityBackground};

  ${breakpoints.tabletLandscape} {
    padding: ${props => props.theme.px(5)} ${props => props.theme.px(61)};
  }

  a {
    color: ${props => CM(props.theme).utilityLinkColor};
    text-decoration: underline;
    cursor: pointer;
  }

  ul {
    margin: 0;
    padding: 0;
    text-align: right;
    list-style-type: none;

    li {
      position: static;
      display: inline-block;
      margin: 0 0 0 ${props => props.theme.space()};
      padding: 0;
      text-indent: 0;

      &:before {
        display: none;
      }

      a {
        text-decoration: none;
      }
    }
  }
`;

export const AlertText = styled(RichText)`
  grid-column: 1 / -1;
  text-align: center;
  ${props =>
    props.theme.website == 'lm-foundation'
      ? 'padding: 10px;'
      : css`
          ${breakpoints.tabletLandscape} {
            position: absolute;
            top: 5px;
            left: 0;
          }

          ${breakpoints.desktopSmall} {
            position: absolute;
            top: 5px;
            left: 50%;
            transform: translateX(-50%);
          }
        `}
`;

export const NavBar = styled.nav`
  position: relative;
  display: flex;
  grid-column: 1 / -1;
  justify-content: space-between;
  height: ${props => props.theme.px(headerHeight.mobile)};
  margin: ${props => props.theme.px(5)} ${props => props.theme.px(8)};

  ${breakpoints.tabletLandscape} {
    justify-content: normal;
    height: ${props => props.theme.px(headerHeight.desktop)};
    margin: ${props => props.theme.px(5)} ${props => props.theme.px(61)};
  }

  ${breakpoints.desktopExtraLarge} {
    justify-content: space-between;
  }

  > a {
    display: flex;
    align-items: center;

    ${breakpoints.tabletLandscape} {
      margin-right: ${props => props.theme.space()};
    }

    ${breakpoints.desktopSmall} {
      margin-right: ${props => props.theme.space(2)};
    }

    ${breakpoints.desktopMedium} {
      margin-right: ${props => props.theme.space(3)};
    }

    // to prevent navigation content wrapping to next line - Ride London
    @media only screen and (max-width: 1150px) and (min-width: 1024px) {
      margin-right: ${props => props.theme.px(16)};
    }

    // to prevent navigation content wrapping to next line - LMF
    @media only screen and (max-width: 1350px) and (min-width: 1280px) {
      margin-right: ${props => props.theme.px(16)};
    }

    ${props => siteSpecificLogoStyles(props.theme)}
  }

  > ul {
    ${props => props.theme.setFont('navigation')}
    display: none;
    height: ${props => props.theme.px(headerHeight.mobile)};
    margin: 0 auto;
    padding: 0;
    line-height: ${props => props.theme.px(headerHeight.mobile)};
    list-style-type: none;

    ${breakpoints.tabletLandscape} {
      display: block;
      height: ${props => props.theme.px(headerHeight.desktop)};
      line-height: ${props => props.theme.px(headerHeight.desktop)};
    }

    li {
      position: relative;
      top: 0;
      left: 0;
      display: inline-block;
      height: 100%;
      margin: 0 0 0 ${props => props.theme.space(2)};
      padding: 0;
      line-height: ${props => props.theme.px(headerHeight.mobile)};
      text-indent: 0;

      ${breakpoints.tabletLandscape} {
        margin: 0 0 0 ${props => props.theme.space(1)};
        line-height: ${props => props.theme.px(headerHeight.desktop)};
      }

      ${breakpoints.desktopSmall} {
        margin: 0 0 0 ${props => props.theme.space(2)};
      }

      // to prevent navigation content wrapping to next line - Ride London
      @media only screen and (max-width: 1150px) and (min-width: 1024px) {
        margin: 0 0 0 ${props => props.theme.px(16)};
      }

      // to prevent navigation content wrapping to next line - LMF
      @media only screen and (max-width: 1350px) and (min-width: 1280px) {
        margin: 0 0 0 ${props => props.theme.px(16)};
      }

      &:before {
        display: none;
      }

      &:first-child {
        margin-left: 0;
      }

      button,
      a {
        display: block;
        height: 100%;
        margin: 0;
        padding: 0;
        color: ${props => CM(props.theme).navLinkColor};
        font-weight: 700;
        letter-spacing: 2px;
        text-transform: uppercase;
        text-decoration: none;
        background: none;
        border: none;
        cursor: pointer;
        appearance: none;

        &.open,
        &:hover {
          &:after {
            position: absolute;
            left: 0px;
            width: 100%;
            bottom: -5px;
            height: 2px;
            margin: 0;
            padding: 0;
            background-color: ${props => CM(props.theme).navLinkAfterBackground};
            content: '';
          }
        }
      }

      /* stylelint-disable-next-line */
      a {
        &.open,
        &:hover {
          &:after {
            bottom: -4px;
          }
        }
      }
    }
  }
`;

export const ButtonContainer = styled.div`
  position: relative;
  display: flex;
  margin-left: ${props => (props.enterCtaVisible ? '0' : 'auto')};

  ${breakpoints.desktopExtraLarge} {
    margin-left: 0;
  }
`;

export const SearchButton = styled.button`
  ${props => {
    if (props.website == 'lm-foundation') {
      return css`
        cursor: pointer;
        margin: auto;
        padding: 0;
        height: 50px;
        width: 50px;
        border: none;
        border-radius: 50px;
        background-color: ${props.theme.colors.secondary[600]};
        transition: all 0.12s;
        :hover {
          background-color: ${props.theme.colors.secondary[700]};
        }

        svg {
          margin: 13px;
        }
        path + path {
          fill: ${props.theme.colors.primary[300]};
        }
      `;
    } else {
      return css`
        margin: 0;
        padding: 0;
        background: none;
        border: 0;
        cursor: pointer;
      `;
    }
  }}
`;

export const Hamburger = styled.button`
  width: 24px;
  margin: 0 0 0 ${props => props.theme.px(4)};
  padding: 0;
  background: none;
  border: none;
  cursor: pointer;
  appearance: none;

  ${({ theme }) =>
    theme.website == 'lm-foundation' ? breakpoints.desktopSmall : breakpoints.tabletLandscape} {
    display: none;
  }
`;

export const MobileNav = styled.div`
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: ${props => CM(props.theme).mobileNavBackground};

  ul {
    display: block;
    height: auto;
    margin: 0;
    padding: ${props => props.theme.space()};
    list-style-type: none;

    ${breakpoints.tabletLandscape} {
      padding: ${props => props.theme.space()} ${props => props.theme.px(61)};
    }

    &.utilityLinks {
      padding-top: 0;
      background-color: ${props => CM(props.theme).utilityLinksBackground};

      &:before {
        display: block;
        margin-bottom: ${props => props.theme.space()};
        border-bottom: 1px solid ${props => CM(props.theme).utilityLinksBorder};
        content: '';
      }
    }

    li {
      display: block;
      margin: 0 0 ${props => props.theme.space()} 0;
      padding: 0;

      button,
      a {
        ${props => props.theme.setFont('h8')}
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        height: auto;
        padding: 0;
        color: ${props => CM(props.theme).mobileLinkColor};
        font-weight: 700;
        text-decoration: none;
        background: none;
        border: 0;
        cursor: pointer;

        svg {
          width: 30px;
          margin: 0;
          padding: 0;
          transform: scale(1.25);
          fill: ${props => CM(props.theme).mobileLinkSvgColor};
        }
      }

      a {
        ${props => props.theme.setFont('h8')}
        color: ${props => CM(props.theme).mobileLinkColor};
        font-weight: 300;
        text-decoration: none;
      }

      &:last-child {
        margin: 0;
      }
    }

    &:first-child li a {
      font-weight: 700;
    }
  }
`;

export const MobileSubMenuHeader = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 0 ${props => props.theme.space()};
  padding: ${props => props.theme.space()} 0;
  border-bottom: 1px solid ${props => CM(props.theme).mobileSubMenuBorder};

  h1 {
    ${props => props.theme.setFont('h8')}
    position: absolute;
    left: 50%;
    margin: 0;
    color: ${props => CM(props.theme).mobileSubMenuTitleColor};
    font-weight: 700;
    line-height: 1.5rem;
    transform: translateX(-50%);

    &:focus {
      outline: none;
    }
  }

  button {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    cursor: pointer;

    svg {
      margin: 0 10px 0 0;
      padding: 0;
      transform: scale(1.25) rotate(180deg);
      fill: ${props => CM(props.theme).mobileSubMenuSvgColor};
    }
  }
`;

export const DesktopNav = styled.div`
  position: absolute;
  top: ${headerHeight.desktop + 5}px;
  left: -61px;
  z-index: 500;
  width: calc(100% + 122px);
  padding: ${props => props.theme.space()} 61px;
  overflow-y: auto;
  overflow-x: hidden;
  background-color: ${props => CM(props.theme).desktopNavBackgroundColor};
  border-top: 1px solid ${props => CM(props.theme).headerBorder};

  button + ul:only-of-type {
    li {
      a {
        ${props =>
          props.textOnlySubNavs &&
          css`
            display: flex;
            justify-content: flex-start;
            gap: 1rem;
          `}
      }
    }
  }

  button ~ ul {
    li {
      &:hover {
        a {
          color: ${props => CM(props.theme).navLinkAfterBackground};
          transition: transform 0.5s ease;
        }
        svg {
          fill: ${props => CM(props.theme).navLinkAfterBackground};
          position: static;
          transition: transform 0.5s ease;
        }
      }
    }
  }

  > div {
    display: grid;
    grid-column-gap: ${props => props.theme.space()};
    grid-template-columns: repeat(${props => props.columns}, calc(25% - 20px));
    justify-content: center;
  }

  > div:has(button + ul:only-of-type) {
    ${props =>
      props.textOnlySubNavs &&
      css`
        display: block;
      `}
  }
`;

export const CloseDesktopMenuButton = styled.button`
  position: absolute;
  top: 0px;
  left: 0px;
`;

export const EnterButtonContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${props => siteSpecificStyles(props.theme).enterBtnMarginRight};
  margin-left: ${props => siteSpecificStyles(props.theme).enterBtnMarginLeft};

  ${({ theme }) =>
    theme.website == 'lm-foundation'
      ? css`
          display: none;

          ${breakpoints.desktopSmall} {
            display: flex;
          }
        `
      : ''}

  ${breakpoints.mobile} {
    margin-right: ${props => props.theme.px(4)};
  }

  ${breakpoints.tabletPortrait} {
    margin-right: ${props => props.theme.px(20)};
    margin-left: auto;
  }

  @media only screen and (max-width: 1150px) and (min-width: 1024px) {
    margin-right: ${props => props.theme.px(16)};
  }

  @media only screen and (max-width: 1350px) and (min-width: 1280px) {
    margin-right: ${props => props.theme.px(12)};
  }

  > a {
    ${props => siteSpecificButtonStyles(props.theme)}
  }
`;

///////////////// BUTTON STYLES /////////////////

const siteSpecificButtonStyles = props => {
  const { website } = props;

  switch (website) {
    case 'london-marathon':
      return LMEEnterBtnStyles;
    case 'the-big-half':
      return BigHalfEnterBtnStyles;
    case 'london-10k':
      return London10kEnterBtnStyles;
    case 'westminster-mile':
      return WestminsterMileEnterBtnStyles;
    case 'swim-serpentine':
      return SwimSerpentineEnterBtnStyles;
    case 'ride-london':
      return RideLondonEnterBtnStyles;
    case 'city-race':
      return CityRaceEnterBtnStyles;
    default:
      return LMEEnterBtnStyles;
  }
};

const defaultBtnStyles = css`
  span {
    height: 45px;
    padding: 0 20px;
    font-size: 12px;
    line-height: 45px;

    ${breakpoints.mobile} {
      padding: 0 25px;
    }
  }
`;

// Styling the enter button on Big half
const BigHalfEnterBtnStyles = css`
  ${defaultBtnStyles}
`;

// Styling the enter button on 10k half
const London10kEnterBtnStyles = css`
  ${defaultBtnStyles}

  span {
    min-width: unset;
    padding: 0 15px;
    line-height: 40px;

    ${breakpoints.tabletLandscape} {
      min-width: 60px;
    }

    ${breakpoints.desktopSmall} {
      min-width: 100px;
    }
  }
`;

// Styling the enter button on WM
const WestminsterMileEnterBtnStyles = css`
  ${defaultBtnStyles}
`;

// Styling the enter button on Swim serpentine
const SwimSerpentineEnterBtnStyles = css`
  ${defaultBtnStyles}

  span {
    font-size: 14px;

    ${breakpoints.tabletPortrait} {
      font-size: 16px;
    }

    ${breakpoints.desktopSmall} {
      font-size: 18px;
    }
  }
`;

const RideLondonEnterBtnStyles = css`
  ${defaultBtnStyles}

  span {
    font-size: 14px;
    padding: 0 25px;

    svg {
      display: none;
    }

    ${breakpoints.tabletPortrait} {
      font-size: 16px;
    }

    ${breakpoints.desktopSmall} {
      font-size: 18px;
    }
  }
`;

const CityRaceEnterBtnStyles = css`
  ${defaultBtnStyles}

  span {
    font-size: 14px;
    padding: 0 17px;

    svg {
      display: none;
    }

    ${breakpoints.tabletPortrait} {
      padding: 0 25px;
      font-size: 16px;
    }

    ${breakpoints.desktopSmall} {
      font-size: 18px;
    }
  }
`;

// Styling the enter button on LME
const LMEEnterBtnStyles = css`
  span {
    z-index: 1;
    padding: 0 15px;

    @media screen and (max-width: 320px) {
      height: ${props => (props.enterCtaVisible ? '42px' : '55px')};
      line-height: ${props => (props.enterCtaVisible ? '42px' : '55px')};
    }

    ${breakpoints.desktopSmall} {
      padding: 0 37px;
    }
  }

  svg {
    &:first-child {
      display: none;
    }

    &:last-child {
      position: absolute;
      right: -12px;

      @media screen and (max-width: 320px) {
        right: -5px;
        height: ${props => (props.enterCtaVisible ? '42px' : '55px')};
      }
    }
  }
`;

///////////////// LOGO STYLES /////////////////

const siteSpecificLogoStyles = props => {
  const { website } = props;

  switch (website) {
    case 'london-marathon':
      return LMELogoStyles;
    case 'the-big-half':
      return BigHalfLogoStyles;
    case 'london-10k':
      return London10kLogoStyles;
    case 'westminster-mile':
      return WestminsterMileLogoStyles;
    case 'swim-serpentine':
      return SwimSerpentineLogoStyles;
    case 'ride-london':
      return RideLondonLogoStyles;
    case 'city-race':
      return CityRaceLogoStyles;
    case 'lm-foundation':
      return LMFoundationLogoStyles;
    default:
      return LMELogoStyles;
  }
};

const defaultLogoStyles = css`
  img {
    height: ${props => props.theme.px(headerHeight.mobile)};

    ${breakpoints.tabletLandscape} {
      height: ${props => props.theme.px(headerHeight.desktop)};
    }
  }
`;

// Styling the logo on LME
const LMELogoStyles = css`
  img {
    height: ${props =>
      props.enterCtaVisible ? props.theme.px(headerHeight.mobileSmall) : props.theme.px(headerHeight.mobile)};
    ${breakpoints.mobile} {
      height: ${props => props.theme.px(headerHeight.mobile)};
    }

    ${breakpoints.tabletLandscape} {
      height: ${props => props.theme.px(headerHeight.desktop)};
    }
  }
`;

// Styling the logo on Big half
const BigHalfLogoStyles = css`
  ${defaultLogoStyles}
`;

const London10kLogoStyles = css`
  img {
    height: ${props => props.theme.px(45)};

    ${breakpoints.mobile} {
      height: ${props => props.theme.px(headerHeight.mobile)};
    }

    ${breakpoints.tabletLandscape} {
      height: ${props => props.theme.px(headerHeight.desktop)};
    }
  }
`;

const WestminsterMileLogoStyles = css`
  ${defaultLogoStyles}
`;

const SwimSerpentineLogoStyles = css`
  ${defaultLogoStyles}
`;

const RideLondonLogoStyles = css`
  ${defaultLogoStyles}
  img {
    margin-right: ${props => props.theme.px(4)};
    @media only screen and (max-width: 1150px) and (min-width: 1024px) {
      height: ${props => props.theme.px(60)};
    }
  }
`;

const CityRaceLogoStyles = css`
  img {
    height: ${props => props.theme.px(45)};

    ${breakpoints.mobile} {
      height: ${props => props.theme.px(headerHeight.mobile)};
    }

    ${breakpoints.tabletLandscape} {
      height: ${props => props.theme.px(headerHeight.desktop)};
    }
  }
`;

const LMFoundationLogoStyles = css`
  img {
    height: ${props => props.theme.px(40)};

    ${breakpoints.tabletLandscape} {
      height: ${props => props.theme.px(50)};
    }
  }
`;
