import { colors } from './colors';

const w = colors.primary[300];
const b = colors.primary[200];

export const buttonVariants = {
  default: [w, colors.secondary[700]],
  whiteA: [b, w],
  blackA: [w, colors.secondary[600]],
  socialIcon: [w, b],
  // Sort out below
  whiteB: [b, w],
  whiteC: [b, w],
  whiteE: [b, w],
  whiteF: [w, colors.secondary[700]],
  blackB: [b, w],
  blackC: [b, w],
  blackD: [b, w],
  blackE: [b, w],
  blackF: [colors.secondary[900], colors.secondary[1000]],
};

export const buttonHoverVariants = {
  default: [b, colors.secondary[600]],
  whiteA: [b, colors.secondary[700]],
  blackA: [w, colors.secondary[700]],
  socialIcon: [w, b],
  // Sort out below
  whiteB: [b, w],
  whiteC: [b, w],
  whiteE: [b, w],
  whiteF: [colors.secondary[900], w],
  blackB: [colors.secondary[700], colors.secondary[1100]],
  blackC: [b, w],
  blackD: [b, w],
  blackE: [b, w],
  blackF: [colors.secondary[900], w],
};
