import React from 'react';
import PropTypes from 'prop-types';
import ArticleListComponent from 'lmel-components/core/article-list';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import { withTranslation } from 'react-i18next';

const ArticleList = props => {
  return (
    <ErrorBoundary>
      <ArticleListComponent translate={props.t} {...props} />
    </ErrorBoundary>
  );
};

ArticleList.propTypes = {
  t: PropTypes.func,
};

export default withTranslation()(ArticleList);
