import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { DynamicsFormComponent, FormWrap, FormContainer, Panel } from './DynamicsForm.styles.js';
import { Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';

const DynamicsForm = props => {
  const {
    fields: {
      code = {},
      treatAsEnquiry = {},
      marketingList = {},
      eventName = {},
      productName = {},
      panelDescription = {},
      panelTitle = {},
      title = {},
    } = {},
    rendering: { uid = '' } = {},
    params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
  } = props;

  const dynamicsFormId = `DynamicsForm_${uid}`;
  const formTitleId = `DynamicsFormTitle_${uid}`;
  let mountTime;
  let loadInterval;

  const renderForm = () => {
    const se = new SurveyEmbed(
      code?.value,
      'https://customervoice.microsoft.com/',
      'https://mfpembedcdnweu.azureedge.net/mfpembedcontweu/',
      'true'
    );

    const context = {
      'First Name': '',
      'Last Name': '',
      locale: '',
      TreatAsEnquiry: treatAsEnquiry.toString(),
      MarketingList: marketingList.value,
      EventName: eventName.value,
      ProductName: productName.value,
    };

    se.renderInline(dynamicsFormId, context);
  };

  useEffect(() => {
    mountTime = new Date().getTime();
    if (!isServer()) {
      loadInterval = setInterval(() => {
        if (typeof SurveyEmbed !== 'undefined') {
          clearInterval(loadInterval);
          renderForm();
        } else {
          const nowTime = new Date().getTime();
          if (nowTime - mountTime >= 120000) {
            clearInterval(loadInterval);
          }
        }
      }, 100);
    }

    return () => {
      clearInterval(loadInterval);
    };
  }, []);

  return (
    <DynamicsFormComponent
      className="limitedWidth"
      aria-labelledby={formTitleId}
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      <Text field={title} tag="h1" id={formTitleId} />
      <FormWrap>
        <FormContainer id={dynamicsFormId} />
        <Panel>
          <Text field={panelTitle} tag="h2" />
          <RichText field={panelDescription} />
        </Panel>
      </FormWrap>
    </DynamicsFormComponent>
  );
};

DynamicsForm.propTypes = {
  fields: PropTypes.object,
  rendering: PropTypes.object,
  params: PropTypes.object,
};

export default DynamicsForm;
