import styled, { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const AnchorMenuComponent = styled.nav`
  position: sticky;
  top: 0;
  z-index: 50;
  width: 100%;
  height: ${({ theme }) => (theme.website !== 'lm-foundation' ? 52 : 80)}px;
  margin: calc(${props => props.theme.space(-1)} - 1px) 0 ${props => props.theme.space(props.spaceMultiplierMobile)} 0;
  overflow-x: hidden;
  background-color: ${props => CM(props.theme).backgroundColor};

  ${breakpoints.tabletLandscape} {
    width: calc(100% - 122px);
    margin: calc(${props => props.theme.space(-1)} - 1px) ${props => props.theme.px(61)}
      ${props => props.theme.space(props.spaceMultiplierDesktop)};
    padding: 0;
  }
`;

export const AnchorLinksContainer = styled.ul`
  display: flex;
  align-items: ${props => (props.mobileOverflow ? 'initial' : 'center')};
  justify-content: ${props => (props.mobileOverflow ? 'initial' : 'flex-start')};
  width: ${props => (props.mobileOverflow ? '100%' : 'auto')};
  height: 100%;
  margin: 0;
  padding: 0;
  list-style-type: none;
  transform: translateX(0);
  transition-timing-function: ease-out;
  transition-duration: 0.25s;
  transition-property: transform;

  ${breakpoints.tabletPortrait} {
    align-items: center;
    justify-content: flex-start;
    width: auto;
  }
`;

export const AnchorLink = styled.li`
  ${props => props.theme.setFont('navigation')}
  min-width: fit-content;
  height: 100%;
  padding-left: ${props => props.theme.space()};
  font-weight: 700;
  text-transform: uppercase;
  flex-grow: 1;
  justify-content: center;
  display: flex;

  &:last-child {
    padding-right: ${props => props.theme.space()};
  }

  ${breakpoints.tabletPortrait} {
    margin-right: 0px;
  }

  a {
    display: inline-block;
    height: 100%;
    line-height: ${({ theme }) => (theme.website !== 'lm-foundation' ? 52 : 80)}px;
    text-align: center;
    text-decoration: none;
    transition: all .12s;
    ${({ theme }) =>
      theme.website !== 'lm-foundation'
        ? css`
            &:link,
            &:visited,
            &:active {
              color: ${props => (props.inView ? CM(props.theme).textColorInView : CM(props.theme).textColor)};
              border-bottom: ${props => (props.inView ? `2px solid ${CM(props.theme).borderColor}` : 'none')};
            }

            &:hover {
              ${breakpoints.tabletPortrait} {
                color: ${props => CM(props.theme).hoverColor};
                border-bottom: 2px solid ${props => CM(props.theme).borderColor};
              }
            }
          `
        : css`
            &:link,
            &:visited,
            &:active {
              color: ${props => (props.inView ? CM(props.theme).textColorInView : CM(props.theme).textColor)};
              border-bottom: ${props => (props.inView ? `8px solid ${CM(props.theme).borderColor}` : 'none')};
            }

            &:hover {
              ${breakpoints.tabletPortrait} {
                color: ${props => CM(props.theme).hoverColor};
                border-bottom: 8px solid ${props => CM(props.theme).borderColor};
              }
            }
          `}
        };


    ${breakpoints.tabletPortrait} {
      margin-right: 0;
    }
  }
`;
