export const mapColourToSiteTheme = theme => {
  const { website } = theme;

  const sites = {
    'london-marathon': {
      containerBackground: theme.colors.grays[100],
      colonColor: theme.colors.primary[200],
      countdownSubTitleColor: theme.colors.grays[500],
      countdownTimerColor: theme.colors.primary[200],
      countdownTimerEndedColor: theme.colors.grays[300],
      countdownTimerZeroColor: theme.colors.primary[200],
      countdownTimerZeroColorEnded: theme.colors.grays[300],
      countdownTimerZeroColorFirstType: theme.colors.grays[300],
      countdownTimerLabelColor: theme.colors.primary[200],
      countdownTimerSvgColor: theme.colors.primary[100],
    },
    'the-big-half': {
      containerBackground: theme.colors.grays[500],
      colonColor: theme.colors.secondary[400],
      countdownSubTitleColor: theme.colors.secondary[400],
      countdownTimerColor: theme.colors.secondary[400],
      countdownTimerEndedColor: theme.colors.grays[300],
      countdownTimerZeroColor: theme.colors.secondary[400],
      countdownTimerZeroColorEnded: theme.colors.grays[300],
      countdownTimerZeroColorFirstType: theme.colors.grays[300],
      countdownTimerLabelColor: theme.colors.secondary[400],
      countdownTimerSvgColor: theme.colors.primary[100],
    },
    'london-10k': {
      containerBackground: theme.colors.grays[500],
      colonColor: theme.colors.primary[200],
      countdownSubTitleColor: theme.colors.primary[200],
      countdownTimerColor: theme.colors.primary[200],
      countdownTimerEndedColor: theme.colors.grays[300],
      countdownTimerZeroColor: theme.colors.primary[200],
      countdownTimerZeroColorEnded: theme.colors.grays[300],
      countdownTimerZeroColorFirstType: theme.colors.grays[300],
      countdownTimerLabelColor: theme.colors.primary[200],
      countdownTimerSvgColor: theme.colors.primary[100],
    },
    'westminster-mile': {
      containerBackground: theme.colors.grays[500],
      colonColor: theme.colors.primary[200],
      countdownSubTitleColor: theme.colors.primary[200],
      countdownTimerColor: theme.colors.primary[200],
      countdownTimerEndedColor: theme.colors.grays[300],
      countdownTimerZeroColor: theme.colors.primary[200],
      countdownTimerZeroColorEnded: theme.colors.grays[300],
      countdownTimerZeroColorFirstType: theme.colors.grays[300],
      countdownTimerLabelColor: theme.colors.primary[200],
      countdownTimerSvgColor: theme.colors.primary[100],
    },
    'swim-serpentine': {
      containerBackground: theme.colors.grays[500],
      colonColor: theme.colors.secondary[400],
      countdownSubTitleColor: theme.colors.secondary[400],
      countdownTimerColor: theme.colors.secondary[400],
      countdownTimerEndedColor: theme.colors.grays[300],
      countdownTimerZeroColor: theme.colors.secondary[400],
      countdownTimerZeroColorEnded: theme.colors.grays[300],
      countdownTimerZeroColorFirstType: theme.colors.grays[300],
      countdownTimerLabelColor: theme.colors.secondary[400],
      countdownTimerSvgColor: theme.colors.primary[200],
    },
    'ride-london': {
      containerBackground: theme.colors.grays[500],
      colonColor: theme.colors.primary[300],
      countdownSubTitleColor: theme.colors.primary[300],
      countdownTimerColor: theme.colors.primary[300],
      countdownTimerEndedColor: theme.colors.grays[300],
      countdownTimerZeroColor: theme.colors.primary[300],
      countdownTimerZeroColorEnded: theme.colors.grays[300],
      countdownTimerZeroColorFirstType: theme.colors.grays[300],
      countdownTimerLabelColor: theme.colors.primary[300],
      countdownTimerSvgColor: theme.colors.primary[200],
    },
    'city-race': {
      containerBackground: theme.colors.grays[500],
      colonColor: theme.colors.primary[300],
      countdownSubTitleColor: theme.colors.primary[300],
      countdownTimerColor: theme.colors.primary[300],
      countdownTimerEndedColor: theme.colors.grays[300],
      countdownTimerZeroColor: theme.colors.primary[300],
      countdownTimerZeroColorEnded: theme.colors.grays[300],
      countdownTimerZeroColorFirstType: theme.colors.grays[300],
      countdownTimerLabelColor: theme.colors.primary[300],
      countdownTimerSvgColor: theme.colors.primary[200],
    },
  };
  if (website in sites) return sites[website];

  return sites['london-marathon'];
};
