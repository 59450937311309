import styled from 'styled-components';
import { mapColourToSiteTheme as CM } from './colourMap.js';
import { breakpoints } from '../global-styles/breakpoints';

export const CtaAltComponent = styled.div`
  border: ${props => `1px solid ${CM(props.theme).ctaBorderColor}`};
  position: relative;
  display: flex;
  flex-basis: 100%;
  justify-content: space-between;
  align-items: center;

  svg {
    transition: transform 0.5s ease;
  }

  &:hover {
    svg {
      transform: translateX(5px);
    }
  }

  a {
    ${props => props.theme.setFont('countdownClockCTA')};
    ${props => (props.small ? `font-size: 16px;` : '')};
    display: block;
    height: 100%;
    width: 100%;
    color: ${props => CM(props.theme).ctaColor};
    padding: ${({ theme, small }) => (small ? theme.px(16) : theme.px(20))};
    font-weight: bold;
    text-decoration: none;
    z-index: 1;

    ${breakpoints.desktopLarge} {
      ${props => (props.small ? `font-size: 21px;` : '')};
      padding: ${({ theme, small }) => (small ? theme.px(18) : theme.px(20))};
    }
  }

  svg {
    position: absolute;
    right: ${props => props.theme.px(10)};
    z-index: 1;

    path {
      &:last-child {
        fill: ${props => CM(props.theme).svgColor};
      }
    }
  }
`;
