import { colors } from './colors';

const w = colors.primary[400];
const b = colors.secondary[400];

// Button hover colours that aren't in the palette
const brinkPink = '#FF6493';
const conifer = '#BDE25A';
const easternBlue = '#1DB1A8';
const minsk = '#3D3580';

export const buttonVariants = {
  default: [b, colors.primary[200]], // Pink
  blackA: [b, colors.primary[100]], // Pink
  blackB: [b, w], // White
  blackC: [b, colors.secondary[100]], // Lime
  whiteA: [w, colors.secondary[200]], // Green
  whiteB: [w, colors.secondary[300]], // Violet
  whiteE: [b, colors.primary[100]], // Used on ArticleListRow
  socialIcon: [w, b],
};

export const buttonHoverVariants = {
  default: [b, brinkPink], // Pink
  blackA: [b, brinkPink], // Pink
  blackB: [b, colors.grays[500]], // White
  blackC: [b, conifer], // Lime
  whiteA: [w, easternBlue], // Green
  whiteB: [w, minsk], // Violet
  whiteE: [b, brinkPink], // ArticleListRow
  socialIcon: [w, b],
};
