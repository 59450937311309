import Actions from './actions';

export function breadthFirst(callback, action) {
  const { forest, isArray } = this.self();
  const queue = [];
  const result = [];
  let found = false;
  let next = () => {};

  const iterate = (element, depth) => {
    const keys = Object.keys(element);

    for (let i = 0; i < keys.length; i++) {
      const prop = element[keys[i]];
      if (Array.isArray(prop)) {
        for (let j = 0; j < prop.length; j++) {
          if (this.isObject(prop[j])) {
            // eslint-disable-next-line no-loop-func
            queue.push(() => next(prop[j], depth + 1, { element: prop, key: j }));
          }
        }
      } else if (this.isObject(prop)) {
        // eslint-disable-next-line no-loop-func
        queue.push(() => next(prop, depth + 1, { element, key: keys[i] }));
      }
    }

    if (queue.length) queue.unshift();
  };

  if (action === Actions.FIND) {
    next = (element, depth, pos) => {
      let value = callback(element, depth, pos);
      if (value) {
        result.push(element);
        found = true;
        return;
      }
      iterate(element, depth);
    };
  } else if (action === Actions.FIND_ALL) {
    next = (element, depth, pos) => {
      let value = callback(element, depth, pos);
      if (value) {
        result.push(element);
      }
      iterate(element, depth);
    };
  } else if (action === Actions.GET) {
    next = (element, depth) => {
      let value = callback(); // () => level
      if (value === depth) {
        result.push(element);
        return;
      }
      iterate(element, depth);
    };
  } else if (action === Actions.REMOVE || action === Actions.REMOVE_ALL) {
    next = (element, depth, pos) => {
      let value = callback(element, depth, pos);
      if (value && pos.element) {
        if (Array.isArray(pos.element)) {
          result.push(pos.element[pos.key]);
          pos.element.splice(pos.key, 1);
        } else {
          result.push({ ...pos.element[pos.key] });
          delete pos.element[pos.key];
        }
        if (action === Actions.REMOVE) {
          found = true;
        }
        return;
      }
      iterate(element, depth);
    };
  } else {
    next = (el, depth, pos) => {
      callback(el, depth, pos);
      iterate(el, depth);
    };
  }

  if (isArray) {
    for (let i = 0; i < forest.length; i++) {
      if (this.isObject(forest[i])) {
        queue.push(() => next(forest[i], 0, { element: forest, key: i }));
      }
    }
  } else if (this.isObject(forest)) {
    queue.push(() => next(forest, 0, {}));
  }

  while (!found && queue.length) {
    queue.shift()();
  }

  return action === Actions.FIND
    ? result[0]
    : action === Actions.FIND_ALL
    ? result
    : action === Actions.GET
    ? result
    : action === Actions.REMOVE
    ? result[0]
    : action === Actions.REMOVE_ALL
    ? result
    : undefined;
}
