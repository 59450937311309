import styled, { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const HeroBasicComponent = styled.section`
  position: relative;
  height: ${props => (props.hasBgImg ? '0px' : '366px')};
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};
  padding-top: ${props => (props.hasBgImg ? 'calc(73 / 75 * 100%)' : '0px')};
  overflow: hidden;
  background-image: ${props => props.theme.gradients[props.gradient]};

  ${breakpoints.tabletLandscape} {
    height: ${props => (props.hasBgImg ? '0px' : '430px')};
    margin: 0 ${props => props.theme.px(61)} ${props => props.theme.space(props.spaceMultiplierDesktop)};
    padding-top: ${props => (props.hasBgImg ? 'calc(43 / 131 * 100%)' : '0px')};
  }

  picture {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  h1 {
    position: absolute;
    bottom: 0px;
    z-index: ${props => (props.pageEditing ? 0 : 1)};
    margin: 0;
    padding: 0 ${props => props.theme.space()} ${props => props.theme.space()};
    color: ${props => CM(props.theme, props.gradient).titleColor};
    ${props => props.theme.setFont('heroBasicHeading')}

    ${breakpoints.tabletLandscape} {
      width: auto;
      padding: 0 0 ${props => props.theme.px(65)} ${props => props.theme.px(65)};
    }
  }
`;

const patternLayout = name => {
  const layout = {
    'twenty-six-degree-mark': {
      desktop: css`
        top: 50%;
        right: ${props => props.theme.space(3)};
        height: 60%;
        transform: translateY(-50%);
      `,
      mobile: css`
        top: 10%;
        right: 10%;
        height: 34%;
      `,
    },
    'the-medal-alt': {
      desktop: css`
        top: 20%;
        right: 0;
        height: 80%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 40%;
      `,
    },
    'the-steps': {
      desktop: css`
        top: 20%;
        right: 0;
        height: 80%;
        transform: rotate(180deg);
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 40%;
        transform: rotate(180deg);
      `,
    },
    'tower-bridge': {
      desktop: css`
        top: 0;
        right: 0;
        height: 100%;
      `,
      mobile: css`
        top: 0;
        right: 0;
        height: 40%;
      `,
    },
    'scattered-circles': {
      desktop: css`
        top: 0%;
        right: 0;
        height: 100%;
        transform: none;
      `,
      mobile: css`
        top: 0%;
        right: 0px;
        height: 50%;
        transform: rotate(180deg);
      `,
    },
    'diagonal-chevrons': {
      desktop: css`
        top: 40%;
        right: -1%;
        height: 65%;
      `,
      mobile: css`
        top: 0%;
        right: -1%;
        height: 40%;
      `,
    },
    'film-reel': {
      desktop: css`
        top: 20%;
        right: 0;
        height: 80%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 50%;
      `,
    },
    'multiple-circles': {
      desktop: css`
        top: -10%;
        right: 0;
        height: 120%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 40%;
      `,
    },
    'parallel-lines': {
      desktop: css`
        top: -50%;
        right: 0;
        height: 200%;
      `,
      mobile: css`
        top: -25%;
        right: 0%;
        height: 100%;
      `,
    },
    peak: {
      desktop: css`
        top: 10%;
        right: 0;
        height: 90%;
      `,
      mobile: css`
        top: 10%;
        right: 0%;
        height: 50%;
      `,
    },
    waves: {
      desktop: css`
        top: 15%;
        right: 0;
        height: 55%;
      `,
      mobile: css`
        top: 10%;
        right: 0%;
        height: 40%;
      `,
    },
    'circle-outlines': {
      desktop: css`
        top: 0%;
        right: 0;
        height: 80%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 55%;
      `,
    },
    'triangle-outlines': {
      desktop: css`
        top: 0%;
        right: 0;
        height: 80%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 55%;
      `,
    },
    power: {
      desktop: css`
        top: 0%;
        right: 0;
        height: 100%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 55%;
      `,
    },
    unity: {
      desktop: css`
        top: 0%;
        right: 0;
        height: 100%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 55%;
      `,
    },
    'shape-of-water-01': {
      desktop: css`
        top: 0%;
        right: 0;
        height: 80%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 50%;
      `,
    },
    'shape-of-water-02': {
      desktop: css`
        top: 0%;
        right: 0;
        height: 80%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 50%;
      `,
    },
    'shape-of-water-03': {
      desktop: css`
        top: 7.5%;
        right: 0;
        height: 80%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 55%;
      `,
    },
    'shape-of-water-04': {
      desktop: css`
        top: 7.5%;
        right: 0;
        height: 80%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 50%;
      `,
    },
    frame: {
      desktop: css`
        top: -3%;
        right: -100px;
        height: 106%;
      `,
      mobile: css`
        top: -2%;
        right: -50px;
        height: 60%;
      `,
    },
    handlebars: {
      desktop: css`
        top: -3%;
        right: 0;
        height: 108%;
      `,
      mobile: css`
        top: -2%;
        right: 0%;
        height: 50%;
      `,
    },
    wheels: {
      desktop: css`
        top: -2%;
        right: -1%;
        height: 107%;
      `,
      mobile: css`
        top: -2%;
        right: 0%;
        height: 60%;
      `,
    },
    'landscape-outlined': {
      desktop: css`
        top: -1%;
        right: -0.5%;
        height: 103%;
      `,
      mobile: css`
        top: -1%;
        right: -1%;
        height: 60%;
      `,
    },
    'on-your-marks': {
      desktop: css`
        top: 7.5%;
        right: 2%;
        height: 80%;
      `,
      mobile: css`
        top: 7.5%;
        right: 7.5%;
        height: 50%;
      `,
    },
    'on-your-marks-alt': {
      desktop: css`
        top: 7.5%;
        right: 2%;
        height: 80%;
      `,
      mobile: css`
        top: 7.5%;
        right: 7.5%;
        height: 50%;
      `,
    },
    'get-set': {
      desktop: css`
        top: 7.5%;
        right: 5%;
        height: 80%;
      `,
      mobile: css`
        top: 5%;
        right: 5%;
        height: 50%;
      `,
    },
    go: {
      desktop: css`
        top: -2%;
        right: 5%;
        height: 105%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 55%;
      `,
    },
    'transformation-full': {
      desktop: css`
        top: -12%;
        right: 0%;
        height: 130%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 70%;
      `,
    },
  };

  if (name in layout) return layout[name];

  return 'twenty-six-degree-mark';
};

export const Pattern = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: ${props => (props.pageEditing ? '50%' : '100%')};
  height: 100%;
  margin-left: ${props => (props.pageEditing ? '50%' : 'unset')};

  svg {
    position: absolute;
    ${props => patternLayout(props.patternName).mobile}
    fill: ${props => props.theme.colors.named[props.patternColor]};

    path,
    g {
      fill: ${props => props.theme.colors.named[props.patternColor]};
    }

    ${breakpoints.tabletLandscape} {
      ${props => patternLayout(props.patternName).desktop}
    }
  }
`;

export const TextContrastGradient = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: ${props => (props.pageEditing ? 0 : 1)};
  width: 100%;
  height: ${props => (props.pageEditing ? '0' : '80%')};
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
`;
