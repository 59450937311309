import * as londonMarathonTheme from './london-marathon';
import * as theBigHalfTheme from './the-big-half';
import * as london10kTheme from './london-10k';
import * as westminsterMileTheme from './westminster-mile';
import * as swimSerpentineTheme from './swim-serpentine';
import * as rideLondonTheme from './ride-london';
import * as cityRaceTheme from './city-race';
import * as lmeAccountTheme from './lme-account';
import * as lmFoundationTheme from './lm-foundation';
import * as utils from './utils';

const generateTheme = props => {
  const baseUnits = props?.baseUnits || 'rem';
  const baseSize = props?.baseSize || 16;
  const website = props?.website || 'london-marathon';
  const spaceSize = props?.spaceSize || 26;
  const websiteThemes = {
    'london-marathon': londonMarathonTheme,
    'the-big-half': theBigHalfTheme,
    'london-10k': london10kTheme,
    'westminster-mile': westminsterMileTheme,
    'swim-serpentine': swimSerpentineTheme,
    'ride-london': rideLondonTheme,
    'city-race': cityRaceTheme,
    'lme-account': lmeAccountTheme,
    'lm-foundation': lmFoundationTheme,
  };

  const pxToBaseUnit = utils.pxToBaseUnit(baseUnits, baseSize);
  const space = utils.spacer(spaceSize, baseUnits, baseSize);
  const setFont = utils.setFont({ typography: websiteThemes[website].typography, px: pxToBaseUnit });

  return {
    ...websiteThemes[website],
    px: pxToBaseUnit,
    space,
    setFont,
    website,
  };
};

export default generateTheme;
