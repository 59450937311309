import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import HelpListComponent from 'lmel-components/core/help-list';
import { withTranslation } from 'react-i18next';

const HelpList = props => {
  return (
    <ErrorBoundary>
      <HelpListComponent translate={props.t} {...props} />
    </ErrorBoundary>
  );
};

HelpList.propTypes = {
  t: PropTypes.func,
};

export default withTranslation()(HelpList);
