import React from 'react';
import VideoEmbedComponent from 'lmel-components/core/video-embed';
import ErrorBoundary from 'lmel-components/core/error-boundary';

const VideoEmbed = props => {
  return (
    <ErrorBoundary>
      <VideoEmbedComponent {...props} />
    </ErrorBoundary>
  );
};

export default VideoEmbed;
