import styled, { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { TagComponentPresentational } from '../tag/Tag.styles';
import { mapColourToSiteTheme as CM } from './colourMap.js';

// Added common svg and common hover styles into variables to try make it a little neater as
// so we can alter if neccessary per site rather than lots of duplication as it got quite complex to handle the SVG states

// Had to add site specific styles using the classname .signpost-${website} so for each rollout due to the SVG states
// Duplicate the .signpost-london-marathon instances and replace with the brand name to begin with swapping the SVG.

const commonSvgStyles = css`
  svg {
    &:first-child {
      position: static;
      transform: scale(1);
      fill: ${props => CM(props.theme).firstIconFill};

      path {
        fill: ${props => CM(props.theme).firstIconFill};
      }

      ${breakpoints.desktopSmall} {
        display: none;
      }
    }

    &:last-child {
      display: none;

      ${breakpoints.desktopSmall} {
        display: block;
        height: 100%;
        margin-left: auto;
        fill: ${props => CM(props.theme).lastIconFill};
      }

      path {
        fill: ${props => CM(props.theme).lastIconPathFill};
      }
    }

    &:nth-child(2) {
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);

      ${breakpoints.tabletLandscape} {
        top: ${props => props.theme.px(29)};
        transform: ${props => (props.hasDescription ? 'translate(-50%, -50%)' : 'translate(-75%, -50%)')};
      }

      ${breakpoints.desktopSmall} {
        right: 5px;
        transform: translate(-100%, -50%);
      }

      path {
        &:last-of-type {
          fill: ${props => CM(props.theme).secondIconPathFill};

          ${breakpoints.desktopSmall} {
            fill: ${props => CM(props.theme).secondIconPathFillDesktop};
          }
        }
      }
    }
  }
`;

const commonHoverStyles = css`
  svg {
    z-index: 1;

    &:first-child {
      fill: ${props => CM(props.theme).signpostHoverIconFill};
    }

    &:last-child {
      display: none;

      ${breakpoints.desktopSmall} {
        display: block;
        height: 100%;
      }

      path {
        fill: ${props => CM(props.theme).signpostHoverIconPathFill};
      }
    }

    &:nth-child(2) {
      transform: translate(-50%, -50%);
      transition: transform 0.5s ease;
      path {
        &:last-of-type {
          fill: ${props => CM(props.theme).signpostHoverIconPathFillB};
        }
      }
    }
  }
`;

export const IconWrap = styled.div`
  display: flex;
  align-items: flex-start;
  /* // You may need to create a site specific instance if this doesn't work */
  ${commonSvgStyles}

  ${breakpoints.tabletLandscape} {
    display: block;
    width: 100%;
    height: 100%;
    height: ${props => (props.hasDescription ? '100%' : '125%')};
  }

  svg {
    &:first-child {
      width: ${props => (props.hasDescription ? '55px' : '45px')};
    }
    &:nth-child(2) {
      top: 30px;
      left: 50%;

      ${breakpoints.tabletLandscape} {
        left: ${({ hasDescription }) => (hasDescription ? '50%' : 'calc(50% + 7px)')};
      }

      ${breakpoints.desktopSmall} {
        left: unset;
      }

      path:not(:first-child) {
        fill: ${props => CM(props.theme).secondIconPathFill};

        ${breakpoints.desktopSmall} {
          fill: ${props => CM(props.theme).secondIconPathFillDesktop};
        }
      }
    }
  }

  &.signpost-london-marathon {
    ${commonSvgStyles};

    svg {
      &:first-child {
        width: ${props => (props.hasDescription ? '73px' : '70px')};
      }
      &:nth-child(2) {
        left: ${props => (props.hasDescription ? 'calc(50% + 7px)' : 'calc(50% + 9px)')};

        ${breakpoints.tabletLandscape} {
          right: ${props => (props.hasDescription ? '5px' : '-8px')};
          left: unset;
        }

        ${breakpoints.desktopSmall} {
          right: ${props => (props.hasDescription ? '5px' : '0')};
        }
      }
    }
  }
`;

export const ImageWrap = styled.div`
  display: none;

  ${breakpoints.desktopSmall} {
    display: block;

    img {
      display: block;
      width: auto;
      height: 100%;
      max-height: 150px;
    }
  }
`;

export const SignpostComponent = styled.li`
  position: relative;
  display: block;
  margin-bottom: ${props => props.theme.space()};
  padding: ${props =>
    props.hasDescription
      ? props.theme.space(0.75)
      : `${props.theme.space(0.75)} ${props.theme.space(0.75)} 0`};
  overflow: hidden;
  border: 1px solid ${props => CM(props.theme).signpostBorder};

  &:last-child {
    margin-bottom: 0;
  }

  & * {
    text-decoration: none;
  }

  ${breakpoints.desktopSmall} {
    padding: 0;
  }

  a {
    position: relative;
    display: block;
    color: ${props => CM(props.theme).signpostLink};

    ${breakpoints.tabletLandscape} {
      width: 100%;
      height: 100%;
    }
  }

  &:hover {
    filter: none;

    ${IconWrap} {
      /* // If these hover styles don't work for your site you may need to make a custom version using &.signpost-{WEBSITE_NAME} */
      ${commonHoverStyles}
      svg {
        &:nth-child(2) {
          ${breakpoints.tabletLandscape} {
            right: 0;
          }
        }
      }
    }
  }
`;

export const SignpostWrap = styled.div`
  display: block;
  height: 100%;

  ${TagComponentPresentational} {
    margin-bottom: ${props => props.theme.space(0.5)};
    white-space: nowrap;

    ${breakpoints.desktopSmall} {
      margin: ${props => props.theme.space(0.75)} 0 ${props => props.theme.space(0.75)}
        ${props => props.theme.space(0.75)};
    }
  }

  ${breakpoints.desktopSmall} {
    display: flex;
    align-items: flex-start;
    max-width: 75%;
    margin-right: ${props => props.theme.space(6)};
  }

  ${breakpoints.desktopLarge} {
    margin-right: ${props => props.theme.space(5.5)};
  }
`;

export const Content = styled.div`
  z-index: ${props => (props.pageEditing ? '99' : 'auto')};

  ${breakpoints.desktopSmall} {
    padding: ${props => props.theme.space(0.75)};
  }
`;

export const Title = styled.p`
  ${props => props.theme.setFont('signpostHeading')}
  margin-top: 0rem;
  margin-right: ${props => props.theme.space(3)};
  margin-bottom: ${props => (props.hasDescription ? props.theme.space() : props.theme.px(20))};
  font-weight: bold;

  ${breakpoints.desktopSmall} {
    margin-top: 0.3rem;
    margin-right: 0;
    margin-bottom: ${props => props.theme.space(0.5)};
    line-height: ${props => props.theme.px(29)};
  }
`;

export const CTAWrap = styled.div`
  position: absolute;
  top: ${props => props.theme.px(-20)};
  right: ${props => props.theme.px(-19.5)};
  z-index: -1;

  ${breakpoints.desktopSmall} {
    position: absolute;
    top: 0;
    right: 0;
    float: none;
    width: 100%;
    height: 100%;
    margin-left: auto;
  }
`;

export const TextWrap = styled.div`
  ${props => props.theme.setFont('signpostBody')}
  margin: 0;
  color: ${props => CM(props.theme).signpostText};

  a {
    display: inline;
  }
`;

export const ComponentDate = styled.span`
  display: block;
  margin-bottom: 26px;
  color: ${props => CM(props.theme).signpostDate};
  font-size: 14px;
  line-height: 22px;

  ${breakpoints.desktopSmall} {
    margin-bottom: 0;
  }
`;
