import React from 'react';
import PropTypes from 'prop-types';
import { RelatedFaqsComponent, TitleContainer, Title } from './RelatedFaqs.styles.js';
import Signpost from '../signpost';
import CTA from '../cta/CTA';
import { useIsMobile } from '../hooks/useIsMobile';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTheme } from 'styled-components';
import { handleCtaMappingByTheme } from '../cta/ctaMapper';

const RelatedFaqs = props => {
  const {
    fields,
    params: { ctaTheme = 'sponsor-red', spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
    theme: { website },
  } = props;
  const isMobile = useIsMobile('tabletLandscape', -1);

  return (
    <RelatedFaqsComponent
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
      className="limitedWidth"
    >
      <div>
        <TitleContainer>
          {fields?.title && (
            <Title>
              <Text field={fields?.title} />
            </Title>
          )}
          {!isMobile && fields?.cta?.value?.href && (
            <CTA
              type="a"
              variant={handleCtaMappingByTheme(website, ctaTheme)}
              label={fields?.cta?.value?.text}
              ariaLabel={fields?.cta?.value?.text}
              link={fields?.cta?.value}
              target={fields?.cta?.value?.target}
            />
          )}
        </TitleContainer>
        {fields?.items?.map((item, i) => (
          <Signpost key={i} url={item?.url} title={item?.fields?.question} summary={item?.fields?.answer} />
        ))}
        {isMobile && fields?.cta?.value?.href && (
          <CTA
            fullwidth={1}
            type="a"
            variant={handleCtaMappingByTheme(website, ctaTheme)}
            label={fields?.cta?.value?.text}
            ariaLabel={fields?.cta?.value?.text}
            link={fields?.cta?.value}
            target={fields?.cta?.value?.target}
          />
        )}
      </div>
    </RelatedFaqsComponent>
  );
};

RelatedFaqs.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  theme: PropTypes.object,
};

export default withTheme(RelatedFaqs);
