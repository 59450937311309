const colorNames = {
  grey: '#525355',
  blue: '#0473EA',
  green: '#38D200',
  black: '#000000',
  white: '#ffffff',
  jet: '#3c3c3b',
  sonicSilver: '#706f6f',
  silver: '#c7c7c6',
  platinum: '#e3e3e3',
  cultured: '#F5F5F5',
};

export const colors = {
  primary: {
    100: colorNames.grey,
    200: colorNames.blue,
    300: colorNames.black,
    400: colorNames.white,
    500: colorNames.green,
  },
  secondary: {},
  grays: {
    100: colorNames.jet,
    200: colorNames.sonicSilver,
    300: colorNames.silver,
    400: colorNames.platinum,
    500: colorNames.cultured,
  },
  named: {
    grey: colorNames.grey,
    blue: colorNames.blue,
    black: colorNames.black,
    white: colorNames.white,
    green: colorNames.green,
    jet: colorNames.jet,
    'sonic-silver': colorNames.sonicSilver,
    silver: colorNames.silver,
    platinum: colorNames.platinum,
    cultured: colorNames.cultured,
  },
};
