import { colors } from './colors';

const w = colors.primary[300];
const b = colors.primary[200];

export const buttonVariants = {
  default: [b, colors.secondary[200]],
  whiteA: [w, colors.primary[100]],
  whiteB: [w, colors.secondary[200]],
  whiteC: [w, colors.secondary[300]],
  socialIcon: [w, b],
  whiteE: [w, colors.secondary[400]],
  blackA: [b, colors.secondary[100]],
  blackB: [b, colors.secondary[400]],
  blackC: [b, colors.secondary[500]],
  blackD: [b, colors.secondary[200]],
  blackE: [b, colors.secondary[300]],
};
