export const mapColourToSiteTheme = theme => {
  const { website } = theme;

  const sites = {
    'london-marathon': {
      titleColor: theme.colors.primary[300],
      nextPrevButtonBackgroundColor: theme.colors.primary[300],
      nextPrevButtonSvgColor: theme.colors.secondary[200],
      slideTextColor: theme.colors.primary[200],
      slideTextBorderColor: theme.colors.grays[200],
      swipeTextColor: theme.colors.grays[500],
      swipeTextSvgColor: theme.colors.grays[500],
    },
    'the-big-half': {
      titleColor: theme.colors.primary[400],
      nextPrevButtonBackgroundColor: theme.colors.primary[100],
      nextPrevButtonSvgColor: theme.colors.primary[400],
      slideTextColor: theme.colors.secondary[400],
      slideTextBorderColor: theme.colors.grays[400],
      swipeTextColor: theme.colors.grays[100],
      swipeTextSvgColor: theme.colors.grays[100],
    },
    'london-10k': {
      titleColor: theme.colors.primary[300],
      nextPrevButtonBackgroundColor: theme.colors.primary[100],
      nextPrevButtonSvgColor: theme.colors.primary[300],
      slideTextColor: theme.colors.primary[200],
      slideTextBorderColor: theme.colors.grays[400],
      swipeTextColor: theme.colors.primary[200],
      swipeTextSvgColor: theme.colors.secondary[200],
    },
    'westminster-mile': {
      titleColor: theme.colors.primary[300],
      nextPrevButtonBackgroundColor: theme.colors.primary[100],
      nextPrevButtonSvgColor: theme.colors.primary[300],
      slideTextColor: theme.colors.primary[200],
      slideTextBorderColor: theme.colors.grays[400],
      swipeTextColor: theme.colors.primary[200],
      swipeTextSvgColor: theme.colors.primary[100],
    },
    'swim-serpentine': {
      titleColor: theme.colors.primary[400],
      nextPrevButtonBackgroundColor: theme.colors.primary[300],
      nextPrevButtonSvgColor: theme.colors.primary[400],
      slideTextColor: theme.colors.secondary[400],
      slideTextBorderColor: theme.colors.grays[400],
      swipeTextColor: theme.colors.secondary[400],
      swipeTextSvgColor: theme.colors.primary[200],
    },
    'ride-london': {
      titleColor: theme.colors.primary[400],
      nextPrevButtonBackgroundColor: theme.colors.secondary[100],
      nextPrevButtonSvgColor: theme.colors.primary[300],
      slideTextColor: theme.colors.primary[300],
      slideTextBorderColor: theme.colors.grays[400],
      swipeTextColor: theme.colors.primary[300],
      swipeTextSvgColor: theme.colors.primary[300],
    },
    'city-race': {
      titleColor: theme.colors.primary[400],
      nextPrevButtonBackgroundColor: theme.colors.primary[500],
      nextPrevButtonSvgColor: theme.colors.primary[300],
      slideTextColor: theme.colors.primary[300],
      slideTextBorderColor: theme.colors.grays[400],
      swipeTextColor: theme.colors.primary[300],
      swipeTextSvgColor: theme.colors.primary[300],
    },
    'lm-foundation': {
      titleColor: theme.colors.primary[300],
      nextPrevButtonBackgroundColor: '#F2F91C',
      nextPrevButtonSvgColor: theme.colors.primary[200],
      slideTextColor: theme.colors.primary[200],
      slideTextBorderColor: theme.colors.grays[200],
      swipeTextColor: theme.colors.primary[200],
      swipeTextSvgColor: theme.colors.grays[500],
    },
  };
  if (website in sites) return sites[website];

  return sites['london-marathon'];
};
