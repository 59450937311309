import React from 'react';
import PropTypes from 'prop-types';
import { ShowcaseHeroComponent, MainHero, SubHero } from './ShowcaseHero.styles.js';
import CountdownClockLayout from '../countdown-clock';
import ArticleCard from '../article-card';
import Empty from '../empty';

const ShowcaseHero = props => {
  const {
    fields: { countdown = {}, featuredArticle = {}, otherArticles = [] } = {},
    rendering = {},
    params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
    translate = {},
    sitecoreContext = {},
  } = props;

  const hasFeatureArticle = !!featuredArticle?.fields;

  const hasSubBgImg = otherArticles.map(
    article => article.fields?.heroImage?.value && Object.keys(article.fields?.heroImage?.value).length > 0
  );

  return (
    <ShowcaseHeroComponent
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
      className="headerComponentClass"
    >
      {hasFeatureArticle && (
        <>
          {countdown?.fields && (
            <CountdownClockLayout variant="b" rendering={rendering} {...countdown.fields} />
          )}
          <div>
            <MainHero gradient={featuredArticle?.fields?.gradient.value}>
              <ArticleCard
                gtm={{ name: 'blogHero', data: ['cardTitle'] }}
                variant="a"
                headingLevel={1}
                translate={translate}
                sitecoreContext={sitecoreContext}
                {...featuredArticle}
              />
            </MainHero>
            <SubHero hasBgImg={hasSubBgImg.includes(true)}>
              {otherArticles.map((article, index) => (
                <ArticleCard
                  key={index}
                  variant="b"
                  translate={translate}
                  headingLevel={2}
                  sitecoreContext={sitecoreContext}
                  gtm={{ name: 'articleCard', data: ['cardTitle'] }}
                  {...article}
                />
              ))}
            </SubHero>
          </div>
        </>
      )}
      {!hasFeatureArticle && sitecoreContext.pageEditing && <Empty message="No featured article specified" />}
    </ShowcaseHeroComponent>
  );
};

ShowcaseHero.propTypes = {
  fields: PropTypes.shape({
    countdown: PropTypes.object,
    featuredArticle: PropTypes.object,
    otherArticles: PropTypes.array,
  }),
  params: PropTypes.object,
  rendering: PropTypes.object,
  translate: PropTypes.func,
  sitecoreContext: PropTypes.object,
};

export default ShowcaseHero;
