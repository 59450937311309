import styled, { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const ArticleListRowComponent = styled.div`
  padding: 0px;
  /* Grey is the value that gets passed down if it's highlighted (an option in the CMS), 
  the colour may not actually be grey but leaving as the check for now as per BE request */
  background-color: ${props =>
    props.bgColor === 'grey'
      ? CM(props.theme).articleListBackgroundHighlighted
      : CM(props.theme).articleListBackground};

  ${breakpoints.tabletLandscape} {
    padding: 61px;
  }

  ${breakpoints.desktopSmall} {
    padding: 61px 122px;
  }

  .text-contrast-gradient {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 20%, rgba(0, 0, 0, 0) 60%);
  }
`;

export const InnerContainer = styled.div`
  padding: ${props => props.theme.space()} 0;

  ${breakpoints.tabletLandscape} {
    width: 100%;
    padding: 0;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 25px;

  ${breakpoints.tabletLandscape} {
    margin: 0 ${props => (props.indent ? props.theme.space() : '0px')};
    padding: 0px;
  }

  .viewAll {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    align-self: flex-start;
    font-size: ${props => props.theme.px(14)};
    ${props =>
      props.theme.website !== 'lm-foundation'
        ? css`
            svg {
              position: static;
            }
          `
        : ''}
  }
`;

export const Title = styled.h2`
  ${props => props.theme.setFont('articleListRowHeading')}
  margin-bottom: ${props => props.theme.space()};
`;

export const ArticleContainer = styled.div`
  display: block;

  .mobile-carousel-container {
    > * {
      height: 380px;
    }
  }
`;

export const SwipeText = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.theme.setFont('visualCardSwipeMessage')}
  margin-bottom: ${props => props.theme.space()};
  color: ${props => CM(props.theme).swipeTextColor};

  ${breakpoints.tabletLandscape} {
    display: none;
  }

  svg {
    width: 15px;
    margin-right: ${props => props.theme.space(0.5)};
    fill: ${props => CM(props.theme).swipeSvgColor};
  }
`;

export const ArticleCarouselContainer = styled.div``;

export const MobileCTAContainer = styled.div`
  margin: 0 21px;
`;
