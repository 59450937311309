import { colors } from './colors';

export const createLinearGradient = (fromColor, toColor, rotation = 0) =>
  `linear-gradient(${rotation}deg, ${fromColor} 0%, ${toColor} 100%)`;

export const createRadialGradient = (fromColor, toColor) => 
  `radial-gradient(at right top, ${fromColor} 0%, ${toColor} 75%)`;

export const gradients = {
  'summer-solstice': createLinearGradient(colors.primary[100], colors.secondary[100]),
  'summer-solstice-180': createLinearGradient(colors.primary[100], colors.secondary[100], 180),
  preparation: createLinearGradient(colors.secondary[800], colors.secondary[700]), // pink -> purple
  'preparation-180': createLinearGradient(colors.secondary[800], colors.secondary[700], 180), // purple -> pink
  'warm-up': createLinearGradient(colors.secondary[100], colors.secondary[700]),
  'warm-up-180': createLinearGradient(colors.secondary[100], colors.secondary[700], 180),
  'refresh-revitalise': createLinearGradient(colors.secondary[100], colors.secondary[200], 270),
  'refresh-revitalise-180': createLinearGradient(colors.secondary[100], colors.secondary[200], 90),
  'excite-energise': createLinearGradient(colors.secondary[200], colors.secondary[700]),
  'excite-energise-180': createLinearGradient(colors.secondary[200], colors.secondary[700], 180),
  blue: createLinearGradient(colors.secondary[600], colors.secondary[600]),
  'cool-and-calm': createRadialGradient(colors.named['british-blue'], colors.named['offBlack']),
  'hos-warm-and-energetic': createRadialGradient(colors.named['sunshine'], colors.named['lipstick']),
};
