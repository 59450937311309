import { colors } from './colors';

const createLinearGradient = (fromColor, toColor) =>
  `linear-gradient(0deg, ${fromColor} 0%, ${toColor} 100%)`;

export const gradients = {
  'london-skies': createLinearGradient(colors.secondary[200], colors.secondary[300]),
  'golden-grass': createLinearGradient(colors.secondary[100], colors.secondary[500]),
  'winter-blues': createLinearGradient(colors.secondary[400], colors.secondary[200]),
  'summer-solstice': createLinearGradient(colors.primary[100], colors.secondary[100]),
};
