export const mapColourToSiteTheme = theme => {
  const { website } = theme;

  const sites = {
    'london-marathon': {
      breadcrumbColor: theme.colors.primary[200],
    },
    'the-big-half': {
      breadcrumbColor: theme.colors.secondary[400],
    },
    'london-10k': {
      breadcrumbColor: theme.colors.primary[200],
    },
    'swim-serpentine': {
      breadcrumbColor: theme.colors.secondary[400],
    },
    'westminster-mile': {
      breadcrumbColor: theme.colors.primary[200],
    },
    'ride-london': {
      breadcrumbColor: theme.colors.primary[300],
    },
    'city-race': {
      breadcrumbColor: theme.colors.primary[300],
    },
  };
  if (website in sites) return sites[website];

  return sites['london-marathon'];
};
