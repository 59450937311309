import React from 'react';
import PropTypes from 'prop-types';
import { QuoteBlockContainer, QuoteBlockComponent } from './QuoteBlock.styles.js';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import withDatasourceCheck from '../helpers/withDatasourceCheck';

const QuoteBlock = props => {
  const {
    fields: { description = {}, name = {}, quote = {} } = {},
    params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
  } = props;

  return (
    <QuoteBlockContainer
      className="limitedWidth"
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      <QuoteBlockComponent>
        <Text field={quote} tag="p" className="quote" />
        <Text field={name} tag="p" className="name" />
        <Text field={description} tag="p" className="description" />
      </QuoteBlockComponent>
    </QuoteBlockContainer>
  );
};

QuoteBlock.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
};

export default withDatasourceCheck(QuoteBlock);
