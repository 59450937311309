import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import ArticleHeroComponent from 'lmel-components/core/article-hero';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ArticleHero = props => {
  const { sitecoreContext } = props;
  return (
    <ErrorBoundary>
      <ArticleHeroComponent translate={props.t} {...props} sitecoreContext={sitecoreContext} />
    </ErrorBoundary>
  );
};

ArticleHero.propTypes = {
  sitecoreContext: PropTypes.object,
  t: PropTypes.func,
};

export default withSitecoreContext()(withTranslation()(ArticleHero));
