import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import {
  VisualCardsComponent,
  ScrollContainer,
  CardContainer,
  Card,
  Controls,
  TitleContainer,
  SwipeText,
} from './VisualCards.styles.js';
import { Text, Link } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import Picture from '../picture';
import Empty from '../empty';
import { getIcon } from '../utils/icon';

const VisualCards = props => {
  const {
    fields: { items = [], title = {} } = {},
    params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
    sitecoreContext: { pageEditing = false } = {},
    translate = {},
  } = props;

  const cardsPerPage = 3;
  const pageCount = Math.ceil(items.length / cardsPerPage);
  const isMob = isServer() ? true : window.innerWidth < 1024;
  const [isMobile, setIsMobile] = useState(isMob);
  const [cardItems, setCardItems] = useState(
    isMob ? [items[items.length - 2], items[items.length - 1], ...items, items[0], items[1]] : items
  );
  const [currentPage, setCurrentPage] = useState(0);
  const scrollContainerRef = useRef();
  let currentSlide = 1;
  let previousSlide = 1;
  const hasItems = items.length > 0;
  const transitionTime = '0.5s';

  const t = key => {
    if (translate && typeof translate === 'function') {
      return translate(`VisualCards_${key}`);
    }

    return key;
  };

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  const swipeText = t('Swipe');

  const getTranslateX = element => {
    if (!isServer()) {
      const style = window.getComputedStyle(element);
      var matrix = new WebKitCSSMatrix(style.transform);
      return matrix.m41;
    }
  };

  const centerCurrentSlide = (disableTransitionAnimation = false) => {
    if (!isMobile) {
      return;
    }

    const scroller = scrollContainerRef.current;
    const currentCard = scroller.children[currentSlide + 1];

    if (currentCard) {
      const slide = currentCard.getBoundingClientRect();
      const windowMiddle = !isServer() ? window.innerWidth / 2 : 0;
      const tX = getTranslateX(scroller);
      const slideScrollPosition = tX - slide.x + windowMiddle - slide.width / 2;
      scroller.style.transitionDuration = disableTransitionAnimation ? '0s' : transitionTime;
      scroller.style.transform = `translateX(${slideScrollPosition}px)`;
    }
  };

  const onSwipe = amount => {
    previousSlide = currentSlide;
    currentSlide += amount;
    centerCurrentSlide();
  };

  const handleSlideEnd = () => {
    const direction = previousSlide < currentSlide ? 'right' : 'left';

    if (direction === 'right') {
      if (currentSlide === items.length + 1) {
        currentSlide = 1;
        centerCurrentSlide(true);
      }
    } else {
      if (currentSlide === 0) {
        currentSlide = items.length;
        centerCurrentSlide(true);
      }
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => onSwipe(1),
    onSwipedRight: () => onSwipe(-1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: false,
  });

  const handleResize = () => {
    if (!isServer()) {
      if (window.innerWidth < 1024) {
        setIsMobile(true);
        setCardItems([items[items.length - 2], items[items.length - 1], ...items, items[0], items[1]]);
      } else {
        setIsMobile(false);
        setCardItems(items);
      }
    }
  };

  useEffect(() => {
    if (!isServer()) {
      window.addEventListener('resize', handleResize);

      if (isMobile) {
        centerCurrentSlide(true);
      }
    }

    return () => {
      if (!isServer()) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  const renderCard = (item, i) => {
    const cardHeadingId = `VisualCardHeading_${i}_${new Date().getTime()}`;
    const cardTextId = `VisualCardText_${i}_${new Date().getTime()}`;

    if (!item) return;

    return (
      <Card
        aria-roledescription="card"
        tabIndex="0"
        aria-describedby={cardTextId}
        aria-labelledby={cardHeadingId}
        onClick={() =>
          dataLayerEvent('visualCard', {
            cardAction: item?.link?.value?.linktype === 'media' ? 'Download PDF' : 'View More',
            cardTitle: item?.title?.value.replace(`'`, ''),
          })
        }
        pageEditing={pageEditing}
      >
        {item?.image?.value && <Picture decorative field={item.image} />}
        {item?.title?.value && <Text field={item.title} tag="h2" id={cardHeadingId} />}
        <div className="cardContent">
          {item?.description?.value && <Text field={item.description} tag="p" id={cardTextId} />}
          {item?.link?.value && item?.link?.value?.href !== '' && (
            <div className="visualCardLinkContainer">
              {getIcon(`${item?.link?.value?.linktype === 'media' ? 'download' : 'arrowLong'}`)}{' '}
              <Link
                className="visualCardLink"
                field={item?.link}
                target={item?.link?.value?.target === '_blank' ? '_blank' : '_self'}
                rel="noreferrer"
              />
            </div>
          )}
        </div>
      </Card>
    );
  };

  const renderCardContainer = () => {
    if (!isMobile && Object.keys(cardItems)?.length > 0) {
      return cardItems?.map(({ fields = {} }, i) => {
        const pageMin = cardsPerPage * currentPage;
        const pageMax = pageMin + cardsPerPage;
        if (i >= pageMin && i < pageMax) {
          return pageEditing || !fields?.link?.value?.href ? (
            <div key={i}>{renderCard(fields, i)}</div>
          ) : (
            <div
              className={fields?.link?.value?.href ? 'hasHover' : ''}
              key={i}
              onClick={e => {
                e.preventDefault();
                const { target } = fields?.link?.value ?? {};
                const windowTarget = target === '_blank' ? '_blank' : '_self';
                window?.open(fields?.link?.value?.href, windowTarget);
              }}
            >
              {renderCard(fields, i)}
            </div>
          );
        }
      });
    } else {
      return (
        Object.keys(cardItems)?.length > 0 && (
          <li>
            <ScrollContainer ref={scrollContainerRef} onTransitionEnd={handleSlideEnd}>
              {cardItems?.map(({ fields = {} }, i) => {
                return (
                  <div
                    className={fields?.link?.value?.href ? 'hasHover' : ''}
                    key={i}
                    onClick={e => {
                      e.preventDefault();
                      const { target } = fields?.link?.value ?? {};
                      const windowTarget = target === '_blank' ? '_blank' : '_self';
                      window?.open(fields?.link?.value?.href, windowTarget);
                    }}
                  >
                    {renderCard(fields, i)}
                  </div>
                );
              })}
            </ScrollContainer>
          </li>
        )
      );
    }
  };

  const handleKeyPress = e => {
    switch (e.key) {
      case 'ArrowRight':
        changePage(1);
        break;
      case 'ArrowLeft':
        changePage(-1);
        break;
    }
  };

  const changePage = amount => {
    if ((amount === -1 && currentPage > 0) || (amount === 1 && currentPage < pageCount - 1)) {
      setCurrentPage(currentPage + amount);
    }
    if (currentPage + amount > pageCount - 1) {
      setCurrentPage(0);
    } else if (currentPage + amount < 0) {
      setCurrentPage(pageCount - 1);
    }
  };

  const headingId = `VisualCardHeading_${new Date().getTime()}`;

  return (
    <VisualCardsComponent
      onKeyUp={handleKeyPress}
      tabIndex="0"
      aria-roledescription="carousel"
      aria-labelledby={headingId}
      className="limitedWidth"
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      <TitleContainer>
        {title?.value && <Text field={title} tag="h1" id={headingId} />}
        {!isMobile && pageCount > 1 && (
          <Controls>
            <button aria-label="Previous page" onClick={() => changePage(-1)}>
              {getIcon('chevronOutline')}
            </button>
            <p>{`${currentPage + 1} of ${pageCount}`}</p>
            <button aria-label="Next page" onClick={() => changePage(1)}>
              {getIcon('chevronOutline')}
            </button>
          </Controls>
        )}
      </TitleContainer>
      {cardItems?.length && (
        <CardContainer {...swipeHandlers} cardCount={cardItems.length}>
          {renderCardContainer()}
        </CardContainer>
      )}
      {swipeText && isMobile && (
        <SwipeText>
          {getIcon('swipe')} <span>{swipeText}</span>
        </SwipeText>
      )}
      {pageEditing && !hasItems && <Empty message="No items specified" />}
    </VisualCardsComponent>
  );
};

VisualCards.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  sitecoreContext: PropTypes.object,
  translate: PropTypes.any,
};

export default VisualCards;
