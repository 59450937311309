export const pxToBaseUnit = (baseUnits, baseSize) => size => {
  if (typeof size === 'object') {
    const { top, right, bottom, left } = size;

    if ((top !== 0, right !== 0, bottom !== 0, left !== 0) && (!top, !right, !bottom, !left)) {
      throw Error(`
      Unknown or missing size property passed to theme spacing function.
      Please make sure you provide the size in the following format:
      {
        top: number,
        right: number,
        bottom: number,
        left: number
      }
      `);
    }

    if (baseUnits === 'px') {
      return `${top}${baseUnits} ${right}${baseUnits} ${bottom}${baseUnits} ${left}${baseUnits}`;
    }

    return `${top / baseSize}${baseUnits} ${right / baseSize}${baseUnits} ${bottom /
      baseSize}${baseUnits} ${left / baseSize}${baseUnits}`;
  }

  if (baseUnits === 'px') {
    return `${size}${baseUnits}`;
  }

  return `${size / baseSize}${baseUnits}`;
};
