export const mapColourToSiteTheme = (theme) => {
  const { website } = theme;

  const sites = {
    'london-marathon': {
      headerBackground: theme.colors.primary[300],
      headerBorder: theme.colors.grays[200],
      utilityColor: theme.colors.grays[500],
      utilityBackground: theme.colors.grays[100],
      utilityLinkColor: theme.colors.grays[500],
      navLinkColor: theme.colors.primary[200],
      navLinkAfterBackground: theme.colors.primary[100],
      mobileNavBackground: theme.colors.primary[300],
      utilityLinksBackground: theme.colors.primary[300],
      utilityLinksBorder: theme.colors.grays[200],
      mobileLinkColor: theme.colors.primary[200],
      mobileLinkSvgColor: theme.colors.grays[300],
      mobileSubMenuBorder: theme.colors.grays[200],
      mobileSubMenuTitleColor: theme.colors.primary[200],
      mobileSubMenuSvgColor: theme.colors.primary[200],
      desktopNavBackgroundColor: theme.colors.primary[300],
    },
    'the-big-half': {
      headerBackground: theme.colors.primary[400],
      headerBorder: theme.colors.grays[400],
      utilityColor: theme.colors.secondary[400],
      utilityBackground: theme.colors.grays[500],
      utilityLinkColor: theme.colors.secondary[400],
      navLinkColor: theme.colors.secondary[400],
      navLinkAfterBackground: theme.colors.primary[100],
      mobileNavBackground: theme.colors.primary[400],
      utilityLinksBackground: theme.colors.primary[400],
      utilityLinksBorder: theme.colors.grays[300],
      mobileLinkColor: theme.colors.secondary[400],
      mobileLinkSvgColor: theme.colors.grays[300],
      mobileSubMenuBorder: theme.colors.grays[300],
      mobileSubMenuTitleColor: theme.colors.secondary[400],
      mobileSubMenuSvgColor: theme.colors.secondary[400],
      desktopNavBackgroundColor: theme.colors.primary[400],
    },
    'london-10k': {
      headerBackground: theme.colors.primary[300],
      headerBorder: theme.colors.grays[400],
      utilityColor: theme.colors.primary[200],
      utilityBackground: theme.colors.grays[500],
      utilityLinkColor: theme.colors.primary[200],
      navLinkColor: theme.colors.primary[200],
      navLinkAfterBackground: theme.colors.primary[100],
      mobileNavBackground: theme.colors.primary[300],
      utilityLinksBackground: theme.colors.primary[300],
      utilityLinksBorder: theme.colors.grays[400],
      mobileLinkColor: theme.colors.primary[200],
      mobileLinkSvgColor: theme.colors.grays[400],
      mobileSubMenuBorder: theme.colors.grays[400],
      mobileSubMenuTitleColor: theme.colors.primary[200],
      mobileSubMenuSvgColor: theme.colors.primary[200],
      desktopNavBackgroundColor: theme.colors.primary[300],
    },
    'westminster-mile': {
      headerBackground: theme.colors.primary[300],
      headerBorder: theme.colors.grays[400],
      utilityColor: theme.colors.primary[200],
      utilityBackground: theme.colors.grays[500],
      utilityLinkColor: theme.colors.primary[200],
      navLinkColor: theme.colors.primary[200],
      navLinkAfterBackground: theme.colors.primary[100],
      mobileNavBackground: theme.colors.primary[300],
      utilityLinksBackground: theme.colors.primary[300],
      utilityLinksBorder: theme.colors.grays[400],
      mobileLinkColor: theme.colors.primary[200],
      mobileLinkSvgColor: theme.colors.grays[400],
      mobileSubMenuBorder: theme.colors.grays[400],
      mobileSubMenuTitleColor: theme.colors.primary[200],
      mobileSubMenuSvgColor: theme.colors.primary[200],
      desktopNavBackgroundColor: theme.colors.primary[300],
    },
    'swim-serpentine': {
      headerBackground: theme.colors.primary[400],
      headerBorder: theme.colors.grays[400],
      utilityColor: theme.colors.secondary[400],
      utilityBackground: theme.colors.grays[500],
      utilityLinkColor: theme.colors.secondary[400],
      navLinkColor: theme.colors.secondary[400],
      navLinkAfterBackground: theme.colors.primary[100],
      mobileNavBackground: theme.colors.primary[400],
      utilityLinksBackground: theme.colors.primary[400],
      utilityLinksBorder: theme.colors.grays[300],
      mobileLinkColor: theme.colors.secondary[400],
      mobileLinkSvgColor: theme.colors.grays[300],
      mobileSubMenuBorder: theme.colors.grays[300],
      mobileSubMenuTitleColor: theme.colors.secondary[400],
      mobileSubMenuSvgColor: theme.colors.secondary[400],
      desktopNavBackgroundColor: theme.colors.primary[400],
    },
    'ride-london': {
      headerBackground: theme.colors.primary[400],
      headerBorder: theme.colors.grays[400],
      utilityColor: theme.colors.primary[300],
      utilityBackground: theme.colors.grays[500],
      utilityLinkColor: theme.colors.primary[300],
      navLinkColor: theme.colors.primary[300],
      navLinkAfterBackground: theme.colors.primary[100],
      mobileNavBackground: theme.colors.primary[400],
      utilityLinksBackground: theme.colors.primary[400],
      utilityLinksBorder: theme.colors.grays[300],
      mobileLinkColor: theme.colors.primary[300],
      mobileLinkSvgColor: theme.colors.grays[300],
      mobileSubMenuBorder: theme.colors.grays[300],
      mobileSubMenuTitleColor: theme.colors.primary[300],
      mobileSubMenuSvgColor: theme.colors.primary[300],
      desktopNavBackgroundColor: theme.colors.primary[400],
    },
    'city-race': {
      headerBackground: theme.colors.primary[400],
      headerBorder: theme.colors.grays[400],
      utilityColor: theme.colors.primary[300],
      utilityBackground: theme.colors.grays[500],
      utilityLinkColor: theme.colors.primary[300],
      navLinkColor: theme.colors.primary[300],
      navLinkAfterBackground: theme.colors.primary[200],
      mobileNavBackground: theme.colors.primary[400],
      utilityLinksBackground: theme.colors.primary[400],
      utilityLinksBorder: theme.colors.grays[300],
      mobileLinkColor: theme.colors.primary[300],
      mobileLinkSvgColor: theme.colors.grays[300],
      mobileSubMenuBorder: theme.colors.grays[300],
      mobileSubMenuTitleColor: theme.colors.primary[300],
      mobileSubMenuSvgColor: theme.colors.primary[300],
      desktopNavBackgroundColor: theme.colors.primary[400],
    },
    'lm-foundation': {
      headerBackground: theme.colors.primary[300],
      headerBorder: theme.colors.grays[400],
      utilityColor: theme.colors.primary[300],
      utilityBackground: theme.colors.secondary[600],
      utilityLinkColor: theme.colors.primary[300],
      utilityLinksBackground: theme.colors.secondary[600],
      utilityLinksBorder: theme.colors.primary[300],
      navLinkColor: theme.colors.primary[200],
      navLinkAfterBackground: theme.colors.secondary[1100],
      mobileNavBackground: theme.colors.primary[300],
      mobileLinkColor: theme.colors.primary[200],
      mobileLinkSvgColor: theme.colors.primary[200],
      mobileSubMenuBorder: theme.colors.primary[200],
      mobileSubMenuTitleColor: theme.colors.primary[200],
      mobileSubMenuSvgColor: theme.colors.primary[200],
      desktopNavBackgroundColor: theme.colors.primary[300],
    },
  };

  if (website in sites) return sites[website];

  return sites['london-marathon'];
};
