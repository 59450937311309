import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import SearchAndResultsComponent from 'lmel-components/core/search-and-results';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const SearchAndResults = props => {
  const { sitecoreContext } = props;

  return (
    <ErrorBoundary>
      <SearchAndResultsComponent translate={props.t} {...props} sitecoreContext={sitecoreContext} />
    </ErrorBoundary>
  );
};

SearchAndResults.propTypes = {
  sitecoreContext: PropTypes.object,
  t: PropTypes.func,
};

export default withSitecoreContext()(withTranslation()(SearchAndResults));
