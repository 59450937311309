const colorNames = {
  pink: '#f41c5e',
  black: '#000000',
  white: '#ffffff',
  darkPink: '#c60d54',
  grey: '#88888d',
  jet: '#3c3c3b',
  sonicSilver: '#706f6f',
  silver: '#c7c7c6',
  platinum: '#e3e3e3',
  cultured: '#f2f2f2',
};

export const colors = {
  primary: {
    100: colorNames.pink,
    200: colorNames.black,
    300: colorNames.white,
  },
  secondary: {
    100: 'initial',
    200: 'initial',
  },
  grays: {
    100: colorNames.jet,
    200: colorNames.sonicSilver,
    300: colorNames.silver,
    400: colorNames.platinum,
    500: colorNames.cultured,
  },
  named: {
    pink: colorNames.pink,
    white: colorNames.white,
    black: colorNames.black,
    'dark-pink': colorNames.darkPink,
    grey: colorNames.grey,
    jet: colorNames.jet,
    'sonic-silver': colorNames.sonicSilver,
    silver: colorNames.silver,
    platinum: colorNames.platinum,
    cultured: colorNames.cultured,
  },
};
