import styled, { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const ArticleStripComponent = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};

  ${breakpoints.tabletLandscape} {
    flex-direction: row;
    justify-content: space-between;
    width: calc(100% - 122px);
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
  }

  .text-contrast-gradient {
    background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
  }
`;

const articleHeight = props => (props.theme.website == 'lm-foundation' ? '320px' : '380px');

export const ArticleContainer = styled.div`
  display: block;

  .mobile-carousel-container > * {
    height: ${props => articleHeight(props)};
  }

  .article-card-date {
    ${breakpoints.tabletLandscape} {
      font-size: ${props => props.theme.px(14)};
      line-height: ${props => props.theme.px(22)};
    }
  }

  /* Change colour of the read time number specifically for ArticleStrip */
  .read-time-wrapper {
    > span {
      color: ${props => CM(props.theme).readTimeNumberColor};
    }
  }

  ${breakpoints.tabletLandscape} {
    display: grid;
    grid-gap: ${props => props.theme.space()};
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;

    > * {
      width: 300px;
      height: ${props => articleHeight(props)};
    }
  }

  ${breakpoints.desktopSmall} {
    > * {
      width: 347px;
    }
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 0 ${props => props.theme.space()} ${props => props.theme.space()} ${props => props.theme.space()};

  ${breakpoints.tabletLandscape} {
    justify-content: center;
    width: calc(373px + ${props => props.theme.space(3)});
    padding: 0 0 0 ${props => props.theme.space(2)};
  }

  ${breakpoints.desktopSmall} {
    width: calc(373px + ${props => props.theme.space(4)});
  }
`;

export const Title = styled.h1`
  ${props => props.theme.setFont('h3')}

  margin-top: ${props => props.theme.space(3)};
  margin-bottom: ${props => props.theme.space()};

  ${breakpoints.tabletLandscape} {
    margin: 0 0 ${props => props.theme.space()} 0;
    padding: 0 ${props => props.theme.space(3)} 0 0;
  }

  ${breakpoints.desktopSmall} {
    padding: 0;
  }
`;

export const patternLayout = name => {
  const layout = {
    'the-medal-thin': {
      desktop: css`
        top: -136px;
        left: -200px;
        transform: scale(1);
      `,
      tabletLandscape: css`
        top: -100px;
        left: -220px;
        transform: scale(1);
      `,
      tablet: css`
        top: -30px;
        left: -200px;
        transform: scale(0.5);
      `,
      mobile: css`
        top: -90px;
        left: -200px;
        transform: scale(0.5);
      `,
    },
    'scattered-circles': {
      desktop: css`
        left: 50px;
      `,
      tabletLandscape: css`
        top: -200px;
        left: 50px;
        height: 55%;
      `,
      tablet: css`
        top: 50px;
        height: 30%;
      `,
      mobile: css`
        top: -10px;
        right: unset;
        left: 0;
        height: 30%;
      `,
      mobileSmall: css`
        top: -65px;
      `,
    },
    peak: {
      desktop: css`
        top: -285px;
        left: -90px;
        transform: scale(0.65) rotate(180deg);
      `,
      tabletLandscape: css`
        top: -300px;
        left: -65px;
        transform: scale(0.6) rotate(180deg);
      `,
      tablet: css`
        top: -110px;
        left: -100px;
        transform: scale(0.33) rotate(180deg);
      `,
      mobile: css`
        top: -170px;
        left: -120px;
        transform: scale(0.35) rotate(180deg);
      `,
      mobileSmall: css`
        top: -210px;
      `,
    },
    'circle-outlines': {
      desktop: css`
        top: -255px;
        left: 0px;
        transform: scale(0.75);
      `,
      tabletLandscape: css`
        top: -200px;
        left: -90px;
        transform: scale(0.5);
      `,
      tablet: css`
        top: -70px;
        left: -100px;
        transform: scale(0.35);
      `,
      mobile: css`
        top: -120px;
        left: -110px;
        transform: scale(0.35);
      `,
    },
    'shape-of-water-03': {
      desktop: css`
        top: -190px;
        left: -35px;
        transform: scale(0.65);
      `,
      tabletLandscape: css`
        top: -220px;
        left: -70px;
        transform: scale(0.5);
      `,
      tablet: css`
        top: -50px;
        left: -95px;
        transform: scale(0.45);
      `,
      mobile: css`
        top: -100px;
        left: -95px;
        transform: scale(0.45);
      `,
    },
    profile: {
      desktopLarge: css`
        left: 0;
      `,
      desktop: css`
        top: -80px;
        left: 100px;
        transform: scale(1.1);
      `,
      tabletLandscape: css`
        top: -100px;
        left: -40px;
        transform: scale(0.7);
      `,
      tablet: css`
        top: 20px;
        left: -50px;
        transform: scale(0.8);
      `,
      mobile: css`
        top: -50px;
        left: -40px;
        transform: scale(0.8);
      `,
    },
    'on-your-marks': {
      desktop: css`
        top: -160px;
        left: 0px;
        transform: scale(0.7);
      `,
      tabletLandscape: css`
        top: -200px;
        left: -40px;
        transform: scale(0.6);
      `,
      tablet: css`
        top: -66px;
        left: -100px;
        transform: scale(0.33);
      `,
      mobile: css`
        top: -135px;
        left: -100px;
        transform: scale(0.3);
      `,
      mobileSmall: css`
        top: -165px;
        left: -100px;
        transform: scale(0.3);
      `,
    },
    transformation: {
      desktop: css`
        top: -400px;
        left: -80px;
        transform: scale(0.45);
      `,
      tabletLandscape: css`
        top: -400px;
        left: -100px;
        transform: scale(0.4);
      `,
      tablet: css`
        top: -385px;
        left: -120px;
        transform: scale(0.33);
      `,
      mobile: css`
        top: -450px;
        left: -130px;
        transform: scale(0.3);
      `,
      mobileSmall: css`
        top: -450px;
        left: -130px;
        transform: scale(0.3);
      `,
    },
  };

  if (name in layout) return layout[name];

  return 'the-medal-thin';
};

export const Pattern = styled.div`
  position: absolute;
  top: 50%;
  left: 0%;
  z-index: -1;
  width: 370px;
  height: 280px;
  transform: translateY(-50%);

  ${breakpoints.tabletPortrait} {
    height: 370px;
  }

  ${breakpoints.tabletLandscape} {
    left: -20%;
  }

  svg {
    position: relative;
    ${props => patternLayout(props.patternName).mobile}
    fill: ${props => props.theme.colors.named[props.patternColor]};

    @media screen and (max-width: 320px) {
      ${props => patternLayout(props.patternName)?.mobileSmall}
    }

    ${breakpoints.tabletPortrait} {
      ${props => patternLayout(props.patternName).tablet}
    }

    ${breakpoints.tabletLandscape} {
      ${props => patternLayout(props.patternName).tabletLandscape}
    }

    ${breakpoints.desktopSmall} {
      ${props => patternLayout(props.patternName).desktop}
    }

    ${breakpoints.desktopLarge} {
      ${props => patternLayout(props.patternName)?.desktopLarge}
    }
  }
`;

export const SwipeText = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.theme.setFont('visualCardSwipeMessage')}
  margin-bottom: 0;
  color: ${props => CM(props.theme).swipeTextColor};

  ${breakpoints.tabletLandscape} {
    display: none;
  }

  svg {
    width: 15px;
    margin-right: ${props => props.theme.space(0.5)};
    fill: ${props => CM(props.theme).swipeTextSvgColor};
  }
`;
