export const loadScript = (source, crossOrigin, callback) => {
  var script = document.createElement('script');
  script.type = 'text/javascript';
  script.crossOrigin = crossOrigin;

  if (script.readyState) {
    // IE
    script.onreadystatechange = function() {
      if (script.readyState == 'loaded' || script.readyState === 'complete') {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    // Others
    script.onload = callback;
  }

  script.src = source;
  document.getElementsByTagName('head')[0].appendChild(script);
};
