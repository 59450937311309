import styled from "styled-components";
import { breakpoints } from "../global-styles/breakpoints";

const siteSpecificStyles = (theme) => {
  const sites = {
    "london-marathon": {
      lineHeight: "57px",
    },
    "the-big-half": {
      lineHeight: "57px",
    },
    "london-10k": {
      lineHeight: "57px",
    },
    "westminster-mile": {
      lineHeight: "57px",
    },
    "swim-serpentine": {
      lineHeight: "65px",
    },
    "ride-london": {
      lineHeight: "65px",
    },
  };
  if (theme.website in sites) return sites[theme.website];
  return sites["london-marathon"];
};

export const ArticleListComponent = styled.section`
  margin-bottom: ${(props) => props.theme.space(props.spaceMultiplierMobile)};

  ${breakpoints.tabletLandscape} {
    margin-bottom: ${(props) =>
      props.theme.space(props.spaceMultiplierDesktop)};
  }

  .article-list-filter {
    transition: top 0.3s ease-in-out;
    margin: 61px 0 0 0;
  }

  .text-contrast-gradient {
    background-image: linear-gradient(
      0deg,
      rgba(0, 0, 0, 0.75) 20%,
      rgba(0, 0, 0, 0) 60%
    );
  }
`;

export const FeaturedArticleComponent = styled.div`
  padding: 61px 0;

  > div {
    display: flex;
    height: 430px;
    margin-bottom: ${(props) => props.theme.space(props.spaceMultiplierMobile)};

    ${breakpoints.tabletLandscape} {
      position: relative;
      flex-direction: column;
      height: 0px;
      margin: 0 61px;
      padding: 61px 0;
      padding-top: calc(43 / 131 * 100%);

      > .article-card {
        position: absolute;

        > a {
          padding: ${(props) => props.theme.space(2)};
        }
      }
    }
  }

  .article-card-featured {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      ${breakpoints.desktopSmall} {
        font-size: 52px;
        line-height: ${(props) => siteSpecificStyles(props.theme).lineHeight};
      }
    }
  }
`;

export const FilteredResultsGrid = styled.div`
  position: relative;
  display: grid;
  grid-row-gap: ${(props) => props.theme.space()};
  grid-column-gap: ${(props) => props.theme.space()};
  grid-template-columns: 1fr;
  margin: 0 ${(props) => props.theme.space()};

  ${breakpoints.tabletLandscape} {
    grid-template-columns: 1fr 1fr 1fr;
    margin: 0 21px;
  }

  ${breakpoints.desktopSmall} {
    margin: 0 130px;
  }

  .offset {
    position: absolute;
    top: 0px;
  }

  .article-card {
    min-height: 380px;

    ${breakpoints.tabletLandscape} {
      padding-top: calc((380 / 352) * 100%);
    }

    ${breakpoints.desktopLarge} {
      padding-top: calc((95 / 88) * 87.5%);
    }
  }
`;

export const LoadMore = styled.button`
  display: flex;
  grid-column: 1 / -1;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;

  svg {
    width: 24px;
    margin-right: ${(props) => props.theme.space(0.5)};
  }
`;

export const NoResults = styled.p`
  text-align: center;
`;
