import React from 'react';
import PropTypes from 'prop-types';
import { CtaAltComponent } from './CtaAlt.styles.js';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import { getIcon } from '../utils/icon';
import { isServer } from '@sitecore-jss/sitecore-jss';

const CtaAlt = ({ cta, small }) => {
  const IgnoreReactRouterAnchorTag = () => {
    const go = e => {
      e.preventDefault();
      const scrollToEl = document.getElementById(cta.anchor);
      scrollToEl?.scrollIntoView();
    };

    return (
      <a href="" onClick={go}>
        {cta?.text}
      </a>
    );
  };

  return (
    <CtaAltComponent small={small}>
      <>
        {!cta.anchor ? (
          <Link
            field={cta.value ? cta.value : cta}
            target={
              cta.value
                ? cta.value.target === '_blank'
                  ? '_blank'
                  : '_self'
                : cta.target === '_blank'
                ? '_blank'
                : '_self'
            }
            onClick={() => {
              if (!isServer()) {
                window.dataLayer.push({
                  event: 'countdownCTA',
                  clickText: cta.text,
                });
              }
            }}
          />
        ) : (
          IgnoreReactRouterAnchorTag()
        )}
        {cta.value ? cta.value.href && getIcon('arrowLong') : cta.href && getIcon('arrowLong')}
      </>
    </CtaAltComponent>
  );
};

CtaAlt.propTypes = {
  cta: PropTypes.object,
  small: PropTypes.bool,
};

export default CtaAlt;
