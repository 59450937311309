import React, { useState, useEffect } from 'react';
import { withTheme } from 'styled-components';
import { handleCtaMappingByTheme } from '../cta/ctaMapper';
import PropTypes from 'prop-types';
import MobileCarousel from '../mobile-carousel';
import ArticleCard from '../article-card';
import CTA from '../cta';
import { getPattern } from '../utils/pattern';
import { getIcon } from '../utils/icon';
import { useIsMobile } from '../hooks/useIsMobile';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import {
  ArticleStripComponent,
  ArticleContainer,
  Title,
  Pattern,
  ContentContainer,
  SwipeText,
} from './ArticleStrip.styles.js';

const ArticleStrip = props => {
  const {
    fields: { articles: items = [], cta = {}, title = {} } = {},
    params: { ctaTheme = 'sponsor-red', spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
    pattern,
    patternColor,
    sitecoreContext: { pageEditing = false } = {},
    translate = {},
    theme: { website },
  } = props;

  const t = key => {
    if (translate && typeof translate === 'function') {
      return translate(`Article_${key}`);
    }

    return key;
  };

  const swipeText = t('Swipe');
  const [isMounted, setIsMounted] = useState(false);
  const isMobile = useIsMobile('tabletLandscape', -1);
  const articleCards = items.map((item, i) => (
    <ArticleCard
      headingLevel={2}
      variant="b"
      key={i}
      gtm={{ name: 'blogStripCard', data: ['pagePath', 'cardTitle'] }}
      translate={translate}
      {...item}
    />
  ));

  useEffect(() => {
    setIsMounted(true);
  }, []);

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  const handleCTAClick = () => {
    if (!isServer()) {
      dataLayerEvent('blogStripCTA', {
        pagePath: window.location.pathname,
        cardTitle: title,
      });
    }
  };
  return (
    isMounted && (
      <ArticleStripComponent
        spaceMultiplierMobile={spaceMultiplierMobile}
        spaceMultiplierDesktop={spaceMultiplierDesktop}
        className="limitedWidth"
      >
        <ContentContainer>
          <Pattern patternColor={patternColor} patternName={pattern} pageEditing={pageEditing}>
            {getPattern(pattern)}
          </Pattern>
          <Title>
            <Text field={title} />
          </Title>
          {cta?.value?.href && website != 'lm-foundation' && (
            <CTA
              onClick={handleCTAClick}
              fullwidth={isMobile ? 1 : 0}
              variant={handleCtaMappingByTheme(website, ctaTheme)}
              label={cta?.value?.text}
              ariaLabel={cta?.value?.text}
              link={cta?.value}
              target={cta?.value?.target}
            />
          )}
          {cta?.value?.href && website == 'lm-foundation' && (
            <>
              {isMobile ? (
                <>
                  <span>{cta?.value?.text}</span>
                  <CTA
                    onClick={handleCTAClick}
                    variant={handleCtaMappingByTheme(website, ctaTheme)}
                    ariaLabel={cta?.value?.text}
                    link={cta?.value}
                    target={cta?.value?.target}
                  />
                </>
              ) : (
                <CTA
                  onClick={handleCTAClick}
                  variant={handleCtaMappingByTheme(website, ctaTheme)}
                  label={cta?.value?.text}
                  ariaLabel={cta?.value?.text}
                  link={cta?.value}
                  target={cta?.value?.target}
                />
              )}
            </>
          )}
        </ContentContainer>
        <ArticleContainer>
          {isMobile ? <MobileCarousel>{articleCards}</MobileCarousel> : articleCards}
        </ArticleContainer>
        {isMobile && (
          <SwipeText>
            {getIcon('swipe')} <span>{swipeText}</span>
          </SwipeText>
        )}
      </ArticleStripComponent>
    )
  );
};

ArticleStrip.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  sitecoreContext: PropTypes.object,
  translate: PropTypes.func,
  pattern: PropTypes.string,
  patternColor: PropTypes.string,
  theme: PropTypes.object,
};

export default withTheme(ArticleStrip);
