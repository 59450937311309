export const mapColourToSiteTheme = theme => {
  const sites = {
    'london-marathon': {
      textColour: theme.colors.primary[200],
      backgroundColour: theme.colors.grays[100],
    },
    'the-big-half': {
      textColour: theme.colors.secondary[400],
      backgroundColour: theme.colors.grays[500],
    },
    'london-10k': {
      textColour: theme.colors.primary[200],
      backgroundColour: theme.colors.grays[500],
    },
    'westminster-mile': {
      textColour: theme.colors.primary[200],
      backgroundColour: theme.colors.grays[500],
    },
    'swim-serpentine': {
      textColour: theme.colors.secondary[400],
      backgroundColour: theme.colors.grays[500],
    },
    'ride-london': {
      textColour: theme.colors.primary[300],
      backgroundColour: theme.colors.grays[500],
    },
    'city-race': {
      textColour: theme.colors.primary[300],
      backgroundColour: theme.colors.grays[500],
    },
  };
  if (theme.website in sites) return sites[theme.website];
  return sites['london-marathon'];
};
