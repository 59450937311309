export const mapColourToSiteTheme = theme => {
  const { website } = theme;

  const sites = {
    'london-marathon': {
      backgroundColor: theme.colors.grays[100],
      svgFillColourA: theme.colors.primary[300],
      svgFillColourB: theme.colors.primary[200],
      svgFillColourC: theme.colors.primary[200],
      optionTextColor: theme.colors.primary[300],
      optionTextBackgroundColor: theme.colors.primary[200],
      inactiveTextColor: theme.colors.grays[400],
      inactiveSvgColor: theme.colors.grays[400],
      voteConfirmedColor: theme.colors.primary[200],
      svgLastOfTypeFill: theme.colors.primary[300],
    },
    'the-big-half': {
      backgroundColor: theme.colors.grays[500],
      svgFillColourA: theme.colors.primary[400],
      svgFillColourB: theme.colors.primary[400],
      svgFillColourC: 'transparent',
      optionTextColor: theme.colors.primary[400],
      optionTextBackgroundColor: theme.colors.secondary[300],
      inactiveTextColor: theme.colors.secondary[400],
      inactiveSvgColor: theme.colors.secondary[400],
      voteConfirmedColor: theme.colors.secondary[400],
      svgLastOfTypeFill: theme.colors.secondary[400],
    },
    'london-10k': {
      backgroundColor: theme.colors.grays[500],
      svgFillColourA: theme.colors.primary[200],
      svgFillColourB: theme.colors.primary[200],
      svgFillColourC: 'transparent',
      optionTextColor: theme.colors.primary[200],
      optionTextBackgroundColor: 'transparent',
      optionTextBorderColor: theme.colors.primary[100],
      inactiveTextColor: theme.colors.primary[200],
      inactiveSvgColor: theme.colors.primary[200],
      voteConfirmedColor: theme.colors.primary[200],
      svgLastOfTypeFill: theme.colors.primary[200],
    },
    'westminster-mile': {
      backgroundColor: theme.colors.grays[500],
      svgFillColourA: theme.colors.primary[300],
      svgFillColourB: theme.colors.primary[300],
      svgFillColourC: 'transparent',
      optionTextColor: theme.colors.primary[300],
      optionTextBackgroundColor: theme.colors.primary[100],
      optionTextBorderColor: theme.colors.primary[300],
      inactiveTextColor: theme.colors.primary[200],
      inactiveSvgColor: theme.colors.primary[200],
      voteConfirmedColor: theme.colors.primary[200],
      svgLastOfTypeFill: theme.colors.primary[200],
    },
    'swim-serpentine': {
      backgroundColor: theme.colors.grays[500],
      svgFillColourA: theme.colors.primary[400],
      svgFillColourB: theme.colors.secondary[400],
      svgFillColourC: 'transparent',
      optionTextColor: theme.colors.secondary[400],
      optionTextBackgroundColor: theme.colors.primary[300],
      inactiveTextColor: theme.colors.secondary[400],
      inactiveSvgColor: theme.colors.secondary[400],
      voteConfirmedColor: theme.colors.secondary[400],
      svgLastOfTypeFill: theme.colors.secondary[400],
    },
    'ride-london': {
      backgroundColor: theme.colors.grays[500],
      svgFillColourA: theme.colors.primary[400],
      svgFillColourB: theme.colors.primary[400],
      svgFillColourC: 'transparent',
      optionTextColor: theme.colors.primary[400],
      optionTextBackgroundColor: theme.colors.primary[100],
      inactiveTextColor: theme.colors.primary[300],
      inactiveSvgColor: theme.colors.primary[300],
      voteConfirmedColor: theme.colors.primary[300],
      svgLastOfTypeFill: theme.colors.primary[300],
    },
    'city-race': {
      backgroundColor: theme.colors.grays[500],
      svgFillColourA: theme.colors.primary[400],
      svgFillColourB: theme.colors.primary[400],
      svgFillColourC: 'transparent',
      optionTextColor: theme.colors.primary[400],
      optionTextBackgroundColor: theme.colors.primary[200],
      inactiveTextColor: theme.colors.primary[300],
      inactiveSvgColor: theme.colors.primary[300],
      voteConfirmedColor: theme.colors.primary[300],
      svgLastOfTypeFill: theme.colors.primary[300],
    },
    'lm-foundation': {
      backgroundColor: theme.colors.grays[100],
      svgFillColourA: theme.colors.primary[300],
      svgFillColourB: theme.colors.primary[300],
      svgFillColourC: theme.colors.secondary[600],
      optionTextColor: theme.colors.primary[300],
      optionTextBackgroundColor: theme.colors.secondary[600],
      inactiveTextColor: theme.colors.grays[400],
      inactiveSvgColor: theme.colors.grays[400],
      voteConfirmedColor: theme.colors.primary[200],
      svgLastOfTypeFill: theme.colors.primary[200],
    },
  };
  if (website in sites) return sites[website];

  return sites['london-marathon'];
};
