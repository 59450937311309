import styled, { css } from 'styled-components';
import { breakpoints } from '../../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

const siteSpecificStyles = theme => {
  const sites = {
    'london-marathon': {
      patternRightPosition: theme.space(),
    },
    'the-big-half': {
      patternRightPosition: theme.space(),
    },
    'london-10k': {
      patternRightPosition: theme.space(),
    },
    'westminster-mile': {
      patternRightPosition: 0,
    },
    'swim-serpentine': {
      patternRightPosition: theme.space(),
    },
    'ride-london': {
      patternRightPosition: theme.space(),
    },
    'city-race': {
      patternRightPosition: 0,
    },
    'lm-foundation': {
      patternRightPosition: theme.space(2),
    },
  };
  if (theme.website in sites) return sites[theme.website];
  return sites['london-marathon'];
};

export const SearchOverlayContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  display: flex;
  width: 100%;
  height: 100%;
  background-image: ${props => props.theme.gradients[props.gradient]};
  border: ${props => props.theme.space(0.5)} solid ${props => CM(props.theme).containerBorder};
  opacity: 0;
  transition: opacity 0.25s ease-out;
  pointer-events: none;

  &.visible {
    opacity: 1;
    pointer-events: auto;
  }

  ${breakpoints.tabletPortrait} {
    border: ${props => props.theme.space()} solid ${props => CM(props.theme).containerBorder};
  }
`;

export const CloseButton = styled.button`
  ${props => props.theme.setFont('searchOverlayCloseButton')}
  position: absolute;
  top: ${props => props.theme.space(0.5)};
  right: ${props => props.theme.space(0.5)};
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${props => CM(props.theme).closeButtonColor};
  background: none;
  border: 0;
  cursor: pointer;

  svg {
    height: ${props => props.theme.space()};
    margin-right: 10px;
    fill: ${props => CM(props.theme).closeButtonSvgColor};
  }
`;

export const SearchContainer = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 85%;
  padding: ${props => props.theme.space(0.5)};

  ${breakpoints.tabletPortrait} {
    width: 100%;
    height: 65%;
    padding: ${props => props.theme.space()};
  }

  ${breakpoints.desktopSmall} {
    width: 66.66%;
  }
`;

export const SearchForm = styled.form`
  position: relative;
  margin-bottom: ${props => props.theme.space()};
  color: ${props => CM(props.theme).searchFormColor};

  label {
    ${props => props.theme.setFont('searchOverlayLabelText')}
    display: block;
    margin-bottom: ${props => props.theme.space(0.5)};
  }

  input {
    width: 100%;
    padding: 0 0 ${props => props.theme.space(0.5)} 0;
    color: ${props => CM(props.theme).searchFormInputColor};
    ${props => props.theme.setFont('searchOverlayInput')}
    font-weight: bold;
    font-family: 'itc-avant-garde-gothic-pro', sans-serif;
    background: none;
    border: 0;
    border-bottom: 2px solid ${props => CM(props.theme).searchFormBorderColor};
    border-radius: 0;

    &::placeholder {
      color: ${props => CM(props.theme).searchFormInputPlaceholderColor};
      opacity: 0.7;
    }

    &:focus {
      outline: none;
    }
  }
`;

export const SearchButton = styled.button`
  position: absolute;
  right: 0;
  bottom: ${props => props.theme.space(0.25)};
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;

  svg {
    opacity: 0.7;
    fill: ${props => CM(props.theme).searchButtonSvgColor};
  }

  ${breakpoints.tabletPortrait} {
    bottom: ${props => props.theme.space(0.6)};
    transform: scale(1.5);
  }

  ${breakpoints.tabletLandscape} {
    bottom: ${props => props.theme.space(0.85)};
  }

  ${breakpoints.desktopSmall} {
    bottom: ${props => props.theme.space()};
  }
`;

export const PopularSearches = styled.div`
  color: ${props => CM(props.theme).popularSearchesColor};

  p {
    ${props => props.theme.setFont('searchOverlayPopularSearchTitle')}
  }

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;

    li {
      a {
        ${props => props.theme.setFont('searchOverlayPopularSearchItem')}

        margin-bottom: 10px;
        color: ${props => CM(props.theme).popularSearchesLinkColor};
        font-weight: bold;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }

        ${breakpoints.tabletPortrait} {
          margin-bottom: 20px;
        }
      }
    }
  }
`;

export const SearchResults = styled.ul`
  width: 100%;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: ${props => props.theme.space()} 0;
    list-style-type: none;
    border-bottom: 1px solid white;

    .searchTag {
      width: fit-content;
      margin-bottom: ${props => props.theme.space(0.5)};

      ${breakpoints.tabletPortrait} {
        margin-bottom: 0;
      }
    }

    ${breakpoints.tabletPortrait} {
      flex-direction: row;
      align-items: center;
    }

    &:first-child {
      padding-top: 0;
    }

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }

    a {
      overflow: hidden;
      text-decoration: none;

      p {
        ${props => props.theme.setFont('searchOverlayResults')}
        margin: 0;
        padding: 0;
        overflow: hidden;
        color: ${props => CM(props.theme).searchResultsTextColorA};
        font-weight: bold;
        white-space: nowrap;
        text-overflow: ellipsis;

        span {
          color: ${props => CM(props.theme).searchResultsTextColorB};
        }
      }

      &:hover {
        text-decoration: underline;
        text-decoration-color: ${props => CM(props.theme).searchResultsHoverColor};
      }
    }
  }
`;

export const SearchTextUnderline = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: ${props => props.underlineWidth}px;
  height: 2px;
  background-color: ${props => CM(props.theme).inputUnderlineColor};
`;

export const HiddenSearchTerm = styled.p`
  position: absolute;
  z-index: -9999;
  display: inline-block;
  font-weight: bold;
  ${props => props.theme.setFont('searchOverlayInput')}
  font-family: 'itc-avant-garde-gothic-pro', sans-serif;
  border: 0;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
`;

const patternLayout = name => {
  const layout = {
    'twenty-six-degree-mark': {
      desktop: css`
        top: 50%;
        right: ${props => props.theme.space(3)};
        height: 60%;
        transform: translateY(-50%);
      `,
    },
    'the-medal-thin': {
      desktop: css`
        top: 0%;
        right: 0;
        height: 100%;
      `,
    },
    'the-steps': {
      desktop: css`
        top: 0%;
        right: 0;
        height: 65%;
        transform: rotate(180deg);
      `,
    },
    'tower-bridge': {
      desktop: css`
        top: 0;
        right: 0;
        height: 100%;
      `,
    },
    'scattered-circles': {
      desktop: css`
        top: 20%;
        right: 0;
        height: 60%;
        transform: none;
      `,
    },
    'diagonal-chevrons': {
      desktop: css`
        top: 27%;
        right: -11%;
        height: 55%;
        transform: rotate(15deg);
      `,
    },
    'film-reel': {
      desktop: css`
        top: 20%;
        right: 0;
        height: 60%;
      `,
    },
    'multiple-circles': {
      desktop: css`
        top: 30%;
        right: 0;
        height: 40%;
      `,
    },
    'parallel-lines': {
      desktop: css`
        top: 20%;
        right: 0;
        height: 60%;
      `,
    },
    peak: {
      desktop: css`
        top: 0%;
        right: 0;
        height: 80%;
      `,
    },
    waves: {
      desktop: css`
        top: 20%;
        right: 0;
        height: 60%;
      `,
    },
    power: {
      desktop: css`
        top: 10%;
        right: '-28px';
        height: 80%;
      `,
    },
    unity: {
      desktop: css`
        top: 10%;
        right: '-28px';
        height: 80%;
      `,
    },
    'shape-of-water-01': {
      desktop: css`
        top: 20%;
        right: 0;
        height: 65%;
      `,
    },
    'shape-of-water-02': {
      desktop: css`
        top: 20%;
        right: 0;
        height: 65%;
      `,
    },
    'shape-of-water-03': {
      desktop: css`
        top: 20%;
        right: 0;
        height: 65%;
      `,
    },
    'shape-of-water-04': {
      desktop: css`
        top: 20%;
        right: 0;
        height: 65%;
      `,
    },
    'landscape-outlined': {
      desktop: css`
        top: 10%;
        right: 0;
        height: 80%;
      `,
    },
    frame: {
      desktop: css`
        top: 10%;
        right: 0;
        height: 80%;
      `,
    },
    handlebars: {
      desktop: css`
        top: 10%;
        right: 0;
        height: 80%;
      `,
    },
    wheels: {
      desktop: css`
        top: 10%;
        right: 0;
        height: 80%;
      `,
    },
    'on-your-marks': {
      desktop: css`
        top: 27%;
        right: 0;
        height: 55%;
      `,
    },
    'on-your-marks-alt': {
      desktop: css`
        top: 27%;
        right: 0;
        height: 55%;
      `,
    },
    'get-set': {
      desktop: css`
        top: 20%;
        right: 0;
        height: 65%;
      `,
    },
    go: {
      desktop: css`
        top: 17%;
        right: 0;
        height: 70%;
      `,
    },
  };
  if (name in layout) return layout[name];

  return 'twenty-six-degree-mark';
};

export const Pattern = styled.div`
  display: none;
  pointer-events: none;

  ${breakpoints.desktopSmall} {
    position: absolute;
    top: 0;
    right: ${props => siteSpecificStyles(props.theme).patternRightPosition};
    display: block;
    width: 30%;
    height: 100%;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    overflow: hidden;
  }

  svg {
    position: absolute;
    width: 100%;
    ${breakpoints.tabletLandscape} {
      ${props => patternLayout(props.patternName).desktop}
    }

    path {
      fill: ${props => props.theme.colors.named[props.patternColor]};
    }
  }
`;

export const LayerImage = styled.div`
  display: none;
  pointer-events: none;

  ${breakpoints.desktopSmall} {
    position: absolute;
    top: 0;
    right: ${props => props.theme.space()};
    display: block;
    width: 30%;
    height: 100%;
    background-image: url(${props => props.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }
`;
