import React from 'react';
import accountUrl, { ReactComponent as Account } from '../../assets/icons/account.svg';
import addUrl, { ReactComponent as Add } from '../../assets/icons/add.svg';
import arrowUrl, { ReactComponent as Arrow } from '../../assets/icons/arrow.svg';
import arrowLongUrl, { ReactComponent as ArrowLong } from '../../assets/icons/arrow-long.svg';
import arrowShortUrl, { ReactComponent as ArrowShort } from '../../assets/icons/arrow-short.svg';
import aspectRatioUrl, { ReactComponent as AspectRatio } from '../../assets/icons/aspect-ratio.svg';
import backToTopDesktopUrl, { ReactComponent as BackToTopDesktop } from '../../assets/icons/back-to-top-desktop.svg';
import backToTopMobileUrl, { ReactComponent as BackToTopMobile } from '../../assets/icons/back-to-top-mobile.svg';
import bookmarkUrl, { ReactComponent as Bookmark } from '../../assets/icons/bookmark.svg';
import botUrl, { ReactComponent as Bot } from '../../assets/icons/bot.svg';
import chatUrl, { ReactComponent as Chat } from '../../assets/icons/chat.svg';
import openChatUrl, { ReactComponent as OpenChat } from '../../assets/icons/open-chat.svg';
import checkboxUrl, { ReactComponent as Checkbox } from '../../assets/icons/checkbox.svg';
import checkboxCheckedUrl, {
  ReactComponent as CheckboxChecked,
} from '../../assets/icons/checkbox-checked.svg';
import checkboxMinusUrl, { ReactComponent as CheckboxMinus } from '../../assets/icons/checkbox-minus.svg';
import chevronUrl, { ReactComponent as Chevron } from '../../assets/icons/chevron.svg';
import chevronOutlineUrl, { ReactComponent as ChevronOutline } from '../../assets/icons/chevron-outline.svg';
import clearUrl, { ReactComponent as Clear } from '../../assets/icons/clear.svg';
import deleteUrl, { ReactComponent as Delete } from '../../assets/icons/delete.svg';
import downloadUrl, { ReactComponent as Download } from '../../assets/icons/download.svg';
import downloadOutlineUrl, {
  ReactComponent as DownloadOutline,
} from '../../assets/icons/download-outline.svg';
import downloadShareUrl, { ReactComponent as DownloadShare } from '../../assets/icons/download-share.svg';
import emailUrl, { ReactComponent as Email } from '../../assets/icons/email.svg';
import errorUrl, { ReactComponent as Error } from '../../assets/icons/error.svg';
import facebookUrl, { ReactComponent as Facebook } from '../../assets/icons/facebook.svg';
import favouriteUrl, { ReactComponent as Favourite } from '../../assets/icons/favourite.svg';
import filterUrl, { ReactComponent as Filter } from '../../assets/icons/filter.svg';
import fullscreenUrl, { ReactComponent as Fullscreen } from '../../assets/icons/fullscreen.svg';
import fullscreenExitUrl, { ReactComponent as FullscreenExit } from '../../assets/icons/fullscreen-exit.svg';
import gridUrl, { ReactComponent as Grid } from '../../assets/icons/grid.svg';
import happyUrl, { ReactComponent as Happy } from '../../assets/icons/happy.svg';
import helpUrl, { ReactComponent as Help } from '../../assets/icons/help.svg';
import homeUrl, { ReactComponent as Home } from '../../assets/icons/home.svg';
import instagramUrl, { ReactComponent as Instagram } from '../../assets/icons/instagram.svg';
import linkUrl, { ReactComponent as Link } from '../../assets/icons/link.svg';
import lockedUrl, { ReactComponent as Locked } from '../../assets/icons/locked.svg';
import menuUrl, { ReactComponent as Menu } from '../../assets/icons/menu.svg';
import moreUrl, { ReactComponent as More } from '../../assets/icons/more.svg';
import notAvailableUrl, { ReactComponent as NotAvailable } from '../../assets/icons/not-available.svg';
import pauseUrl, { ReactComponent as Pause } from '../../assets/icons/pause.svg';
import playUrl, { ReactComponent as Play } from '../../assets/icons/play.svg';
import playOutlineUrl, { ReactComponent as PlayOutline } from '../../assets/icons/play-outline.svg';
import publishUrl, { ReactComponent as Publish } from '../../assets/icons/publish.svg';
import radioCheckedUrl, { ReactComponent as RadioChecked } from '../../assets/icons/radio-checked.svg';
import radioUncheckedUrl, { ReactComponent as RadioUnchecked } from '../../assets/icons/radio-unchecked.svg';
import refreshUrl, { ReactComponent as Refresh } from '../../assets/icons/refresh.svg';
import removeUrl, { ReactComponent as Remove } from '../../assets/icons/remove.svg';
import sadUrl, { ReactComponent as Sad } from '../../assets/icons/sad.svg';
import searchUrl, { ReactComponent as Search } from '../../assets/icons/search.svg';
import settingsUrl, { ReactComponent as Settings } from '../../assets/icons/settings.svg';
import shareUrl, { ReactComponent as Share } from '../../assets/icons/share.svg';
import signpostMobileUrl, { ReactComponent as SignpostMobile } from '../../assets/icons/signpost-mobile.svg';
import signpostDesktopUrl, {
  ReactComponent as SignpostDesktop,
} from '../../assets/icons/signpost-desktop.svg';
import signpostSquareMobileUrl, {
  ReactComponent as SignpostSquareMobile,
} from '../../assets/icons/signpost-square-mobile.svg';
import signpostSquareDesktopUrl, {
  ReactComponent as SignpostSquareDesktop,
} from '../../assets/icons/signpost-square-desktop.svg';

import starUrl, { ReactComponent as Star } from '../../assets/icons/star.svg';
import starOutlineUrl, { ReactComponent as StarOutline } from '../../assets/icons/star-outline.svg';
import swipeUrl, { ReactComponent as Swipe } from '../../assets/icons/swipe.svg';
import thickArrowUrl, { ReactComponent as ThickArrow } from '../../assets/icons/thick-arrow.svg';
import thumbsUpIconUrl, { ReactComponent as ThumbsUpIcon } from '../../assets/icons/thumbs-up.svg';
import thumbsDownIconUrl, { ReactComponent as ThumbsDownIcon } from '../../assets/icons/thumbs-down.svg';
import tickUrl, { ReactComponent as Tick } from '../../assets/icons/tick.svg';
import tiktokUrl, { ReactComponent as Tiktok } from '../../assets/icons/tiktok.svg';
import toggleOffUrl, { ReactComponent as ToggleOff } from '../../assets/icons/toggle-off.svg';
import toggleOnUrl, { ReactComponent as ToggleOn } from '../../assets/icons/toggle-on.svg';
import twitterUrl, { ReactComponent as Twitter } from '../../assets/icons/twitter.svg';
import unlockedUrl, { ReactComponent as Unlocked } from '../../assets/icons/unlocked.svg';

const iconKeys = (key, returnUrl, props = {}) => {
  const icons = {
    account: returnUrl ? accountUrl : <Account {...props} />,
    add: returnUrl ? addUrl : <Add {...props} />,
    arrow: returnUrl ? arrowUrl : <Arrow {...props} />,
    arrowLong: returnUrl ? arrowLongUrl : <ArrowLong {...props} />,
    arrowShort: returnUrl ? arrowShortUrl : <ArrowShort {...props} />,
    aspectRatio: returnUrl ? aspectRatioUrl : <AspectRatio {...props} />,
    backToTopDesktop: returnUrl ? backToTopDesktopUrl : <BackToTopDesktop {...props} />,
    backToTopMobile: returnUrl ? backToTopMobileUrl : <BackToTopMobile {...props} />,
    bookmark: returnUrl ? bookmarkUrl : <Bookmark {...props} />,
    bot: returnUrl ? botUrl : <Bot {...props} />,
    chat: returnUrl ? chatUrl : <Chat {...props} />,
    openChat: returnUrl ? openChatUrl : <OpenChat {...props} />,
    checkbox: returnUrl ? checkboxUrl : <Checkbox {...props} />,
    checkboxChecked: returnUrl ? checkboxCheckedUrl : <CheckboxChecked {...props} />,
    checkboxMinus: returnUrl ? checkboxMinusUrl : <CheckboxMinus {...props} />,
    chevron: returnUrl ? chevronUrl : <Chevron {...props} />,
    chevronOutline: returnUrl ? chevronOutlineUrl : <ChevronOutline {...props} />,
    clear: returnUrl ? clearUrl : <Clear {...props} />,
    delete: returnUrl ? deleteUrl : <Delete {...props} />,
    download: returnUrl ? downloadUrl : <Download {...props} />,
    downloadOutline: returnUrl ? downloadOutlineUrl : <DownloadOutline {...props} />,
    downloadShare: returnUrl ? downloadShareUrl : <DownloadShare {...props} />,
    email: returnUrl ? emailUrl : <Email {...props} />,
    error: returnUrl ? errorUrl : <Error {...props} />,
    facebook: returnUrl ? facebookUrl : <Facebook {...props} />,
    favourite: returnUrl ? favouriteUrl : <Favourite {...props} />,
    filter: returnUrl ? filterUrl : <Filter {...props} />,
    fullscreen: returnUrl ? fullscreenUrl : <Fullscreen {...props} />,
    fullscreenExit: returnUrl ? fullscreenExitUrl : <FullscreenExit {...props} />,
    grid: returnUrl ? gridUrl : <Grid {...props} />,
    happy: returnUrl ? happyUrl : <Happy {...props} />,
    help: returnUrl ? helpUrl : <Help {...props} />,
    home: returnUrl ? homeUrl : <Home {...props} />,
    instagram: returnUrl ? instagramUrl : <Instagram {...props} />,
    link: returnUrl ? linkUrl : <Link {...props} />,
    locked: returnUrl ? lockedUrl : <Locked {...props} />,
    menu: returnUrl ? menuUrl : <Menu {...props} />,
    more: returnUrl ? moreUrl : <More {...props} />,
    notAvailable: returnUrl ? notAvailableUrl : <NotAvailable {...props} />,
    pause: returnUrl ? pauseUrl : <Pause {...props} />,
    play: returnUrl ? playUrl : <Play {...props} />,
    playOutline: returnUrl ? playOutlineUrl : <PlayOutline {...props} />,
    publish: returnUrl ? publishUrl : <Publish {...props} />,
    radioChecked: returnUrl ? radioCheckedUrl : <RadioChecked {...props} />,
    radioUnchecked: returnUrl ? radioUncheckedUrl : <RadioUnchecked {...props} />,
    refresh: returnUrl ? refreshUrl : <Refresh {...props} />,
    remove: returnUrl ? removeUrl : <Remove {...props} />,
    sad: returnUrl ? sadUrl : <Sad {...props} />,
    search: returnUrl ? searchUrl : <Search {...props} />,
    settings: returnUrl ? settingsUrl : <Settings {...props} />,
    share: returnUrl ? shareUrl : <Share {...props} />,
    signpostDesktop: returnUrl ? signpostDesktopUrl : <SignpostDesktop />,
    signpostMobile: returnUrl ? signpostMobileUrl : <SignpostMobile />,
    signpostSquareMobile: returnUrl ? signpostSquareMobileUrl : <SignpostSquareMobile />,
    signpostSquareDesktop: returnUrl ? signpostSquareDesktopUrl : <SignpostSquareDesktop />,
    star: returnUrl ? starUrl : <Star {...props} />,
    starOutline: returnUrl ? starOutlineUrl : <StarOutline {...props} />,
    swipe: returnUrl ? swipeUrl : <Swipe {...props} />,
    thickArrow: returnUrl ? thickArrowUrl : <ThickArrow {...props} />,
    thumbsUpIcon: returnUrl ? thumbsUpIconUrl : <ThumbsUpIcon {...props} />,
    thumbsDownIcon: returnUrl ? thumbsDownIconUrl : <ThumbsDownIcon {...props} />,
    tick: returnUrl ? tickUrl : <Tick {...props} />,
    tiktok: returnUrl ? tiktokUrl : <Tiktok {...props} />,
    toggleOff: returnUrl ? toggleOffUrl : <ToggleOff {...props} />,
    toggleOn: returnUrl ? toggleOnUrl : <ToggleOn {...props} />,
    twitter: returnUrl ? twitterUrl : <Twitter {...props} />,
    unlocked: returnUrl ? unlockedUrl : <Unlocked {...props} />,
  };

  return icons[key];
};

export const getIcon = (key, returnUrl = false, props = {}) => iconKeys(key, returnUrl, props) || null;
