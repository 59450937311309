import React from 'react';
import PropTypes from 'prop-types';
import { useSitecoreContext } from '../hooks/useSitecoreContext';
import styled from 'styled-components';
import branch from './branch';
import hasProps from './hasProps';

const EditingErrorComponent = styled.div`
  padding: 1em;
  background-color: lightyellow;
  border: 2px dashed red;
`;

const EditingError = ({ componentName = 'component' }) => {
  const sitecoreContext = useSitecoreContext();

  if (!sitecoreContext.pageEditing) {
    return null;
  }

  return (
    <EditingErrorComponent role="alert">
      This {componentName} requires a datasource. Please select the associated content.
    </EditingErrorComponent>
  );
};

EditingError.propTypes = {
  componentName: PropTypes.string,
};

/**
 * Checks whether a Sitecore datasource is present and renders appropriately depending on page mode (normal vs editing).
 *
 * @param {import('react').Component} Component
 * @returns
 *  The wrapped component, if a datasource is present.
 *  A null component (in normal mode) or an error component (in editing mode), if a datasource is not present.
 */
const WithDatasourceCheck = WrappedComponent => {
  const HasDatasource = props =>
    branch(
      props.rendering.fields && Object.keys(props.rendering.fields).length > 0,
      WrappedComponent,
      hasProps({
        componentName:
          props.rendering && props.rendering.componentName ? props.rendering.componentName : 'component',
      })(EditingError)
    )(props);

  return HasDatasource;
};

export default WithDatasourceCheck;
