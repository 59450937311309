import styled, { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const BreadcrumbsComponent = styled.nav`
  display: none;

  ${breakpoints.tabletLandscape} {
    &:not(.empty) {
      display: flex;
    }
    align-items: center;
    height: 40px;
    padding: 0 ${props => props.theme.px(61)};
  }

  ul {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      ${props => props.theme.setFont('breadcrumbs')}
      display: flex;
      align-items: center;

      a {
        color: ${props => CM(props.theme).breadcrumbColor};
      }
      &:last-child {
        a {
          pointer-events: none;
        }
      }
      ${({ theme: { website } }) => (website == 'lm-foundation' ? LMFoundationLinkStyles : defaultLinkStyles)}
    }
  }
`;

const defaultLinkStyles = css`
  a {
    text-decoration: none;
  }

  svg {
    width: 20px;
    transform: rotate(180deg);
  }

  &:last-child {
    font-weight: bold;

    svg {
      display: none;
    }
  }
`;

const LMFoundationLinkStyles = css`
  a:after {
    content: '';
    border-top: 1px solid;
    width: 4px;
    height: 010px;
    margin: 0 4px;
    position: relative;
    top: 5px;
    display: inline-block;
  }
  :last-child {
    a {
      text-decoration: none;
      :after {
        display: none;
      }
    }
  }
`;
