import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const FilterComponent = styled.div`
  position: sticky;
  top: -1px;
  z-index: 99;
  display: flex;
  overflow-x: scroll;
  background-color: ${props => CM(props.theme).filterBackground};

  &.sticky {
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      width: ${props => (props.listRefWidth > 0 ? `${props.listRefWidth}px` : `calc(100% + 52px)`)};
      height: 1px;
      background-color: ${props => CM(props.theme).filterBorder};
      content: '';

      ${breakpoints.tabletLandscape} {
        width: 100%;
      }
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  ${breakpoints.tabletLandscape} {
    align-items: flex-start;
    justify-content: center;
    padding: 0 61px;
    overflow-x: unset;
  }
`;

export const FilterText = styled.p`
  margin: ${props => props.theme.px(34)} 0 0;
  span {
    position: relative;
    top: -3px;
  }
`;

export const FilterComponentWrapper = styled.ul`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: ${props => props.theme.space(0.25)};
  padding: 0 ${props => props.theme.space()};
  list-style: none;

  ${breakpoints.tabletLandscape} {
    flex-wrap: wrap;
    margin-bottom: ${props => props.theme.space(0.5)};
    padding-left: ${props => props.theme.space()};
  }

  li {
    margin-bottom: ${props => props.theme.space(0.5)};
    &:not(:last-of-type) {
      margin-right: ${props => props.theme.space(0.5)};
    }
  }
`;

export const Filter = styled.button`
  ${props => props.theme.setFont('categoryFilterButton')}
  padding: 5px 20px;
  color: ${props =>
    props.active ? CM(props.theme).filterButtonActiveColor : CM(props.theme).filterButtonColor};
  font-size: 18px;
  line-height: 29px;
  white-space: nowrap;
  background-color: ${props =>
    props.active
      ? CM(props.theme).filterButtonActiveBackgroundColor
      : CM(props.theme).filterButtonBackgroundColor};
  border-color: transparent;
  border-radius: ${props => (props.theme.website !== 'lm-foundation' ? 50 : 10)}px;
  font-weight: ${props => (props.theme.website == 'lm-foundation' && props.active ? 'bold' : 'normal')};
  cursor: pointer;
  transition: background-color 0.25s;

  ${breakpoints.desktopSmall} {
    &:hover {
      background-color: ${props =>
        props.active
          ? CM(props.theme).filterButtonActiveBackgroundHoverColor
          : CM(props.theme).filterButtonBackgroundHoverColor};
      color: ${props =>
        props.theme.website !== 'lm-foundation' ? 'inherit' : CM(props.theme).filterButtonHoverColor};
    }
  }
`;

export const Text = styled.span`
  ${props => props.theme.setFont('categoryFilterText')}
  display: none;

  ${breakpoints.tabletLandscape} {
    display: block;
    color: ${props => CM(props.theme).textColor};
    font-weight: bold;
    font-size: 14px;
  }
`;
