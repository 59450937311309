import React from 'react';
import PropTypes from 'prop-types';
import { ResultsWrap, ResultTitle, Pattern, NoResults } from './Intro.styles';

const Intro = ({
  terms,
  count = 0,
  hasLoaded,
  noResultsTitle,
  noResultsText,
  totalResultsText,
  oneResultText,
  resultsLength = 0,
}) => {
  const resultsTitle = resultsLength === 1 ? oneResultText : totalResultsText.replace('{0}', count);

  return (
    <ResultsWrap>
      {hasLoaded ? (
        <>
          <span>{count > 0 ? resultsTitle : noResultsTitle}</span>
          <ResultTitle>{`'${terms}'`}</ResultTitle>
          {count <= 0 ? (
            <>
              <Pattern>
                <span />
                <span />
                <span />
              </Pattern>
              <NoResults>{noResultsText}</NoResults>
            </>
          ) : null}
        </>
      ) : null}
    </ResultsWrap>
  );
};

Intro.propTypes = {
  terms: PropTypes.string,
  count: PropTypes.number,
  hasLoaded: PropTypes.bool,
  noResultsTitle: PropTypes.string,
  noResultsText: PropTypes.string,
  totalResultsText: PropTypes.string,
  oneResultText: PropTypes.string,
  resultsLength: PropTypes.number,
};

export default Intro;
