import styled, { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import { mapColourToSiteTheme as CM } from './colourMap.js';

// Had to add this to position/modify the SVG per site
const handleValuesBySite = (props, alignment) => {
  const { website } = props;
  const sites = {
    'london-marathon': {
      mobileSVGHeight: '100%',
      landscapeSVGWidth: '300px',
      desktopSVGWidth: '300px',
      desktopLargeSVGWidth: '300px',
      landscapeSVGRightPosition: '-50px',
      portraitLeftSVGTopPosition: 'calc(100% + 25px)',
      portraitLeftSVGRightosition: 'calc(100% + 2525px',
      svgLeftPosition: '-100px',
      landscapeTopPosition: '120px',
      desktopTopPosition: '150px',
      desktopLeftAlignedLeftPosition: '0',
      desktopRightAlignedRightPosition: '0',
      portraitMaxHeight: '300px',
      landscapeMaxHeight: '300px',
      portraitFeatureRightAlignedLeftPosition: '-200px',
      desktopLargeTopPosition: '150px',
    },
    'the-big-half': {
      mobileSVGHeight: '40%',
      landscapeSVGWidth: '200px',
      desktopSVGWidth: '170px',
      desktopLargeSVGWidth: '250px',
      landscapeSVGRightPosition: '0',
      portraitLeftSVGTopPosition: '75%',
      portraitRightSVGTopPosition: '25px',
      svgLeftPosition: '-100px',
      landscapeTopPosition: '120px',
      desktopTopPosition: '150px',
      desktopLeftAlignedLeftPosition: '0',
      desktopRightAlignedRightPosition: '0',
      portraitMaxHeight: '300px',
      landscapeMaxHeight: '300px',
      portraitFeatureRightAlignedLeftPosition: '-200px',
      desktopLargeTopPosition: '150px',
    },
    'london-10k': {
      mobileSVGHeight: '50%',
      landscapeSVGWidth: '200px',
      desktopSVGWidth: '170px',
      desktopLargeSVGWidth: '250px',
      landscapeSVGRightPosition: '0',
      portraitLeftSVGTopPosition: '75%',
      portraitRightSVGTopPosition: '25px',
      rotateMobile: 'rotate(180deg)',
      rotateDesktop: 'unset',
      svgLeftPosition: '-100px',
      landscapeTopPosition: '120px',
      desktopTopPosition: '150px',
      desktopLeftAlignedLeftPosition: '0',
      desktopRightAlignedRightPosition: '0',
      portraitMaxHeight: '300px',
      landscapeMaxHeight: '300px',
      portraitFeatureRightAlignedLeftPosition: '-200px',
      desktopLargeTopPosition: '150px',
    },
    'westminster-mile': {
      mobileSVGHeight: '60%',
      landscapeSVGWidth: '250px',
      desktopSVGWidth: '250px',
      desktopLargeSVGWidth: '300px',
      landscapeSVGRightPosition: '0',
      portraitLeftSVGTopPosition: '75%',
      portraitRightSVGTopPosition: '25px',
      svgLeftPosition: '-75px',
      landscapeTopPosition: '120px',
      desktopTopPosition: '150px',
      desktopLeftAlignedLeftPosition: '0',
      desktopRightAlignedRightPosition: '0',
      rotateDesktop: alignment === 'right' ? 'rotate(90deg)' : 'unset',
      portraitMaxHeight: '300px',
      landscapeMaxHeight: '300px',
      portraitFeatureRightAlignedLeftPosition: '-200px',
      desktopLargeTopPosition: '150px',
    },
    'swim-serpentine': {
      mobileSVGHeight: '45%',
      landscapeSVGWidth: '200px',
      desktopSVGWidth: '170px',
      desktopLargeSVGWidth: '250px',
      landscapeSVGRightPosition: '0',
      portraitLeftSVGTopPosition: '75%',
      portraitRightSVGTopPosition: '25px',
      rotateMobile: 'unset',
      rotateDesktop: alignment === 'right' ? 'rotate(90deg)' : 'unset',
      svgLeftPosition: '-85px',
      landscapeTopPosition: '150px',
      desktopTopPosition: '190px',
      desktopLeftAlignedLeftPosition: '0',
      desktopRightAlignedRightPosition: '0',
      portraitMaxHeight: '300px',
      landscapeMaxHeight: '300px',
      portraitFeatureRightAlignedLeftPosition: '-200px',
      desktopLargeTopPosition: '190px',
    },
    'ride-london': {
      mobileSVGHeight: '50%',
      portraitLeftSVGTopPosition: '65%',
      portraitRightSVGTopPosition: '25px',
      portraitMaxHeight: '250px',
      landscapeSVGWidth: '170px',
      landscapeTopPosition: '120px',
      landscapeMaxHeight: '300px',
      landscapeSVGRightPosition: '0',
      desktopTopPosition: '180px',
      desktopLeftAlignedLeftPosition: '-22px',
      desktopRightAlignedRightPosition: '-30px',
      desktopSVGWidth: '225px',
      desktopLargeSVGWidth: '230px',
      desktopLargeTopPosition: '230px',
      svgLeftPosition: '-85px',
      portraitFeatureRightAlignedLeftPosition: '-100px',
    },
    'city-race': {
      mobileSVGHeight: '50%',
      portraitLeftSVGTopPosition: '70%',
      portraitRightSVGTopPosition: '0px',
      portraitMaxHeight: '250px',
      portraitFeatureRightAlignedLeftPosition: '-100px',
      landscapeSVGWidth: '170px',
      landscapeTopPosition: '150px',
      landscapeMaxHeight: '300px',
      landscapeSVGRightPosition: '0',
      desktopTopPosition: '150px',
      desktopLeftAlignedLeftPosition: '0px',
      desktopRightAlignedRightPosition: '0px',
      desktopSVGWidth: '200px',
      desktopLargeSVGWidth: '200px',
      desktopLargeTopPosition: '200px',
      svgLeftPosition: '-85px',
    },
    'lm-foundation': {
      mobileSVGHeight: '80%',
      landscapeSVGWidth: '300px',
      desktopSVGWidth: '350px',
      desktopLargeSVGWidth: '400px',
      landscapeSVGRightPosition: '-50px',
      portraitLeftSVGTopPosition: '85%',
      portraitRightSVGTopPosition: '25px',
      svgLeftPosition: '-100px',
      landscapeTopPosition: '120px',
      desktopTopPosition: '150px',
      desktopLeftAlignedLeftPosition: '0',
      desktopRightAlignedRightPosition: '-25px',
      portraitMaxHeight: '300px',
      landscapeMaxHeight: '300px',
      portraitFeatureRightAlignedLeftPosition: '-200px',
      desktopLargeTopPosition: '150px',
    },
  };

  if (website in sites) {
    return sites[website];
  }

  return sites['london-marathon'];
};

export const ComponentWrapper = styled.section`
  width: 100%;
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};
  overflow: hidden;

  ${breakpoints.tabletLandscape} {
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
  }

  ${breakpoints.desktopLarge} {
    min-width: calc(100% - 230px);
  }
`;

export const SitecoreLink = styled(Link)``;

export const FeatureComponent = styled.div`
  position: relative;
  grid-column: 1 / -1;
  margin-right: ${({ alignment }) => (alignment === 'left' ? '4px' : 'unset')};
  margin-left: ${({ alignment }) => (alignment === 'right' ? '2px' : 'unset')};

  ${breakpoints.tabletPortrait} {
    display: flex;
    flex-direction: column;
  }

  ${breakpoints.tabletLandscape} {
    flex-direction: ${props => (props.alignment === 'left' ? 'row' : 'row-reverse')};
  }

  ${breakpoints.desktopMedium} {
    margin-left: none;
    margin-right: none;
  }
`;

export const ImageWrap = styled.div`
  position: relative;

  ${breakpoints.tabletPortrait} {
    flex: 1;
    margin-right: ${props => (props.alignment === 'right' ? 0 : props.theme.px(258))};
    margin-left: ${props => (props.alignment === 'left' ? 0 : props.theme.px(258))};
  }

  ${breakpoints.tabletLandscape} {
    margin-right: auto;
    margin-left: auto;
  }
`;

export const ImageContainer = styled.div`
  height: 0;
  margin: 0;
  padding-top: calc(258 / 328 * 100%);
  overflow: hidden;

  ${breakpoints.tabletPortrait} {
    padding-top: calc(428 / 510 * 100%);
  }

  ${breakpoints.tabletLandscape} {
    padding-top: calc(311 / 290 * 100%);
  }

  picture {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    img {
      width: calc(100% - 50px);
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transform: ${props => `${props.alignment === 'left' ? 'translateY(25px)' : 'translate(50px, 25px)'}`};
      ${breakpoints.tabletPortrait} {
        width: 100%;
        transform: translateY(50px);
      }

      ${breakpoints.tabletLandscape} {
        width: calc(100% + 30px);
        height: auto;
        transform: ${props =>
          `${props.alignment === 'left' ? 'translateY(-50px)' : 'translate(-30px, -50px)'}`};
      }

      ${breakpoints.desktopSmall} {
        width: 100%;
        height: 100%;
        transform: ${props =>
          `${props.alignment === 'left' ? 'translate(60px, -50px)' : 'translate(-60px, -50px)'}`};
      }

      ${breakpoints.desktopLarge} {
        transform: ${props =>
          `${props.alignment === 'left' ? 'translate(75px, -50px)' : 'translate(-75px, -50px)'}`};
      }
    }
  }
`;

export const VideoWrapper = styled.div`
  --offset-x: ${({ theme: { px } }) => px(0)};
  --offset-y: ${({ alignment, theme: { px } }) => px(alignment === 'left' ? 25 : 50)};

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform: translateX(var(--offset-x)) translateY(var(--offset-y));

  ${breakpoints.tabletPortrait} {
    --offset-y: ${({ theme: { px } }) => px(50)};
  }

  ${breakpoints.tabletLandscape} {
    --offset-x: ${({ alignment, theme: { px } }) => px(alignment === 'left' ? 0 : -30)};
    --offset-y: ${({ alignment, theme: { px } }) => px(alignment === 'left' ? -50 : -30)};

    width: ${({ theme: { px } }) => `calc(100% + ${px(30)})`};
  }

  ${breakpoints.desktopSmall} {
    --offset-x: ${({ alignment, theme: { px } }) => px(alignment === 'left' ? 60 : -60)};
    --offset-y: ${({ theme: { px } }) => px(-50)};

    width: 100%;
  }

  ${breakpoints.desktopLarge} {
    --offset-x: ${({ alignment, theme: { px } }) => px(alignment === 'left' ? 75 : -75)};
  }

  .react-player {
    position: relative;
    left: 50%;
    top: 50%;
    width: 100%;
    height: 100%;
    transform: translate(-50%, -50%);
  }
`;

export const PatternWrap = styled.div`
  position: absolute;
  top: 0;
  left: ${props => handleValuesBySite(props.theme).svgLeftPosition};
  width: auto;
  height: 100%;

  ${breakpoints.tabletPortrait} {
    top: ${props =>
      `${
        props.alignment === 'left'
          ? handleValuesBySite(props.theme).portraitLeftSVGTopPosition
          : handleValuesBySite(props.theme).portraitRightSVGTopPosition
      }`};
    left: ${props =>
      `${
        props.alignment === 'left'
          ? '-50px'
          : handleValuesBySite(props.theme).portraitFeatureRightAlignedLeftPosition
      }`};
    max-height: ${({ theme }) => handleValuesBySite(theme).portraitMaxHeight};
  }

  ${breakpoints.tabletLandscape} {
    top: ${props => handleValuesBySite(props.theme).landscapeTopPosition};
    right: ${props =>
      `${props.alignment === 'right' ? handleValuesBySite(props.theme).landscapeSVGRightPosition : 'auto'}`};
    left: unset;
    max-height: ${({ theme }) => handleValuesBySite(theme).landscapeMaxHeight};
  }

  ${breakpoints.desktopSmall} {
    top: ${props => handleValuesBySite(props.theme).desktopTopPosition};
    right: ${props => `${props.alignment === 'right' ? 0 : 'auto'}`};
    left: ${props => `${props.alignment === 'left' ? 0 : 'auto'}`};
    max-height: none;
  }

  ${breakpoints.desktopLarge} {
    right: ${props =>
      `${
        props.alignment === 'right'
          ? handleValuesBySite(props.theme).desktopRightAlignedRightPosition
          : 'auto'
      }`};
    left: ${props =>
      `${
        props.alignment === 'left' ? handleValuesBySite(props.theme).desktopLeftAlignedLeftPosition : 'auto'
      }`};
  }

  ${breakpoints.desktopLarge} {
    top: ${props => handleValuesBySite(props.theme).desktopLargeTopPosition};
  }

  svg {
    width: 100%;
    height: ${props => handleValuesBySite(props.theme).mobileSVGHeight};
    transform: ${props => handleValuesBySite(props.theme)?.rotateMobile};

    ${breakpoints.tabletLandscape} {
      transform: ${props => handleValuesBySite(props.theme, props.alignment)?.rotateDesktop};
    }

    path {
      fill: ${props => props.theme.colors.named[props.patternColor]};
    }

    g {
      fill: ${props =>
        props.theme.website === 'london-marathon'
          ? 'transparent'
          : props.theme.colors.named[props.patternColor]};
    }

    ${breakpoints.tabletPortrait} {
      height: 100%;
    }

    ${breakpoints.tabletLandscape} {
      width: ${props => handleValuesBySite(props.theme).landscapeSVGWidth};
    }

    ${breakpoints.desktopSmall} {
      width: ${props => handleValuesBySite(props.theme).desktopSVGWidth};
    }

    /* Had to add this in for a specific issue where the SVG would sometimes be cropped. */
    @media screen and (min-width: 1800px) {
      width: ${props => handleValuesBySite(props.theme).desktopLargeSVGWidth};
    }
  }
`;

export const Content = styled.div`
  margin-right: ${props => (props.alignment === 'left' ? 0 : props.theme.space())};
  margin-left: ${props => (props.alignment === 'right' ? 0 : props.theme.space())};
  padding: ${props => props.theme.space(1.5)} ${props => props.theme.space()};
  background-color: ${props => CM(props.theme).contentBackgroundColor};
  border: ${props => CM(props.theme).contentBorderColor};
  background-image: ${({ theme, gradient }) =>
    theme.website === 'lm-foundation' ? theme.gradients[gradient ?? 'preparation'] : ''};

  ${props =>
    props.theme.website === 'lm-foundation'
      ? css`
          color: ${props => props.theme.colors.primary[300]};
          h1 {
            color: ${props => props.theme.colors.primary[300]};
          }
        `
      : ''}

  ${breakpoints.tabletPortrait} {
    flex: 1.5;
    margin-top: ${props => props.theme.space(-1.5)};
    margin-right: ${props => (props.alignment === 'left' ? 0 : props.theme.px(164))};
    margin-left: ${props => (props.alignment === 'right' ? 0 : props.theme.px(164))};
  }

  ${breakpoints.tabletLandscape} {
    margin-top: ${props => props.theme.space(1.5)};
    margin-right: 0;
    margin-left: 0;
  }

  ${breakpoints.desktopSmall} {
    margin-top: ${props => props.theme.space(2)};
    margin-right: ${props => (props.alignment === 'left' ? props.theme.px(60) : 0)};
    margin-left: ${props => (props.alignment === 'right' ? props.theme.px(60) : 0)};
  }

  ${breakpoints.desktopLarge} {
    margin-right: ${props => (props.alignment === 'left' ? props.theme.space(3) : 0)};
    margin-left: ${props => (props.alignment === 'right' ? props.theme.space(3) : 0)};
  }

  & * {
    ${props => props.theme.setFont('featureBody')}
    margin: 0;
  }
`;

export const ContentWrap = styled.div`
  ${breakpoints.tabletPortrait} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin-top: ${props => props.theme.px(100)};
    padding-right: ${props => (props.alignment === 'left' ? props.theme.px(26) : props.theme.px(39))};
    padding-left: ${props => (props.alignment === 'right' ? props.theme.px(26) : props.theme.px(39))};

    ${breakpoints.tabletLandscape} {
      margin-top: 0;
      padding-right: ${props => (props.alignment === 'left' ? props.theme.px(20) : props.theme.px(70))};
      padding-left: ${props => (props.alignment === 'right' ? props.theme.px(20) : props.theme.px(70))};
    }

    ${breakpoints.desktopSmall} {
      padding-right: ${props => (props.alignment === 'left' ? props.theme.px(104) : props.theme.px(130))};
      padding-left: ${props => (props.alignment === 'right' ? props.theme.px(104) : props.theme.px(130))};
    }
  }
`;

export const TitleWrap = styled.div`
  margin-top: ${props => props.theme.space()};

  ${breakpoints.desktopSmall} {
    margin-top: 0;
  }

  & * {
    margin-bottom: ${props => props.theme.space(0.5)};
    ${props => props.theme.setFont('featureHeading')};
    ${props =>
      props.theme.website !== 'lm-foundation'
        ? css`
            ${breakpoints.tabletPortrait} {
              font-size: 30px;
              line-height: 39px;
            }

            ${breakpoints.desktopSmall} {
              font-size: 40px;
              line-height: 56px;
            }
          `
        : ``}
  }
`;

export const LinkWrap = styled.div`
  position: relative;
  display: flex;
  padding: 0;
  align-items: center;
  margin-top: ${props => props.theme.space()};

  ${breakpoints.desktopSmall} {
    margin-top: ${props => props.theme.space(2)};
  }
`;

export const LinkInner = styled.div`
  position: relative;
  display: flex;
  padding: 0;
  flex-direction: row-reverse;
  align-items: center;

  ${SitecoreLink}:hover + svg {
    transform: translateX(5px);
    transition: transform 0.5s ease;
    stroke: currentColor;
    stroke-width: 0.7;
    color: ${props => props.theme.colors.named[props.ctaHoverColour]};
    fill: ${props => props.theme.colors.named[props.ctaHoverColour]};
  }

  > svg {
    top: 0px;
    transform: scale(1);
    stroke: currentColor;
    stroke-width: 0.7;
    margin-right: ${props => props.theme.px(5)};
    color: ${props => props.theme.colors.named[props.ctaColour]};
    fill: ${props => props.theme.colors.named[props.ctaColour]};
  }

  a {
    display: inline-block;
    ${props => props.theme.setFont('featureLink')}
    color: ${props => CM(props.theme).featureLinkColor};
    font-weight: 700;
    text-decoration: none;

    &:hover {
      color: ${props => CM(props.theme).featureLinkHoverColor};
    }
  }
`;
