import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const DynamicsFormComponent = styled.section`
  width: calc(100% - 50px);
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};

  h1 {
    ${props => props.theme.setFont('h3')}
  }

  ${breakpoints.tabletLandscape} {
    width: calc(100% - 280px);
    max-width: 1246px !important;
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
  }
`;

export const FormWrap = styled.div`
  display: flex;
  flex-direction: column;

  ${breakpoints.desktopSmall} {
    flex-direction: row;
  }
`;

export const FormContainer = styled.div`
  width: 100%;
  height: 480px;
  min-height: 480px;

  ${breakpoints.desktopSmall} {
    margin-right: ${props => props.theme.space(3)};
  }
`;

export const Panel = styled.aside`
  width: 100%;
  margin-top: ${props => props.theme.space()};
  padding: ${props => props.theme.space(2)};
  background-color: ${props => CM(props.theme).panelBackgroundColor};

  ${breakpoints.desktopSmall} {
    width: 404px;
    min-width: 404px;
    margin-top: 0;
  }

  h2 {
    ${props => props.theme.setFont('bodyExtraSmall')}
    color: ${props => CM(props.theme).panelTitleColor};
  }
`;
