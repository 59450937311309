import { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const LMEIconStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0;
  background: transparent;
  border: none;
  transform: translate(-50%, -50%);
  cursor: pointer;

  ${breakpoints.tabletLandscape} {
    position: relative;
    top: auto;
    right: 0;
    bottom: 0;
    left: auto;
    display: flex;
    flex: 1;
    align-items: flex-end;
    align-self: flex-end;
    justify-content: flex-end;
    transform: none;
  }

  ${breakpoints.desktopSmall} {
    right: ${props => props.theme.space(2)};
    bottom: ${props => props.theme.space()};
  }

  svg {
    width: 45px;

    ${breakpoints.tabletLandscape} {
      width: 100%;
      height: 100%;
    }

    &:first-child {
      position: absolute;
      width: 75px;
      height: 75px;

      ${breakpoints.tabletLandscape} {
        width: 120px;
        height: 120px;
        transform: ${props => (props.imageHovered ? 'translateY(-20px)' : 'unset')};
        transition: transform 0.3s ease-out;
      }

      ${breakpoints.desktopSmall} {
        width: 175px;
        height: 175px;
      }

      path {
        &:last-child {
          fill: ${props => CM(props.theme).svgColorA};
        }
      }
    }

    &:not(:first-child) {
      display: none;
      transform: none;

      ${breakpoints.tabletLandscape} {
        display: flex;
        width: auto;
        height: ${props => (props.imageHovered ? '250px' : '200px')};
        transition: height 0.3s ease-out;
      }

      path {
        fill: ${props => CM(props.theme).svgColorB};
      }
    }
  }
`;

export const TheBigHalfIconStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0;
  background: transparent;
  border: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.3s;

  ${breakpoints.tabletLandscape} {
    position: relative;
    top: auto;
    right: 0;
    bottom: ${props => props.theme.space(7)};
    left: auto;
    display: flex;
    flex: 1;
    align-items: flex-end;
    align-self: flex-end;
    justify-content: flex-end;
    transform: none;
  }

  ${breakpoints.desktopSmall} {
    right: 0;
    bottom: ${props => (props.imageHovered ? props.theme.space(4) : props.theme.space(2))};
  }

  svg {
    width: 75px;

    ${breakpoints.tabletLandscape} {
      width: 100%;
      height: 100%;
    }

    &:first-child {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 90px;
      height: 90px;
      transform: translate(-50%, -50%);

      ${breakpoints.tabletLandscape} {
        top: 10px;
        left: -40px;
        width: 150px;
        height: 150px;
        transform: unset;
      }

      ${breakpoints.desktopSmall} {
        top: -10px;
        left: -85px;
        width: 175px;
        height: 175px;
      }

      path {
        &:last-child {
          fill: ${props => CM(props.theme).svgColorA};
        }
      }
    }

    &:not(:first-child) {
      position: absolute;
      display: none;

      ${breakpoints.tabletLandscape} {
        top: -50px;
        left: -150px;
        display: flex;
        width: 250px;
        height: 250px;
      }

      ${breakpoints.desktopSmall} {
        top: -82px;
        left: -200px;
        width: 250px;
        height: 300px;
      }

      path {
        fill: ${props => CM(props.theme).svgColorB};
      }
    }
  }
`;

export const London10kIconStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0;
  background: transparent;
  border: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.3s;

  ${breakpoints.tabletLandscape} {
    position: relative;
    top: auto;
    right: 0;
    bottom: ${props => props.theme.space(7)};
    left: auto;
    display: flex;
    flex: 1;
    align-items: flex-end;
    align-self: flex-end;
    justify-content: flex-end;
    transform: none;
  }

  ${breakpoints.desktopSmall} {
    right: 0;
    bottom: ${props => (props.imageHovered ? props.theme.space(4) : props.theme.space(2))};
  }

  svg {
    width: 75px;

    ${breakpoints.tabletLandscape} {
      width: 100%;
      height: 100%;
    }

    &:first-child {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 90px;
      height: 90px;
      transform: translate(-50%, -50%);

      ${breakpoints.tabletLandscape} {
        top: 10px;
        left: -40px;
        width: 150px;
        height: 150px;
        transform: unset;
      }

      ${breakpoints.desktopSmall} {
        top: -10px;
        left: -85px;
        width: 175px;
        height: 175px;
      }

      path {
        &:last-child {
          fill: ${props => CM(props.theme).svgColorA};
        }
      }
    }

    &:not(:first-child) {
      position: absolute;
      width: 175px;

      ${breakpoints.mobile} {
        width: 250px;
      }

      ${breakpoints.tabletLandscape} {
        top: -35px;
        left: -100px;
        display: flex;
        width: 250px;
        height: 250px;
      }

      ${breakpoints.desktopSmall} {
        top: -100px;
        left: -200px;
        width: 350px;
        height: 350px;
      }

      path {
        fill: ${props => CM(props.theme).svgColorB};
      }
    }
  }
`;

export const WestminsterMileIconStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0;
  background: transparent;
  border: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.3s;

  ${breakpoints.tabletLandscape} {
    position: relative;
    top: auto;
    right: 0;
    bottom: ${props => props.theme.space(7)};
    left: auto;
    display: flex;
    flex: 1;
    align-items: flex-end;
    align-self: flex-end;
    justify-content: flex-end;
    transform: none;
  }

  ${breakpoints.desktopSmall} {
    right: 0;
    bottom: ${props => (props.imageHovered ? props.theme.space(4) : props.theme.space(2))};
  }

  svg {
    width: 75px;

    ${breakpoints.tabletLandscape} {
      width: 100%;
      height: 100%;
    }

    &:first-child {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 90px;
      height: 90px;
      transform: translate(-50%, -50%);

      ${breakpoints.tabletLandscape} {
        top: 10px;
        left: -40px;
        width: 150px;
        height: 150px;
        transform: unset;
      }

      ${breakpoints.desktopSmall} {
        top: -35px;
        left: -85px;
        width: 175px;
        height: 175px;
      }

      path {
        &:last-child {
          fill: ${props => CM(props.theme).svgColorA};
        }
      }
    }

    &:not(:first-child) {
      position: absolute;
      width: 120px;

      ${breakpoints.mobile} {
        width: 175px;
      }

      ${breakpoints.tabletLandscape} {
        top: -35px;
        left: -100px;
        display: flex;
        width: 250px;
        height: 250px;
      }

      ${breakpoints.desktopSmall} {
        top: -100px;
        left: -150px;
        width: 300px;
        height: 300px;
      }

      path {
        fill: ${props => CM(props.theme).svgColorB};
      }
    }
  }
`;

export const SwimSerpentineIconStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0;
  background: transparent;
  border: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.3s;

  ${breakpoints.tabletLandscape} {
    position: relative;
    top: auto;
    right: 0;
    bottom: ${props => props.theme.space(7)};
    left: auto;
    display: flex;
    flex: 1;
    align-items: flex-end;
    align-self: flex-end;
    justify-content: flex-end;
    transform: none;
  }

  ${breakpoints.desktopSmall} {
    right: 0;
    bottom: ${props => (props.imageHovered ? props.theme.space(4) : props.theme.space(2))};
  }

  svg {
    width: 140px;

    ${breakpoints.tabletLandscape} {
      width: 100%;
      height: 100%;
    }

    &:first-child {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 90px;
      height: 90px;
      transform: translate(-50%, -50%);

      ${breakpoints.tabletLandscape} {
        top: -10px;
        left: -40px;
        width: 120px;
        height: 120px;
        transform: unset;
      }

      ${breakpoints.desktopSmall} {
        top: -10px;
        left: -85px;
        width: 175px;
        height: 175px;
      }

      path {
        &:last-child {
          fill: ${props => CM(props.theme).svgColorA};
        }
      }
    }

    &:not(:first-child) {
      position: absolute;
      left: calc(50% + 10px);
      transform: translateX(-50%);

      ${breakpoints.tabletLandscape} {
        top: -50px;
        left: -75px;
        display: flex;
        width: 200px;
        height: 225px;
        transform: unset;
      }

      ${breakpoints.desktopSmall} {
        top: ${props => (props.imageHovered ? '-100px' : '-65px')};
        left: -120px;
        width: 275px;
        height: 300px;
        transition: top 0.3s ease-out;
      }

      path {
        fill: ${props => CM(props.theme).svgColorB};
      }
    }
  }
`;

export const RideLondonIconStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0;
  background: transparent;
  border: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.3s;

  ${breakpoints.tabletLandscape} {
    position: relative;
    top: auto;
    right: 0;
    bottom: ${props => props.theme.space(7)};
    left: auto;
    display: flex;
    flex: 1;
    align-items: flex-end;
    align-self: flex-end;
    justify-content: flex-end;
    transform: none;
  }

  ${breakpoints.desktopSmall} {
    right: 0;
    bottom: ${props => (props.imageHovered ? props.theme.space(4) : props.theme.space(2))};
  }

  svg {
    width: 250px;

    ${breakpoints.tabletLandscape} {
      width: 100%;
      height: 100%;
    }

    &:first-child {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 90px;
      height: 90px;
      transform: translate(-50%, -50%);

      ${breakpoints.tabletLandscape} {
        top: -10px;
        left: -40px;
        width: 120px;
        height: 120px;
        transform: unset;
      }

      ${breakpoints.desktopSmall} {
        top: -10px;
        left: -85px;
        width: 175px;
        height: 175px;
      }

      path {
        &:last-child {
          fill: ${props => CM(props.theme).svgColorA};
        }
      }
    }

    &:not(:first-child) {
      position: absolute;
      left: calc(50% + 10px);
      transform: translateX(-50%);

      ${breakpoints.tabletLandscape} {
        top: -60px;
        left: -120px;
        display: flex;
        width: 300px;
        height: 225px;
        transform: unset;
      }

      ${breakpoints.desktopSmall} {
        top: ${props => (props.imageHovered ? '-60px' : '-65px')};
        left: -200px;
        width: 425px;
        height: 300px;
        transition: top 0.3s ease-out;
      }
    }
  }
`;

export const CityRaceIconStyles = css`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  display: flex;
  align-items: center;
  padding: 0;
  background: transparent;
  border: none;
  transform: translate(-50%, -50%);
  cursor: pointer;
  transition: all 0.3s;

  ${breakpoints.tabletLandscape} {
    position: relative;
    top: auto;
    right: 0;
    bottom: ${props => props.theme.space(5)};
    left: auto;
    display: flex;
    flex: 1;
    align-items: flex-end;
    align-self: flex-end;
    justify-content: flex-end;
    transform: none;
  }

  ${breakpoints.desktopSmall} {
    right: 0;
    bottom: ${props => (props.imageHovered ? props.theme.space(4) : props.theme.space(2))};
  }

  svg {
    width: 250px;

    ${breakpoints.tabletLandscape} {
      width: 100%;
      height: 100%;
    }

    &:first-child {
      position: absolute;
      top: 50%;
      left: 50%;
      z-index: 1;
      width: 90px;
      height: 90px;
      transform: translate(-50%, -50%);

      ${breakpoints.tabletLandscape} {
        top: -10px;
        left: -40px;
        width: 120px;
        height: 120px;
        transform: unset;
      }

      ${breakpoints.desktopSmall} {
        top: -10px;
        left: -85px;
        width: 175px;
        height: 175px;
      }

      path {
        &:last-child {
          fill: ${props => CM(props.theme).svgColorA};
        }
      }
    }

    &:not(:first-child) {
      position: absolute;
      display: none;
      transform: none;

      ${breakpoints.tabletLandscape} {
        top: -50px;
        left: -80px;
        display: flex;
        width: 200px;
        height: 200px;
        transform: unset;
      }

      ${breakpoints.desktopSmall} {
        top: ${props => (props.imageHovered ? '-60px' : '-65px')};
        left: -120px;
        width: 250px;
        height: 250px;
        transition: top 0.3s ease-out;
      }
    }
  }
`;
