import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Placeholder, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { getIcon } from '../utils/icon';
import { HeaderAdsComponent, CloseContainer, AdContainer } from './HeaderAds.styles.js';
import { isServer } from '@sitecore-jss/sitecore-jss';

const HeaderAds = props => {
  const { route = {}, sitecoreContext: { pageEditing = false } = {} } = props;
  const [adsHidden, setAdsHidden] = useState(false);
  const headerAddRef = useRef(null);

  const bannerAds1 = route?.placeholders?.['jss-banner-ad-1'];
  const bannerAds2 = route?.placeholders?.['jss-banner-ad-2'];

  useEffect(() => {
    if (!isServer()) {
      setAdsHidden(sessionStorage.getItem('adsHidden') === 'true');
    }
  }, []);

  // Hide header ads if no banner ads in route.
  if (!pageEditing && (!bannerAds1 || bannerAds1.length === 0) && (!bannerAds2 || bannerAds2.length === 0)) {
    return null;
  }

  const closeAds = () => {
    sessionStorage.setItem('adsHidden', true);
    setAdsHidden(true);
    calculateMobileNavStyle();
  };

  const calculateMobileNavStyle = () => {
    const navPos = document.querySelector('.header-navbar').getBoundingClientRect();
    const mobileNavRef = document.querySelector('.header-mobileNav');

    if (mobileNavRef) {
      mobileNavRef.style.top = `${navPos.top + navPos.height - 134}px`;
      mobileNavRef.style.height = `calc(100% - ${navPos.top + navPos.height - 134}px)`;
    }
  };

  useEffect(() => {
    if (adsHidden) {
      headerAddRef.current.classList.add('hidden');
    } else {
      headerAddRef.current.classList.remove('hidden');
    }
  }, [adsHidden]);

  return (
    <HeaderAdsComponent id="header-ads" className="hidden" ref={headerAddRef}>
      <AdContainer editing={pageEditing}>
        <div>
          <Placeholder name="jss-banner-ad-1" rendering={route} />
        </div>
        <div>
          <Placeholder name="jss-banner-ad-2" rendering={route} />
        </div>
      </AdContainer>
      <CloseContainer onClick={closeAds}>{getIcon('clear')}</CloseContainer>
    </HeaderAdsComponent>
  );
};

HeaderAds.propTypes = {
  route: PropTypes.object,
  sitecoreContext: PropTypes.object,
};

export default withSitecoreContext()(HeaderAds);
