import React from 'react';
import PropTypes from 'prop-types';
import AdSlot from 'lmel-components/core/ads';
import ErrorBoundary from 'lmel-components/core/error-boundary';

const Ad = props => {
  const { fields, params } = props;

  return (
    <ErrorBoundary>
      <AdSlot {...fields} params={params} />
    </ErrorBoundary>
  );
};

Ad.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
};

export default Ad;
