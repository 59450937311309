import React from 'react';
import HeroBasicComponent from 'lmel-components/core/hero-basic';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const HeroBasic = props => {
  return (
    <ErrorBoundary>
      <HeroBasicComponent {...props} />
    </ErrorBoundary>
  );
};

export default withSitecoreContext()(HeroBasic);
