import styled, { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const ArticleCardComponent = styled.div`
  position: relative;
  top: 0;
  left: 0;
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .read-time-mobile-hide {
    display: none;

    ${breakpoints.tabletLandscape} {
      display: flex;
    }
  }
`;

const wrapperStyles = css`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: ${props => props.theme.px(25)};

  .variant-d & {
    ${breakpoints.tabletLandscape} {
      padding: ${props => props.theme.px(52)};
    }
  }

  picture,
  .sc-image-wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    transform: scale(1);
    transition: transform 0.3s ease-in;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  img.card-image {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    transform: scale(1);
    transition: transform 0.3s ease-in;

    &.hasHover {
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  &:hover {
    .hasHover {
      transform: scale(1.1);
    }
  }
`;

export const ArticleWrap = styled.div`
  ${wrapperStyles}
`;

export const ArticleLinkWrap = styled.a`
  ${wrapperStyles}
  text-decoration: none;

  &.js-focus-visible &.focus-visible,
  &:focus-visible {
    border: 2px solid ${props => CM(props.theme).borderColor};
  }
`;
