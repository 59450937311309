import Actions from './actions';

export function depthFirst(callback, action) {
  const { forest, isArray } = this.self();
  const result = [];
  let found = false;
  let next = () => {};

  const iterate = (element, depth) => {
    const keys = Object.keys(element);
    let hasChildren = false;

    for (let i = 0; i < keys.length; i++) {
      const prop = element[keys[i]];
      if (Array.isArray(prop)) {
        for (let j = 0; j < prop.length; j++) {
          if (this.isObject(prop[j])) {
            next(prop[j], depth + 1, { element: prop, key: j });
            hasChildren = true;
          }
          if (found) break;
        }
      } else if (this.isObject(prop)) {
        next(prop, depth + 1, { element, key: keys[i] });
        hasChildren = true;
      }
      if (found) break;
    }

    return hasChildren;
  };

  if (action === Actions.MAP) {
    next = (el, depth, pos) => {
      let hasChildren = iterate(el, depth);
      if (!hasChildren) {
        let value = callback(el, depth, pos);
        result.push(value);
      }
    };
  } else if (action === Actions.FIND) {
    next = (el, depth, pos) => {
      let hasChildren = iterate(el, depth);
      if (!hasChildren) {
        let value = callback(el, depth, pos);
        if (value) {
          result.push(el);
          found = true;
        }
      }
    };
  } else if (action === Actions.FIND_ALL) {
    next = (el, depth, pos) => {
      let hasChildren = iterate(el, depth);
      if (!hasChildren) {
        let value = callback(el, depth, pos);
        if (value) {
          result.push(el);
        }
      }
    };
  } else if (action === Actions.REMOVE || action === Actions.REMOVE_ALL) {
    next = (el, depth, pos) => {
      let hasChildren = iterate(el, depth);
      if (!hasChildren) {
        let value = callback(el, depth, pos);
        if (value && pos.element) {
          if (Array.isArray(pos.element)) {
            result.push(pos.element[pos.key]);
            pos.element.splice(pos.key, 1);
          } else {
            result.push({ ...pos.element[pos.key] });
            delete pos.element[pos.key];
          }
          if (action === Actions.REMOVE) {
            found = true;
          }
        }
      }
    };
  } else {
    next = (el, depth, pos) => {
      let hasChildren = iterate(el, depth);
      if (!hasChildren) {
        callback(el, depth, pos);
      }
    };
  }

  if (isArray) {
    for (let i = 0; i < forest.length; i++) {
      if (this.isObject(forest[i])) {
        next(forest[i], 0, { element: forest, key: i });
      }
    }
  } else if (this.isObject(forest)) {
    next(forest, 0, callback, {});
  }

  return action === Actions.MAP
    ? result
    : action === Actions.FIND
    ? result[0]
    : action === Actions.FIND_ALL
    ? result
    : action === Actions.REMOVE
    ? result[0]
    : action === Actions.REMOVE_ALL
    ? result
    : undefined;
}
