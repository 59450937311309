import React from 'react';
import ImageCarouselComponent from 'lmel-components/core/image-carousel';
import ErrorBoundary from 'lmel-components/core/error-boundary';

const ImageCarousel = props => {
  return (
    <ErrorBoundary>
      <ImageCarouselComponent {...props} />
    </ErrorBoundary>
  );
};

export default ImageCarousel;
