import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';

import {
  ImageGalleryContainer,
  ImageGalleryComponent,
  MainContainer,
  Title,
  NextPrevButton,
  ImageContainer,
  ImageItem,
  SlideText,
  SwipeText,
} from './ImageGallery.styles.js';
import Picture from '../picture';
import { useIsMobile } from '../hooks/useIsMobile';
import { getIcon } from '../utils/icon';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { withTheme } from 'styled-components';

const ImageGallery = props => {
  const {
    fields: { title = {}, items = [] } = {},
    params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
    translate = {},
    theme: { website },
  } = props;

  const t = key => {
    if (translate && typeof translate === 'function') {
      return translate(`ImageGallery_${key}`);
    }

    return key;
  };

  const swipeText = t('Swipe');

  const isMobile = useIsMobile();
  const [currentSlide, setCurrentSlide] = useState(0);
  const carouselRef = useRef();
  const singleImage = items.length < 2;
  const slides = singleImage ? [...items] : [...items, items[0]];

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  const moveCarouselTo = (xPos, instant = false) => {
    carouselRef.current.style.transitionDuration = instant ? '0s' : '0.5s';
    carouselRef.current.style.transform = `translateX(-${xPos}px)`;
  };

  const gotoSlide = dir => {
    if (singleImage) {
      return;
    }
    let nextSlide = currentSlide + dir;
    if (nextSlide < 0) {
      return;
    }
    dataLayerEvent('imageGalleryAction', { cardAction: dir < 0 ? 'left' : 'right' });
    const oneSlideWidth = carouselRef.current.children[0].getBoundingClientRect().width + 13;
    moveCarouselTo(oneSlideWidth * nextSlide);
    setCurrentSlide(nextSlide);
  };

  const handleSlideEnd = () => {
    if (currentSlide === slides.length - 1) {
      moveCarouselTo(0, true);
      setCurrentSlide(0);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => gotoSlide(1),
    onSwipedRight: () => gotoSlide(-1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const handleKeyUp = e => {
    switch (e.key) {
      case 'ArrowLeft':
        gotoSlide(-1);
        break;
      case 'ArrowRight':
        gotoSlide(1);
        break;
    }
  };

  const titleId = `ImageGalleryTitle_${new Date().getTime()}`;

  return (
    <ImageGalleryContainer
      className="limitedWidth"
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      <ImageGalleryComponent aria-labelledby={titleId} tabIndex="0" onKeyUp={handleKeyUp} {...swipeHandlers}>
        <MainContainer>
          {title && currentSlide === 0 && (
            <Title id={titleId}>
              <Text field={title} />
            </Title>
          )}
          {!isMobile && !singleImage && (
            <NextPrevButton aria-label="Next image" onClick={() => gotoSlide(1)}>
              {getIcon('arrowLong')}
            </NextPrevButton>
          )}
          {currentSlide > 0 && !isMobile && !singleImage && (
            <NextPrevButton aria-label="Previous image" previous onClick={() => gotoSlide(-1)}>
              {getIcon('arrowLong')}
            </NextPrevButton>
          )}
          <ImageContainer
            ref={carouselRef}
            singleImage={singleImage}
            imageCount={slides.length}
            onTransitionEnd={handleSlideEnd}
          >
            {slides.map((slide, i) => (
              <ImageItem key={i} itemNumber={i} imageCount={slides.length}>
                <Picture field={slide?.fields?.image} />
              </ImageItem>
            ))}
          </ImageContainer>
        </MainContainer>
        <SlideText singleImage={singleImage}>
          {slides[currentSlide]?.fields && <Text field={slides[currentSlide]?.fields?.description} />}
          {website == 'lm-foundation' && (
            <span>
              {slides[currentSlide + 1]?.fields && slides.length > currentSlide + 1 && (
                <Text field={slides[currentSlide + 1]?.fields?.description} />
              )}
              {slides[0]?.fields && slides.length == currentSlide && (
                <Text field={slides[0]?.fields?.description} />
              )}
            </span>
          )}
        </SlideText>
        {swipeText && isMobile && !singleImage && (
          <SwipeText>
            {getIcon('swipe')} <span>{swipeText}</span>
          </SwipeText>
        )}
      </ImageGalleryComponent>
    </ImageGalleryContainer>
  );
};

ImageGallery.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  theme: PropTypes.object,
  translate: PropTypes.any,
};

export default withTheme(ImageGallery);
