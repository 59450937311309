import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';

export const RelatedFaqsComponent = styled.section`
  width: calc(100% - 50px);
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};

  ${breakpoints.tabletLandscape} {
    width: calc(100% - 122px);
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.space()};
`;

export const Title = styled.h1`
  ${props => props.theme.setFont('articleListRowHeading')}
  margin: 0;
`;
