import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../../../tag';
import CTA from '../../../cta';
import Picture from '../../../picture';
import { format } from 'date-fns';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { withTheme } from 'styled-components';
import {
  HeadingWrap,
  ContentWrap,
  CTAWrap,
  ReadTimeWrapper,
  EventWrap,
  ReadTimeNumber,
  ReadTimeText,
  EventDateWrapper,
  TextContrastGradient,
  Gradient,
} from './VariantOne.styles.js';
import { useIsMobile } from '../../../hooks/useIsMobile';

const VariantOne = props => {
  const {
    fields: {
      title = {},
      featureImage = {},
      publicationDate: { value: publicationDate = null } = {},
      readingTime: { value: readingTime = null } = {},
      tag: { fields: tag = {} } = {},
      gradient = null,
    } = {},
    sitecoreContext: { pageEditing = false } = {},
    translate = {},
    forceHover = false,
    headingLevel = 3,
    theme: { website },
  } = props;

  const t = key => {
    if (translate && typeof translate === 'function') {
      return translate(`Article_${key}`);
    }
    return key;
  };

  const minText = t('Min');
  const minsText = t('Mins');
  const readMoreText = t('ReadMore');

  const readingTimeText = parseInt(readingTime, 10) === 1 ? minText : minsText;
  const readingTimeVisibleCondition = website !== 'lm-foundation';

  const isMobile = useIsMobile('tabletPortrait');
  const hasBgImg = featureImage?.value && Object.keys(featureImage.value).length > 0;

  const showGradient = pageEditing || !hasBgImg ? gradient?.value : null;

  const mapCtaVariant = () => {
    const sites = {
      'london-marathon': 'blackC',
      'the-big-half': 'blackC',
      'london-10k': 'whiteA',
      'westminster-mile': 'whiteA',
      'swim-serpentine': 'blackA',
      'ride-london': 'whiteB',
      'city-race': 'blackA',
      'lm-foundation': 'blackA',
    };
    if (website in sites) return sites[website];
    return sites['london-marathon'];
  };

  return (
    <>
      {gradient && !hasBgImg && <Gradient gradient={showGradient} />}
      {hasBgImg && <Picture field={featureImage} className={(forceHover && 'hasHover') || null} />}
      {hasBgImg && <TextContrastGradient className="text-contrast-gradient" pageEditing={pageEditing} />}
      <HeadingWrap gradient={showGradient}>
        {isMobile && readingTime && readingTimeVisibleCondition ? (
          <ReadTime
            time={readingTime}
            readingTimeText={readingTimeText}
            hasBgImg={hasBgImg}
            gradient={showGradient}
          />
        ) : (
          <Tag
            textColour={tag.textColour?.value}
            backgroundColour={tag.colour?.value}
            label={tag.title?.value}
            isPresentationalOnly
          />
        )}
      </HeadingWrap>
      <ContentWrap gradient={showGradient} readingTimeVisibleCondition={readingTimeVisibleCondition}>
        <EventWrap>
          {isMobile ? (
            <>
              <Tag
                textColour={tag.textColour?.value}
                backgroundColour={tag.colour?.value}
                label={tag.title?.value}
                isPresentationalOnly
              />

              {publicationDate && (
                <EventDateWrapper className="article-card-date" gradient={showGradient}>
                  {format(Date.parse(publicationDate), 'dd-MM-yyyy')}
                </EventDateWrapper>
              )}
            </>
          ) : (
            <>
              {publicationDate && (
                <EventDateWrapper className="article-card-date" gradient={showGradient}>
                  {format(Date.parse(publicationDate), 'dd-MM-yyyy')}
                </EventDateWrapper>
              )}
              {readingTime && readingTimeVisibleCondition && (
                <ReadTime
                  time={readingTime}
                  readingTimeText={readingTimeText}
                  hasBgImg={hasBgImg}
                  gradient={showGradient}
                />
              )}
            </>
          )}
        </EventWrap>
        {title && <Text className="title" field={title} tag={`h${headingLevel}`} />}
        <CTAWrap>
          <CTA type="a" variant={mapCtaVariant()} label={readMoreText} ariaLabel={readMoreText} />
        </CTAWrap>
      </ContentWrap>
    </>
  );
};

const ReadTime = ({ time, readingTimeText, hasBgImg, gradient }) => (
  <ReadTimeWrapper className="read-time-mobile-hide" hasBgImg={hasBgImg} gradient={gradient}>
    {time && <ReadTimeNumber gradient={gradient}>{time}</ReadTimeNumber>}
    {readingTimeText && <ReadTimeText>{readingTimeText}</ReadTimeText>}
  </ReadTimeWrapper>
);

VariantOne.propTypes = {
  fields: PropTypes.object,
  title: PropTypes.object,
  gradient: PropTypes.object,
  heroTitle: PropTypes.object,
  heroImage: PropTypes.object,
  publicationDate: PropTypes.object,
  readingTime: PropTypes.object,
  tag: PropTypes.object,
  params: PropTypes.object,
  sitecoreContext: PropTypes.object,
  translate: PropTypes.func,
  forceHover: PropTypes.bool,
  headingLevel: PropTypes.number,
  theme: PropTypes.shape({
    website: PropTypes.string,
  }),
};

ReadTime.propTypes = {
  time: PropTypes.number,
  readingTimeText: PropTypes.string,
  hasBgImg: PropTypes.bool,
  gradient: PropTypes.string,
};

export default withTheme(VariantOne);
