import { colors } from './colors';

const w = colors.primary[300];
const b = colors.primary[200];
const primary = colors.primary[100];

const brinkPink = '#FF6493';

// [textColor, backgroundColor]

export const buttonVariants = {
  default: [b, primary],
  blackA: [b, w],
  blackC: [b, primary],
  whiteA: [w, primary],
  whiteE: [w, primary],
  socialIcon: [w, w], // Footer social links
};

export const buttonHoverVariants = {
  default: [w, brinkPink],
  blackA: [b, colors.grays[500]],
  blackC: [b, colors.grays[500]],
  whiteA: [w, brinkPink],
  whiteE: [w, brinkPink],
  socialIcon: [w, b], // Footer social links
};
