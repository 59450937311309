import styled, { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';

export const ArticleHero = styled.section`
  position: relative;
  height: ${props => (props.hasBgImg ? '0px' : '366px')};
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};
  padding-top: ${props => (props.hasBgImg ? 'calc(73 / 75 * 100%)' : '0px')};
  overflow: hidden;
  background-image: ${props => props.theme.gradients[props.gradient]};

  ${breakpoints.tabletLandscape} {
    height: ${props => (props.hasBgImg ? '0px' : '430px')};
    margin: 0 ${props => props.theme.px(61)} ${props => props.theme.space(props.spaceMultiplierDesktop)};
    padding-top: ${props => (props.hasBgImg ? 'calc(43 / 131 * 100%)' : '0px')};
  }

  > div {
    position: absolute;
  }

  picture {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
  ${props =>
    props.theme.website !== 'lm-foundation'
      ? css`
          .title {
            ${breakpoints.tabletLandscape} {
              max-width: 75%;
              font-size: 30px;
              line-height: 39px;
            }
            ${breakpoints.desktopSmall} {
              font-size: 52px;
              line-height: 77px;
            }
          }
        `
      : ''}

  .article-wrap {
    padding: ${props => `${props.theme.px(27)} ${props.theme.px(26)}`};

    ${breakpoints.desktopSmall} {
      padding: ${props => `${props.theme.px(53)} ${props.theme.px(52)}`};
    }
  }
`;
