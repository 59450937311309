import React, { useEffect, useRef } from 'react';
import { withTheme } from 'styled-components';
import { handleCtaMappingByTheme } from '../../cta/ctaMapper';
import PropTypes from 'prop-types';
import {
  SlideComponent,
  Background,
  MobileSlice,
  Timer,
  TimerElement,
  Pattern,
  ContentContainer,
  LayerImage,
  LayerImagePreview,
  Preview,
  NextButton,
  BackgroundGradient,
} from './Slide.styles';
import CTA from '../../cta';
import Picture from '../../picture';
import { getIcon } from '../../utils/icon';
import { getPattern } from '../../utils/pattern';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';

const Slide = props => {
  const {
    backgroundImage,
    slideNumber,
    colour: color = { value: 'golden' },
    gradient,
    layerImageDesktop,
    layerImageMobile,
    link,
    pattern = {},
    subTitle,
    title,
    shortTitle = { value: ' ' },
    nextSlideText,
    nextSlideFn,
    isCarousel,
    isMobile,
    isEE = false,
    theme: { website },
    setCurrentSlideGradient,
  } = props;

  // if shortTitle is an empty string, make it a single space to ensure something renders
  if (!shortTitle.value) {
    shortTitle.value = ' ';
  }

  const previewRef = useRef();
  const slideID = `slide_${slideNumber}_${new Date().getTime()}`;
  const hasDesktopLayerImage = layerImageDesktop?.value?.src ? true : false;
  const hasMobileLayerImage = layerImageMobile?.value?.src ? true : false;

  const patternName = () => {
    if (pattern.value === 'tower-bridge') return 'tower-bridge-alt';
    if (pattern.value === 'transformation') return 'transformation-full';

    return pattern.value;
  };

  const hasBgImg = backgroundImage?.value && Object.keys(backgroundImage?.value).length > 0;
  const showGradient = isEE || !hasBgImg ? gradient?.value : null;

  const patternColourCondition = website === 'ride-london' || website === 'city-race' ? null : color?.value;

  const isEmpty = obj => {
    if (typeof obj !== 'object') {
      return true;
    }
    const keyCount = Object.keys(obj).length;
    return keyCount < 1 ? true : false;
  };

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  const handleNextSlide = e => {
    dataLayerEvent('heroCarouselCTA', {
      heroAction: 'Next slide',
      heroTitle: title.value,
    });
    setCurrentSlideGradient(showGradient);
    nextSlideFn(e.type);
  };

  useEffect(() => {
    if (isCarousel) {
      previewRef.current.addEventListener('mousedown', handleNextSlide);
      previewRef.current.addEventListener('keypress', handleNextSlide);
    }

    return () => {
      previewRef?.current?.removeEventListener('mousedown', handleNextSlide);
      previewRef?.current?.removeEventListener('keypress', handleNextSlide);
    };
  }, []);

  return (
    <SlideComponent
      aria-labelledby={`${slideID}_title`}
      slideNumber={slideNumber}
      isCarousel={isCarousel}
      className="heroImpactSlide"
      data-gradient={showGradient}
    >
      <Background
        className="slideBackground"
        isFirst={slideNumber === 0}
        backgroundImage={backgroundImage?.value?.src}
        gradient={showGradient}
        isCarousel={isCarousel}
      >
        {!isEmpty(backgroundImage?.value) && (
          <Picture className="backgroundImage" lazy={false} field={backgroundImage} />
        )}
        {!isEmpty(backgroundImage?.value) && !isMobile && isCarousel && (
          <Picture className="backgroundImagePreview" lazy={false} field={backgroundImage} />
        )}
        {!isEmpty(backgroundImage?.value) && <BackgroundGradient />}
        {gradient && isEmpty(backgroundImage?.value) && isMobile && <MobileSlice />}
        {isCarousel && (
          <Timer className="timer" isCarousel={isCarousel} color={color?.value}>
            <TimerElement timerColor={color.value} className="timerElement" />
          </Timer>
        )}
        {pattern.value && !isMobile && (
          <Pattern
            className="pattern"
            patternName={patternName()}
            patternColor={patternColourCondition}
            isCarousel={isCarousel}
          >
            {getPattern(patternName())}
          </Pattern>
        )}
        {(hasDesktopLayerImage || hasMobileLayerImage) && (
          <LayerImage
            className="layerImage"
            isCarousel={isCarousel}
            url={isMobile ? layerImageMobile?.value?.src : layerImageDesktop?.value?.src}
          />
        )}
      </Background>
      <ContentContainer isCarousel={isCarousel} className="content" gradient={showGradient}>
        <h1 id={`${slideID}_title`}>
          <Text field={title} />
        </h1>
        <p>
          <Text field={subTitle} />
        </p>
        {link?.value?.href && (
          <CTA
            fullwidth={isMobile ? 1 : 0}
            variant={handleCtaMappingByTheme(website, color.value)}
            label={link?.value?.text}
            ariaLabel={link?.value?.text}
            link={link?.value}
            target={link?.value?.target}
            className="slideCTA"
            isEE={isEE}
            onClick={() =>
              dataLayerEvent('heroCarouselCTA', {
                heroAction: 'CTA',
                heroTitle: title.value,
              })
            }
          />
        )}
      </ContentContainer>
      {isCarousel && (
        <Preview
          ref={previewRef}
          aria-label={nextSlideText}
          className="nextButton"
          tabIndex="-1"
          gradient={showGradient}
        >
          <Text field={shortTitle} tag="p" />
          <NextButton gradient={showGradient}>
            <span>{nextSlideText}</span> {getIcon('arrowLong')}
          </NextButton>
        </Preview>
      )}
      {isCarousel && hasDesktopLayerImage && !isMobile && (
        <LayerImagePreview
          className="layerImagePreview"
          isCarousel={isCarousel}
          url={layerImageDesktop?.value?.src}
        />
      )}
    </SlideComponent>
  );
};

Slide.propTypes = {
  backgroundImage: PropTypes.object,
  slideNumber: PropTypes.number,
  colour: PropTypes.object,
  gradient: PropTypes.object,
  layerImageDesktop: PropTypes.object,
  layerImageMobile: PropTypes.object,
  link: PropTypes.object,
  pattern: PropTypes.object,
  subTitle: PropTypes.object,
  title: PropTypes.object,
  shortTitle: PropTypes.object,
  nextSlideText: PropTypes.string,
  nextSlideFn: PropTypes.func,
  isCarousel: PropTypes.bool,
  isMobile: PropTypes.bool,
  isEE: PropTypes.bool,
  theme: PropTypes.object,
  setCurrentSlideGradient: PropTypes.func,
};

export default withTheme(Slide);
