import { CTAHTML as LMEHTML } from './london-marathon';
import { CTAHTML as TheBigHalfHTML } from './the-big-half';
import { CTAHTML as London10kHTML } from './london-10k';
import { CTAHTML as SwimSerpentineHTML } from './swim-serpentine';
import { CTAHTML as WestminsterMileHTML } from './westminster-mile';
import { CTAHTML as RideLondonHTML } from './ride-london';
import { CTAHTML as CityRaceHTML } from './city-race';
import { CTAHTML as LMFoundationHTML } from './lm-foundation';
export const CTAHTML = (website, icon, label, socialIcon) => {
  switch (website) {
    case 'london-marathon':
      return LMEHTML(icon, label, socialIcon);
    case 'the-big-half':
      return TheBigHalfHTML(icon, label, socialIcon);
    case 'london-10k':
      return London10kHTML(icon, label, socialIcon);
    case 'swim-serpentine':
      return SwimSerpentineHTML(icon, label, socialIcon);
    case 'westminster-mile':
      return WestminsterMileHTML(icon, label, socialIcon);
    case 'ride-london':
      return RideLondonHTML(icon, label, socialIcon);
    case 'city-race':
      return CityRaceHTML(icon, label, socialIcon);
    case 'lm-foundation':
      return LMFoundationHTML(icon, label, socialIcon);
    default:
      return LMEHTML(icon, label, socialIcon);
  }
};
