import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';

export const HelpListComponent = styled.section`
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};

  ${breakpoints.tabletLandscape} {
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
  }

  .help-list-filter {
    transition: top 0.3s ease-in-out;
    z-index: 99;

    margin-bottom: ${props => props.theme.space(2)};
    ${breakpoints.tabletLandscape} {
      margin-bottom: ${props => props.theme.space(4)};
    }
  }
`;

export const HelpSectionContainer = styled.section`
  position: relative;

  .offset {
    position: absolute;
    top: 0px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${props => props.theme.space()};
`;

export const Title = styled.h1`
  ${props => props.theme.setFont('articleListRowHeading')}
  margin: 0;
`;

export const HelpSection = styled.div`
  margin: 0 25px ${props => props.theme.space(2)} 25px;

  ${breakpoints.tabletLandscape} {
    margin: 0 61px ${props => props.theme.space(4)} 61px;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const LoadMore = styled.button`
  display: flex;
  align-items: center;
  width: fit-content;
  margin: 0 auto;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;

  svg {
    width: 24px;
    margin-right: ${props => props.theme.space(0.5)};
  }
`;

export const NoResults = styled.p`
  text-align: center;
`;
