const colorNames = {
  sponsorPink: '#e41165',
  black: '#000000',
  white: '#ffffff',
  seaGreen: '#00ffc5',
  cultured: '#f5f5f5',
  platinum: '#e1e1e1',
  grayX: '#b9b9b9',
  spanishGray: '#919191',
  davysGray: '#555555',
  jet: '#373737',
  squash: '#FF7300',
  positivePink: '#ff33a1',
  reassuringNavy: '#041347',
  extraordinaryOrange: '#FF8234',
  genuineGold: '#FFC834',
  britishBlue: '#5B34FF',
  passionatePurple: '#5B34FF',
  goodLuckGreen: '#34FF63',
  aqua: '#34FFFF',
  electricBlue: '#1037FB',
  offBlack: '#00144B',
  sunshine: '#FED621',
  lipstick: '#D60072',
};

export const colors = {
  primary: {
    100: colorNames.sponsorPink,
    200: colorNames.black,
    300: colorNames.white,
  },
  secondary: {
    100: colorNames.genuineGold,
    200: colorNames.britishBlue,

    300: colorNames.extraordinaryOrange,
    400: colorNames.seaGreen,

    500: colorNames.goodLuckGreen,
    600: colorNames.reassuringNavy,
    700: colorNames.positivePink,
    800: colorNames.passionatePurple,
    900: colorNames.offBlack,
    1000: colorNames.aqua,
    1100: colorNames.electricBlue,
  },
  grays: {
    100: colorNames.cultured,
    200: colorNames.platinum,
    300: colorNames.grayX,
    400: colorNames.spanishGray,
    500: colorNames.davysGray,
    600: colorNames.jet,
  },
  named: {
    white: colorNames.white,
    black: colorNames.black,
    'british-blue': colorNames.britishBlue,
    'extraordinary-orange': colorNames.extraordinaryOrange,
    'genuine-gold': colorNames.genuineGold,
    'good-luck-green': colorNames.goodLuckGreen,
    'passionate-purple': colorNames.passionatePurple,
    'positive-pink': colorNames.positivePink,
    'reassuring-navy': colorNames.reassuringNavy,
    'sponsor-red': colorNames.sponsorPink,
    'sea-green': colorNames.seaGreen,
    'white-cliffs': colorNames.white,
    aqua: colorNames.aqua,
    electricBlue: colorNames.electricBlue,
    offBlack: colorNames.offBlack,
    sunshine: colorNames.sunshine,
    lipstick: colorNames.lipstick,
    squash: colorNames.squash,
  },
};
