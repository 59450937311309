import styled, { keyframes, css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

const blinkText = keyframes`
  50% {
      opacity: 0;
    }
`;

export const Layout = styled.section`
  width: 100%;
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};

  ${breakpoints.tabletLandscape} {
    width: calc(100% - 122px);
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
  }

  &.fullWidth {
    margin-top: ${props => props.theme.px(70)};
    margin-bottom: 0;

    ${breakpoints.tabletLandscape} {
      grid-template-columns: auto;
      width: 100%;
      margin: ${props => `0 0 ${props.theme.px(26)} 0`};

      > *:first-child {
        margin: 0;
      }
    }
  }

  > *:last-child {
    display: flex;
    justify-content: center;
    width: 100%;

    &:not(.empty-placeholder) {
      margin-top: ${props => props.theme.space()};
    }

    ${breakpoints.tabletLandscape} {
      justify-content: flex-end;
    }
  }

  .empty-placeholder {
    &.hidden {
      display: none;
    }
  }

  ${breakpoints.tabletLandscape} {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    margin-right: ${props => props.theme.px(61)};
    margin-left: ${props => props.theme.px(61)};

    > *:first-child {
      margin: 0 ${props => props.theme.px(61)} 0 0;
    }

    > *:last-child {
      grid-column: 10 / -1;

      &:not(.empty-placeholder) {
        margin-top: ${props => props.theme.px(29)};
      }
    }
  }

  ${breakpoints.desktopSmall} {
    > *:last-child {
      &:not(.empty-placeholder) {
        margin-top: ${props => props.theme.px(50)};
      }
    }
  }
`;

export const CountdownClockComponent = styled.div`
  margin: 0 ${props => props.theme.px(25)};

  ${breakpoints.tabletLandscape} {
    display: grid;
    grid-column: 1 / 9;
  }

  ${breakpoints.desktopSmall} {
    grid-column: 1 / 10;
  }
`;

export const Title = styled.h1`
  ${props => props.theme.setFont('countdownClockTitle')}
  margin: 0;
`;

export const SubTitle = styled.p`
  ${props => props.theme.setFont('countdownClockSubTitle')}
  margin: 0;
  color: ${props => CM(props.theme).countdownSubTitleColor};

  ${breakpoints.tabletLandscape} {
    line-height: ${props => props.theme.px(20)};
  }

  ${breakpoints.desktopSmall} {
    line-height: ${props => props.theme.px(29)};
  }
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${props => props.theme.px(13)};

  ${breakpoints.tabletLandscape} {
    display: ${props => props.hiddenOnDesktop && 'none'};
    flex-direction: row;
    margin-bottom: ${props => props.theme.px(10)};

    > *:first-child {
      margin-right: ${props => props.theme.px(20)};
    }
  }

  ${breakpoints.desktopSmall} {
    margin-bottom: ${props => props.theme.px(20)};
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 194px;
  padding: 30px 17px 17px;
  background-color: ${props => CM(props.theme).containerBackground};

  ${breakpoints.tabletPortrait} {
    height: 214px;
  }

  ${breakpoints.tabletLandscape} {
    flex-direction: row;
    height: ${props => (props.variant === 'b' ? '93px' : '238px')};
    padding: ${props => (props.variant === 'b' ? `0 61px` : '17px')};
  }

  ${TitleWrap} {
    display: none;

    ${breakpoints.tabletLandscape} {
      display: flex;
      flex: 7;
      flex-direction: column;
      margin-bottom: 0;

      ${Title} {
        ${props => props.theme.setFont('countdownClockTitleSmall')}
      }

      ${SubTitle} {
        ${props => props.theme.setFont('countdownClockSubTitleSmall')}
      }
    }
  }

  img {
    width: auto;
    height: 50px;
    ${breakpoints.tabletLandscape} {
      flex: ${props => (props.variant === 'b' ? 'none' : '1')};
    }
  }
`;

export const Colon = styled.span`
  display: flex;
  align-items: flex-start;
  align-self: flex-start;
  margin-top: ${props => props.theme.px(-5)};
  color: ${props => CM(props.theme).colonColor};
  ${props => props.theme.setFont('countdownClockTimer')}

  @media (prefers-reduced-motion) {
    animation: none;
  }

  @media screen and (max-width: 320px) {
    font-size: ${props => props.theme.px(35)};
  }

  ${breakpoints.tabletLandscape} {
    display: ${props => props.variant === 'b' && 'none'};
  }
`;

export const CountdownWrap = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  margin-bottom: ${props => props.theme.px(20)};

  ${breakpoints.tabletLandscape} {
    flex: 5;
    height: auto;
    margin-bottom: 0;
  }
`;

export const CountdownSection = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;

  ${breakpoints.tabletLandscape} {
    flex-direction: ${props => (props.variant === 'b' ? 'row' : 'column')};
    margin-right: ${props => (props.variant === 'b' ? '26px' : '0')};
  }
`;

export const CountdownTimer = styled.p`
  ${props =>
    props.variant === 'b'
      ? props.theme.setFont('countdownClockTimerSmall')
      : props.theme.setFont('countdownClockTimer')}
  margin: 0;
  animation: ${props =>
    props.hasEnded
      ? css`
          ${blinkText} 1s step-start infinite;
        `
      : ''};

  color: ${props =>
    props.hasEnded ? CM(props.theme).countdownTimerEndedColor : CM(props.theme).countdownTimerColor};
  font-weight: bold;

  @media (prefers-reduced-motion) {
    animation: none;
  }

  @media screen and (max-width: 320px) {
    font-size: ${props => props.theme.px(55)};
  }

  ${breakpoints.tabletLandscape} {
    margin-right: ${props => (props.variant === 'b' ? props.theme.px(13) : 0)};
    font-size: ${props => props.variant === 'b' && '54px'};
    line-height: ${props => props.variant === 'b' && '59px'};
  }

  span {
    color: ${props =>
      props.hasEnded
        ? CM(props.theme).countdownTimerZeroColorEnded
        : CM(props.theme).countdownTimerZeroColor};
    &:first-of-type {
      color: ${props => CM(props.theme).countdownTimerZeroColorFirstType};
    }
  }
`;

export const CountdownTimerLabel = styled.span`
  ${props =>
    props.variant === 'b'
      ? props.theme.setFont('countdownClockTimerLabelSmall')
      : props.theme.setFont('countdownClockTimerLabel')}
  display: block;
  color: ${props => CM(props.theme).countdownTimerLabelColor};
  margin-bottom: 0;
  font-weight: bold;
  margin-top: 20px;

  @media screen and (max-width: 320px) {
    font-size: ${props => props.theme.px(15)};
  }

  ${breakpoints.tabletLandscape} {
    margin-top: ${props => (props.variant === 'b' ? '0' : '20px')};
  }
`;

export const CTAWrap = styled.ul`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.px(25)};
  margin-bottom: 0;
  padding: 0;
  list-style: none;

  ${breakpoints.tabletLandscape} {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
  }
`;

export const CTAItem = styled.li`
  margin-bottom: ${props => props.theme.space()};

  &:last-child {
    margin-bottom: 0;
  }

  ${breakpoints.tabletLandscape} {
    flex-basis: ${props => `calc(50% - ${props.theme.space(0.25)})`};
    width: ${props => (props.alignLeft ? '50%' : 'auto')};
    margin-right: ${props => props.theme.space(0.5)};
    margin-bottom: ${props => props.theme.space()};

    &:nth-last-child(2) {
      margin-bottom: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &:nth-of-type(even) {
    ${breakpoints.tabletLandscape} {
      margin-right: 0;
    }
  }
`;
