import React from 'react';
import twentySixAngleUrl, {
  ReactComponent as TwentySixAngle,
} from '../../assets/patterns/twenty-six-degree-mark.svg';
import medalsUrl, { ReactComponent as Medals } from '../../assets/patterns/the-medal.svg';
import medalsAltUrl, { ReactComponent as MedalsAlt } from '../../assets/patterns/the-medal-alt.svg';
import medalsThinUrl, { ReactComponent as MedalsThin } from '../../assets/patterns/the-medal-thin.svg';
import stepsUrl, { ReactComponent as Steps } from '../../assets/patterns/the-steps.svg';
import towerBridgeUrl, { ReactComponent as TowerBridge } from '../../assets/patterns/tower-bridge.svg';
import towerBridgeAltUrl, {
  ReactComponent as TowerBridgeAlt,
} from '../../assets/patterns/tower-bridge-alt.svg';
import diagonalChevronsUrl, {
  ReactComponent as DiagonalChevrons,
} from '../../assets/patterns/diagonal-chevrons.svg';
import filmReelUrl, { ReactComponent as FilmReel } from '../../assets/patterns/film-reel.svg';
import multipleCirclesUrl, {
  ReactComponent as MultipleCircles,
} from '../../assets/patterns/multiple-circles.svg';
import parallelLinesUrl, { ReactComponent as ParallelLines } from '../../assets/patterns/parallel-lines.svg';
import scatteredCirclesUrl, {
  ReactComponent as ScatteredCircles,
} from '../../assets/patterns/scattered-circles.svg';
import scatteredCirclesAltUrl, {
  ReactComponent as ScatteredCirclesAlt,
} from '../../assets/patterns/scattered-circles-alt.svg';
import peakUrl, { ReactComponent as Peak } from '../../assets/patterns/peak.svg';
import wavesUrl, { ReactComponent as Waves } from '../../assets/patterns/waves.svg';
import waterShapeOneUrl, { ReactComponent as WaterShapeOne } from '../../assets/patterns/water-shape-1.svg';
import waterShapeTwoUrl, { ReactComponent as WaterShapeTwo } from '../../assets/patterns/water-shape-2.svg';
import waterShapeThreeUrl, {
  ReactComponent as WaterShapeThree,
} from '../../assets/patterns/water-shape-3.svg';
import waterShapeFourUrl, { ReactComponent as WaterShapeFour } from '../../assets/patterns/water-shape-4.svg';
import circleOutlinesUrl, {
  ReactComponent as CircleOutlines,
} from '../../assets/patterns/circle-outlines.svg';
import triangleOutlinesUrl, {
  ReactComponent as TriangleOutlines,
} from '../../assets/patterns/triangle-outlines.svg';
import powerUrl, { ReactComponent as Power } from '../../assets/patterns/power.svg';
import unityUrl, { ReactComponent as Unity } from '../../assets/patterns/unity.svg';

import frameUrl, { ReactComponent as Frame } from '../../assets/patterns/frame.svg';
import handlebarsUrl, { ReactComponent as Handlebars } from '../../assets/patterns/handlebars.svg';
import landscapeUrl, { ReactComponent as Landscape } from '../../assets/patterns/landscape.svg';
import pelotonUrl, { ReactComponent as Peloton } from '../../assets/patterns/peloton.svg';
import profileUrl, { ReactComponent as Profile } from '../../assets/patterns/profile.svg';
import wheelsUrl, { ReactComponent as Wheels } from '../../assets/patterns/wheels.svg';
import landscapeOutlinedUrl, {
  ReactComponent as LandscapeOutlined,
} from '../../assets/patterns/landscape-outlined.svg';

import onYourMarksUrl, { ReactComponent as OnYourMarks } from '../../assets/patterns/on-your-marks.svg';
import onYourMarksAltUrl, {
  ReactComponent as OnYourMarksAlt,
} from '../../assets/patterns/on-your-marks-alt.svg';
import getSetUrl, { ReactComponent as GetSet } from '../../assets/patterns/get-set.svg';
import goUrl, { ReactComponent as Go } from '../../assets/patterns/go.svg';
import transformationUrl, {
  ReactComponent as Transformation,
} from '../../assets/patterns/transformation.svg';
import transformationFullUrl, {
  ReactComponent as TransformationFull,
} from '../../assets/patterns/transformation-full.svg';

const patternKeys = (key, returnUrl) => {
  const patterns = {
    'twenty-six-degree-mark': returnUrl ? twentySixAngleUrl : <TwentySixAngle />,
    'the-medal': returnUrl ? medalsUrl : <Medals />,
    'the-medal-alt': returnUrl ? medalsAltUrl : <MedalsAlt />,
    'the-medal-thin': returnUrl ? medalsThinUrl : <MedalsThin />,
    'the-steps': returnUrl ? stepsUrl : <Steps />,
    'tower-bridge': returnUrl ? towerBridgeUrl : <TowerBridge />,
    'tower-bridge-alt': returnUrl ? towerBridgeAltUrl : <TowerBridgeAlt />,
    'diagonal-chevrons': returnUrl ? diagonalChevronsUrl : <DiagonalChevrons />,
    'film-reel': returnUrl ? filmReelUrl : <FilmReel />,
    'multiple-circles': returnUrl ? multipleCirclesUrl : <MultipleCircles />,
    'parallel-lines': returnUrl ? parallelLinesUrl : <ParallelLines />,
    'scattered-circles': returnUrl ? scatteredCirclesUrl : <ScatteredCircles />,
    'scattered-circles-alt': returnUrl ? scatteredCirclesAltUrl : <ScatteredCirclesAlt />,
    peak: returnUrl ? peakUrl : <Peak />,
    waves: returnUrl ? wavesUrl : <Waves />,
    'shape-of-water-01': returnUrl ? waterShapeOneUrl : <WaterShapeOne />,
    'shape-of-water-02': returnUrl ? waterShapeTwoUrl : <WaterShapeTwo />,
    'shape-of-water-03': returnUrl ? waterShapeThreeUrl : <WaterShapeThree />,
    'shape-of-water-04': returnUrl ? waterShapeFourUrl : <WaterShapeFour />,
    'circle-outlines': returnUrl ? circleOutlinesUrl : <CircleOutlines />,
    'triangle-outlines': returnUrl ? triangleOutlinesUrl : <TriangleOutlines />,
    power: returnUrl ? powerUrl : <Power />,
    unity: returnUrl ? unityUrl : <Unity />,
    frame: returnUrl ? frameUrl : <Frame />,
    handlebars: returnUrl ? handlebarsUrl : <Handlebars />,
    landscape: returnUrl ? landscapeUrl : <Landscape />,
    peloton: returnUrl ? pelotonUrl : <Peloton />,
    profile: returnUrl ? profileUrl : <Profile />,
    wheels: returnUrl ? wheelsUrl : <Wheels />,
    'landscape-outlined': returnUrl ? landscapeOutlinedUrl : <LandscapeOutlined />,
    'on-your-marks': returnUrl ? onYourMarksUrl : <OnYourMarks />,
    'on-your-marks-alt': returnUrl ? onYourMarksAltUrl : <OnYourMarksAlt />,
    'get-set': returnUrl ? getSetUrl : <GetSet />,
    go: returnUrl ? goUrl : <Go />,
    transformation: returnUrl ? transformationUrl : <Transformation />,
    'transformation-full': returnUrl ? transformationFullUrl : <TransformationFull />,
  };

  return patterns[key];
};

export const getPattern = (key, returnUrl = false) => patternKeys(key, returnUrl) || null;
