import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'i18next';
import { VisitorIdentification } from '@sitecore-jss/sitecore-jss-react';
import Helmet from 'react-helmet';
import Footer from 'lmel-components/core/footer';
import Header from 'lmel-components/core/header';

// Renders a route-not-found message when no route is available from Sitecore
// The JSS equivalent of a 404 Not Found page.

// This is invoked from RouteHandler when Sitecore returns no valid route data.
// The NotFound component receives the Layout Service Context data, but no route data.
// This can be used to power parts of your site, such as navigation, from LS context additions
// without losing the ability to render them on your 404 pages :)

const NotFound = ({ context }) => {
  const { header = {}, footer = {} } = context;

  return (
    <>
      <Helmet>
        <title>{i18n.t('Page not found')}</title>
      </Helmet>
      <VisitorIdentification />
      <Header {...header} />
      <main>
        <h1>{i18n.t('Page not found')}</h1>
      </main>
      <Footer {...footer} />
    </>
  );
};

NotFound.propTypes = {
  context: PropTypes.object,
};

export default NotFound;
