import { colors } from './colors';

const createLinearGradient = (fromColor, toColor) =>
  `linear-gradient(0deg, ${fromColor} 0%, ${toColor} 100%)`;

export const gradients = {
  black: createLinearGradient(colors.primary[300], colors.primary[300]),
  blue: createLinearGradient(colors.primary[200], colors.primary[200]),
  green: createLinearGradient(colors.primary[500], colors.primary[500]),
  'green-to-blue': createLinearGradient(colors.primary[200], colors.primary[500]),
};
