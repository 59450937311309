import styled, { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';

const siteSpecificStyles = theme => {
  const sites = {
    'london-marathon': {
      lineHeight: '57px',
    },
    'the-big-half': {
      lineHeight: '57px',
    },
    'london-10k': {
      lineHeight: '57px',
    },
    'westminster-mile': {
      lineHeight: '57px',
    },
    'swim-serpentine': {
      lineHeight: '65px',
    },
    'ride-london': {
      lineHeight: '65px',
    },
  };
  if (theme.website in sites) return sites[theme.website];
  return sites['london-marathon'];
};

export const ShowcaseHeroComponent = styled.section`
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};

  ${breakpoints.tabletLandscape} {
    flex-direction: column;
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
  }

  picture {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  .title {
    ${props =>
      props.theme.website !== 'lm-foundation'
        ? css`
            ${breakpoints.tabletPortrait} {
              font-size: 30px;
              line-height: 39px;
            }
          `
        : css`
            ${props.theme.setFont('h5')};
            ${breakpoints.tabletPortrait} {
              ${props.theme.setFont('h3')};
            }
          `}
  }

  .variant-a .title {
    ${breakpoints.desktopSmall} {
      font-size: 52px;
      line-height: ${props => siteSpecificStyles(props.theme).lineHeight};
    }
  }

  .article-wrap {
    padding: ${props => `${props.theme.px(27)} ${props.theme.px(26)}`};
    ${breakpoints.desktopSmall} {
      padding: ${props => `${props.theme.px(53)} ${props.theme.px(52)}`};
    }
    ${props =>
      props.theme.website == 'lm-foundation'
        ? css`
            ${breakpoints.tabletLandscape} {
              div {
                max-width: 100%;
              }
            }
          `
        : ''}
  }
`;

const commonHeroStyles = css`
  position: relative;
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};
  overflow: hidden;
  background-image: ${props => props.theme.gradients[props.gradient]};
`;

export const MainHero = styled.div`
  ${commonHeroStyles}
  height: 0;
  padding-top: calc(34 / 25 * 100%);

  > div {
    position: absolute;
  }

  &:hover {
    picture,
    .sc-image-wrapper {
      transform: scale(1.1);
    }
  }

  ${breakpoints.tabletPortrait} {
    padding-top: calc(100% / 1.5);
  }

  ${breakpoints.tabletLandscape} {
    margin: 0 ${props => props.theme.px(61)} ${props => props.theme.space(props.spaceMultiplierDesktop)};
    padding-top: calc(43 / 131 * 100%);
  }
`;

export const SubHero = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 0 ${props => props.theme.px(25)};

  ${breakpoints.tabletPortrait} {
    flex-direction: row;
  }

  ${breakpoints.tabletLandscape} {
    margin: 0 ${props => props.theme.px(61)};
  }

  > div {
    position: relative;
    height: ${props => (props.hasBgImg ? '0px' : '380px')};
    padding-top: ${props => (props.hasBgImg ? 'calc(190 / 161 * 100%)' : '0px')};

    &:not(:first-child) {
      margin-top: ${props => props.theme.px(26)};
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
      transition: all 0.5s ease-in-out;
    }

    &:hover {
      img {
        transform: scale(1.1);
      }
    }

    ${breakpoints.tabletPortrait} {
      padding-top: ${props => (props.hasBgImg ? '53%' : '0px')};
      &:not(:first-child) {
        margin-top: 0;
        margin-left: ${props => props.theme.px(26)};
      }
    }

    ${breakpoints.tabletLandscape} {
      height: ${props => (props.hasBgImg ? '0px' : '400px')};
      padding-top: ${props => (props.hasBgImg ? 'calc(305 / 517 * 38.05%)' : '0px')};
    }
  }

  .read-time-wrapper {
    ${breakpoints.tabletLandscape} {
      bottom: 42px;
    }
  }

  .article-wrap {
    padding: ${props => `${props.theme.px(27)} ${props.theme.px(26)}`};

    ${breakpoints.desktopSmall} {
      padding: ${props => props.theme.px(33)};
    }
  }
`;
