import React from 'react';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import ClickDimensionsFormComponent from 'lmel-components/core/clickdimensions-form';

const ClickDimensionsForm = props => {
  return (
    <ErrorBoundary>
      <ClickDimensionsFormComponent {...props} />
    </ErrorBoundary>
  );
};

export default ClickDimensionsForm;
