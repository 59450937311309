import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const RestrictWidthContainer = styled.div`
  width: 100%;
  max-width: 900px;
`;

export const ImageCarouselComponent = styled.section`
  position: relative;
  width: calc(100% - 50px);
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};
  overflow: hidden;

  ${breakpoints.tabletLandscape} {
    width: calc(100% - 122px);
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  height: 0;
  margin: 0;
  padding-top: calc(95 / 163 * 100%);
  overflow: hidden;

  picture {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
`;

export const ImageBottom = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: ${props => props.theme.space(0.5)};

  > div > p {
    ${props => props.theme.setFont('imageCarouselText')}
    margin: 0px;
    color: ${props => CM(props.theme).imageBottomText};
  }
`;

export const Controls = styled.nav`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: ${props => props.theme.space(0.25)};

  button {
    height: 25px;
    margin: 0;
    padding: 0;
    background: none;
    border: 0;
    cursor: pointer;
    appearance: none;

    svg {
      width: 25px;
      fill: ${props => CM(props.theme).controlsButtonSvgColor};
    }

    &:last-of-type svg {
      transform: rotate(180deg);
    }
  }

  p {
    ${props => props.theme.setFont('imageCarouselText')}
    width: 60px;
    margin: 0px;
    color: ${props => CM(props.theme).controlsText};
    text-align: center;
  }
`;
