import React, { useEffect, useState, useRef } from 'react';
import { intervalToDuration, differenceInDays, isBefore, add } from 'date-fns';
import PropTypes from 'prop-types';
import { DateField, Text, Image, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import CtaAlt from '../cta-alt';

import {
  CountdownClockComponent,
  Container,
  TitleWrap,
  Title,
  SubTitle,
  CTAWrap,
  CTAItem,
  Layout,
  CountdownWrap,
  CountdownSection,
  CountdownTimer,
  CountdownTimerLabel,
  Colon,
} from './CountdownClock.styles.js';

const CountdownClockLayout = props => {
  const {
    rendering = {},
    rendering: {
      placeholders = {},
      params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' } = {},
    },
    variant = 'a',
  } = props;

  return (
    <Layout
      className={`${variant === 'a' ? 'limitedWidth' : 'fullWidth'}`}
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      <CountdownClock {...props} />
      {placeholders?.['jss-mpu-ads']?.length && variant === 'a' ? (
        <Placeholder name="jss-mpu-ads" rendering={rendering} />
      ) : (
        <div className={`empty-placeholder ${variant === 'b' ? 'hidden' : ''}`}></div>
      )}
    </Layout>
  );
};

const CountdownClock = props => {
  const {
    title = {},
    subTitle = {},
    targetDate = {},
    logo = {},
    ctas = {},
    translate = {},
    sitecoreContext: { pageEditing = false } = {},
    variant = 'a',
  } = props;

  const t = key => {
    if (translate && typeof translate === 'function') {
      return translate(`CountdownClock_${key}`);
    }
    return key;
  };

  const monthText = t('Month');
  const monthsText = t('Months');
  const weekText = t('Week');
  const weeksText = t('Weeks');
  const dayText = t('Day');
  const daysText = t('Days');
  const hourText = t('Hour');
  const hoursText = t('Hours');
  const minuteText = t('Minute');
  const minutesText = t('Minutes');
  const secondText = t('Second');
  const secondsText = t('Seconds');

  const countdownLabelOne = useRef(null);
  const countdownLabelTwo = useRef(null);
  const countdownLabelThree = useRef(null);
  const countdownValueOne = useRef(null);
  const countdownValueTwo = useRef(null);
  const countdownValueThree = useRef(null);

  const formatTargetDate = targetDate?.value?.replace('/', '-');

  let futureDate = new Date();

  if (formatTargetDate) futureDate = new Date(formatTargetDate);

  const futureTimezoneOffset = futureDate.getTimezoneOffset();
  const futureUTCTime = add(futureDate, {
    minutes: futureTimezoneOffset,
  });

  const ctaWrapperRef = useRef(null);
  const countdownWrapRef = useRef();
  const [hasEnded, setHasEnded] = useState(false);
  const [isHidden, setIsHidden] = useState(true);

  let distanceInDays = differenceInDays(futureUTCTime, new Date());

  useEffect(() => {
    onUpdate();
    setIsHidden(false);
    const interval = setInterval(() => onUpdate(), 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  function onUpdate(updateDOM = true) {
    let duration = intervalToDuration({
      start: new Date(),
      end: futureUTCTime,
    });

    function addWeeks(duration) {
      if (!duration.weeks) {
        duration.weeks = (duration.days / 7) | 0;
        duration.days = duration.days - duration.weeks * 7;
      }
    }

    function addMonths(duration) {
      if (duration.years >= 1) {
        const yearsToMonths = duration.years * 12;
        duration.months += yearsToMonths;
      }
    }

    addWeeks(duration);
    addMonths(duration);

    distanceInDays = differenceInDays(futureUTCTime, new Date());

    if (updateDOM) {
      return handleCountdown(duration);
    } else {
      return duration;
    }
  }

  const handleCountdown = duration => {
    if (!duration) {
      return;
    }

    if (isBefore(new Date(), futureUTCTime)) {
      return handleClockUnitType(duration);
    } else {
      setHasEnded(true);
      return (
        <>
          {(countdownLabelOne.current.innerHTML = hoursText)}
          {(countdownLabelTwo.current.innerHTML = minutesText)}
          {(countdownLabelThree.current.innerHTML = secondsText)}
          {(countdownValueOne.current.innerHTML = '00')}
          {(countdownValueTwo.current.innerHTML = '00')}
          {(countdownValueThree.current.innerHTML = '00')}
        </>
      );
    }
  };

  function handleClockUnitType(duration) {
    const { years, months, weeks, days, hours, minutes, seconds } = duration;

    const isMoreThanOneDay = () => {
      if (years >= 1 || months >= 1 || weeks >= 1 || days >= 1) {
        return true;
      }

      return false;
    };

    switch (true) {
      case distanceInDays >= 100:
        return (
          <>
            {(countdownLabelOne.current.innerHTML = months === 1 ? monthText : monthsText)}
            {(countdownLabelTwo.current.innerHTML = weeks === 1 ? weekText : weeksText)}
            {(countdownLabelThree.current.innerHTML = days === 1 ? dayText : daysText)}
            {(countdownValueOne.current.innerHTML = handleFormat(months))}
            {(countdownValueTwo.current.innerHTML = handleFormat(weeks))}
            {(countdownValueThree.current.innerHTML = handleFormat(days))}
          </>
        );
      case distanceInDays <= 99 && isMoreThanOneDay():
        return (
          <>
            {(countdownLabelOne.current.innerHTML = distanceInDays === 1 ? dayText : daysText)}
            {(countdownLabelTwo.current.innerHTML = hours === 1 ? hourText : hoursText)}
            {(countdownLabelThree.current.innerHTML = minutes === 1 ? minuteText : minutesText)}
            {(countdownValueOne.current.innerHTML = handleFormat(distanceInDays))}
            {(countdownValueTwo.current.innerHTML = handleFormat(hours))}
            {(countdownValueThree.current.innerHTML = handleFormat(minutes))}
          </>
        );
      default:
        return (
          <>
            {(countdownLabelOne.current.innerHTML = hours === 1 ? hourText : hoursText)}
            {(countdownLabelTwo.current.innerHTML = minutes === 1 ? minuteText : minutesText)}
            {(countdownLabelThree.current.innerHTML = seconds === 1 ? secondText : secondsText)}
            {(countdownValueOne.current.innerHTML = handleFormat(hours))}
            {(countdownValueTwo.current.innerHTML = handleFormat(minutes))}
            {(countdownValueThree.current.innerHTML = handleFormat(seconds))}
          </>
        );
    }
  }

  useEffect(() => {
    if (variant === 'a') {
      const childNodes = ctaWrapperRef?.current?.childNodes;

      const maxHeight = childNodes
        ? [...childNodes]?.reduce((acc, el) => {
            const height = el.getBoundingClientRect().height;
            return height > acc ? height : acc;
          }, 0)
        : null;

      childNodes?.forEach(el => (el.style.height = `${maxHeight ? `${maxHeight}px` : 'auto'}`));
    }
  }, []);

  function handleFormat(str) {
    const result = str.toLocaleString(undefined, { minimumIntegerDigits: 2 });

    if (str < 10) {
      return result.replace('0', '<span>0</span>');
    }
    return result;
  }

  const getAccessibleTimeLeft = () => {
    const duration = onUpdate(false);
    let a11yTime = '';

    if (duration.months > 0) {
      a11yTime += `${duration.months} ${duration.months > 1 ? monthsText : monthText}, `;
    }
    if (duration.weeks > 0) {
      a11yTime += `${duration.weeks} ${duration.weeks > 1 ? weeksText : weekText}, `;
    }
    if (duration.days > 0) {
      a11yTime += `${duration.days} ${duration.days > 1 ? daysText : dayText}`;
    }

    if (a11yTime) {
      return <p className="visually-hidden">{`${a11yTime} remaining`}</p>;
    } else {
      return null;
    }
  };

  return (
    <CountdownClockComponent>
      <TitleWrap hiddenOnDesktop={variant === 'b'}>
        {title && (
          <Title>
            <Text field={title} />
          </Title>
        )}
        {subTitle && (
          <SubTitle>
            <Text field={subTitle} />
          </SubTitle>
        )}
      </TitleWrap>
      <Container hidden={isHidden} variant={variant}>
        {pageEditing && <DateField field={targetDate} />}
        {variant === 'b' && (
          <TitleWrap>
            {title && (
              <Title>
                <Text field={title} />
              </Title>
            )}
            {subTitle && (
              <SubTitle>
                <Text field={subTitle} />
              </SubTitle>
            )}
          </TitleWrap>
        )}
        {getAccessibleTimeLeft()}
        <CountdownWrap aria-hidden="true" ref={countdownWrapRef}>
          <CountdownSection variant={variant}>
            <CountdownTimer hasEnded={hasEnded} ref={countdownValueOne} variant={variant}></CountdownTimer>
            <CountdownTimerLabel ref={countdownLabelOne} variant={variant}></CountdownTimerLabel>
          </CountdownSection>
          <Colon hasEnded={hasEnded} variant={variant}>
            &#58;
          </Colon>
          <CountdownSection variant={variant}>
            <CountdownTimer hasEnded={hasEnded} ref={countdownValueTwo} variant={variant}></CountdownTimer>
            <CountdownTimerLabel ref={countdownLabelTwo} variant={variant}></CountdownTimerLabel>
          </CountdownSection>
          <Colon hasEnded={hasEnded} variant={variant}>
            &#58;
          </Colon>
          <CountdownSection variant={variant}>
            <CountdownTimer hasEnded={hasEnded} ref={countdownValueThree} variant={variant}></CountdownTimer>
            <CountdownTimerLabel ref={countdownLabelThree} variant={variant}></CountdownTimerLabel>
          </CountdownSection>
        </CountdownWrap>
        <Image field={logo} />
      </Container>
      {ctas?.value && variant === 'a' && (
        <CTAWrap ref={ctaWrapperRef}>
          {ctas.value.map((cta, index) => (
            <CTAItem key={index} alignLeft={ctas.value.length <= 2}>
              <CtaAlt cta={cta} />
            </CTAItem>
          ))}
        </CTAWrap>
      )}
    </CountdownClockComponent>
  );
};

CountdownClock.propTypes = {
  translate: PropTypes.func,
  sitecoreContext: PropTypes.object,
  title: PropTypes.object,
  subTitle: PropTypes.object,
  targetDate: PropTypes.object,
  logo: PropTypes.object,
  ctas: PropTypes.object,
  variant: PropTypes.string,
};

CountdownClockLayout.propTypes = {
  rendering: PropTypes.object,
  spaceMultiplierMobile: PropTypes.string,
  spaceMultiplierDesktop: PropTypes.string,
  variant: PropTypes.string,
};

export default CountdownClockLayout;
