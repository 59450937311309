import React, { useRef, useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import shuffle from 'lodash.shuffle';
import EditFrame from '../edit-frame';
import Empty from '../empty';
import Signpost from '../signpost';
import { withSitecoreContext, Text, Placeholder } from '@sitecore-jss/sitecore-jss-react';

import {
  ContentWrap,
  SignpostListComponent,
  SignpostListTitle,
  AdSlotLayout,
  AdContainer,
  NoAdsLayout,
} from './SignpostList.styles.js';

const SignpostLayout = props => {
  const {
    fields: { title = {} } = {},
    rendering = {},
    rendering: {
      placeholders = {},
      params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' } = {},
    },
  } = props;

  return placeholders?.['jss-mpu-ads']?.length ? (
    <AdSlotLayout
      className="limitedWidth"
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      {title?.value && (
        <SignpostListTitle>
          <Text field={title} />
        </SignpostListTitle>
      )}
      <ContentWrap>
        <SignpostList {...props} />
        <AdContainer>
          <Placeholder name="jss-mpu-ads" rendering={rendering} />
        </AdContainer>
      </ContentWrap>
    </AdSlotLayout>
  ) : (
    <NoAdsLayout
      className="limitedWidth"
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      {title?.value && (
        <SignpostListTitle>
          <Text field={title} />
        </SignpostListTitle>
      )}
      <SignpostList {...props} />
    </NoAdsLayout>
  );
};

const SignpostList = props => {
  const {
    fields: { items: signpostItems } = {},
    sitecoreContext: { pageEditing = false } = {},
    params: { shuffle: shuffleSignpostItems = '0' } = {},
  } = props;

  const hasItems = signpostItems && signpostItems.length > 0;
  const containerRef = useRef(null);
  const refs = [];

  const [signpostItemsArr, setSignpostItemsArr] = useState(signpostItems);

  useEffect(() => {
    setSignpostItemsArr(shuffleSignpostItems === '1' ? shuffle(signpostItems) : signpostItems);
  }, []);

  signpostItemsArr?.forEach(() => {
    refs.push(useRef());
  });

  useEffect(() => {
    const tagWidth = refs
      .filter(element => element.current !== undefined)
      .reduce((acc, el) => {
        const width = el.current.getBoundingClientRect().width;
        return width > acc ? width : acc;
      }, 0);

    refs?.forEach(el => el.current !== undefined && (el.current.style.width = `${tagWidth}px`));
  }, []);

  return (
    <SignpostListComponent ref={containerRef}>
      {hasItems &&
        signpostItemsArr.map((item, index) => (
          <EditFrame
            itemId={item.id}
            header="Edit Signpost"
            commandDisplayName="Signpost"
            fields={['title', 'description', 'link', 'tag', 'image']}
            key={index}
          >
            <Signpost
              key={`signpost-list-item-${index}`}
              url={item.fields?.link}
              ariaLabel={item.fields?.link?.value?.text}
              title={item.fields?.title}
              summary={item.fields?.description?.value}
              tagTextColour={item.fields?.tag?.fields?.textColour?.value}
              tagBackgroundColour={item.fields?.tag?.fields?.colour?.value}
              tagLabel={item.fields?.tag?.fields?.title?.value}
              tagRef={refs[index]}
              image={item?.fields?.image}
            />
          </EditFrame>
        ))}
      {pageEditing && !hasItems && <Empty message="No items specified" />}
    </SignpostListComponent>
  );
};

SignpostLayout.propTypes = {
  fields: PropTypes.object,
  rendering: PropTypes.object,
  spaceMultiplierMobile: PropTypes.string,
  spaceMultiplierDesktop: PropTypes.string,
};

SignpostList.propTypes = {
  props: PropTypes.object,
  fields: PropTypes.object,
  params: PropTypes.object,
  sitecoreContext: PropTypes.object,
  theme: PropTypes.object,
};

export default withSitecoreContext()(withTheme(SignpostLayout));
