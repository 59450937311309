import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import {
  FullWidthVideoComponent,
  ImageWrapper,
  TitleWrap,
  Title,
  SubTitle,
  Icon,
  IconWrap,
  VideoTitle,
  ButtonText,
  OverlayWrap,
  TextContrastGradient,
} from './FullWidthVideo.styles.js';
import { getIcon } from '../utils/icon';
import { getPattern } from '../utils/pattern';
import ReactPlayer from 'react-player/lazy';
import Picture from '../picture';
import Modal from '../modal';

const FullWidthVideo = props => {
  const playerRef = useRef(null);
  const [imageHovered, setImageHovered] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [duration, setDuration] = useState(0);
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const [previousVideoStartPoint, setPreviousVideoStartPoint] = useState(0);
  const {
    fields: { title = {}, subTitle = {}, poster = {}, provider = {}, videoTitle = {}, url = {} } = {},
    translate = {},
    params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
    pattern = 'twenty-six-degree-mark',
  } = props;

  const handleDuration = duration => {
    setDuration(duration);
  };

  const handleProgress = state => {
    if (duration) {
      setSecondsElapsed(duration * state.played);
    }
  };

  const handleModalClose = showModal => {
    setShowModal(showModal);
    setPreviousVideoStartPoint(secondsElapsed);
  };

  const handleOnReady = () => {
    if (previousVideoStartPoint === 0 || !provider) {
      return url.value;
    }

    return playerRef.current.seekTo(previousVideoStartPoint);
  };

  const getVideoTitle = () => {
    if (!provider.value) {
      return;
    }

    if (provider.value.toLowerCase() === 'vimeo') {
      return playerRef.current.getInternalPlayer().element?.title || null;
    }

    if (provider.value.toLowerCase() === 'youtube') {
      return playerRef.current.getInternalPlayer().getVideoData().title || null;
    }
  };

  const hasBgImg = poster?.value && Object.keys(poster?.value).length > 0;
  const hasTitle = title?.value || subTitle?.value;

  // creates an iterator that returns a new heading level with each next() call
  // Needed because titles are optional and we don't want to skip heading levels
  function* headingLevelGenerator() {
    let i = 0;
    while (i < 6) {
      i++;
      yield `h${i}`;
    }
  }

  const headingLevels = headingLevelGenerator();

  return (
    <FullWidthVideoComponent
      className="limitedWidth"
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      {url?.value && ReactPlayer.canPlay(url.value) && (
        <>
          {hasTitle && (
            <TitleWrap>
              {title && (
                <Title as={headingLevels.next().value || 'h1'}>
                  <Text field={title} />
                </Title>
              )}
              {subTitle && (
                <SubTitle as={headingLevels.next().value || 'h2'}>
                  <Text field={subTitle} />
                </SubTitle>
              )}
            </TitleWrap>
          )}

          <ImageWrapper
            onMouseEnter={() => setImageHovered(true)}
            onMouseLeave={() => setImageHovered(false)}
            onClick={() => {
              setShowModal(true);

              if (!isServer()) {
                window.dataLayer.push({
                  event: 'video',
                  videoAction: 'Open',
                });
              }
            }}
            hasBgImg={hasBgImg}
          >
            {hasBgImg && <Picture field={poster} />}
            {hasBgImg && <TextContrastGradient />}

            <OverlayWrap>
              {videoTitle && (
                <VideoTitle as={headingLevels.next().value || 'h3'}>
                  <Text field={videoTitle} />
                </VideoTitle>
              )}
              <PlayIcon imageHovered={imageHovered} translate={translate} pattern={pattern} />
            </OverlayWrap>
          </ImageWrapper>

          {showModal && (
            <Modal isOpen={handleModalClose} visible={showModal}>
              <ReactPlayer
                url={url.value}
                ref={playerRef}
                controls
                light
                playing
                width="100%"
                height="100%"
                className="react-player"
                onReady={() => handleOnReady()}
                onDuration={handleDuration}
                onProgress={handleProgress}
                onEnded={() => setShowModal(false)}
                onPlay={() => {
                  if (!isServer()) {
                    window.dataLayer.push({
                      event: 'video',
                      videoAction: 'Play',
                      videoTitle: getVideoTitle(),
                    });
                  }
                }}
                onPause={() => {
                  if (!isServer()) {
                    window.dataLayer.push({
                      event: 'video',
                      videoAction: 'Pause',
                      videoTitle: getVideoTitle(),
                    });
                  }
                }}
              />
            </Modal>
          )}
        </>
      )}
    </FullWidthVideoComponent>
  );
};

const PlayIcon = ({ imageHovered, translate, pattern }) => {
  const t = key => {
    if (translate && typeof translate === 'function') {
      return translate(`FullWidthVideo_${key}`);
    }

    return key;
  };

  const playVideoText = t('Play');

  return (
    <Icon imageHovered={imageHovered} pattern={pattern}>
      <IconWrap imageHovered={imageHovered}>
        {getIcon('playOutline')}
        {getPattern(pattern)}
        <ButtonText imageHovered={imageHovered}>
          <p>{playVideoText}</p>
        </ButtonText>
      </IconWrap>
    </Icon>
  );
};

FullWidthVideo.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  title: PropTypes.object,
  subTitle: PropTypes.object,
  poster: PropTypes.object,
  videoTitle: PropTypes.object,
  url: PropTypes.object,
  provider: PropTypes.object,
  translate: PropTypes.func,
  pattern: PropTypes.string,
};

PlayIcon.propTypes = {
  imageHovered: PropTypes.bool,
  translate: PropTypes.any,
  pattern: PropTypes.string,
};

export default FullWidthVideo;
