import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const ImageGalleryContainer = styled.section`
  width: calc(100% - 50px);
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};

  ${breakpoints.tabletLandscape} {
    width: calc(100% - 122px);
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
  }
`;

export const ImageGalleryComponent = styled.div`
  position: relative;
  margin: 0;
  overflow: hidden;
`;

export const MainContainer = styled.div`
  position: relative;
`;

export const Title = styled.h1`
  ${props => props.theme.setFont('imageGalleryHeading')}
  position: absolute;
  top: 50%;
  z-index: 10;
  width: ${props => (props.theme.website == 'lm-foundation' ? '75%' : '100%')};
  margin: 0;
  padding: 0 ${props => props.theme.space(2)};
  color: ${props => CM(props.theme).titleColor};
  text-align: center;
  transform: translateY(-50%);
  filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.3));

  ${breakpoints.tabletLandscape} {
    padding: 0 calc(${props => props.theme.space(2)} + ${props => props.theme.px(100)});
  }
`;

export const NextPrevButton = styled.button`
  position: absolute;
  top: 50%;
  left: ${props => (props.previous ? props.theme.space() : `calc(100% - 100px - ${props.theme.space()})`)};
  z-index: 11;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
  margin: 0;
  padding: 0;
  background-color: ${props => CM(props.theme).nextPrevButtonBackgroundColor};
  border: 0;
  border-radius: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  appearance: none;

  svg {
    transform: ${props => (props.previous ? `scale(1.25) rotate(180deg)` : 'scale(1.25)')};
    fill: ${props => CM(props.theme).nextPrevButtonSvgColor};
  }
`;

export const ImageContainer = styled.ul`
  position: relative;
  display: flex;
  width: ${props => (props.singleImage ? '100%' : `calc(90% * ${props.imageCount})`)};

  height: 0px;
  margin: 0;
  padding: 0;
  padding-top: ${props => (props.singleImage ? `calc(1 / 1 * 100%)` : `calc(1 / 1 * 90%)`)};
  list-style-type: none;
  transition-timing-function: ease-out;
  transition-duration: 0.5s;
  transition-property: transform;

  ${breakpoints.tabletLandscape} {
    width: ${props => (props.singleImage ? '100%' : `calc(75% * ${props.imageCount})`)};
    padding-top: ${props => (props.singleImage ? `calc(37 / 64 * 100%)` : `calc(37 / 64 * 75%)`)};
  }
`;

export const ImageItem = styled.li`
  position: absolute;
  top: 0px;
  left: calc(
    ((100% / ${props => props.imageCount}) * ${props => props.itemNumber}) +
      (${props => props.theme.space(0.5)} * ${props => props.itemNumber})
  );
  width: calc(100% / ${props => props.imageCount});
  height: 100%;

  picture {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
`;

export const SlideText = styled.p`
  ${props => props.theme.setFont('imageCarouselText')}
  margin: 0px ${props => props.theme.space()};
  padding: ${props => props.theme.space(0.5)} 0;
  color: ${props => CM(props.theme).slideTextColor};

  span {
    display: none;
  }
  border-bottom: ${props =>
    props.singleImage ? 'none' : `1px solid ${CM(props.theme).slideTextBorderColor}`};

  ${breakpoints.tabletLandscape} {
    position: relative;
    max-width: 75%;
    margin: ${props => props.theme.space(0.5)} 0 0 0;
    padding: 0;
    border: 0;
    span {
      display: inline-block;
      position: absolute;
      left: calc(100% + 0.8125rem);
      width: calc(100% / 3);
    }
  }
`;

export const SwipeText = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.theme.setFont('visualCardSwipeMessage')}
  margin-bottom: 0;
  color: ${props => CM(props.theme).swipeTextColor};

  svg {
    width: 15px;
    margin-right: ${props => props.theme.space(0.5)};
    fill: ${props => CM(props.theme).swipeTextSvgColor};
  }
`;
