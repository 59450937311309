import { css } from 'styled-components';
import { breakpoints } from './breakpoints';
import CTAEnd from '../../assets/button-end.svg';
import ArrowLong from '../../assets/icons/arrow-long.svg';
import Settings from '../../assets/icons/settings.svg';
import { mapColourToSiteTheme as CM } from './colourMap.js';

// Had to use !important to override existing styles as the custom CSS In OneTrust never seemed to take priority.

// We need to replicate the main CTA that the site uses and add it in here for it to display.

const handleCtaStyleBySite = props => {
  const { website } = props;

  switch (website) {
    case 'london-marathon':
      return LMECtaStyles;
    case 'the-big-half':
      return TheBigHalfCtaStyles;
    case 'london-10k':
      return London10kCtaStyles;
    case 'westminster-mile':
      return WestminsterMileCtaStyles;
    case 'swim-serpentine':
      return SwimSerpentineCtaStyles;
    case 'ride-london':
      return RideLondonCtaStyles;
    case 'city-race':
      return CityRaceCtaStyles;
    case 'lm-foundation':
      return LMfoundationCtaStyles;
    default:
      return LMECtaStyles;
  }
};

const siteSpecificStyles = theme => {
  const sites = {
    'london-marathon': {},
    'the-big-half': {
      cookieSettingsMarginBottom: '0',
      cookieSettingsAndAcceptContainerWidth: '40%',
      cookieSettingsAndAcceptContainerLeft: '60%',
    },
    'london-10k': {
      cookieSettingsMarginBottom: '0',
      cookieSettingsAndAcceptContainerWidth: '40%',
      cookieSettingsAndAcceptContainerLeft: '60%',
    },
    'westminster-mile': {
      cookieSettingsMarginBottom: '0',
      cookieSettingsAndAcceptContainerWidth: '40%',
      cookieSettingsAndAcceptContainerLeft: '60%',
    },
    'swim-serpentine': {
      cookieSettingsMarginBottom: '0',
      cookieSettingsAndAcceptContainerWidth: '40%',
      cookieSettingsAndAcceptContainerLeft: '60%',
      borderRadius: '47px',
      padding: '0 20px',
      height: '55px',
    },
    'ride-london': {
      cookieSettingsMarginBottom: '0',
      cookieSettingsAndAcceptContainerWidth: '40%',
      cookieSettingsAndAcceptContainerLeft: '60%',
      borderRadius: '47px',
      padding: '0 20px',
      height: '55px',
    },
    'city-race': {
      cookieSettingsMarginBottom: '0',
      cookieSettingsAndAcceptContainerWidth: '40%',
      cookieSettingsAndAcceptContainerLeft: '60%',
      borderRadius: '47px',
      padding: '0 20px',
      height: '55px',
    },
  };
  if (theme.website in sites) return sites[theme.website];
  return sites['london-marathon'];
};

const LMECtaStyles = css`
  position: relative !important;
  display: inline-block !important;
  width: auto !important;
  min-width: 150px !important;
  margin-right: 0 !important;
  font-weight: 700 !important;
  border: none !important;
  transform: translateZ(0) !important;
  cursor: pointer !important;
  transition: filter 0.25s !important;
  appearance: none !important;

  &:before {
    position: absolute;
    top: 15px;
    left: 7px;
    width: 100%;
    height: 100%;
    background-image: url(${ArrowLong});
    background-repeat: no-repeat;
    content: '';
  }
  &:after {
    position: absolute;
    top: 0;
    width: 55px;
    height: 100%;
    background-image: url(${CTAEnd});
    background-repeat: no-repeat;
    filter: invert(90%) sepia(25%) saturate(2877%) hue-rotate(346deg) brightness(104%) contrast(101%);
    content: '';
  }
`;

const TheBigHalfCtaStyles = css`
  position: relative !important;
  display: inline-block !important;
  width: auto !important;
  min-width: 120px !important;
  height: 55px !important;
  min-height: unset !important;
  margin: 0 !important;
  padding: 0 20px !important;
  font-weight: 700 !important;
  border: none !important;
  border-radius: 47px !important;
  transform: translateZ(0) !important;
  cursor: pointer !important;
  transition: filter 0.25s !important;
  appearance: none !important;
`;

const London10kCtaStyles = css`
  position: relative !important;
  display: inline-block !important;
  width: auto !important;
  min-width: 120px !important;
  height: 58px !important;
  min-height: unset !important;
  margin: 0 !important;
  padding: 0 27px !important;
  font-weight: 700 !important;
  border: 3px solid !important;
  border-color: ${props => CM(props.theme)?.cookieCtaBorderColor} !important;
  transform: translateZ(0) !important;
  cursor: pointer !important;
  transition: filter 0.25s !important;
  appearance: none !important;
  background-color: #fff !important;
`;

const LMfoundationCtaStyles = css`
  position: relative !important;
  display: inline-block !important;
  width: auto !important;
  min-width: 120px !important;
  height: 60px !important;
  min-height: unset !important;
  margin: 0 !important;
  padding: 0 27px !important;
  font-weight: 700 !important;
  border-radius: 10px;
  transform: translateZ(0) !important;
  cursor: pointer !important;
  transition: filter 0.25s !important;
  appearance: none !important;
  background-color: ${props => props.theme.colors.secondary[600]};
  color: ${props => props.theme.colors.primary[300]};
`;

const WestminsterMileCtaStyles = css`
  position: relative !important;
  display: inline-block !important;
  width: auto !important;
  min-width: 120px !important;
  height: 60px !important;
  min-height: unset !important;
  margin: 0 !important;
  padding: 0 27px !important;
  font-weight: 700 !important;
  border: 3px solid !important;
  border-color: ${props => CM(props.theme)?.cookieCtaBorderColor} !important;
  transform: translateZ(0) !important;
  cursor: pointer !important;
  transition: filter 0.25s !important;
  appearance: none !important;
`;

const SwimSerpentineCtaStyles = css`
  position: relative !important;
  display: inline-block !important;
  width: auto !important;
  min-width: 120px !important;
  height: 55px !important;
  min-height: unset !important;
  margin: 0 !important;
  padding: 0 20px !important;
  font-weight: 700 !important;
  border: none !important;
  border-radius: 47px !important;
  transform: translateZ(0) !important;
  cursor: pointer !important;
  transition: filter 0.25s !important;
  appearance: none !important;
`;

const RideLondonCtaStyles = css`
  position: relative !important;
  display: inline-block !important;
  width: auto !important;
  min-width: 120px !important;
  height: 55px !important;
  min-height: unset !important;
  margin: 0 !important;
  padding: 0 20px !important;
  font-weight: 700 !important;
  border: none !important;
  border-radius: 47px !important;
  transform: translateZ(0) !important;
  cursor: pointer !important;
  transition: filter 0.25s !important;
  appearance: none !important;
`;

const CityRaceCtaStyles = css`
  position: relative !important;
  display: inline-block !important;
  width: auto !important;
  min-width: 120px !important;
  height: 55px !important;
  min-height: unset !important;
  margin: 0 !important;
  padding: 0 20px !important;
  font-weight: 700 !important;
  border: none !important;
  border-radius: 47px !important;
  transform: translateZ(0) !important;
  cursor: pointer !important;
  transition: filter 0.25s !important;
  appearance: none !important;
`;

export const CookieStyles = css`
  .ot-sdk-container {
    padding: 26px !important;
  }

  #onetrust-button-group-parent {
    ${breakpoints.tabletLandscape} {
      width: ${props => siteSpecificStyles(props.theme).cookieSettingsAndAcceptContainerWidth} !important;

      ${breakpoints.desktopSmall} {
        left: ${props => siteSpecificStyles(props.theme).cookieSettingsAndAcceptContainerLeft} !important;
      }
    }
  }

  .category-menu-switch-handler {
    &.ot-active-menu {
      border-color: transparent !important;
    }
  }

  .ot-pc-header {
    display: flex !important;
    align-items: center !important;
    background-color: ${props => CM(props.theme).cookieHeaderBackground} !important;

    .ot-title-cntr {
      ${breakpoints.tabletLandscape} {
        padding-left: 65px !important;
      }
    }

    #ot-pc-title {
      display: none !important;

      ${breakpoints.tabletLandscape} {
        display: block !important;
      }

      &:before,
      &:after {
        content: none !important;
      }
    }
  }
  .ot-pc-footer {
    background-color: ${props => CM(props.theme).cookieFooterBackground} !important;

    .ot-pc-footer-logo {
      display: none !important;
    }
  }

  #onetrust-policy {
    margin: 0 0 18px !important;

    #onetrust-policy-title {
      display: block !important;
      margin-bottom: 14px !important;
      font-size: 14px !important;
      line-height: 25px !important;

      ${breakpoints.tabletLandscape} {
        margin-bottom: 6px !important;
      }
    }

    #onetrust-policy-text {
      margin-bottom: 0 !important;
      font-size: 12px !important;
      line-height: 21px !important;

      a {
        color: ${props => CM(props.theme).cookieLinkColor} !important;
        font-weight: normal !important;
        border: none !important;
        outline: none !important;
      }
    }
  }

  #onetrust-button-group {
    display: flex !important;
    flex-direction: ${props =>
      props.theme.website == 'lm-foundation' ? 'column-reverse' : 'row-reverse'} !important;
    align-items: center !important;
    justify-content: space-between !important;

    ${breakpoints.tabletLandscape} {
      flex-direction: row !important;
      justify-content: flex-end !important;
    }
  }

  #onetrust-button-group button {
    width: auto !important;
    height: 55px !important;
    font-size: 14px !important;
    line-height: 25px !important;
  }
  .cookie-setting-link {
    position: relative !important;
    color: #000000 !important;
    font-size: 14px !important;
    text-decoration: none !important;
    opacity: 1;
    margin-bottom: ${props => siteSpecificStyles(props.theme)?.cookieSettingsMarginBottom} !important;

    &:hover {
      text-decoration: underline !important;
      opacity: 1 !important;
    }

    ${breakpoints.tabletLandscape} {
      margin-right: 40px !important;
    }

    &:before {
      position: absolute;
      top: 50%;
      left: ${props => (props.theme.website == 'lm-foundation' ? -10 : -5)}px;
      width: 10px;
      height: 10px;
      background-image: url(${Settings});
      background-repeat: no-repeat;
      transform: translateY(-50%);
      content: '';
    }
  }
  #onetrust-accept-btn-handler {
    ${props => handleCtaStyleBySite(props.theme)};
  }

  .ot-btn-container {
    display: flex !important;
    justify-content: flex-end !important;

    button {
      width: auto !important;
    }
  }

  #accept-recommended-btn-handler {
    border-radius: ${props => siteSpecificStyles(props.theme).borderRadius} !important;
    padding: ${props => siteSpecificStyles(props.theme).padding} !important;
    height: ${props => siteSpecificStyles(props.theme).height} !important;
  }
`;
