import { colors } from './colors';

const createLinearGradient = (fromColor, toColor) =>
  `linear-gradient(0deg, ${fromColor} 0%, ${toColor} 100%)`;

export const gradients = {
  'london-skies': createLinearGradient(colors.secondary[200], colors.secondary[300]), // light -> dark blue
  'apperal-energy': createLinearGradient(colors.secondary[800], colors.secondary[700]), // pink -> orange
  'royal-attendance': createLinearGradient(colors.secondary[900], colors.secondary[700]), // pink -> purple
  'golden-grass': createLinearGradient(colors.secondary[100], colors.secondary[500]), // green -> gold
};
