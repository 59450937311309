import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { FAQVotingComponent, VoteWrap, VoteConfirmedWrap } from './FAQVoting.styles.js';
import { getIcon } from '../utils/icon.js';
import CTA from '../cta';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { useLocation } from 'react-router-dom';
import { withTheme } from 'styled-components';

const FAQVoting = props => {
  const {
    params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
    theme: { website },
  } = props;

  const t = key => {
    if (props.translate && typeof props.translate === 'function') {
      return props.translate(`FAQVoting_${key}`);
    }
    return key;
  };
  const location = useLocation();
  const questionText = t('Question');
  const confirmationText = t('Confirmation');
  const yesText = t('Yes');
  const noText = t('No');

  const [selectedVoteOption, setSelectedVoteOption] = useState(null);
  const [hoverText, setHoverText] = useState(null);
  const voteConfirmedRef = useRef(null);

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  function handleVoteClick(option) {
    setSelectedVoteOption(option);
    const storedVoteJson = sessionStorage.getItem('storedVoteOption');
    const storedVoteOption = storedVoteJson ? JSON.parse(storedVoteJson) : {};
    const locationSplit = location.pathname.split('/')[2];
    storedVoteOption[locationSplit] = option;
    sessionStorage.setItem('storedVoteOption', JSON.stringify(storedVoteOption));

    dataLayerEvent('faqAction', {
      faqAction: option,
      faqTitle: questionText,
    });
  }

  useEffect(() => {
    if (selectedVoteOption !== null) {
      voteConfirmedRef.current.classList.add('animate-icon');

      setTimeout(() => {
        voteConfirmedRef.current.classList.add('animate-text');
      }, 700);
    }
  }, [selectedVoteOption]);

  useEffect(() => {
    const storedVoteJson = sessionStorage.getItem('storedVoteOption');
    const storedVote = storedVoteJson ? JSON.parse(storedVoteJson) : {};
    const locationSplit = location.pathname.split('/')[2];

    if (storedVote[locationSplit]) {
      setSelectedVoteOption(storedVote[locationSplit]);
    }
  }, []);

  return (
    <FAQVotingComponent
      className="limitedWidth"
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      <h1>{questionText}</h1>
      <VoteWrap>
        {selectedVoteOption !== null || selectedVoteOption?.length ? (
          <VoteConfirmedWrap ref={voteConfirmedRef}>
            <p>{confirmationText}</p>
            {selectedVoteOption === 'like' ? getIcon('thumbsUpIcon') : getIcon('thumbsDownIcon')}
          </VoteConfirmedWrap>
        ) : (
          <>
            <CTA
              onClick={() => handleVoteClick('like')}
              label={yesText}
              type={website == 'lm-foundation' ? 'B' : 'A'}
              icon="thumbsUpIcon"
              onMouseEnter={() => setHoverText('like')}
              onMouseLeave={() => setHoverText(null)}
              className={hoverText !== null && hoverText !== 'like' ? 'inactive' : ''}
            />
            <CTA
              onClick={() => handleVoteClick('dislike')}
              label={noText}
              icon="thumbsDownIcon"
              type={website == 'lm-foundation' ? 'B' : 'A'}
              onMouseEnter={() => setHoverText('dislike')}
              onMouseLeave={() => setHoverText(null)}
              className={hoverText !== null && hoverText !== 'dislike' ? 'inactive' : ''}
            />
          </>
        )}
      </VoteWrap>
    </FAQVotingComponent>
  );
};

FAQVoting.propTypes = {
  translate: PropTypes.func,
  params: PropTypes.object,
  theme: PropTypes.object,
};

export default withTheme(FAQVoting);
