import { colors } from './colors';

const w = colors.primary[300];
const b = colors.primary[200];
const primary = colors.primary[100];

// [textColor, backgroundColor, borderColor]

export const buttonVariants = {
  default: [b, w, primary],
  whiteA: [w, b, w],
  whiteE: [w, b, w],
  socialIcon: [w, w, w],
  blackA: [b, w, primary],
  blackC: [b, w, primary],
};

export const buttonHoverVariants = {
  default: [b, primary, primary],
  whiteA: [b, w, w],
  whiteE: [b, w, w],
  socialIcon: [w, w, w],
  blackA: [b, primary, primary],
  blackC: [b, primary, primary],
};
