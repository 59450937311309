import styled, { css } from 'styled-components';
import { breakpoints } from '../../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

const handleSpecificPatternStyles = pattern => {
  const patterns = {
    default: {
      landscape: {
        top: 0,
        right: '-24%',
        scale: 1,
        skew: '26deg',
        rotate: 0,
        svgScale: 0.5,
      },
      desktop: {
        top: 0,
        right: '-24%',
        scale: 1,
        skew: '26deg',
        rotate: 0,
        svgScale: 0.5,
      },
      desktopLarge: {
        top: 0,
        right: '-24%',
        scale: 1,
        skew: '26deg',
        rotate: 0,
        svgScale: 0.5,
      },
    },
    'tower-bridge-alt': {
      landscape: {
        top: 0,
        right: '-23%',
        scale: 1,
        skew: '26deg',
        rotate: 0,
        svgScale: 0.5,
      },
      desktop: {
        top: 0,
        right: '-23%',
        scale: 1,
        skew: '26deg',
        rotate: 0,
        svgScale: 0.5,
      },
      desktopLarge: {
        top: 0,
        right: '-23%',
        scale: 1,
        skew: '26deg',
        rotate: 0,
        svgScale: 0.5,
      },
    },
    'parallel-lines': {
      landscape: {
        top: 0,
        right: '-24%',
        scale: 1.1,
        skew: '26deg',
        rotate: 0,
        svgScale: 0.5,
      },
      desktop: {
        top: 0,
        right: '-24%',
        scale: 1.1,
        skew: '26deg',
        rotate: 0,
        svgScale: 0.5,
      },
      desktopLarge: {
        top: 0,
        right: '-24%',
        scale: 1.1,
        skew: '26deg',
        rotate: 0,
        svgScale: 0.5,
      },
    },
    'scattered-circles': {
      landscape: {
        top: 0,
        right: '-24%',
        scale: 1.1,
        skew: '26deg',
        rotate: 0,
        svgScale: 0.5,
      },
      desktop: {
        top: 0,
        right: '-24%',
        scale: 1.1,
        skew: '26deg',
        rotate: 0,
        svgScale: 0.5,
      },
      desktopLarge: {
        top: 0,
        right: '-24%',
        scale: 1.1,
        skew: '26deg',
        rotate: 0,
        svgScale: 0.5,
      },
    },
    peak: {
      landscape: {
        top: '-9%',
        right: '-46%',
        scale: '-1, 2',
        skew: 0,
        rotate: '153deg',
        svgScale: 1,
      },
      desktop: {
        top: '-9%',
        right: '-46%',
        scale: '-1, 2',
        skew: 0,
        rotate: '153deg',
        svgScale: 1,
      },
      desktopLarge: {
        top: '-9%',
        right: '-46%',
        scale: '-1, 2',
        skew: 0,
        rotate: '153deg',
        svgScale: 1,
      },
    },
    waves: {
      landscape: {
        top: '-11%',
        right: '-26%',
        scale: 0.7,
        skew: '20deg',
        rotate: 0,
        svgScale: 0.5,
      },
      desktop: {
        top: '-11%',
        right: '-26%',
        scale: 0.7,
        skew: '20deg',
        rotate: 0,
        svgScale: 0.5,
      },
      desktopLarge: {
        top: '-11%',
        right: '-26%',
        scale: 0.7,
        skew: '20deg',
        rotate: 0,
        svgScale: 0.5,
      },
    },
    'shape-of-water-01': {
      landscape: {
        top: '7%',
        right: '-34%',
        scale: 1,
        skew: '23deg',
        rotate: 0,
        svgScale: 0.8,
      },
      desktop: {
        top: '7%',
        right: '-34%',
        scale: 1,
        skew: '23deg',
        rotate: 0,
        svgScale: 0.8,
      },
      desktopLarge: {
        top: '7%',
        right: '-34%',
        scale: 1,
        skew: '23deg',
        rotate: 0,
        svgScale: 0.8,
      },
    },
    'shape-of-water-02': {
      landscape: {
        top: '7%',
        right: '-36%',
        scale: 1,
        skew: '16deg',
        rotate: '-368deg',
        svgScale: 0.8,
      },
      desktop: {
        top: '7%',
        right: '-36%',
        scale: 1,
        skew: '16deg',
        rotate: '-368deg',
        svgScale: 0.8,
      },
      desktopLarge: {
        top: '7%',
        right: '-36%',
        scale: 1,
        skew: '16deg',
        rotate: '-368deg',
        svgScale: 0.8,
      },
    },
    'shape-of-water-03': {
      landscape: {
        top: '12%',
        right: '-39%',
        scale: 1.1,
        skew: '25deg',
        rotate: '362deg',
        svgScale: 0.8,
      },
      desktop: {
        top: '12%',
        right: '-39%',
        scale: 1.1,
        skew: '25deg',
        rotate: '362deg',
        svgScale: 0.8,
      },
      desktopLarge: {
        top: '12%',
        right: '-39%',
        scale: 1.1,
        skew: '25deg',
        rotate: '362deg',
        svgScale: 0.8,
      },
    },
    'shape-of-water-04': {
      landscape: {
        top: '8%',
        right: '-39%',
        scale: 1,
        skew: '28deg',
        rotate: '-3deg',
        svgScale: 0.8,
      },
      desktop: {
        top: '8%',
        right: '-39%',
        scale: 1,
        skew: '28deg',
        rotate: '-3deg',
        svgScale: 0.8,
      },
      desktopLarge: {
        top: '8%',
        right: '-39%',
        scale: 1,
        skew: '28deg',
        rotate: '-3deg',
        svgScale: 0.8,
      },
    },
    'circle-outlines': {
      landscape: {
        top: '-23%',
        right: '-31%',
        scale: 0.6,
        rotate: '71deg',
        skew: '-21deg, -7deg',
        svgScale: 1,
      },
      desktop: {
        top: '-22%',
        right: '-37%',
        scale: 0.6,
        rotate: '71deg',
        skew: '-21deg, -5deg',
        svgScale: 1,
      },
      desktopLarge: {
        top: '-20%',
        right: '-39%',
        scale: 0.65,
        rotate: '71deg',
        skew: '-28deg, 2deg',
        svgScale: 1,
      },
    },
    'triangle-outlines': {
      landscape: {
        top: '-22%',
        right: '-32%',
        scale: 0.6,
        skew: '-23deg',
        rotate: '-119deg',
        svgScale: 1,
      },
      desktop: {
        top: '-20%',
        right: '-38%',
        scale: 0.61,
        skew: '-23deg',
        rotate: '-115deg',
        svgScale: 1,
      },
      desktopLarge: {
        top: '-20%',
        right: '-41%',
        scale: 0.6,
        skew: '-23deg',
        rotate: '-115deg',
        svgScale: 1,
      },
    },
    power: {
      landscape: {
        top: '-18%',
        right: '-42%',
        scale: 0.7,
        skew: '-23deg',
        rotate: '-114deg',
        svgScale: 1,
      },
      desktop: {
        top: '-18%',
        right: '-42%',
        scale: 0.7,
        skew: '-23deg',
        rotate: '-114deg',
        svgScale: 1,
      },
      desktopLarge: {
        top: '-18%',
        right: '-42%',
        scale: 0.7,
        skew: '-23deg',
        rotate: '-114deg',
        svgScale: 1,
      },
    },
    unity: {
      landscape: {
        top: '-18%',
        right: '-42%',
        scale: 0.7,
        skew: '-24deg',
        rotate: '-114deg',
        svgScale: 1,
      },
      desktop: {
        top: '-18%',
        right: '-42%',
        scale: 0.7,
        skew: '-24deg',
        rotate: '-114deg',
        svgScale: 1,
      },
      desktopLarge: {
        top: '-18%',
        right: '-42%',
        scale: 0.7,
        skew: '-24deg',
        rotate: '-114deg',
        svgScale: 1,
      },
    },
    frame: {
      landscape: {
        top: 0,
        right: '-25%',
        scale: 1.1,
        skew: '26deg',
        rotate: 0,
        svgScale: 1,
      },
      desktop: {
        top: 0,
        right: '-32.5%',
        scale: 1.1,
        skew: '26deg',
        rotate: 0,
        svgScale: 1,
      },
      desktopLarge: {
        top: 0,
        right: '-35%',
        scale: 1.1,
        skew: '26deg',
        rotate: 0,
        svgScale: 1,
      },
    },
    wheels: {
      landscape: {
        top: 0,
        right: '-15%',
        scale: 1.1,
        skew: '26deg',
        rotate: 0,
        svgScale: 1,
      },
      desktop: {
        top: 0,
        right: '-26%',
        scale: 1.1,
        skew: '26deg',
        rotate: 0,
        svgScale: 1,
      },
      desktopLarge: {
        top: 0,
        right: '-32.5%',
        scale: 1.1,
        skew: '26deg',
        rotate: 0,
        svgScale: 1,
      },
    },
    handlebars: {
      landscape: {
        top: 0,
        right: '-27%',
        scale: 1.2,
        skew: '26deg',
        rotate: 0,
        svgScale: 1,
      },
      desktop: {
        top: 0,
        right: '-1.5%',
        scale: 1.1,
        skew: '26deg',
        rotate: 0,
        svgScale: 1,
      },
      desktopLarge: {
        top: '-11%',
        right: '-12%',
        scale: 1.3,
        skew: '26deg',
        rotate: 0,
        svgScale: 1.2,
      },
    },
    'landscape-outlined': {
      landscape: {
        top: '-1%',
        right: '-26%',
        scale: 1,
        skew: '26deg',
        rotate: 0,
        svgScale: 0.8,
      },
      desktop: {
        top: '-1%',
        right: '-30%',
        scale: 1,
        skew: '26deg',
        rotate: 0,
        svgScale: 0.8,
      },
      desktopLarge: {
        top: '-1%',
        right: '-33%',
        scale: 1,
        skew: '26deg',
        rotate: 0,
        svgScale: 0.8,
      },
    },
    'on-your-marks': {},
    'on-your-marks-alt': {},
    'get-set': {},
    go: {},
    'transformation-full': {
      landscape: {
        top: '1%',
        right: '-28%',
        scale: 1.3,
        skew: '26deg',
        rotate: '-10deg',
        svgScale: 1,
      },
      desktop: {
        top: '1%',
        right: '-31%',
        scale: 1.35,
        skew: '26deg',
        rotate: '-10deg',
        svgScale: 1,
      },
      desktopLarge: {
        top: '1%',
        right: '-35%',
        scale: 1.45,
        skew: '26deg',
        rotate: '-7deg',
        svgScale: 1,
      },
    },
  };

  if (pattern in patterns) return patterns[pattern];

  return patterns.default;
};

const animationTimings = {
  content: {
    time: '0.75s',
    delay: '0.1s',
  },
  layerImage: {
    time: '0.5s',
    delay: '0.75s',
  },
  pattern: {
    time: '0.5s',
    delay: '0.5s',
  },
};

export const SlideComponent = styled.li`
  position: absolute;
  top: 0;
  left: calc(${props => props.slideNumber * 100}%);
  width: 100%;
  height: 100%;

  ${breakpoints.tabletLandscape} {
    left: ${props => (props.isCarousel ? `calc(${props.slideNumber * 100}% - 330px)` : '0%')};

    &:first-of-type {
      width: ${props => (props.isCarousel ? 'calc(100% + 330px)' : '100%')};

      .content {
        width: calc(85% - ${props => props.theme.space(2)} - 330px);
      }
    }
  }
`;

export const ContentContainer = styled.div`
  position: absolute;
  bottom: -300px;
  left: 0px;
  width: 100%;
  height: auto;
  padding: 0 ${props => props.theme.space()};
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  transition: bottom ${animationTimings.content.time} ease-out;
  transition-delay: ${animationTimings.content.delay};

  h1 {
    margin-bottom: 0;
    color: ${props => CM({ theme: props.theme, gradient: props?.gradient }).titleColor};
    font-family: ${props => props.theme.typography.headings};
    ${props => props.theme.setFont('heroImpactHeading')}
  }

  p {
    ${props => props.theme.setFont('h8')}
    color: ${props => CM({ theme: props.theme, gradient: props?.gradient }).textColor};
  }

  ${breakpoints.tabletPortrait} {
    padding: 0 ${props => props.theme.space(2)};
    h1 {

      max-width: ${({ theme: { website } }) => (website === 'lm-foundation' ? '65%' : 'unset')};
    }
  }

  ${breakpoints.tabletLandscape} {
    left: ${props => (props.isCarousel ? '330px' : '0px')};
    width: calc(85% - ${props => props.theme.space(2)});
    padding: ${props => props.theme.space(2.5)} ${props => props.theme.space(5)} 0
      ${props => props.theme.space(2)};
  }

  ${breakpoints.desktopSmall} {
    width: 75%;
    padding: ${props => props.theme.space(2.5)} 0 0 ${props => props.theme.space(2)};
  }

  ${breakpoints.desktopLarge} {
    width: 80%;
  }

  .activeSlide & {
    bottom: 71px;
  }

  .slideCTA {
    ${breakpoints.tabletLandscape} {
      max-width: max-content;
    }
  }
`;

const bgFirstElement = props => css`
  width: ${props => (props.isCarousel ? 'calc(100% - 355px)' : '100%')};

  &:before {
    left: 158px;
    width: 100%;
  }
`;

const backgroundLayers = props => {
  let bgString = 'none';
  if (props?.backgroundImage && props?.gradient) {
    bgString = `url(${props?.backgroundImage}), ${props?.theme?.gradients?.[props?.gradient] || ''}`;
  } else if (!props?.backgroundImage && props?.gradient) {
    bgString = `${props?.theme?.gradients?.[props?.gradient] || 'none'}`;
  } else if (props?.backgroundImage && !props?.gradient) {
    bgString = `url(${props?.backgroundImage})`;
  }
  return bgString;
};

export const Background = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: transparent;
  background-image: ${props => (props?.gradient && props?.theme?.gradients?.[props?.gradient]) || 'none'};
  background-position: center;
  background-size: cover;

  picture.backgroundImage,
  .sc-image-wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    ${breakpoints.tabletLandscape} {
      left: -150px;
      width: calc(100% + 300px);
      transform: ${props => (props.isCarousel ? 'skewX(26deg)' : 'none')};
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  picture.backgroundImagePreview {
    position: absolute;
    top: 0px;
    left: -140px;
    width: 304px;
    height: 100%;

    ${breakpoints.tabletLandscape} {
      transform: ${props => (props.isCarousel ? 'skewX(26deg)' : 'none')};
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  ${breakpoints.tabletLandscape} {
    left: ${props => (props.isCarousel ? '165px' : '0px')};
    width: calc(100% - 26px);
    height: 100%;
    overflow: hidden;
    background: none;
    transform: ${props => (props.isCarousel ? 'skewX(-26deg)' : 'none')};

    &:before {
      position: absolute;
      top: 0px;
      left: -247px;
      display: block;
      width: ${props => (props.isCarousel ? 'calc(100% + 386px)' : '100%')};
      height: 100%;
      background-color: transparent;
      background-image: ${props => backgroundLayers(props)};
      background-position: center;
      background-size: cover;
      transform: ${props => (props.isCarousel ? 'skewX(26deg)' : 'none')};
      content: '';
    }

    ${props => (props.isFirst ? bgFirstElement(props) : null)}
  }
`;

export const BackgroundGradient = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(to top, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
`;

export const Timer = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 5px;
  background-color: ${props => CM({ theme: props.theme, color: props.color }).timerBackgroundColor};
  transform: rotate(180deg);
  z-index: 1;

  ${breakpoints.tabletLandscape} {
    left: 303px;
    width: calc(100% - 301px);
    transform: rotate(180deg) ${props => (props.isCarousel ? 'skewX(26deg)' : 'none')};
  }
`;

export const TimerElement = styled.span`
  display: block;
  width: 100%;
  height: 100%;
  background-color: ${props => props?.theme?.colors?.named?.[props?.timerColor] || 'golden'};
  transition-timing-function: linear;
  transition-property: width;
  transform: skew(-40deg);
`;

export const Pattern = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  perspective: 1000;
  opacity: 0;
  transition: opacity ${animationTimings.pattern.time} ease-out;
  transition-delay: ${animationTimings.pattern.delay};

  svg {
    position: absolute;
    top: ${props => props.theme.space(0.5)};
    left: 50%;
    width: 30%;
    height: 50%;
    transform: translateX(-50%);

    fill: ${props =>
      props?.theme?.colors?.named?.[props?.patternColor] || props?.theme?.colors?.named['golden']};
    path,
    g {
      fill: ${props =>
        props?.theme?.colors?.named?.[props?.patternColor] || props?.theme?.colors?.named['golden']};
    }
  }

  ${breakpoints.tabletLandscape} {
    right: ${props =>
      props.isCarousel ? handleSpecificPatternStyles(props.patternName)?.landscape?.right : `-10%`};
    top: ${props =>
      props.isCarousel ? handleSpecificPatternStyles(props.patternName)?.landscape?.top : '0'};
    width: unset;

    transform: ${props =>
      `translateZ(0) rotate(${handleSpecificPatternStyles(props.patternName)?.landscape?.rotate}) skew(${
        handleSpecificPatternStyles(props.patternName)?.landscape?.skew
      }) scale(${handleSpecificPatternStyles(props.patternName)?.landscape?.scale})`};

    svg {
      position: static;
      top: 0px;
      left: unset;
      width: 100%;
      height: 100%;

      transform: ${props => `scale(${handleSpecificPatternStyles(props.patternName)?.landscape?.svgScale})`};

      transform-origin: top right;
      fill: ${props =>
        props?.theme?.colors?.named?.[props?.patternColor] || props?.theme?.colors?.named['golden']};
      path,
      g {
        fill: ${props =>
          props?.theme?.colors?.named?.[props?.patternColor] || props?.theme?.colors?.named['golden']};
      }
    }
  }

  ${breakpoints.desktopSmall} {
    right: ${props =>
      props.isCarousel ? handleSpecificPatternStyles(props.patternName)?.desktop?.right : `-10%`};
    top: ${props => (props.isCarousel ? handleSpecificPatternStyles(props.patternName)?.desktop?.top : '0')};
    width: unset;

    transform: ${props =>
      `translateZ(0) rotate(${handleSpecificPatternStyles(props.patternName)?.desktop?.rotate}) skew(${
        handleSpecificPatternStyles(props.patternName)?.desktop?.skew
      }) scale(${handleSpecificPatternStyles(props.patternName)?.desktop?.scale})`};

    svg {
      transform: ${props => `scale(${handleSpecificPatternStyles(props.patternName)?.desktop?.svgScale})`};
    }
  }

  ${breakpoints.desktopLarge} {
    right: ${props =>
      props.isCarousel ? handleSpecificPatternStyles(props.patternName)?.desktopLarge?.right : `-10%`};
    top: ${props =>
      props.isCarousel ? handleSpecificPatternStyles(props.patternName)?.desktopLarge?.top : '0'};
    width: unset;

    transform: ${props =>
      `translateZ(0) rotate(${handleSpecificPatternStyles(props.patternName)?.desktopLarge?.rotate}) skew(${
        handleSpecificPatternStyles(props.patternName)?.desktopLarge?.skew
      }) scale(${handleSpecificPatternStyles(props.patternName)?.desktopLarge?.scale})`};

    svg {
      transform: ${props =>
        `scale(${handleSpecificPatternStyles(props.patternName)?.desktopLarge?.svgScale})`};
    }
  }

  .activeSlide & {
    opacity: 1;
  }
`;

export const LayerImage = styled.div`
  position: absolute;
  top: ${props => props.theme.space(0.5)};
  right: 50%;
  width: 50%;
  height: 50%;
  background-image: ${props => `url(${props.url})` || 'none'};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  transform: translateX(50%) translateZ(0);
  backface-visibility: hidden;
  perspective: 1000;
  opacity: 0;
  transition: opacity ${animationTimings.layerImage.time} ease-out;
  transition-delay: ${animationTimings.layerImage.delay};

  ${breakpoints.tabletLandscape} {
    top: 50%;
    right: ${props => (props.isCarousel ? '-15%' : '0%')};
    height: 100%;
    transform: scale(1) translateZ(0) ${props => (props.isCarousel ? 'skewX(26deg)' : '')} translateY(-50%);
  }

  .activeSlide & {
    opacity: 1;
  }
`;

export const LayerImagePreview = styled.div`
  position: absolute;
  bottom: 10px;
  left: 135px;
  width: 180px;
  height: 300px;
  background-image: ${props => `url(${props.url})` || 'none'};
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
`;

export const Preview = styled.button`
  position: absolute;
  right: 20px;
  bottom: ${props => props.theme.space()};
  z-index: 10;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: left;
  background: none;
  border: none;
  cursor: pointer;
  appearance: none;

  p {
    display: none;
  }

  ${breakpoints.tabletPortrait} {
    right: 46px;
  }

  ${breakpoints.tabletLandscape} {
    bottom: 0px;
    left: 80px;
    align-items: flex-end;
    width: 229px;

    p {
      display: block;
      ${props => props.theme.setFont('carouselSmallText')}
      width: 100%;
      color: ${props => CM({ theme: props.theme, gradient: props?.gradient }).previewButtonColor};
      font-weight: 700;
    }
  }
`;

export const NextButton = styled.div`
  ${props => props.theme.setFont('carouselStateText')}
  display: flex;
  align-items: center;
  margin: 0;
  color: ${props => CM({ theme: props.theme, gradient: props?.gradient }).nextButtonColor};

  p {
    color: ${props => CM({ theme: props.theme, gradient: props?.gradient }).nextButtonColor};
  }

  ${breakpoints.tabletLandscape} {
    margin: 0 0 22px ${props => props.theme.space()};
  }

  svg {
    margin-left: 10px;
    transform: scale(1.25);
    fill: ${props => CM({ theme: props.theme, gradient: props?.gradient }).nextButtonSvgColor};
  }
`;

export const MobileSlice = styled.div`
  position: absolute;
  width: 100%;
  height: 30%;
  background-color: white;
  clip-path: polygon(0% 0%, 100% 0%, 100% 50%, 0% 100%);
`;
