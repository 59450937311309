import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { enableBodyScroll, disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';
import { useIsMobile } from '../hooks/useIsMobile';
import {
  HeaderComponent,
  UtilityBar,
  NavBar,
  AlertText,
  Hamburger,
  MobileNav,
  MobileSubMenuHeader,
  DesktopNav,
  CloseDesktopMenuButton,
  SearchButton,
  ButtonContainer,
  EnterButtonContainer,
} from './Header.styles.js';
import Card from './Card/Card';
import CTA from '../cta';
import SearchOverlay from './SearchOverlay/SearchOverlay';
import { breakpointValues } from '../global-styles/breakpoints';
import { getIcon } from '../utils/icon';
import { setInert } from '../utils/inert';
import { Text, withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { withTheme } from 'styled-components';
import { handleCtaMappingByTheme } from '../cta/ctaMapper';

const Header = props => {
  const {
    alert = {},
    utilityLinks = [],
    items = [],
    showSearch = false,
    cta = {},
    ctaTheme = '',
    sitecoreContext: { searchOverlay = {}, pageEditing = false },
    theme: { website },
  } = props;

  let logo = null;

  if (props?.logo?.value) {
    logo = props.logo.value;
  }

  const mobileBreakpoint = website !== 'lm-foundation' ? 'tabletLandscape' : 'desktopSmall';

  const mql =
    typeof window !== 'undefined'
      ? window.matchMedia(`(min-width: ${breakpointValues[mobileBreakpoint]}px)`)
      : { matches: true };

  const desktopMenuButtonRefs = items.map(() => useRef(null));
  const headerRef = useRef(null);
  const navRef = useRef(null);
  const mobileNavRef = useRef(null);
  const firstMenuItemRef = useRef(null);
  const mobileNavBackButtonRef = useRef(null);
  const searchOverlayRef = useRef(null);
  const [isDesktop, setIsDesktop] = useState(mql.matches);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [menuIndex, setMenuIndex] = useState(-1);
  const desktopNavItemsRef = useRef([]);
  const subNavItemRef = useRef(null);
  const [previousMenuIndex, setPreviousMenuIndex] = useState(-1);
  const [isMounted, setIsMounted] = useState(false);
  const [searchOverlayIsVisible, setSearchOverlayIsVisible] = useState(false);
  const isMobile = useIsMobile('tabletLandscape', Number('-1'));
  const enterCtaVisible = cta?.text?.length > 0 && cta?.href?.length > 0;

  const handleMediaQuery = e => {
    if (e.matches) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
  };

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  const handleMobileMenuBack = sectionName => {
    dataLayerEvent('navBackMobile', { navigationSection: sectionName });
    setMenuIndex(-1);
  };

  useEffect(() => {
    if (!isServer()) {
      mql.addListener(handleMediaQuery);
      setIsMounted(true);
    }

    return () => {
      mql.removeListener(handleMediaQuery);
    };
  }, []);

  useEffect(() => {
    if (mobileMenuOpen && mobileNavRef.current) {
      disableBodyScroll(mobileNavRef.current);
      setInert('main, mainContent, footer', true);
      const navPos = navRef.current.getBoundingClientRect();
      const barHeight = navPos.top + navPos.height + (website == 'lm-foundation' ? 3 : 6);
      mobileNavRef.current.style.top = `${barHeight}px`;
      mobileNavRef.current.style.height = `calc(100% - ${barHeight}px)`;
    } else {
      clearAllBodyScrollLocks();
      setMenuIndex(-1);

      setInert('main, mainContent, footer', false);

      const bodySelector = document.getElementsByTagName('body')[0];
      const revealComponent = document.querySelector('.reveal');

      if (pageEditing || (!pageEditing && !revealComponent)) {
        bodySelector.classList.remove('body-hidden');
        bodySelector.classList.add('body-visible');
        bodySelector.style.removeProperty('overflow');
      }
    }
  }, [mobileMenuOpen]);

  useEffect(() => {
    const heroimpact = document.querySelector('.headerComponentClass');

    if (searchOverlayIsVisible && searchOverlayRef.current) {
      disableBodyScroll(searchOverlayRef.current);
      setInert('main, footer, .skipLink, .header-navbar, .header-utilitybar, #header-ads', true);
      setInert('#search-overlay', false);
      if (heroimpact) {
        heroimpact.style.visibility = 'hidden';
      }
    } else {
      if (searchOverlayRef.current) {
        enableBodyScroll(searchOverlayRef.current);
      }
      setInert('main, footer, .skipLink, .header-navbar, .header-utilitybar, #header-ads', false);
      setInert('#search-overlay', true);
      if (heroimpact) {
        heroimpact.style.visibility = 'visible';
      }
    }
  }, [searchOverlayIsVisible]);

  useEffect(() => {
    if (!isDesktop) {
      if (menuIndex > -1) {
        mobileNavBackButtonRef.current.focus();
      } else if (menuIndex === -1 && firstMenuItemRef.current) {
        firstMenuItemRef.current.focus();
      }
    } else if (previousMenuIndex > -1) {
      const menuElement = desktopMenuButtonRefs[previousMenuIndex].current;
      menuElement.focus();
      setPreviousMenuIndex(-1);
    }
  }, [menuIndex]);

  useEffect(() => {
    setMenuIndex(-1);
    setMobileMenuOpen(false);
  }, [isDesktop]);

  const doesSubNavItemsContainAnyImageCard = () => {
    let subNavItemsContainCard = false;
    items.forEach((subNavItem) => {
      if(subNavItem?.items.some((item) => item.type === 'Card')) {
        subNavItemsContainCard = true;
      }
    });

    return subNavItemsContainCard;
  };

  const subNavsContainImageCard = doesSubNavItemsContainAnyImageCard();

  useEffect(() => {
    if (menuIndex !== -1) {
      const parentNavItem = desktopNavItemsRef.current[menuIndex];
      const subNavElement = subNavItemRef.current;

      if (subNavElement) {
        subNavElement.style.transform = "";
      }

      if (parentNavItem && subNavElement && !subNavsContainImageCard) {
        const parentRect = parentNavItem.getBoundingClientRect();
        const subNavRect = subNavElement.getBoundingClientRect();
        const transformValue = `translateX(${parentRect.left - subNavRect.left}px)`;
        subNavElement.style.transform = transformValue;
      }
    }
  }, [menuIndex]);

  const setOverlayVisibility = overlayState => {
    const overlayElement = document.getElementById('header-overlay');
    if (overlayElement) {
      if (overlayState === 'visible') {
        overlayElement.classList.add('visible');
      } else if (overlayState === 'hidden') {
        overlayElement.classList.remove('visible');
      }
    }
  };

  const toggleSearchOverlay = () => {
    const overlayState = !searchOverlayIsVisible;
    if (!isServer()) {
      dataLayerEvent(overlayState ? 'searchOpen' : 'searchClose', {
        pagePath: window.location.pathname,
      });
    }
    setSearchOverlayIsVisible(overlayState);
  };

  const toggleMenu = () => {
    if (isMobile) {
      dataLayerEvent(mobileMenuOpen ? 'navClosedMobile' : 'navOpenMobile');
    }

    setMobileMenuOpen(!mobileMenuOpen);
  };

  const toggleMenuIndex = (index, e, noMenu = false) => {
    // ignore mouseenter event on touch devices
    if ('ontouchstart' in window && e.type === 'mouseenter') {
      return;
    }

    if (noMenu) {
      setMenuIndex(-1);
      setOverlayVisibility('hidden');
      return;
    }

    if (menuIndex === index && e.type === 'mouseenter') {
      return;
    }

    if (menuIndex === index) {
      setMenuIndex(-1);
    } else {
      setMenuIndex(index);
    }

    setOverlayVisibility('visible');
  };

  const handleMouseOut = () => {
    if (menuIndex > -1) {
      toggleMenu();
      setOverlayVisibility('hidden');
    }
  };

  const renderUtilityLinks = () => {
    return (
      <ul className="utilityLinks">
        {utilityLinks?.map((utilLink, i) => (
          <li key={i}>
            <a
              target={utilLink?.linktype === 'external' ? '_blank' : '_self'}
              href={utilLink?.href}
              onClick={() =>
                dataLayerEvent('navUtility', {
                  clickText: utilLink?.text,
                  clickURL: utilLink?.href,
                })
              }
              rel="noreferrer"
            >
              {utilLink?.text}
            </a>
          </li>
        ))}
      </ul>
    );
  };

  const renderMobileMenu = () => {
    if (items.length) {
      if (menuIndex === -1) {
        return (
          <ul>
            {items?.map((item, i) => (
              <li key={i}>
                {item?.link ? (
                  <a
                    onClick={() => dataLayerEvent('navGlobal')}
                    href={item?.link?.href}
                    target={item?.link?.linktype === 'external' ? '_blank' : '_self'}
                    ref={i === 0 ? firstMenuItemRef : null}
                    rel="noreferrer"
                  >
                    <Text field={item?.title} />
                    {getIcon('arrowLong')}
                  </a>
                ) : (
                  <button
                    aria-label={`Open ${item?.title?.value} sub menu`}
                    aria-expanded={menuIndex === i}
                    onClick={() => setMenuIndex(i)}
                    ref={i === 0 ? firstMenuItemRef : null}
                  >
                    <Text field={item?.title} />
                    {getIcon('arrowLong')}
                  </button>
                )}
              </li>
            ))}
          </ul>
        );
      } else {
        return (
          <ul>
            {items?.[menuIndex]?.items
              .reduce((acc, item) => {
                if (item?.type === 'Card') {
                  acc.push(item?.link);
                } else if (item?.type === 'LinkList') {
                  acc.push(...item.links);
                }
                return acc;
              }, [])
              .map((link, i) => (
                <li key={i}>
                  <a
                    onClick={() =>
                      dataLayerEvent('navSubMenu', {
                        clickText: link?.text.replace(`'`, ''),
                        clickURL: link?.href,
                      })
                    }
                    href={link?.href}
                    target={link?.linktype === 'external' ? '_blank' : '_self'}
                    rel="noreferrer"
                  >
                    {link?.text}
                    {getIcon('arrowLong')}
                  </a>
                </li>
              ))}
          </ul>
        );
      }
    }
  };

  const renderMobileSubMenu = () => {
    return (
      mobileMenuOpen && (
        <MobileNav ref={mobileNavRef} className="header-mobileNav">
          {menuIndex > -1 && (
            <MobileSubMenuHeader>
              <h1 tabIndex="-1" ref={mobileNavBackButtonRef}>
                {items?.[menuIndex]?.title?.value}
              </h1>
              <button onClick={() => handleMobileMenuBack(items?.[menuIndex]?.title?.value)}>
                {getIcon('arrowLong')} <span>Back</span>
              </button>
            </MobileSubMenuHeader>
          )}
          {renderMobileMenu()}
          {menuIndex === -1 && utilityLinks.length > 0 && renderUtilityLinks()}
        </MobileNav>
      )
    );
  };

  const renderDesktopMenu = () => {
    return (
      items && (
        <ul>
          {items.map((item, i) => (
            <li key={i} ref={(el) => (desktopNavItemsRef.current[i] = el)}>
              {item.link ? (
                <a
                  onClick={() => dataLayerEvent('navGlobal')}
                  href={item.link.href}
                  target={item.link.linktype === 'external' ? '_blank' : '_self'}
                  onMouseEnter={e => toggleMenuIndex(i, e, true)}
                  rel="noreferrer"
                >
                  <Text field={item.title} />
                </a>
              ) : (
                <button
                  ref={desktopMenuButtonRefs[i]}
                  aria-haspopup="true"
                  aria-expanded={i === menuIndex}
                  onMouseEnter={e => toggleMenuIndex(i, e)}
                  className={i === menuIndex && menuIndex !== -1 ? 'open' : ''}
                >
                  <Text field={item.title} />
                </button>
              )}
            </li>
          ))}
        </ul>
      )
    );
  };

  const handleCloseDesktopMenuButton = e => {
    const index = menuIndex;
    setPreviousMenuIndex(index);
    toggleMenuIndex(menuIndex, e);
  };

  const renderDesktopDropDown = () => {
    return (
      menuIndex > -1 && (
        <DesktopNav columns={items[menuIndex].items.length} textOnlySubNavs={!subNavsContainImageCard}>
          <div ref={subNavItemRef}>
            <CloseDesktopMenuButton className="visually-hidden" onClick={handleCloseDesktopMenuButton}>
              Close menu
            </CloseDesktopMenuButton>
            {items[menuIndex].items.map((item, i) => (
              <Card key={i} item={item} receiveFocus={i === 0} />
            ))}
          </div>
        </DesktopNav>
      )
    );
  };

  return (
    <HeaderComponent
      ref={headerRef}
      onMouseLeave={handleMouseOut}
      className="main-header"
      isMounted={isMounted}
    >
      {showSearch && (
        <SearchOverlay
          id="search-overlay"
          className={(searchOverlayIsVisible && 'visible') || null}
          ref={searchOverlayRef}
          toggleFn={toggleSearchOverlay}
          {...searchOverlay}
        />
      )}
      {(alert?.value || (utilityLinks && utilityLinks.length > 0)) && (
        <UtilityBar className="header-utilitybar">
          {alert?.value && <AlertText field={alert} onClick={() => dataLayerEvent('alert')} />}
          {utilityLinks && isDesktop && isMounted && renderUtilityLinks()}
        </UtilityBar>
      )}
      <NavBar
        ref={navRef}
        className="header-navbar"
        aria-label="Main navigation"
        enterCtaVisible={enterCtaVisible}
        website={website}
      >
        {logo && (
          <a href="/">
            <img alt={logo.alt} src={logo.src} />
          </a>
        )}
        {isDesktop && renderDesktopMenu()}
        {!isDesktop && utilityLinks && renderUtilityLinks()}
        {enterCtaVisible && (
          <EnterButtonContainer enterCtaVisible={enterCtaVisible}>
            <CTA label={cta?.text} link={cta} variant={handleCtaMappingByTheme(website, ctaTheme)} />
          </EnterButtonContainer>
        )}

        <ButtonContainer enterCtaVisible={enterCtaVisible}>
          {showSearch && (
            <SearchButton onClick={toggleSearchOverlay} website={website} aria-label="Open search">
              {getIcon('search')}
            </SearchButton>
          )}
          <Hamburger
            onClick={toggleMenu}
            aria-expanded={mobileMenuOpen}
            aria-label={`${mobileMenuOpen ? 'Close main menu' : 'Open main menu'}`}
          >
            {getIcon(`${mobileMenuOpen ? 'clear' : 'menu'}`)}
          </Hamburger>
        </ButtonContainer>
        {!isDesktop && renderMobileSubMenu()}
        {isDesktop && renderDesktopDropDown()}
      </NavBar>
    </HeaderComponent>
  );
};

Header.propTypes = {
  logo: PropTypes.object,
  alert: PropTypes.object,
  sitecoreContext: PropTypes.object,
  utilityLinks: PropTypes.array,
  items: PropTypes.array,
  showSearch: PropTypes.bool,
  cta: PropTypes.object,
  ctaTheme: PropTypes.string,
  theme: PropTypes.object,
};

export default withSitecoreContext()(withTheme(Header));
