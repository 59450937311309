export const mapColourToSiteTheme = (theme, gradient) => {
  const sites = {
    'london-marathon': {
      introText: theme.colors.primary[300],
      searchInputBorderBottom: theme.colors.primary[300],
      inputUnderline: theme.colors.primary[100],
      inputText: theme.colors.primary[300],
      inputPlaceholderText: theme.colors.primary[300],
      searchButtonFill: theme.colors.primary[300],
      resultsWrapperBackground: theme.colors.grays[100],
      resultsContainerBackground: theme.colors.primary[300],
      resultsIconFill: theme.colors.primary[100],
      resultsTitleText: theme.colors.primary[200],
      resultsTitleHighlight: theme.colors.secondary[300],
    },
    'the-big-half': {
      introText: theme.colors.primary[400],
      searchInputBorderBottom: theme.colors.grays[300],
      inputUnderline: theme.colors.primary[100],
      inputText: theme.colors.primary[400],
      inputPlaceholderText: theme.colors.grays[300],
      searchButtonFill: theme.colors.grays[300],
      resultsWrapperBackground: theme.colors.grays[300],
      resultsContainerBackground: theme.colors.primary[400],
      resultsIconFill: theme.colors.primary[100],
      resultsTitleText: theme.colors.secondary[400],
      resultsTitleHighlight: theme.colors.primary[100],
    },
    'london-10k': {
      introText: theme.colors.primary[300],
      searchInputBorderBottom: theme.colors.primary[300],
      inputUnderline: gradient === 'black' ? theme.colors.primary[100] : theme.colors.primary[200],
      inputText: theme.colors.primary[300],
      inputPlaceholderText: theme.colors.primary[300],
      searchButtonFill: theme.colors.primary[300],
      resultsWrapperBackground: theme.colors.grays[500],
      resultsContainerBackground: theme.colors.primary[300],
      resultsIconFill: theme.colors.primary[100],
      resultsTitleText: theme.colors.primary[200],
      resultsTitleHighlight: theme.colors.primary[100],
    },
    'westminster-mile': {
      introText: theme.colors.primary[300],
      searchInputBorderBottom: theme.colors.primary[300],
      inputUnderline: theme.colors.primary[200],
      inputText: theme.colors.primary[300],
      inputPlaceholderText: theme.colors.primary[300],
      searchButtonFill: theme.colors.primary[300],
      resultsWrapperBackground: theme.colors.grays[500],
      resultsContainerBackground: theme.colors.primary[300],
      resultsIconFill: theme.colors.primary[100],
      resultsTitleText: theme.colors.primary[200],
      resultsTitleHighlight: theme.colors.primary[100],
    },
    'swim-serpentine': {
      introText: theme.colors.primary[400],
      searchInputBorderBottom: theme.colors.primary[400],
      inputUnderline: theme.colors.secondary[400],
      inputText: theme.colors.primary[400],
      inputPlaceholderText: theme.colors.primary[400],
      searchButtonFill: theme.colors.primary[400],
      resultsWrapperBackground: theme.colors.grays[300],
      resultsContainerBackground: theme.colors.primary[400],
      resultsIconFill: theme.colors.primary[100],
      resultsTitleText: theme.colors.secondary[400],
      resultsTitleHighlight: theme.colors.primary[300],
    },
    'ride-london': {
      introText: theme.colors.primary[400],
      searchInputBorderBottom: theme.colors.primary[400],
      inputUnderline: theme.colors.secondary[400],
      inputText: theme.colors.primary[400],
      inputPlaceholderText: theme.colors.primary[400],
      searchButtonFill: theme.colors.primary[400],
      resultsWrapperBackground: theme.colors.grays[300],
      resultsContainerBackground: theme.colors.primary[400],
      resultsIconFill: theme.colors.primary[100],
      resultsTitleText: theme.colors.primary[300],
      resultsTitleHighlight: theme.colors.primary[200],
    },
    'city-race': {
      introText: theme.colors.primary[400],
      searchInputBorderBottom: theme.colors.primary[400],
      inputUnderline: theme.colors.primary[500],
      inputText: theme.colors.primary[400],
      inputPlaceholderText: theme.colors.primary[400],
      searchButtonFill: theme.colors.primary[400],
      resultsWrapperBackground: theme.colors.grays[300],
      resultsContainerBackground: theme.colors.primary[400],
      resultsIconFill: theme.colors.primary[500],
      resultsTitleText: theme.colors.primary[300],
      resultsTitleHighlight: theme.colors.primary[500],
    },
    'lm-foundation': {
      introText: theme.colors.primary[300],
      searchInputBorderBottom: theme.colors.primary[300],
      inputUnderline: theme.colors.secondary[400],
      inputText: theme.colors.primary[300],
      inputPlaceholderText: theme.colors.primary[300],
      searchButtonFill: theme.colors.primary[300],
      resultsWrapperBackground: theme.colors.grays[200],
      resultsContainerBackground: theme.colors.primary[300],
      resultsIconFill: '#61CDCC',
      resultsTitleText: theme.colors.secondary[600],
      resultsTitleHighlight: theme.colors.primary[500],
    },
  };
  if (theme.website in sites) return sites[theme.website];
  return sites['london-marathon'];
};
