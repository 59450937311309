import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const QuoteBlockContainer = styled.section`
  width: calc(100% - 50px);
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};

  ${breakpoints.tabletLandscape} {
    width: calc(100% - 122px);
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
  }
`;

export const QuoteBlockComponent = styled.blockquote`
  width: 100%;
  max-width: ${props => props.theme.px(900)};
  margin: 0;
  padding-left: ${props => props.theme.px(29)};
  border-left: 4px solid ${props => CM(props.theme).borderColor};

  ${breakpoints.tabletLandscape} {
    width: 50%;
  }

  p {
    margin: 0;
    color: ${props => CM(props.theme).textColor};

    &.quote {
      ${props => props.theme.setFont('quote')}
      margin-bottom: ${props => props.theme.px(13)};
      font-weight: 700;
    }

    &.description {
      ${props => props.theme.setFont('bodyExtraSmall')}
    }

    &.name {
      ${props => props.theme.setFont('bodyExtraSmall')}
      font-weight: 700;
    }

  }

`;
