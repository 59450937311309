import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import { useQuery } from '@apollo/react-hooks';
import { useIsMobile } from '../hooks/useIsMobile';
import { SearchAndResultsComponent, ResultContainer, LoadMoreBtn } from './SearchAndResults.styles.js';
import MobileFilters from './MobileFilters/MobileFilters';
import DesktopFilters from './DesktopFilters/DesktopFilters';
import Intro from './Intro/Intro';
import ArticleCard from '../article-card';
import SignpostComponent from '../signpost';
import { getIcon } from '../utils/icon';
import { useTracking } from '../tracking';
import { loader } from 'graphql.macro';
const SEARCH = loader('./Search.graphql');

const mapArticle = article => {
  if (article) {
    article.fields = {
      publicationDate: article.date,
      title: article.title,
      readingTime: article.readingtime,
      gradient: article.gradient,
      heroImage: article.heroImage,
      tag: {
        title: null,
        colour: null,
        textColour: null,
      },
    };

    if (article.contentTypeTitle) {
      article.fields.tag.title = article.contentTypeTitle;
      article.fields.tag.colour = article.contentTypeColour;
      article.fields.tag.textColour = article.contentTypeTextColour;
    }

    article.params = {
      pattern: '',
    };
  }

  return article;
};

const mapType = (typeMap, type) => {
  const typeName = type?.toLowerCase();
  if (typeName && typeName in typeMap) {
    return typeMap[typeName];
  }

  return null;
};

const SearchAndResults = props => {
  const {
    fields: { pageSize = {}, facets = [], typeMap = {} } = {},
    params: { ctaThemeDesktop = 'whiteC', ctaThemeMobile = 'blackC' },
    sitecoreContext: { route: { queryString: { q: terms = '', type = '' } = {} } = {} } = {},
    translate = {},
  } = props;

  const mappedType = mapType(typeMap, type);

  const { trackEvent } = useTracking();
  const resultsRef = useRef(null);
  const mobileFilterRef = useRef(null);
  const [restrictLoadMore, setRestrictLoadMore] = useState(false);
  const [hasLoaded, setHasLoaded] = useState(false);
  const isMobile = useIsMobile('tabletPortrait', 66);
  const [results, setResults] = useState([]);
  const [filterOptions, setFilterOptions] = useState([]);
  const [paging, setPaging] = useState({ totalCount: 0, hasNextPage: false, endCursor: '0' });
  const [checkedItems, setCheckedItems] = useState(
    mappedType ? [{ name: 'contenttype', value: mappedType }] : []
  );

  const t = key => {
    if (translate && typeof translate === 'function') {
      return translate(`SearchAndResults_${key}`);
    }
    return key;
  };

  const filtersTitle = t('FiltersTitle');
  const noResultsTitle = t('NoResultsTitle');
  const noResultsText = t('NoResultsText');

  const loadMoreText = t('LoadMore');
  const clearAllText = t('ClearAll');
  const appliedText = t('Applied');
  const showResultsText = t('ShowResults');
  const totalResultsText = t('TotalResults');
  const oneResultText = t('OneResult');

  function generateFilters() {
    const filters = facets.reduce((acc, el) => {
      filterOptions.find(
        filter =>
          filter.name === el.fields?.field?.value && acc.push({ title: el.fields?.title?.value, filter })
      );
      return acc;
    }, []);

    return filters;
  }

  const filterProps = {
    checkedItems,
    setCheckedItems,
    generateFilters,
    type,
    filtersTitle,
    clearAllText,
  };

  // Get facet field names, filtering nulls.
  const facetOn = facets.map(facet => facet?.fields?.field?.value).filter(x => x);

  // eslint-disable-next-line no-unused-vars
  const { loading, error, data, fetchMore } = useQuery(SEARCH, {
    variables: {
      terms: terms,
      facetOn: facetOn,
      resultCount: parseInt(pageSize?.value, 10),
      filters: checkedItems,
      after: '0',
      first: parseInt(pageSize?.value, 10),
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (!loading) {
      if (data?.search?.results?.items) {
        setResults(data.search.results.items);
      }

      if (data?.search?.facets) {
        setFilterOptions(data.search.facets);
      }

      if (data?.search?.results?.pageInfo) {
        trackEvent([{ eventId: 'Search', terms: terms, count: data.search.results.totalCount }]);

        setPaging({
          totalCount: data.search.results.totalCount,
          hasNextPage: data.search.results.pageInfo.hasNextPage,
          endCursor: data.search.results.pageInfo.endCursor,
        });
      }

      setHasLoaded(true);
      setRestrictLoadMore(false);
    }
  }, [data]);

  useEffect(() => {
    if (isMobile) {
      if (mobileFilterRef.current) {
        mobileFilterRef.current.scrollIntoView();
      }
    } else {
      if (resultsRef.current) {
        resultsRef.current.scrollIntoView();
      }
    }
  }, [checkedItems]);

  const handleLoadMore = () => {
    if (paging.hasNextPage && !restrictLoadMore) {
      setRestrictLoadMore(true);
      fetchMore({
        variables: {
          after: paging.endCursor,
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          if (!fetchMoreResult) return prev;

          return {
            search: {
              results: {
                items: [...prev.search.results.items, ...fetchMoreResult.search.results.items],
                pageInfo: fetchMoreResult.search.results.pageInfo,
                __typename: fetchMoreResult.search.results.__typename,
              },
              facets: prev.search.facets,
              __typename: prev.search.__typename,
            },
          };
        },
      });
    }
  };

  const mappedResults = results => {
    return results.map((result, i) => {
      const mappedArticle = mapArticle(result);
      if (result.templateName === 'Blog Article Page' || result.templateName === 'News Article Page') {
        return (
          <li className="article-card" key={i}>
            <ArticleCard variant="d" translate={translate} {...mappedArticle} />
          </li>
        );
      } else {
        return (
          <SignpostComponent
            title={result.title}
            url={result.url}
            summary={result.summary}
            tagLabel={result.contentTypeTitle}
            tagTextColour={result.contentTypeTextColour}
            tagBackgroundColour={result.contentTypeColour}
            key={i}
            truncated
          />
        );
      }
    });
  };

  return (
    <>
      {hasLoaded && (
        <SearchAndResultsComponent>
          {results?.length ? (
            isMobile ? (
              <MobileFilters
                appliedText={appliedText}
                showResultsText={showResultsText}
                ctaTheme={ctaThemeMobile}
                ref={mobileFilterRef}
                {...filterProps}
              />
            ) : (
              <DesktopFilters {...filterProps} ctaTheme={ctaThemeDesktop} />
            )
          ) : null}
          <ResultContainer ref={resultsRef}>
            <Intro
              terms={terms}
              hasLoaded={hasLoaded}
              count={paging.totalCount}
              noResultsTitle={noResultsTitle}
              noResultsText={noResultsText}
              totalResultsText={totalResultsText}
              oneResultText={oneResultText}
              resultsLength={results?.length}
            />
            {results?.length ? (
              <InfiniteScroll
                pageStart={0}
                loadMore={handleLoadMore}
                hasMore={paging?.hasNextPage}
                threshold={0}
                initialLoad={false}
              >
                <ul>{mappedResults(results)}</ul>
              </InfiniteScroll>
            ) : null}
            {paging?.hasNextPage && (
              <LoadMoreBtn>
                {getIcon('refresh')}
                <span>{loadMoreText}</span>
              </LoadMoreBtn>
            )}
          </ResultContainer>
        </SearchAndResultsComponent>
      )}
    </>
  );
};

SearchAndResults.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  sitecoreContext: PropTypes.object,
  translate: PropTypes.func,
  theme: PropTypes.object,
};

export default SearchAndResults;
