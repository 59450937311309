import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSwipeable } from 'react-swipeable';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { MobileCarouselComponent, SlideContainer } from './MobileCarousel.styles.js';

const MobileCarousel = props => {
  const { children, includeMargins = false } = props;
  const slides = [
    children[children.length - 2],
    children[children.length - 1],
    ...children,
    children[0],
    children[1],
  ];

  const [isMounted, setIsMounted] = useState(false);
  let currentSlide = 1;
  let previousSlide = 1;
  const transitionTime = '0.5s';
  const slideContainerRef = useRef();
  const outerRef = useRef();

  const getTranslateX = element => {
    if (!isServer()) {
      const style = window.getComputedStyle(element);
      var matrix = new WebKitCSSMatrix(style.transform);
      return matrix.m41;
    }
  };

  const centerCurrentSlide = (disableTransitionAnimation = false) => {
    const scroller = slideContainerRef?.current;
    const currentCard = scroller?.children[currentSlide + 1];

    if (currentCard) {
      const slide = currentCard.getBoundingClientRect();
      let margins = 0;
      if (includeMargins && !isServer()) {
        const cardStyles = window.getComputedStyle(currentCard);
        margins = parseInt(cardStyles['margin-left'], 10) + parseInt(cardStyles['margin-right'], 10);
      }
      const outer = outerRef.current.getBoundingClientRect();
      const containerMiddle = !isServer() ? outer.width / 2 : 0;
      const tX = getTranslateX(scroller);
      const slideScrollPosition = tX - slide.x + containerMiddle - slide.width / 2 + margins;
      scroller.style.transitionDuration = disableTransitionAnimation ? '0s' : transitionTime;
      scroller.style.transform = `translateX(${slideScrollPosition}px)`;
    }
  };

  const onSwipe = amount => {
    previousSlide = currentSlide;
    currentSlide += amount;
    centerCurrentSlide();
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => onSwipe(1),
    onSwipedRight: () => onSwipe(-1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  const refPassthrough = el => {
    swipeHandlers.ref(el);
    outerRef.current = el;
  };

  const handleSlideEnd = () => {
    const direction = previousSlide < currentSlide ? 'right' : 'left';
    if (direction === 'right') {
      if (currentSlide === slides.length - 3) {
        currentSlide = 1;
        centerCurrentSlide(true);
      }
    } else {
      if (currentSlide === 0) {
        currentSlide = slides.length - 4;
        centerCurrentSlide(true);
      }
    }
  };

  const handleResize = e => {
    centerCurrentSlide(true);
  };

  useEffect(() => {
    if (isMounted) {
      centerCurrentSlide(true);
    }
  }, [isMounted]);

  useEffect(() => {
    setIsMounted(true);

    if (!isServer()) {
      window.addEventListener('resize', handleResize);
    }

    return () => {
      if (!isServer()) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  return (
    isMounted && (
      <MobileCarouselComponent {...swipeHandlers} ref={refPassthrough}>
        <SlideContainer
          ref={slideContainerRef}
          className="mobile-carousel-container"
          onTransitionEnd={handleSlideEnd}
          slideCount={slides.length}
        >
          {slides.map((slide, i) => (
            <li key={i}>{slide}</li>
          ))}
        </SlideContainer>
      </MobileCarouselComponent>
    )
  );
};

MobileCarousel.propTypes = {
  children: PropTypes.array,
  includeMargins: PropTypes.bool,
};

export default MobileCarousel;
