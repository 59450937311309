import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { VideoEmbedComponent, VideoWrapper, VideoInner } from './VideoEmbed.styles.js';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import ReactPlayer from 'react-player/lazy';

const VideoEmbed = props => {
  const {
    fields: { url = {}, provider = {} } = {},
    sitecoreContext: { pageEditing = false } = {},
    params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
  } = props;

  const playerRef = useRef(null);

  const getVideoTitle = () => {
    if (!provider.value) {
      return;
    }

    if (provider.value.toLowerCase() === 'vimeo') {
      return playerRef.current.getInternalPlayer().element?.title || null;
    }

    if (provider.value.toLowerCase() === 'youtube') {
      return playerRef.current.getInternalPlayer().getVideoData().title || null;
    }
  };

  return (
    <VideoEmbedComponent
      className="limitedWidth"
      pageEditing={pageEditing}
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      <VideoInner>
        <VideoWrapper>
          {url?.value && (
            <ReactPlayer
              url={url.value}
              ref={playerRef}
              controls={true}
              width="100%"
              height="100%"
              className="react-player"
              frameBorder="0"
              onPlay={() => {
                if (!isServer()) {
                  window.dataLayer.push({
                    event: 'video',
                    videoAction: 'Play',
                    videoTitle: getVideoTitle(),
                  });
                }
              }}
              onPause={() => {
                if (!isServer()) {
                  window.dataLayer.push({
                    event: 'video',
                    videoAction: 'Pause',
                    videoTitle: getVideoTitle(),
                  });
                }
              }}
            />
          )}
        </VideoWrapper>
      </VideoInner>
    </VideoEmbedComponent>
  );
};

VideoEmbed.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object,
  params: PropTypes.object,
  spaceMultiplierMobile: PropTypes.string,
  spaceMultiplierDesktop: PropTypes.string,
};

export default withSitecoreContext()(VideoEmbed);
