import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const ArticleRowCarouselContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: ${props => props.theme.space()} 0;
`;

export const CarouselOuter = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const CarouselButton = styled.button`
  width: 24px;
  height: 24px;
  margin: 0;
  padding: 0;
  background: none;
  border: 0;
  cursor: pointer;
  appearance: none;

  svg {
    transform: rotate(${props => (props.flipped ? '180' : '0')}deg);
  }
`;

export const CarouselContainer = styled.ul`
  display: grid;
  grid-column-gap: ${props => props.theme.space()};
  grid-template-columns: 1fr 1fr 1fr;
  width: ${props => (props.noCarousel ? `100%` : `calc(100% - ${props.theme.space(2)})`)};
  margin: 0;
  padding: 0;
  list-style-type: none;
`;

export const CarouselItem = styled.li`
  position: relative;
  display: block;
  height: 0;
  min-height: 380px;
  padding-top: calc((380 / 352) * 100%);

  ${breakpoints.desktopLarge} {
    padding-top: calc((95 / 88) * 87.5%);
  }

  > * {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const CarouselNavigation = styled.ul`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin: 0 ${props => props.theme.space(0.5)} 0 0;

    button {
      width: 8px;
      height: 8px;
      padding: 0;
      background: none;
      border: 2px solid ${props => CM(props.theme).carouselButtonBorder};
      border-radius: 50%;
      cursor: pointer;
      appearance: none;

      &:last-child {
        margin-right: 0;
      }

      &.selected {
        border: 2px solid ${props => CM(props.theme).carouselSelectedButtonBorder};
      }
    }
  }
`;
