import { depthFirst } from './depthFirst';
import { breadthFirst } from './breadthFirst';
import Actions from './actions';

class Forest {
  constructor(forest) {
    this.self = () => ({
      forest: forest,
      isArray: Array.isArray(forest),
    });
  }

  isObject(element) {
    return typeof element === 'object' && element !== null;
  }

  forEachLeaf(callback) {
    depthFirst.call(this, callback);
  }

  forEachNode(callback) {
    breadthFirst.call(this, callback);
  }

  findLeaf(callback) {
    return depthFirst.call(this, callback, Actions.FIND);
  }

  findNode(callback) {
    return breadthFirst.call(this, callback, Actions.FIND);
  }

  findLeaves(callback) {
    return depthFirst.call(this, callback, Actions.FIND_ALL);
  }

  findNodes(callback) {
    return breadthFirst.call(this, callback, Actions.FIND_ALL);
  }

  mapLeaves(callback) {
    return depthFirst.call(this, callback, Actions.MAP);
  }

  nodesByLevel(level) {
    if (level < 1) return [];
    return breadthFirst.call(this, () => level, Actions.GET);
  }

  removeLeaf(callback) {
    return depthFirst.call(this, callback, Actions.REMOVE);
  }

  removeNode(callback) {
    return breadthFirst.call(this, callback, Actions.REMOVE);
  }

  removeLeaves(callback) {
    return depthFirst.call(this, callback, Actions.REMOVE_ALL);
  }

  removeNodes(callback) {
    return breadthFirst.call(this, callback, Actions.REMOVE_ALL);
  }
}

export default function createForest(forest) {
  return new Forest(forest);
}
