import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { AdSlotWrapper, AdSlotComponent } from './AdSlot.styles';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import Empty from '../empty';

const AdSlot = props => {
  const {
    adId: { value: adId = '' } = {},
    adUnitPath: { value: adUnitPath = '' } = {},
    slotWidth: { value: slotWidth = null } = {},
    slotHeight: { value: slotHeight = null } = {},
    sitecoreContext: { pageEditing = false } = {},
    params: { spaceMultiplierMobile = '0', spaceMultiplierDesktop = '0' },
  } = props;

  useEffect(() => {
    if (!isServer()) {
      window.googletag = window.googletag || { cmd: [] };
      window.googletag.cmd.push(function() {
        window.googletag.display(adId);
      });
    }
  });

  return (
    (adId && adUnitPath && (
      <AdSlotWrapper slotHeight={slotHeight} key={new Date().getTime()}>
        <AdSlotComponent
          id={adId}
          slotWidth={slotWidth}
          slotHeight={slotHeight}
          pointerEvents={pageEditing ? 'none' : 'all'}
          spaceMultiplier={{ desktop: spaceMultiplierDesktop, mobile: spaceMultiplierMobile }}
        />
      </AdSlotWrapper>
    )) ||
    (pageEditing && !adId && !adUnitPath && <Empty message="No adId/adUnitPath specified" />)
  );
};

AdSlot.propTypes = {
  adId: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
  adUnitPath: PropTypes.shape({
    value: PropTypes.string.isRequired,
  }).isRequired,
  slotWidth: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  slotHeight: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
  sitecoreContext: PropTypes.object,
  params: PropTypes.object,
};

export default withSitecoreContext()(AdSlot);
