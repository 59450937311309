import styled from 'styled-components';
import { ButtonStyles as LMEButtonStyles } from './london-marathon/CTA.styles';
import { ButtonStyles as TheBigHalfStyles } from './the-big-half/CTA.styles';
import { ButtonStyles as London10kStyles } from './london-10k/CTA.styles';
import { ButtonStyles as SwimSerpentineStyles } from './swim-serpentine/CTA.styles';
import { ButtonStyles as WestminsterMileStyles } from './westminster-mile/CTA.styles';
import { ButtonStyles as RideLondonStyles } from './ride-london/CTA.styles';
import { ButtonStyles as CityRaceStyles } from './city-race/CTA.styles';
import { ButtonStyles as LMFoundationStyles } from './lm-foundation/CTA.styles';

const getStyles = theme => {
  const { website } = theme;

  switch (website) {
    case 'london-marathon':
      return LMEButtonStyles;
    case 'the-big-half':
      return TheBigHalfStyles;
    case 'london-10k':
      return London10kStyles;
    case 'swim-serpentine':
      return SwimSerpentineStyles;
    case 'westminster-mile':
      return WestminsterMileStyles;
    case 'ride-london':
      return RideLondonStyles;
    case 'lm-foundation':
      return LMFoundationStyles;
    case 'city-race':
      return CityRaceStyles;
    default:
      return LMEButtonStyles;
  }
};

export const Button = styled.button`
  ${props => getStyles(props.theme)}
`;

export const Link = styled.a`
  ${props => getStyles(props.theme)}
`;

export const SitecoreLinkWrap = styled.div`
  ${props => getStyles(props.theme)}
`;
