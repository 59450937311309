import { colors } from './colors';

const w = colors.primary[400];
const b = colors.primary[300];
const primary = colors.primary[200];
const secondary = colors.primary[500];

// [textColor, backgroundColor, arrowColor]

export const buttonVariants = {
  default: [b, secondary, b],
  blackA: [b, secondary, b],
  blackB: [b, w, b],
  blackC: [b, w, b],
  whiteA: [w, primary, w],
  whiteB: [w, secondary, w], // TODO
  whiteE: [w, primary, w], // TODO
  socialIcon: [w, w], // Footer social links
};

export const buttonHoverVariants = {
  default: [w, primary, w],
  blackA: [w, primary, w],
  blackB: [b, colors.grays[500], b],
  blackC: [b, colors.grays[500], b],
  whiteA: [b, secondary, b],
  whiteB: [b, primary, b], // TODO
  whiteE: [b, secondary, b], // TODO
  socialIcon: [w, b], // Footer social links
};
