export const mapColourToSiteTheme = theme => {
  const sites = {
    'london-marathon': {
      intro: {
        subHeadingText: theme.colors.grays[300],
        noResultsText: theme.colors.primary[200],
        introSearchedTerm: theme.colors.primary[200],
        patternBorder: theme.colors.secondary[500],
      },
      desktopFilters: {
        filterWrapBorder: theme.colors.grays[200],
        filterWrapText: theme.colors.primary[200],
        labelColor: theme.colors.primary[200],
        checkmarkColor: theme.colors.primary[100],
        filterTitleColor: theme.colors.primary[200],
      },
      mobileFilters: {
        wrapBackground: theme.colors.primary[300],
        headingInnerBorderBottom: theme.colors.grays[100],
        headingButtonBorderLeft: theme.colors.grays[100],
        headingButtonOpenIconFill: theme.colors.primary[100],
        headingButtonClosedIconFill: theme.colors.primary[200],
        headingInnerText: theme.colors.primary[200],
        headerWrapText: theme.colors.primary[200],
        accordionText: theme.colors.primary[200],
        accordionBackground: theme.colors.grays[100],
        accordionItemLabel: theme.colors.primary[200],
        accordionItemCheckmark: theme.colors.primary[100],
      },
    },
    'the-big-half': {
      intro: {
        subHeadingText: theme.colors.secondary[400],
        noResultsText: theme.colors.secondary[400],
        introSearchedTerm: theme.colors.secondary[400],
        patternBorder: theme.colors.primary[100],
      },
      desktopFilters: {
        filterWrapBorder: theme.colors.grays[400],
        filterWrapText: theme.colors.secondary[400],
        labelColor: theme.colors.secondary[400],
        checkmarkColor: theme.colors.primary[100],
        filterTitleColor: theme.colors.secondary[400],
      },
      mobileFilters: {
        wrapBackground: theme.colors.primary[400],
        headingInnerBorderBottom: theme.colors.grays[300],
        headingButtonBorderLeft: theme.colors.grays[300],
        headingButtonOpenIconFill: theme.colors.primary[100],
        headingButtonClosedIconFill: theme.colors.secondary[400],
        headingInnerText: theme.colors.secondary[400],
        headerWrapText: theme.colors.secondary[400],
        accordionText: theme.colors.secondary[400],
        accordionBackground: theme.colors.grays[400],
        accordionItemLabel: theme.colors.secondary[400],
        accordionItemCheckmark: theme.colors.primary[100],
      },
    },
    'london-10k': {
      intro: {
        subHeadingText: theme.colors.primary[200],
        noResultsText: theme.colors.primary[200],
        introSearchedTerm: theme.colors.primary[200],
        patternBorder: theme.colors.primary[100],
      },
      desktopFilters: {
        filterWrapBorder: theme.colors.grays[400],
        filterWrapText: theme.colors.primary[200],
        labelColor: theme.colors.primary[200],
        checkmarkColor: theme.colors.primary[100],
        filterTitleColor: theme.colors.primary[200],
      },
      mobileFilters: {
        wrapBackground: theme.colors.primary[300],
        headingInnerBorderBottom: theme.colors.grays[500],
        headingButtonBorderLeft: theme.colors.grays[500],
        headingButtonOpenIconFill: theme.colors.primary[100],
        headingButtonClosedIconFill: theme.colors.primary[200],
        headingInnerText: theme.colors.primary[200],
        headerWrapText: theme.colors.primary[200],
        accordionText: theme.colors.primary[200],
        accordionBackground: theme.colors.grays[500],
        accordionItemLabel: theme.colors.primary[200],
        accordionItemCheckmark: theme.colors.primary[100],
      },
    },
    'westminster-mile': {
      intro: {
        subHeadingText: theme.colors.primary[200],
        noResultsText: theme.colors.primary[200],
        introSearchedTerm: theme.colors.primary[200],
        patternBorder: theme.colors.primary[100],
      },
      desktopFilters: {
        filterWrapBorder: theme.colors.grays[400],
        filterWrapText: theme.colors.primary[200],
        labelColor: theme.colors.primary[200],
        checkmarkColor: theme.colors.primary[100],
        filterTitleColor: theme.colors.primary[200],
      },
      mobileFilters: {
        wrapBackground: theme.colors.primary[300],
        headingInnerBorderBottom: theme.colors.grays[500],
        headingButtonBorderLeft: theme.colors.grays[500],
        headingButtonOpenIconFill: theme.colors.primary[100],
        headingButtonClosedIconFill: theme.colors.primary[200],
        headingInnerText: theme.colors.primary[200],
        headerWrapText: theme.colors.primary[200],
        accordionText: theme.colors.primary[200],
        accordionBackground: theme.colors.grays[500],
        accordionItemLabel: theme.colors.primary[200],
        accordionItemCheckmark: theme.colors.primary[100],
      },
    },
    'swim-serpentine': {
      intro: {
        subHeadingText: theme.colors.secondary[400],
        noResultsText: theme.colors.secondary[400],
        introSearchedTerm: theme.colors.secondary[400],
        patternBorder: theme.colors.primary[200],
      },
      desktopFilters: {
        filterWrapBorder: theme.colors.grays[400],
        filterWrapText: theme.colors.secondary[400],
        labelColor: theme.colors.secondary[400],
        checkmarkColor: theme.colors.primary[100],
        filterTitleColor: theme.colors.secondary[400],
      },
      mobileFilters: {
        wrapBackground: theme.colors.primary[400],
        headingInnerBorderBottom: theme.colors.grays[300],
        headingButtonBorderLeft: theme.colors.grays[300],
        headingButtonOpenIconFill: theme.colors.primary[100],
        headingButtonClosedIconFill: theme.colors.secondary[400],
        headingInnerText: theme.colors.secondary[400],
        headerWrapText: theme.colors.secondary[400],
        accordionText: theme.colors.secondary[400],
        accordionBackground: theme.colors.grays[400],
        accordionItemLabel: theme.colors.secondary[400],
        accordionItemCheckmark: theme.colors.primary[100],
      },
    },
    'ride-london': {
      intro: {
        subHeadingText: theme.colors.primary[300],
        noResultsText: theme.colors.primary[300],
        introSearchedTerm: theme.colors.primary[300],
        patternBorder: theme.colors.secondary[100],
      },
      desktopFilters: {
        filterWrapBorder: theme.colors.grays[400],
        filterWrapText: theme.colors.primary[300],
        labelColor: theme.colors.primary[300],
        checkmarkColor: theme.colors.primary[100],
        filterTitleColor: theme.colors.primary[300],
      },
      mobileFilters: {
        wrapBackground: theme.colors.primary[400],
        headingInnerBorderBottom: theme.colors.grays[300],
        headingButtonBorderLeft: theme.colors.grays[300],
        headingButtonOpenIconFill: theme.colors.primary[100],
        headingButtonClosedIconFill: theme.colors.primary[300],
        headingInnerText: theme.colors.primary[300],
        headerWrapText: theme.colors.primary[300],
        accordionText: theme.colors.primary[300],
        accordionBackground: theme.colors.grays[400],
        accordionItemLabel: theme.colors.primary[300],
        accordionItemCheckmark: theme.colors.primary[100],
      },
    },
    'city-race': {
      intro: {
        subHeadingText: theme.colors.primary[300],
        noResultsText: theme.colors.primary[300],
        introSearchedTerm: theme.colors.primary[300],
        patternBorder: theme.colors.primary[200],
      },
      desktopFilters: {
        filterWrapBorder: theme.colors.grays[400],
        filterWrapText: theme.colors.primary[300],
        labelColor: theme.colors.primary[300],
        checkmarkColor: theme.colors.primary[200],
        filterTitleColor: theme.colors.primary[300],
      },
      mobileFilters: {
        wrapBackground: theme.colors.primary[400],
        headingInnerBorderBottom: theme.colors.grays[300],
        headingButtonBorderLeft: theme.colors.grays[300],
        headingButtonOpenIconFill: theme.colors.primary[100],
        headingButtonClosedIconFill: theme.colors.primary[300],
        headingInnerText: theme.colors.primary[300],
        headerWrapText: theme.colors.primary[300],
        accordionText: theme.colors.primary[300],
        accordionBackground: theme.colors.grays[400],
        accordionItemLabel: theme.colors.primary[300],
        accordionItemCheckmark: theme.colors.primary[200],
      },
    },
    'lm-foundation': {
      intro: {
        subHeadingText: theme.colors.primary[200],
        noResultsText: theme.colors.primary[200],
        introSearchedTerm: theme.colors.primary[200],
        patternBorder: theme.colors.secondary[700],
      },
      desktopFilters: {
        filterWrapBorder: theme.colors.grays[200],
        filterWrapText: theme.colors.primary[200],
        labelColor: theme.colors.primary[200],
        checkmarkColor: theme.colors.primary[100],
        filterTitleColor: theme.colors.primary[200],
      },
      mobileFilters: {
        wrapBackground: theme.colors.primary[300],
        headingInnerBorderBottom: theme.colors.grays[100],
        headingButtonBorderLeft: theme.colors.grays[100],
        headingButtonOpenIconFill: theme.colors.primary[100],
        headingButtonClosedIconFill: theme.colors.primary[200],
        headingInnerText: theme.colors.primary[200],
        headerWrapText: theme.colors.primary[200],
        accordionText: theme.colors.primary[200],
        accordionBackground: theme.colors.grays[100],
        accordionItemLabel: theme.colors.primary[200],
        accordionItemCheckmark: theme.colors.primary[100],
      },
    },
  };
  if (theme.website in sites) return sites[theme.website];
  return sites['london-marathon'];
};
