export const truncateString = (str, length = 35, delim = ' ', ellipses = '...') => {
  if (str.length <= length) {
    return str;
  }

  let trimmedStr = str.substr(0, length + delim.length);

  const lastDelimIndex = trimmedStr.lastIndexOf(delim);

  if (lastDelimIndex >= 0) {
    trimmedStr = trimmedStr.substr(0, lastDelimIndex);
  }

  if (trimmedStr) {
    trimmedStr += ellipses;
  }
  return trimmedStr;
};
