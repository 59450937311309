import React from 'react';
import PropTypes from 'prop-types';
import { ArticleCardComponent, ArticleWrap, ArticleLinkWrap } from './ArticleCard.styles.js';
import VariantOne from './variants/VariantOne/VariantOne';
import VariantTwo from './variants/VariantTwo/VariantTwo';
import VariantThree from './variants/VariantThree/VariantThree';
import VariantFour from './variants/VariantFour/VariantFour';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { withTheme } from 'styled-components';

const ArticleCard = props => {
  const {
    variant = 'a',
    headingLevel = 3,
    url = '',
    fields,
    className,
    forceHover = false,
    gtm = null,
  } = props;

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  const handleCardGTMClick = e => {
    if (gtm?.name && !isServer()) {
      const gtmFields =
        gtm?.data?.reduce((acc, el) => {
          switch (el) {
            case 'cardTitle':
              acc.cardTitle = props?.fields?.title?.value;
              break;
            case 'pagePath':
              acc.pagePath = window.location.pathname;
              break;
          }
          return acc;
        }, {}) || {};

      dataLayerEvent(gtm.name, gtmFields);
    }
  };

  return (
    <ArticleCardComponent
      onClick={handleCardGTMClick}
      className={`${className} variant-${variant}`}
      variant={variant}
    >
      {url.length ? (
        <ArticleLinkWrap className="article-wrap" href={url}>
          {handleArticleVariant(props)}
        </ArticleLinkWrap>
      ) : (
        <ArticleWrap className="article-wrap">{handleArticleVariant(props)}</ArticleWrap>
      )}
    </ArticleCardComponent>
  );
};

const handleArticleVariant = props => {
  const { variant = 'a' } = props;

  switch (variant.toLowerCase()) {
    case 'a':
      return <VariantOne {...props} />;
    case 'b':
      return <VariantTwo {...props} />;
    case 'c':
      return <VariantThree {...props} />;
    case 'd':
      return <VariantFour {...props} />;
  }
};

ArticleCard.propTypes = {
  spaceMultiplierMobile: PropTypes.string,
  spaceMultiplierDesktop: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  url: PropTypes.string,
  forceHover: PropTypes.bool,
  gtm: PropTypes.object,
  fields: PropTypes.object,
  headingLevel: PropTypes.number,
};

export default withTheme(ArticleCard);
