import React from 'react';
import PropTypes from 'prop-types';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import {
  ComponentWrapper,
  FeatureComponent,
  ImageWrap,
  ImageContainer,
  PatternWrap,
  Content,
  ContentWrap,
  TitleWrap,
  LinkWrap,
  LinkInner,
  SitecoreLink,
  VideoWrapper,
} from './Feature.styles.js';
import Picture from '../picture';
import { getPattern } from '../utils/pattern';
import { getIcon } from '../utils/icon';
import AmbientVideoInner from '../ambient-video-inner';

const Feature = ({ fields, params, pattern, patternColor }) => {
  const { image = {}, title = {}, description = {}, cta = {}, videoUrl = {} } = fields ?? {};

  const {
    alignment = 'left',
    ctaColour = '',
    ctaHoverColour = '',
    spaceMultiplierMobile = '1',
    spaceMultiplierDesktop = '1',
    gradient,
  } = params ?? {};

  return (
    <ComponentWrapper
      className="limitedWidth"
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
      alignment={alignment}
    >
      <FeatureComponent alignment={alignment}>
        {image && (
          <ImageWrap alignment={alignment}>
            <ImageContainer alignment={alignment}>
              {!videoUrl.value ? (
                <Picture field={image} />
              ) : (
                <VideoWrapper alignment={alignment}>
                  <AmbientVideoInner
                    url={videoUrl?.value}
                    fallback={image?.value?.fallback}
                    playing={true}
                    // Vimeo videos letterbox when not 16:9 so force a wide
                    // aspect ratio, size the video to the height of the parent
                    // and hide the overflow.
                    width="200%"
                  />
                </VideoWrapper>
              )}
            </ImageContainer>

            <PatternWrap patternColor={patternColor} alignment={alignment}>
              {getPattern(pattern)}
            </PatternWrap>
          </ImageWrap>
        )}
        <Content alignment={alignment} gradient={gradient}>
          <ContentWrap alignment={alignment}>
            {title.value && (
              <TitleWrap>
                <Text field={title} tag="h1" />
              </TitleWrap>
            )}
            {description.value && <Text field={description} tag="p" encode={false} />}
            {Object.keys(cta).length && (
              <LinkWrap>
                <LinkInner ctaColour={ctaColour} ctaHoverColour={ctaHoverColour}>
                  <SitecoreLink
                    field={cta}
                    target={cta.value.target === '_blank' ? '_blank' : '_self'}
                    onClick={() => {
                      if (!isServer()) {
                        window.dataLayer.push({
                          event: 'featureCTA',
                          cardTitle: title.value,
                        });
                      }
                    }}
                  />
                  {cta.value?.href && getIcon('arrowLong')}
                </LinkInner>
              </LinkWrap>
            )}
          </ContentWrap>
        </Content>
      </FeatureComponent>
    </ComponentWrapper>
  );
};

Feature.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  image: PropTypes.object,
  title: PropTypes.object,
  description: PropTypes.object,
  cta: PropTypes.object,
  alignment: PropTypes.string,
  pattern: PropTypes.string,
  patternColor: PropTypes.string,
  spaceMultiplierMobile: PropTypes.string,
  spaceMultiplierDesktop: PropTypes.string,
};

export default Feature;
