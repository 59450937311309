import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const HeroImpactComponent = styled.section`
  position: relative;
  grid-column: 1 / -1;
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};
  overflow: hidden;

  ${breakpoints.tabletLandscape} {
    margin: 0 ${props => props.theme.px(61)} ${props => props.theme.space(props.spaceMultiplierDesktop)};
  }
`;

export const SlideContainer = styled.ul`
  position: relative;
  top: 0px;
  height: ${props => props.theme.px(541)};
  margin: 0;
  padding: 0;
  list-style-type: none;
  opacity: 0;
  transition-timing-function: ease-out;
  transition-duration: 0.5s;
  transition-property: transform;

  ${breakpoints.tabletLandscape} {
    height: ${props => props.theme.px(570)};
  }

  @media (prefers-reduced-motion) {
    transition-duration: 0 !important;
  }
`;

export const Pause = styled.button`
  ${props => props.theme.setFont('carouselStateText')}
  position: absolute;
  bottom: ${props => props.theme.space()};
  left: 20px;
  z-index: 20;
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  color: ${({ theme, gradient }) => CM(theme, gradient).pauseButtonColor};
  font-size: 16px;
  background: none;
  border: none;
  cursor: pointer;

  span {
    color: ${({ theme, gradient }) => CM(theme, gradient).pauseButtonColor};
  }

  ${breakpoints.tabletLandscape} {
    left: 46px;
    font-size: 18px;
  }

  svg {
    width: 24px;
    height: 24px;
    margin-right: 5px;
    fill: ${props => CM(props.theme, props.gradient).pauseButtonSvg};
  }
`;
