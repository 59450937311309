export const mapColourToSiteTheme = (theme, gradient) => {
  const { website } = theme;

  const whiteGradient = gradient && gradient === 'white';

  const sites = {
    'london-marathon': {
      pauseButtonColor: theme.colors.primary[300],
      pauseButtonSvg: theme.colors.primary[300],
    },
    'the-big-half': {
      pauseButtonColor: theme.colors.primary[400],
      pauseButtonSvg: theme.colors.primary[400],
    },
    'london-10k': {
      pauseButtonColor: whiteGradient ? theme.colors.primary[200] : theme.colors.primary[300],
      pauseButtonSvg: whiteGradient ? theme.colors.primary[200] : theme.colors.primary[300],
    },
    'westminster-mile': {
      pauseButtonColor: whiteGradient ? theme.colors.primary[200] : theme.colors.primary[300],
      pauseButtonSvg: whiteGradient ? theme.colors.primary[200] : theme.colors.primary[300],
    },
    'swim-serpentine': {
      pauseButtonColor: theme.colors.primary[400],
      pauseButtonSvg: theme.colors.primary[400],
    },
    'ride-london': {
      pauseButtonColor: theme.colors.primary[400],
      pauseButtonSvg: theme.colors.primary[400],
    },
    'city-race': {
      pauseButtonColor: theme.colors.primary[400],
      pauseButtonSvg: theme.colors.primary[400],
    },
  };
  if (website in sites) return sites[website];

  return sites['london-marathon'];
};
