import React from 'react';
import PropTypes from 'prop-types';
import { AmbientVideoInnerComponent, Fallback } from './AmbientVideoInner.styles.js';
import ReactPlayer from 'react-player/lazy';
import { useState } from 'react';
import { AnimatePresence } from 'framer-motion';

const AmbientVideoInner = ({ url, fallback, playing, width = '100%', onReadyCallback }) => {
  const [ready, setReady] = useState(false);
  return (
    <AmbientVideoInnerComponent>
      <ReactPlayer
        onReady={() => {
          setReady(true);
          onReadyCallback(true);
        }}
        onStart={() => onReadyCallback(false)}
        url={url}
        controls={false}
        playing={playing}
        muted
        width={width}
        height="100%"
        className="react-player"
        loop
        frameBorder="0"
        playsinline={true}
      />
      <AnimatePresence>
        {!ready && (
          <Fallback url={fallback?.src} alt={fallback?.alt} exit={{ opacity: 0, transition: { delay: 1 } }} />
        )}
      </AnimatePresence>
    </AmbientVideoInnerComponent>
  );
};

AmbientVideoInner.propTypes = {
  url: PropTypes.string,
  fallback: PropTypes.object,
  playing: PropTypes.boolean,
  width: PropTypes.string,
  onReadyCallback: PropTypes.func,
};

export default AmbientVideoInner;
