import React from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import { Button, Link, SitecoreLinkWrap } from './CTA.styles.js';
import { getIcon } from '../utils/icon';
import { ReactComponent as CTAEnd } from '../../assets/button-end.svg';
import { Link as SitecoreLink } from '@sitecore-jss/sitecore-jss-react';
import { CTAHTML as HTML } from './CTAHTML';

const CTA = React.forwardRef((props, ref) => {
  const {
    link = null,
    type = 'A',
    label = '',
    variant = 'blackA',
    inactive = false,
    socialIcon = '',
    ariaLabel = '',
    target = '_self',
    tabIndex = '0',
    fullwidth = 0,
    className = '',
    isEE = false,
    field = {},
    onClick = null,
    icon = 'arrowLong',
    onMouseEnter = null,
    onMouseLeave = null,
    theme: { website },
  } = props;

  const CTAHTML = HTML(website, icon, label, socialIcon);

  const commonProps = {
    variant,
    inactive,
    type,
    disabled: inactive || false,
    'aria-label': ariaLabel,
    tabIndex,
    ref,
    fullwidth,
    className,
    onClick,
    onMouseEnter,
    onMouseLeave,
  };

  if (isEE) {
    return (
      <SitecoreLinkWrap {...commonProps}>
        {getIcon(icon)}
        <SitecoreLink field={field} />
        <CTAEnd />
      </SitecoreLinkWrap>
    );
  } else if (!link) {
    return <Button {...commonProps}>{CTAHTML(type)}</Button>;
  } else {
    return (
      <Link href={link.href || ''} target={target} {...commonProps}>
        {CTAHTML(type)}
      </Link>
    );
  }
});

CTA.displayName = 'CTA';

CTA.propTypes = {
  link: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
  variant: PropTypes.string,
  inactive: PropTypes.bool,
  socialIcon: PropTypes.string,
  ariaLabel: PropTypes.string,
  tabIndex: PropTypes.string,
  target: PropTypes.string,
  className: PropTypes.string,
  fullwidth: PropTypes.number,
  isEE: PropTypes.bool,
  field: PropTypes.object,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  theme: PropTypes.object,
};

export default withTheme(CTA);
