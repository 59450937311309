import { useEffect, useState } from 'react';

const config = {
  root: null,
  rootMargin: '0px',
  threshold: 0,
};

const useObserver = ({ ref, htmlElement, options = {}, removeAfterInView = false }) => {
  const [isElementInView, setElementInView] = useState(false);
  const targetElement = htmlElement ?? ref?.current;

  let observerRefValue = null;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setElementInView(entry.isIntersecting);
        if (entry.isIntersecting) {
          setElementInView(entry.isIntersecting);
          if (removeAfterInView && targetElement) observer.unobserve(targetElement);
        }
      },
      { ...config, ...options }
    );

    if (targetElement) {
      observer.observe(targetElement);
      observerRefValue = targetElement;
    }

    return () => {
      if (observerRefValue) {
        observer.unobserve(observerRefValue);
      }
    };
  }, [isElementInView, options, ref, removeAfterInView, htmlElement]);

  return isElementInView;
};

export default useObserver;
