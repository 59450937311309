import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { withTheme } from 'styled-components';
import { useLazyQuery } from '@apollo/react-hooks';
import debounce from 'debounce';
import { loader } from 'graphql.macro';
import { Text, Image } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { useOnClickOutside } from '../hooks/useOnClickOutside';
import {
  SearchHeroComponent,
  SearchWrapper,
  InputWrapper,
  ImageWrapper,
  Pattern,
  ResultWrapper,
  LayerImage,
  ResultContainer,
  ResultsTitle,
} from './SearchHero.styles.js';
import { getIcon } from '../utils/icon.js';
import { getPattern } from '../utils/pattern.js';
import Tag from '../tag';

const SEARCH = loader('./SearchHero.graphql');

const SearchHero = props => {
  const {
    fields: { layerImage = {}, searchTitle = {}, searchPlaceholder = {}, contentType = {} } = {},
    params: {
      spaceMultiplierMobile = '1',
      spaceMultiplierDesktop = '1',
      gradient,
      pattern,
      colour: patternColor = 'sea-green',
    },
    sitecoreContext: { pageEditing = false } = {},
    searchUrl = '/search',
    theme: { website },
  } = props;

  let contentTypeId = '';
  if (contentType?.id) {
    contentTypeId = contentType.id.split('-').join('');
  }
  let contentTypeName = '';
  if (contentType?.fields?.title?.value) {
    contentTypeName = contentType.fields.title.value.trim().toLowerCase();
  }

  const [inputWidth, setInputWidth] = useState(0);
  const [resultsHeight, setResultsHeight] = useState(0);
  const [searchText, setSearchText] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [searchResultsVisible, setSearchResultsVisible] = useState(false);
  const [doSearch, { loading, data }] = useLazyQuery(SEARCH, { fetchPolicy: 'no-cache' });
  const hiddenDivRef = useRef(null);
  const resultsRef = useRef(null);

  const handlePattern = () => {
    if (pattern === 'the-medal') return 'the-medal-thin';
    if (pattern === 'transformation') return 'transformation-full';

    return pattern;
  };

  const patternColourCondition = website === 'ride-london' || website === 'city-race' ? null : patternColor;

  const handleAnimation = () =>
    setTimeout(() => {
      setInputWidth(hiddenDivRef.current.clientWidth + 1);
    });

  const mapResult = result => {
    // Can't get graphql to produce the same structure for tags...
    if (result.contentTypeTitle) {
      result.tag = {
        title: result.contentTypeTitle,
        colour: result.contentTypeColour,
        textColour: result.contentTypeTextColour,
      };
    }

    return result;
  };

  const search = searchTerm => {
    doSearch({ variables: { terms: searchTerm, contentType: contentTypeId } });
  };

  const searchDebounced = debounce(search, 300);

  const handleSearchInputChange = e => {
    const value = e.target.value;
    setSearchText(value);
    setSearchResultsVisible(true);

    if (value?.length > 2) {
      searchDebounced(value);
    } else {
      setSearchResults([]);
    }
  };

  const highlightSearchTerms = inputString => {
    const searchInputValue = document.getElementById('searchHeroInput').value.trim();
    const searchWords = searchInputValue.split(' ').join('|');
    const regex = new RegExp(`\\b(?=\\w*(${searchWords}))\\w+\\b`, 'gi');
    const matches = inputString.match(regex);
    return inputString
      .split(/\b/)
      .reduce((acc, word) => {
        if (matches?.findIndex(w => w.toLowerCase() === word.toLowerCase()) === 0) {
          acc.push(`<span>${word}</span>`);
          matches.shift();
        } else {
          acc.push(word);
        }
        return acc;
      }, [])
      .join('');
  };

  const handleSubmit = e => {
    e.preventDefault();
    const searchString = document?.getElementById('searchHeroInput')?.value?.trim();
    if (searchString && !isServer()) {
      const q = encodeURIComponent(searchString).replace(/%20(\D)?/g, '+$1');
      let url = `${searchUrl}?q=${q}`;
      if (contentTypeName) {
        const type = encodeURIComponent(contentTypeName).replace(/%20(\D)?/g, '+$1');
        url += `&type=${type}`;
      }

      window.location.replace(url);
    }
  };

  useOnClickOutside(resultsRef, () => {
    setSearchResultsVisible(false);
  });

  useEffect(() => {
    if (!loading) {
      const searchString = document?.getElementById('searchHeroInput')?.value?.trim();
      if (searchString.length > 2) {
        setSearchResults(data?.search?.results?.items || []);
      } else {
        setSearchResults([]);
      }
    }
  }, [data]);

  useEffect(() => {
    handleAnimation();

    if (searchResults?.length > 0) {
      setResultsHeight(resultsRef.current.getBoundingClientRect().height);
    } else {
      setResultsHeight(0);
    }
  }, [searchResults]);

  return (
    <SearchHeroComponent
      gradient={gradient}
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
      onSubmit={handleSubmit}
    >
      <SearchWrapper>
        {searchTitle && <Text field={searchTitle} tag="p" />}
        <InputWrapper inputWidth={inputWidth} gradient={gradient}>
          <input
            id="searchHeroInput"
            type="text"
            value={searchText}
            onChange={handleSearchInputChange}
            placeholder={searchPlaceholder?.value || ''}
            spellCheck="false"
            autoComplete="off"
          />
          <button type="submit">{getIcon('arrowLong')}</button>
          {searchResults?.length > 0 && searchResultsVisible && (
            <ResultWrapper resultsHeight={resultsHeight} ref={resultsRef}>
              <ul>
                {searchResults.map((result, i) => {
                  const mappedResult = mapResult(result);
                  return (
                    <li key={i}>
                      <ResultContainer href={result.url}>
                        {mappedResult.contentTypeTitle && (
                          <Tag
                            textColour={mappedResult.contentTypeTextColour}
                            backgroundColour={mappedResult.contentTypeColour}
                            label={mappedResult.contentTypeTitle}
                            isPresentationalOnly
                          />
                        )}
                        <ResultsTitle
                          dangerouslySetInnerHTML={{ __html: highlightSearchTerms(result?.title) }}
                        ></ResultsTitle>
                        {getIcon('arrowLong')}
                      </ResultContainer>
                    </li>
                  );
                })}
              </ul>
            </ResultWrapper>
          )}
          <span className="hidden" ref={hiddenDivRef}>
            {searchText}
          </span>
        </InputWrapper>
      </SearchWrapper>
      <ImageWrapper>
        {pattern && (
          <Pattern patternName={handlePattern()} patternColor={patternColourCondition}>
            {getPattern(handlePattern())}
          </Pattern>
        )}
        <LayerImage pageEditing={pageEditing}>
          <Image field={layerImage} />
        </LayerImage>
      </ImageWrapper>
    </SearchHeroComponent>
  );
};

SearchHero.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  results: PropTypes.array,
  searchUrl: PropTypes.string,
  sitecoreContext: PropTypes.object,
  theme: PropTypes.object,
};

export default withTheme(SearchHero);
