import styled from 'styled-components';

export const BackToTopWrapper = styled.div`
  opacity: 0;
  visibility: hidden;
  position: fixed;
  bottom: 15px;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out, visibility 0s linear 0.3s;

  ${({ chatBotExists, isMobile }) => `
    right: ${chatBotExists ? `45%` : `15px`};
    ${chatBotExists && isMobile ? `left: 15px;` : ''}
    ${chatBotExists && isMobile ? `bottom: 7px;` : ''}
  `}

  ${({ displayBackToTop }) =>
    displayBackToTop &&
    `
    opacity: 1;
    visibility: visible;
    transition: opacity 0.5s ease-in-out;
  `}
`;
export const BackToTopMobileButton = styled.button`
  border: none;
  background: none;
  outline: none;
`;

export const BackToTopDesktopButton = styled.button`
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  width: 175px;
  height: 50px;
  `;