import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';
import { trackingApi } from '@sitecore-jss/sitecore-jss-tracking';

export const TrackingContext = createContext();

export const TrackingProvider = ({ children, config }) => {
  const trackEvent = events => {
    trackingApi
      .trackEvent(events, {
        host: config.host,
        querystringParams: {
          sc_apikey: config.apiKey,
        },
        fetcher: config.dataFetcher,
      })
      .then(() => {
        if (config.debug) {
          console.log('Page event(s) pushed', events);
        }
      })
      .catch(error => {
        console.error(error);
      });
  };

  return <TrackingContext.Provider value={{ trackEvent: trackEvent }}>{children}</TrackingContext.Provider>;
};

TrackingProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]).isRequired,
  config: PropTypes.shape({
    host: PropTypes.string.isRequired,
    apiKey: PropTypes.string.isRequired,
    dataFetcher: PropTypes.func.isRequired,
    debug: PropTypes.bool,
  }),
};

export const useTracking = () => {
  const { trackEvent = () => {} } = useContext(TrackingContext) || {};

  return { trackEvent };
};
