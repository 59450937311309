export const mapColourToSiteTheme = (theme, gradient) => {
  const { website, colors } = theme;

  // Had to add !important to the london-10k readTimeNumberColour as an
  // override is set on the ArticleStrip component. However that was taking
  // Priority so the colour wasn't visible, revisit this later.

  const sites = {
    'london-marathon': {
      titleColor: colors.primary[300],
      readTimeColor: colors.primary[300],
      eventColor: colors.primary[300],
      borderColor: colors.primary[300],
      readTimeNumberColor: colors.secondary[500],
    },
    'the-big-half': {
      titleColor: colors.primary[400],
      readTimeColor: colors.primary[400],
      eventColor: colors.primary[400],
      borderColor: colors.primary[400],
      readTimeNumberColor: colors.primary[400],
    },
    'london-10k': {
      titleColor: gradient === 'white' ? colors.primary[200] : colors.primary[300],
      readTimeColor: gradient === 'white' ? colors.primary[200] : colors.primary[300],
      eventColor: gradient === 'white' ? colors.primary[200] : colors.primary[300],
      borderColor: gradient === 'white' ? colors.primary[200] : colors.primary[300],
      readTimeNumberColor: gradient === 'white' ? `${colors.primary[200]}!important` : colors.primary[300],
    },
    'westminster-mile': {
      titleColor: gradient === 'white' ? colors.primary[200] : colors.primary[300],
      readTimeColor: gradient === 'white' ? colors.primary[200] : colors.primary[300],
      eventColor: gradient === 'white' ? colors.primary[200] : colors.primary[300],
      borderColor: gradient === 'white' ? colors.primary[200] : colors.primary[300],
      readTimeNumberColor: gradient === 'white' ? `${colors.primary[200]}!important` : colors.primary[300],
    },
    'swim-serpentine': {
      titleColor: colors.primary[400],
      readTimeColor: colors.primary[400],
      eventColor: colors.primary[400],
      borderColor: colors.primary[400],
      readTimeNumberColor: colors.primary[400],
    },
    'ride-london': {
      titleColor: colors.primary[400],
      readTimeColor: colors.primary[400],
      eventColor: colors.primary[400],
      borderColor: colors.primary[400],
      readTimeNumberColor: colors.primary[400],
    },
    'city-race': {
      titleColor: colors.primary[400],
      readTimeColor: colors.primary[400],
      eventColor: colors.primary[400],
      borderColor: colors.primary[400],
      readTimeNumberColor: colors.primary[400],
    },
  };
  if (website in sites) return sites[website];

  return sites['london-marathon'];
};
