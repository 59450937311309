import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { HeroImpactComponent, SlideContainer, Pause } from './HeroImpact.styles.js';
import Slide from './Slide/Slide';
import { getIcon } from '../utils/icon';
import Empty from '../empty';
import { isServer } from '@sitecore-jss/sitecore-jss';

const HeroImpact = props => {
  const {
    fields: { items = [], slideTimeout: { value: slideTimeout = 5 } = {} } = {},
    params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
    sitecoreContext: { pageEditing = false } = {},
  } = props;

  const t = key => {
    if (props.translate && typeof props.translate === 'function') {
      return props.translate(`HeroImpact_${key}`);
    }

    return key;
  };

  const pauseText = t('Pause');
  const resumeText = t('Resume');
  const nextText = t('Next');

  const carouselRef = useRef(null);
  const outerRef = useRef(null);
  const pauseRef = useRef(null);
  const pauseTextRef = useRef(null);
  const timerRefs = [];

  const isCarousel = items.length > 1;
  const slides = !isCarousel ? [...items] : [items[items.length - 1], ...items, items[0]];
  const slideTime = (slideTimeout < 3 ? 3 : slideTimeout > 10 ? 10 : slideTimeout) * 1000;
  const transitionTime = '0.25s';
  let isPaused = false;
  let remainingTime = slideTime;
  let remainingPercent = 100;
  let currentSlide = 1;
  let slideInterval = null;
  let slideIntervalStartTime = 0;
  let transitionInProgress = false;
  let carouselHasFocus = false;
  let nextButtonClickType;
  let preHiddenPauseState = false;
  let isStarted = false;

  const isMob = isServer() ? true : window.innerWidth < 1024;
  const [isMobile, setIsMobile] = useState(isMob);
  const [actionIcon, setActionIcon] = useState('pause');
  const [currentSlideGradient, setCurrentSlideGradient] = useState(null);

  if (pageEditing || !isCarousel) {
    isPaused = true;
  }

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  const setHasFocus = hasFocus => (carouselHasFocus = hasFocus);

  const startCurrentTimerAnimation = (percent = 100) => {
    if (!isServer() && isCarousel) {
      const secondsLeft = ((percent / 100) * slideTime) / 1000;
      const currentTimer = timerRefs?.[currentSlide];
      if (!currentTimer) {
        return;
      }
      currentTimer.style.transitionDuration = `0s`;
      // setTimeout(() => {
      /*
        have to push this section into the callback queue to ensure
        the transitionDuration DOM change has happened
      */
      currentTimer.style.width = `${percent}%`;
      currentTimer.style.transitionDuration = `${secondsLeft.toFixed(2)}s`;
      currentTimer.style.width = '0%';
      // }, 0);
    }
  };

  const pauseCurrentTimerAnimation = percent => {
    if (!isServer() && isCarousel) {
      const currentTimer = timerRefs?.[currentSlide];
      if (!currentTimer) {
        return;
      }
      currentTimer.style.transitionDuration = `0s`;
      // setTimeout(() => {
      /*
        have to push this section into the callback queue to ensure
        the transitionDuration DOM change has happened
      */
      currentTimer.style.width = `${percent}%`;
      // }, 0);
    }
  };

  const resetAllTimerAnimations = () => {
    timerRefs.forEach(timerElement => {
      timerElement.style.transitionDuration = `0s`;
      timerElement.style.width = '100%';
    });
  };

  const startCountdownTimer = time => {
    /* 
      pausing with less than 300ms remaining causes weird behaviour
      this adds a bit of time on if needs be to stop it happening
    */
    if (!isServer() && isCarousel) {
      if (time < 300) {
        time = 300;
      }
      if (slideInterval) {
        clearInterval(slideInterval);
      }
      slideIntervalStartTime = new Date().getTime();
      slideInterval = setInterval(() => {
        moveCarousel(1);
      }, time);
    }
  };

  const setCarouselPosition = (xPos, noSlide = false) => {
    if (!carouselRef?.current) {
      return;
    }
    if (noSlide) {
      carouselRef.current.style.transitionDuration = '0s';
    } else {
      carouselRef.current.style.transitionDuration = transitionTime;
    }
    transitionInProgress = true;
    carouselRef.current.style.transform = `translateX(-${xPos * 100}%)`;
    currentSlide = xPos;
  };

  const moveCarousel = moveBy => {
    const nextSlide = currentSlide + moveBy;

    resetAllTimerAnimations();
    if (nextSlide === 0) {
      setCarouselPosition(slides.length - 2, true);
      currentSlide = slides.length - 2;
    } else if (nextSlide === slides[slides.length - 1]) {
      setCarouselPosition(0, true);
      currentSlide = 0;
    } else {
      setCarouselPosition(nextSlide, false);
      currentSlide = nextSlide;
    }
  };

  const setSlideDefaults = () => {
    transitionInProgress = false;
    remainingTime = slideTime;
    remainingPercent = 100;
    if (!isPaused) {
      startCountdownTimer(slideTime);
      startCurrentTimerAnimation();
    }
  };

  const handleSlideChange = () => {
    const slides = Array.from(carouselRef?.current?.querySelectorAll('.heroImpactSlide'));
    slides.forEach((slide, i) => {
      const slideCTA = slide.querySelector('.slideCTA');
      const timerElement = slide.querySelector('.timerElement');
      timerRefs[i] = timerElement;
      if (i === currentSlide) {
        setCurrentSlideGradient(slide?.dataset?.gradient);
        // active slide
        slide.classList?.add('activeSlide');
        slide.setAttribute('aria-hidden', 'false');
        slideCTA?.removeAttribute('tabindex');
        const nextNextButton = slide.nextSibling.querySelector('.nextButton');
        nextNextButton?.removeAttribute('tabindex');

        if (nextButtonClickType === 'keypress') {
          nextNextButton?.focus({ preventScroll: true });
        }
      } else {
        slide.classList?.remove('activeSlide');
        slide.nextSibling?.querySelector('.nextButton')?.setAttribute('tabindex', '-1');
        slide.setAttribute('aria-hidden', 'true');
        slideCTA?.setAttribute('tabindex', '-1');
      }
    });
  };

  const slideAnimationComplete = e => {
    if (!e.target.classList.contains('slideContainer')) {
      return;
    }
    if (currentSlide === slides.length - 2) {
      setCarouselPosition(0, true);
    }
    resetAllTimerAnimations();
    setSlideDefaults();
    handleSlideChange();
  };

  const gotoNextSlide = eventType => {
    if (!isServer() && isCarousel) {
      if (!transitionInProgress) {
        nextButtonClickType = eventType;
        transitionInProgress = true;
        clearInterval(slideInterval);
        moveCarousel(1);
      }
    }
  };

  const handlePauseButtonPress = () => {
    if (!isPaused) {
      dataLayerEvent('heroCarouselCTA', {
        heroAction: 'Pause',
        heroTitle: slides[currentSlide].fields.title.value,
      });
    } else {
      dataLayerEvent('heroCarouselCTA', {
        heroAction: 'Resume',
        heroTitle: slides[currentSlide].fields.title.value,
      });
    }

    pauseCarousel();
  };

  const pauseCarousel = () => {
    if (!isServer() && isCarousel && pauseTextRef?.current && pauseRef?.current) {
      isPaused = !isPaused;
      if (isPaused) {
        remainingTime = remainingTime - (new Date().getTime() - slideIntervalStartTime);
        remainingPercent = (remainingTime / slideTime) * 100;
        clearInterval(slideInterval);
        pauseCurrentTimerAnimation(remainingPercent);
        setActionIcon('play');
        pauseTextRef.current.innerText = resumeText;
        pauseRef.current.ariaLabel = resumeText;
      } else {
        startCountdownTimer(remainingTime);
        startCurrentTimerAnimation(remainingPercent);
        setActionIcon('pause');
        pauseTextRef.current.innerText = pauseText;
        pauseRef.current.ariaLabel = pauseText;
      }
    }
  };

  const handleKeyPress = e => {
    if (carouselHasFocus) {
      switch (e.key) {
        case 'ArrowRight':
          gotoNextSlide();
      }
    }
  };

  const handleFocus = () => {
    // only pause on keyboard focus
    if (outerRef?.current?.classList?.contains('focus-visible')) {
      setHasFocus(true);
      if (!isPaused) {
        pauseCarousel();
      }
    }
  };

  const handleResize = () => {
    if (!isServer()) {
      if (window.innerWidth < 1024) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    }
  };

  const startCarousel = () => {
    isStarted = true;
    if (!isServer() && carouselRef?.current) {
      // carousel is initially invisible to stop FOUC
      carouselRef.current.style.opacity = '1';
      outerRef.current.addEventListener('focus', handleFocus);
      if (!isCarousel) {
        outerRef.current.querySelector('li').classList.add('activeSlide');
      }
    }
    if (!isServer() && isCarousel) {
      pauseRef.current.addEventListener('click', handlePauseButtonPress);
      carouselRef.current.addEventListener('transitionend', slideAnimationComplete);
      window.addEventListener('keyup', handleKeyPress);
      window.addEventListener('resize', handleResize);
      handleSlideChange();
      setCarouselPosition(currentSlide, true);
      setSlideDefaults();
    }
  };

  const handleVisibilityChange = () => {
    if (!isStarted && !document.hidden) {
      startCarousel();
      return;
    }
    if (isStarted) {
      if (document.hidden) {
        preHiddenPauseState = isPaused;
        if (!isPaused) {
          pauseCarousel();
        }
      } else {
        if (preHiddenPauseState === false && isPaused) {
          pauseCarousel();
        }
      }
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    if (!document.hidden) {
      startCarousel();
    }
  }, []);

  useEffect(() => {
    if (!isServer() && slides.length) {
      return () => {
        if (!isServer()) {
          window.removeEventListener('resize', handleResize);
          pauseRef.current.removeEventListener('click', handlePauseButtonPress);
          outerRef.current.removeEventListener('focus', handleFocus);
          carouselRef.current.removeEventListener('transitionend', slideAnimationComplete);
          window.removeEventListener('keyup', handleKeyPress);
          document.removeEventListener('visibilitychange', handleVisibilityChange);
          clearInterval(slideInterval);
        }
      };
    }
  }, []);

  return (
    (slides.length && (
      <HeroImpactComponent
        tabIndex="0"
        ref={outerRef}
        spaceMultiplierMobile={spaceMultiplierMobile}
        spaceMultiplierDesktop={spaceMultiplierDesktop}
        className="headerComponentClass"
      >
        <SlideContainer
          className="slideContainer"
          currentSlide={currentSlide}
          ref={carouselRef}
          aria-live="off"
          aria-atomic="false"
        >
          {slides.map((slide, i) => (
            <Slide
              {...slide.fields}
              key={i}
              pauseCarousel={pauseCarousel}
              slideNumber={i}
              nextSlideText={nextText}
              nextSlideFn={gotoNextSlide}
              isCarousel={isCarousel}
              isMobile={isMobile}
              isEE={pageEditing}
              currentSlide={currentSlide}
              setCurrentSlideGradient={setCurrentSlideGradient}
            />
          ))}
        </SlideContainer>
        {isCarousel && (
          <Pause
            aria-label={isPaused ? resumeText : pauseText}
            ref={pauseRef}
            gradient={currentSlideGradient}
          >
            {getIcon(actionIcon)} <span ref={pauseTextRef}>{isPaused ? resumeText : pauseText}</span>
          </Pause>
        )}
      </HeroImpactComponent>
    )) ||
    (!slides.length && pageEditing && <Empty message="No items specified" />)
  );
};

HeroImpact.propTypes = {
  translate: PropTypes.func,
  params: PropTypes.object,
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object,
};

export default HeroImpact;
