const colorNames = {
  green: '#003333',
  midGreen: '#007A6D',
  turquoise: '#61CDCC',
  white: '#ffffff',
  fireRed: '#F72A0E',
  burntOrange: '#F77C15',
  amberGlow: '#FEA621',
  neonYellow: '#F2F91C',
  black: '#000000',
  jet: '#3c3c3b',
  sonicSilver: '#706f6f',
  silver: '#c7c7c6',
  platinum: '#e3e3e3',
  cultured: '#f2f2f2',
};

export const colors = {
  primary: {
    100: colorNames.green,
    200: colorNames.midGreen,
    300: colorNames.turquoise,
    400: colorNames.white,
  },
  secondary: {
    100: colorNames.fireRed,
    200: colorNames.burntOrange,
    300: colorNames.amberGlow,
    400: colorNames.black,
    500: colorNames.neonYellow,
  },
  grays: {
    100: colorNames.jet,
    200: colorNames.sonicSilver,
    300: colorNames.silver,
    400: colorNames.platinum,
    500: colorNames.cultured,
  },
  named: {
    green: colorNames.green,
    'mid-green': colorNames.midGreen,
    turquoise: colorNames.turquoise,
    white: colorNames.white,
    'fire-red': colorNames.fireRed,
    'burnt-orange': colorNames.burntOrange,
    'amber-glow': colorNames.amberGlow,
    black: colorNames.black,
    'neon-yellow': colorNames.neonYellow,
    grey: colorNames.grey,
    jet: colorNames.jet,
    'sonic-silver': colorNames.sonicSilver,
    silver: colorNames.silver,
    platinum: colorNames.platinum,
    cultured: colorNames.cultured,
  },
};
