import React from 'react';
import PropTypes from 'prop-types';
import ImageGalleryComponent from 'lmel-components/core/image-gallery';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import { withTranslation } from 'react-i18next';

const ImageGallery = props => {
  return (
    <ErrorBoundary>
      <ImageGalleryComponent translate={props.t} {...props} />
    </ErrorBoundary>
  );
};

ImageGallery.propTypes = {
  fields: PropTypes.object,
  t: PropTypes.func,
};

export default withTranslation()(ImageGallery);
