const colorNames = {
  sponsorPink: '#e41165',
  black: '#000000',
  white: '#ffffff',
  golden: '#ffd810',
  blueRYB: '#0f37ff',
  vividSky: '#00cff8',
  winterSky: '#e41165',
  seaGreen: '#00ffc5',
  cultured: '#f5f5f5',
  platinum: '#e1e1e1',
  grayX: '#b9b9b9',
  spanishGray: '#919191',
  davysGray: '#555555',
  jet: '#373737',
};

export const colors = {
  primary: {
    100: colorNames.sponsorPink,
    200: colorNames.black,
    300: colorNames.white,
  },
  secondary: {
    100: colorNames.golden,
    200: colorNames.blueRYB,
    300: colorNames.vividSky,
    400: colorNames.winterSky,
    500: colorNames.seaGreen,
  },
  grays: {
    100: colorNames.cultured,
    200: colorNames.platinum,
    300: colorNames.grayX,
    400: colorNames.spanishGray,
    500: colorNames.davysGray,
    600: colorNames.jet,
  },
  named: {
    white: colorNames.white,
    black: colorNames.black,
    'blue-ryb': colorNames.blueRYB,
    golden: colorNames.golden,
    'sea-green': colorNames.seaGreen,
    'sponsor-red': colorNames.sponsorPink,
    'vivid-sky': colorNames.vividSky,
    'winter-sky': colorNames.winterSky,
  },
};
