import React from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbsComponent } from './Breadcrumbs.styles.js';
import { getIcon } from '../utils/icon';
import { withTheme } from 'styled-components';

const Breadcrumbs = props => {
  const {
    theme: { website },
    ...otherProps
  } = props;
  const breadcrumbItems = Object.values(otherProps);

  return (
    <BreadcrumbsComponent
      aria-label="Breadcrumb"
      id="breadcrumb"
      className={!breadcrumbItems.length && website == 'lm-foundation' ? 'empty' : undefined}
    >
      <ul>
        {breadcrumbItems &&
          breadcrumbItems.map(({ url, title }, index) => (
            <li key={index}>
              <a href={url}>{title}</a>
              {website != 'lm-foundation' ? getIcon('chevronOutline') : ''}
            </li>
          ))}
      </ul>
    </BreadcrumbsComponent>
  );
};

Breadcrumbs.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  theme: PropTypes.object,
};

export default withTheme(Breadcrumbs);
