import React from 'react';
import PropTypes from 'prop-types';
import { HeroBasicComponent, Pattern, TextContrastGradient } from './HeroBasic.styles.js';
import { getPattern } from '../utils/pattern';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import Picture from '../picture';
import { withTheme } from 'styled-components';

const HeroBasic = props => {
  const {
    fields: { heroTitle, heroBackgroundImage = { value: {} } } = {},
    params: {
      gradient,
      pattern,
      colour: color = 'golden',
      spaceMultiplierMobile = '1',
      spaceMultiplierDesktop = '1',
    },
    sitecoreContext: { pageEditing = false } = {},
    theme: { website },
  } = props;

  const hasBgImg = Object.keys(heroBackgroundImage.value).length > 0;

  const patternName = () => {
    if (pattern === 'the-medal') return 'the-medal-alt';
    if (pattern === 'transformation') return 'transformation-full';

    return pattern;
  };

  const showGradient = pageEditing || !hasBgImg ? gradient : null;

  const patternColourCondition = website === 'ride-london' || website === 'city-race' ? null : color;

  return (
    <HeroBasicComponent
      hasBgImg={hasBgImg}
      gradient={showGradient}
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
      className="headerComponentClass"
      pageEditing={pageEditing}
    >
      {hasBgImg && <TextContrastGradient pageEditing={pageEditing} />}
      {pattern && (
        <Pattern patternColor={patternColourCondition} patternName={patternName()} pageEditing={pageEditing}>
          {getPattern(patternName())}
        </Pattern>
      )}
      {hasBgImg && <Picture field={heroBackgroundImage} />}
      {heroTitle?.value && (
        <h1>
          <Text field={heroTitle} />
        </h1>
      )}
    </HeroBasicComponent>
  );
};

HeroBasic.propTypes = {
  fields: PropTypes.shape({
    heroTitle: PropTypes.object,
    heroBackgroundImage: PropTypes.object,
  }),
  params: PropTypes.object,
  sitecoreContext: PropTypes.object,
  gradient: PropTypes.string,
  pattern: PropTypes.string,
  colour: PropTypes.string,
  spaceMultiplierMobile: PropTypes.string,
  spaceMultiplierDesktop: PropTypes.string,
  theme: PropTypes.object,
};

export default withTheme(HeroBasic);
