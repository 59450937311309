import styled from 'styled-components';
import { mapColourToSiteTheme as CM } from './colourMap.js';
import { breakpoints } from '../../global-styles/breakpoints';

export const CardContainer = styled.a`
  position: relative;
  display: block;
  height: 384px;
  text-decoration: none;
  background-image: url(${props => props.backgroundimage});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  &:before {
    position: absolute;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
    content: '';
  }
`;

export const CardLink = styled.p`
  ${props => props.theme.setFont('h8')}
  position: absolute;
  bottom: ${props => props.theme.space(0.5)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0;
  padding: 0 ${props => props.theme.space(0.5)};
  color: ${props => CM(props.theme).cardLinkColor};
  font-weight: 700;

  span {
    width: 100%;
    padding-right: ${props => props.theme.space()};
  }

  svg {
    transform: scale(1.75);
    fill: ${props => CM(props.theme).cardLinkSVGColor};
  }
`;

export const LinkList = styled.ul`
  margin: 0;
  padding: 0;
  list-style-type: none;

  li {
    margin-bottom: ${props => props.theme.px(15)};

    &:last-child {
      margin-bottom: 0;
    }

    a {
      ${props => props.theme.setFont('navCardLink')}
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: ${props => CM(props.theme).linkColor};
      font-weight: 700;
      text-decoration: none;

      ${breakpoints.tabletLandscape} {
        line-height: 1.5;
      }

      ${breakpoints.desktopSmall} {
        ${props => props.theme.setFont('navCardLink')}
      }

      svg {
        transform: scale(1.5);
        fill: ${props => CM(props.theme).linkSVGColor};

        ${breakpoints.tabletLandscape} {
          margin-left: ${props => props.theme.px(10)};
        }

        ${breakpoints.desktopSmall} {
          margin-left: unset;
        }
      }
    }
  }
`;
