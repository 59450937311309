import styled, { css } from 'styled-components';
import { breakpoints } from '../../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from '../colourMap.js';

export const ResultsWrap = styled.div`
  width: calc(100% - 116px);
  margin: 0 auto 50px;
  text-align: center;

  span {
    ${props => props.theme.setFont('searchAndResultsIntroSubHeadingText')}
    display: block;
    color: ${props => CM(props.theme).intro.subHeadingText};
  }
`;

export const NoResults = styled.p`
  ${props => props.theme.setFont('searchAndResultsIntroHeadingText')}
  margin: 0;
  color: ${props => CM(props.theme).intro.noResultsText};
  ${props => (props.theme.website !== 'lm-foundation' ? 'font-weight: bold;' : '')}
`;

export const ResultTitle = styled.p`
  ${props => props.theme.setFont('searchAndResultsIntroSearchedTerm')}
  margin: 0;

  color: ${props => CM(props.theme).intro.introSearchedTerm};
  font-weight: bold;

  ${breakpoints.tabletLandscape} {
    font-size: 30px;
  }
`;

export const Pattern = styled.div`
  display: flex;
  justify-content: center;
  ${props =>
    props.theme.website == 'lm-foundation'
      ? css`
          margin-top: ${props => props.theme.px(26)};
        `
      : ''}
  margin-bottom: ${props => props.theme.px(26)};

  span {
    position: relative;
    width: 10px;
    height: 10px;
    border: ${props => `7px solid ${CM(props.theme).intro.patternBorder}`};
    border-radius: 50%;

    &:not(:first-of-type) {
      margin-left: 30px;
    }

    &:after {
      position: absolute;
      top: 50%;
      left: 50%;
      width: 10px;
      height: 10px;
      background: white;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      content: '';
    }
  }
`;
