import styled, { css } from 'styled-components';
import { mapColourToSiteTheme as CM } from '../colourMap';

const buttonBgColour = props => {
  if (props.type.toLowerCase() === 'b') {
    return 'none';
  }
  if (props.inactive) {
    return CM(props.theme).inactiveBackgroundColor;
  }
  return props.theme.buttonVariants[props.variant][1];
};

const buttonHover = props => {
  if (props.type.toLowerCase() === 'b') {
    return props.inactive
      ? `color: ${CM(props.theme).inactiveHoverColor}`
      : `
      color: ${props.theme.buttonVariants['default'][1]}
      `;
  }
  return props.inactive
    ? ''
    : `filter: drop-shadow(0px 5px 3px ${props.theme.buttonVariants[props.variant][1]}8c)`;
};

const buttonTextColour = props => {
  if (props.type.toLowerCase() === 'b') {
    if (props.inactive) {
      return CM(props.theme).inactiveTextColor;
    }
    return props.theme.buttonVariants['default'][0];
  } else {
    return props.theme.buttonVariants[props.variant][0];
  }
};

const arrowFillColour = (props, hover = false) => {
  if (props.type.toLowerCase() === 'b') {
    if (props.inactive) {
      return CM(props.theme).inactiveArrowFillColor;
    }
    if (hover) {
      return props.theme.buttonVariants['default'][1];
    } else {
      return props.theme.buttonVariants['default'][0];
    }
  }
  return props.theme.buttonVariants[props.variant][0];
};

export const ButtonStyles = css`
  position: relative;
  display: flex;
  ${props => props.theme.setFont('cta')}
  width: ${props => (props.fullwidth ? '100%' : 'auto')};
  padding: 0;
  color: ${props => buttonTextColour(props)};
  font-weight: 700;
  text-align: left;
  text-decoration: none;
  background: none;
  border: none;
  transform: translateZ(0);
  cursor: ${props => (props.inactive ? 'not-allowed' : 'pointer')};
  filter: ${props =>
    props.type.toLowerCase() === 'a'
      ? `drop-shadow(0px 0px 0px ${props => props.theme.buttonVariants[props.variant][1]}8c)`
      : 'none'};
  transition: filter 0.25s;
  appearance: none;

  span, a {
    display: inline-block;
    width: ${props => (props.fullwidth ? 'calc(100% - 55px)' : 'auto')};
    height: ${props => (props.type.toLowerCase() === 'a' ? `55px` : 'auto')};
    padding: 0 3px 0 37px;
    color: ${props => buttonTextColour(props)};
    line-height: ${props => (props.type.toLowerCase() === 'a' ? `55px` : 'auto')};
    text-decoration: none;
    background-color: ${props => buttonBgColour(props)};
  }

  svg:first-child {
    position: absolute;
    top: ${props => (props.type.toLowerCase() === 'a' ? `15px` : '0px')};
    left: ${props => (props.type.toLowerCase() === 'a' ? `6px` : '0px')};
    transform: scale(0.75);
    fill: ${props => arrowFillColour(props, false)};
  }

  svg:last-child {
    vertical-align: bottom;
    transform: translateX(-0.5px);
    fill: ${props =>
      props.inactive ? CM(props.theme).inactiveSvgColor : props.theme.buttonVariants[props.variant][1]};
  }

  &:hover {
    svg:first-child {
      fill: ${props => arrowFillColour(props, true)};
    }
    ${props => buttonHover(props)}
  }
`;
