import styled from 'styled-components';
import CTA from '../cta';
import { breakpoints } from '../global-styles/breakpoints';

export const AdContainer = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    &:not(:first-child) {
      margin-top: ${props => props.theme.space()};
    }
  }
`;

export const TextBlockComponent = styled.article`
  width: 100%;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  ol,
  ul {
    max-width: 900px;
    margin-bottom: ${props => props.theme.space()};

    &[style='text-align: center;'],
    &.centered {
      margin-right: auto;
      margin-left: auto;
    }

    &[style='text-align: right;'] {
      float: right;
    }

    &[style='text-align: left;'] {
      float: left;
    }

    &:before {
      display: table;
      clear: both;
      content: '';
    }
  }

  ul.centered {
    padding-left: ${props => props.theme.space()};
  }

  .textBlockH1 {
    ${props => props.theme.setFont('textBlockHeading')}
    margin-bottom: ${props => props.theme.space()};
  }

  .textBlockH1Center {
    margin: 0 auto ${props => props.theme.space()} auto;
    text-align: center;
    ${breakpoints.tabletLandscape} {
      max-width: 900px;
    }
  }

  > div {
    margin-bottom: 0;
    & > *:last-child {
      margin-bottom: 0;
    }
  }
`;

export const NoAdsLayout = styled.section`
  width: calc(100% - 50px);
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};

  ${breakpoints.tabletLandscape} {
    width: calc(100% - 122px);
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
  }
`;

export const AdSlotLayout = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 50px);
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};

  &.has-meta-data {
    flex-direction: column-reverse;

    ${breakpoints.tabletLandscape} {
      flex-direction: unset;
    }
  }

  article {
    > div {
      & > *:last-child {
        margin-bottom: ${props => props.theme.space()};

        ${breakpoints.tabletLandscape} {
          margin-bottom: 0;
        }
      }
    }
  }

  > *:last-child {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0 ${props => props.theme.px(25)};

    &.meta-data {
      align-items: flex-start;
      margin-bottom: ${props => props.theme.space()};

      ${breakpoints.tabletLandscape} {
        margin-bottom: 0;
      }

      > div {
        width: 100%;

        &:not(:first-child) {
          display: flex;
          justify-content: center;
        }
      }
    }
  }

  ${breakpoints.tabletLandscape} {
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    width: calc(100% - 122px);
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};

    > *:last-child {
      width: 300px;
      margin: 0 0 0 ${props => props.theme.space()};
    }
  }
`;

export const CTAComponent = styled(CTA)`
  margin-top: 30px !important;
  display: inline-block;
`;
