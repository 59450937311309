import React from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import withDatasourceCheck from '../helpers/withDatasourceCheck';
import compose from '../utils/compose';

import { AnchorPointComponent } from './AnchorPoint.styles';

const AnchorPoint = props => {
  const {
    fields: { name: { value: anchorName = null } } = {},
    sitecoreContext: { pageEditing = false } = {},
  } = props;

  return anchorName ? (
    <AnchorPointComponent id={anchorName} pageEditing={pageEditing} className="anchorPoint" />
  ) : null;
};

AnchorPoint.propTypes = {
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object,
};

export default compose(withSitecoreContext(), withDatasourceCheck)(AnchorPoint);
