export const mapColourToSiteTheme = theme => {
  const sites = {
    'london-marathon': {
      firstIconFill: theme.colors.primary[100],
      lastIconFill: theme.colors.primary[300],
      lastIconPathFill: theme.colors.primary[300],
      secondIconPathFill: theme.colors.primary[300],
      secondIconPathFillDesktop: theme.colors.primary[100],
      signpostBorder: theme.colors.grays[300],
      signpostLink: theme.colors.primary[200],
      signpostHoverIconFill: theme.colors.primary[300],
      signpostHoverIconPathFill: theme.colors.primary[100],
      signpostHoverIconPathFillB: theme.colors.primary[300],
      signpostText: theme.colors.primary[200],
      signpostDate: theme.colors.grays[400],
    },
    'the-big-half': {
      firstIconFill: theme.colors.primary[100],
      lastIconFill: theme.colors.primary[400],
      lastIconPathFill: theme.colors.primary[400],
      secondIconPathFill: theme.colors.primary[400],
      secondIconPathFillDesktop: theme.colors.primary[100],
      signpostBorder: theme.colors.grays[300],
      signpostLink: theme.colors.secondary[400],
      signpostHoverIconFill: theme.colors.primary[400],
      signpostHoverIconPathFill: theme.colors.primary[100],
      signpostHoverIconPathFillB: theme.colors.primary[400],
      signpostText: theme.colors.secondary[400],
      signpostDate: theme.colors.grays[300],
    },
    'london-10k': {
      firstIconFill: theme.colors.primary[100],
      lastIconFill: theme.colors.primary[300],
      lastIconPathFill: theme.colors.primary[300],
      secondIconPathFill: theme.colors.primary[300],
      secondIconPathFillDesktop: theme.colors.primary[100],
      signpostBorder: theme.colors.grays[300],
      signpostLink: theme.colors.primary[200],
      signpostHoverIconFill: theme.colors.primary[300],
      signpostHoverIconPathFill: theme.colors.primary[100],
      signpostHoverIconPathFillB: theme.colors.primary[300],
      signpostText: theme.colors.primary[200],
      signpostDate: theme.colors.grays[300],
    },
    'westminster-mile': {
      firstIconFill: theme.colors.primary[100],
      lastIconFill: theme.colors.primary[300],
      lastIconPathFill: theme.colors.primary[300],
      secondIconPathFill: theme.colors.primary[300],
      secondIconPathFillDesktop: theme.colors.primary[100],
      signpostBorder: theme.colors.grays[300],
      signpostLink: theme.colors.primary[200],
      signpostHoverIconFill: theme.colors.primary[300],
      signpostHoverIconPathFill: theme.colors.primary[100],
      signpostHoverIconPathFillB: theme.colors.primary[300],
      signpostText: theme.colors.primary[200],
      signpostDate: theme.colors.grays[300],
    },
    'swim-serpentine': {
      firstIconFill: theme.colors.primary[200],
      lastIconFill: theme.colors.primary[400],
      lastIconPathFill: theme.colors.primary[400],
      secondIconPathFill: theme.colors.primary[400],
      secondIconPathFillDesktop: theme.colors.primary[200],
      signpostBorder: theme.colors.grays[300],
      signpostLink: theme.colors.secondary[400],
      signpostHoverIconFill: theme.colors.primary[400],
      signpostHoverIconPathFill: theme.colors.primary[200],
      signpostHoverIconPathFillB: theme.colors.primary[400],
      signpostText: theme.colors.secondary[400],
      signpostDate: theme.colors.grays[300],
    },
    'ride-london': {
      firstIconFill: theme.colors.primary[200],
      lastIconFill: theme.colors.primary[400],
      lastIconPathFill: theme.colors.primary[400],
      secondIconPathFill: theme.colors.primary[400],
      secondIconPathFillDesktop: theme.colors.primary[200],
      signpostBorder: theme.colors.grays[300],
      signpostLink: theme.colors.primary[300],
      signpostHoverIconFill: theme.colors.primary[400],
      signpostHoverIconPathFill: theme.colors.primary[200],
      signpostHoverIconPathFillB: theme.colors.primary[400],
      signpostText: theme.colors.primary[300],
      signpostDate: theme.colors.grays[300],
    },
    'city-race': {
      firstIconFill: theme.colors.primary[500],
      lastIconFill: theme.colors.primary[400],
      lastIconPathFill: theme.colors.primary[400],
      secondIconPathFill: theme.colors.primary[300],
      secondIconPathFillDesktop: theme.colors.primary[500],
      signpostBorder: theme.colors.grays[300],
      signpostLink: theme.colors.primary[300],
      signpostHoverIconFill: theme.colors.primary[300],
      signpostHoverIconPathFill: theme.colors.primary[500],
      signpostHoverIconPathFillB: theme.colors.primary[300],
      signpostText: theme.colors.primary[300],
      signpostDate: theme.colors.grays[300],
    },
    'lm-foundation': {
      firstIconFill: theme.colors.secondary[600],
      lastIconFill: theme.colors.primary[300],
      lastIconPathFill: theme.colors.primary[300],
      secondIconPathFill: theme.colors.primary[300],
      secondIconPathFillDesktop: theme.colors.secondary[700],
      signpostBorder: theme.colors.grays[300],
      signpostLink: theme.colors.primary[200],
      signpostHoverIconFill: theme.colors.primary[300],
      signpostHoverIconPathFill: theme.colors.secondary[600],
      signpostHoverIconPathFillB: theme.colors.primary[300],
      signpostText: theme.colors.primary[200],
      signpostDate: theme.colors.grays[400],
    },
  };
  if (theme.website in sites) return sites[theme.website];
  return sites['london-marathon'];
};
