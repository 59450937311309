import styled, { keyframes } from 'styled-components';
import { breakpoints } from '../../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from '../colourMap.js';

const animateOpacity = keyframes`
  0%   { opacity: 0; }
  100% { opacity: 1; }
`;

export const MobileFiltersWrap = styled.div`
  position: ${props => (props.filtersOpen ? 'fixed' : 'initial')};
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  background-color: ${props => CM(props.theme).mobileFilters.wrapBackground};
  transform: ${props => (props.filtersOpen ? 'translateX(100%)' : 'translateX(0%)')};

  &.animate-in {
    transform: translateX(0);
    transition: transform 0.5s ease-in;
    animation: ${animateOpacity} 0.5s ease-in;

    @media (prefers-reduced-motion) {
      animation: none;
    }
  }

  .apply-filters-cta {
    width: 100%;
    margin: 50px auto 75px;

    span {
      width: ${props => (props.theme.website === 'london-marathon' ? 'calc(100% - 55px)' : '100%')};
    }
  }
`;

export const MobileFilterHeader = styled.div`
  display: flex;

  &.filter-header {
    margin-top: ${props => `-${props.theme.space()}`};
    margin-bottom: 50px;
  }

  button {
    width: 65px;
    height: 60px;
    padding: 10px;
    background: transparent;
    border-top: none;
    border-right: 1px solid #f5f5f5;
    border-bottom: none;
    border-left: none;

    svg {
      width: 24px;
      height: 24px;
    }
  }
`;

export const MobileFilterContainer = styled.div`
  overflow-y: scroll;
`;

export const MobileFilterHeaderInner = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: ${props => (props.filtersOpen ? 'calc(100% - 65px)' : '100%')};
  padding: ${props => (props.filtersOpen ? '0 20px' : '0 10px 0 20px')};
  border-bottom: ${props =>
    props.filtersOpen ? 'none' : `1px solid ${CM(props.theme).mobileFilters.headingInnerBorderBottom}`};

  button {
    display: flex;
    align-items: center;
    width: auto;
    padding-left: ${props => (props.filtersOpen ? '0' : '20px')};
    border: none;
    border-left: ${props =>
      props.filtersOpen ? 'none' : `1px solid ${CM(props.theme).mobileFilters.headingInnerBorderLeft}`};

    svg {
      width: 20px;
      margin-right: 5px;

      path:not(:first-child) {
        fill: ${props =>
          props.filtersOpen
            ? `${CM(props.theme).mobileFilters.headingButtonOpenIconFill}`
            : `${CM(props.theme).mobileFilters.headingButtonClosedIconFill}`};
      }
    }

    span {
      font-weight: bold;
      font-size: 14px;
      line-height: 25px;
    }
  }

  p {
    ${props => props.theme.setFont('searchAndResultsFilterTitle')}
    margin: 0;
    color: ${props => CM(props.theme).mobileFilters.headingInnerText};
    font-weight: bold;
  }
`;

export const MobileFilterItems = styled.div`
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
`;

export const IconWrap = styled.div`
  display: flex;
  align-items: center;
`;

export const MobileFilterHeaderWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-right: 10px;

  ${breakpoints.mobile} {
    margin-right: 20px;
  }

  p {
    ${props => props.theme.setFont('searchAndResultsFilterTitle')}
    margin: 0;
    color: ${props => CM(props.theme).mobileFilters.headerWrapText};
  }

  svg {
    width: 20px;
    height: 20px;
    margin-right: 5px;
  }
`;

export const Accordion = styled.div``;

export const AccordionTitle = styled.p`
  ${props => props.theme.setFont('searchAndResultsFilterTitle')}
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin: 0;
  padding: 15px 20px;
  color: ${props => CM(props.theme).mobileFilters.accordionText};
  font-weight: bold;
  background-color: ${props => CM(props.theme).mobileFilters.accordionBackground};

  svg {
    width: 25px;
    transform: ${props => (props.isOpen ? 'rotate(270deg)' : 'rotate(90deg)')};
    transition: transform 0.3s ease;
  }
`;

export const AccordionItems = styled.div`
  padding: 0 30px;
  overflow: hidden;
  transition: max-height 0.3s ease;

  label {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 0;
    color: ${props => CM(props.theme).mobileFilters.accordionItemLabel};
    font-size: 18px;
    line-height: 18px;
    border-bottom: 1px solid #f5f5f5;
    cursor: pointer;
    user-select: none;

    &.checked {
      font-weight: bold;
    }

    input {
      position: absolute;
      width: 0;
      height: 0;
      cursor: pointer;
      opacity: 0;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark {
      position: absolute;
      top: 50%;
      right: 0;
      width: 25px;
      height: 25px;
      border: 2px solid #000;
      transform: translateY(-50%);

      &:after {
        position: absolute;
        top: 2px;
        left: 8px;
        display: none;
        width: 6px;
        height: 15px;
        border: ${props => `solid ${CM(props.theme).mobileFilters.accordionItemCheckmark}`};
        border-width: 0 2px 2px 0;
        transform: rotate(42deg);
        content: '';
      }
    }
  }
`;

export const CTAWrap = styled.div`
  margin: 0 30px;
`;
