import React, { useEffect, useState } from 'react';
import { BackToTopWrapper, BackToTopMobileButton, BackToTopDesktopButton } from './BackToTop.styles.js';
import { useIsMobile } from '../hooks/useIsMobile.js';
import { getIcon } from '../utils/icon.js';
import useObserver from '../hooks/useObserver';
import { isServer } from '@sitecore-jss/sitecore-jss';

const BackToTop = () => {
  const [displayBackToTop, setDisplayBackToTop] = useState(false);
  const [chatBotExists, setChatBotExists] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const isMobile = useIsMobile('tabletPortrait');
  const footer = !isServer() && document.getElementById('footer');
  
  const footerInView = useObserver({
    htmlElement: footer,
    options: {
      threshold: isMobile ? 0.05 : 0.1,
    },
  });

  const handleButtonVisibility = () => {
    setDisplayBackToTop(window.pageYOffset > 1600 && !footerInView);
  };

  const handleScrollUp = () => {
    window.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    window.addEventListener('scroll', handleButtonVisibility);
    return () => {
      window.removeEventListener('scroll', handleButtonVisibility);
    };
  }, [footerInView]);

  useEffect(() => {
    if(window.WebChat) {
      setChatBotExists(true);
    }
  }, [displayBackToTop]);

  useEffect(() => {
    setIsMounted(true);
  }, [])

  if (!isMounted) return null;

  return (
    <BackToTopWrapper isMobile={isMobile} chatBotExists={chatBotExists} displayBackToTop={displayBackToTop} onClick={handleScrollUp}>
      {isMobile && <BackToTopMobileButton>{getIcon('backToTopMobile')}</BackToTopMobileButton>}
      {!isMobile && (
        <BackToTopDesktopButton>
          {getIcon('backToTopDesktop')}
        </BackToTopDesktopButton>
      )}
    </BackToTopWrapper>
  );
};

export default BackToTop;
