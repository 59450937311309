import React from 'react';
import PropTypes from 'prop-types';
import { ArticleHero } from './ArticleHero.styles.js';
import ArticleCard from '../article-card';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ArticleHeroComponent = props => {
  const {
    fields: { heroImage = {}, gradient: { value: gradient = null } = {} } = {},
    params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
    sitecoreContext: { pageEditing = false } = {},
  } = props;

  const hasBgImg = heroImage?.value && Object.keys(heroImage.value).length > 0;

  const showGradient = pageEditing || !hasBgImg ? gradient : null;

  return (
    <ArticleHero
      hasBgImg={hasBgImg}
      gradient={showGradient}
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
      className="headerComponentClass"
    >
      <ArticleCard headingLevel={1} variant="c" {...props} />
    </ArticleHero>
  );
};

ArticleHeroComponent.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  sitecoreContext: PropTypes.object,
};

export default withSitecoreContext()(ArticleHeroComponent);
