export const mapColourToSiteTheme = theme => {
  const { website } = theme;

  const sites = {
    'london-marathon': {
      footerBackgroundColor: theme.colors.primary[200],
      footerSplitterColor: theme.colors.grays[300],
      footerSectionBorderColor: theme.colors.grays[300],
      footerSvgColorA: theme.colors.primary[200],
      footerSvgColorB: theme.colors.primary[300],
      footerSvgColorC: theme.colors.primary[300],
      footerSvgColorD: theme.colors.primary[200],
      footerSvgColorE: theme.colors.primary[200],
      footerCopyrightColor: theme.colors.grays[300],
      legalLinks: theme.colors.primary[300],
      statementText: theme.colors.primary[300],
      linklistTitleColor: theme.colors.primary[300],
      linklistItemColor: theme.colors.primary[300],
    },
    'the-big-half': {
      footerBackgroundColor: theme.colors.secondary[400],
      footerSplitterColor: theme.colors.primary[100],
      footerSectionBorderColor: theme.colors.primary[100],
      footerSvgColorA: theme.colors.secondary[400],
      footerSvgColorB: theme.colors.secondary[400],
      footerSvgColorC: theme.colors.primary[400],
      footerSvgColorD: theme.colors.secondary[400],
      footerSvgColorE: theme.colors.secondary[400],
      footerCopyrightColor: theme.colors.primary[400],
      legalLinks: theme.colors.primary[400],
      statementText: theme.colors.primary[400],
      linklistTitleColor: theme.colors.primary[300],
      linklistItemColor: theme.colors.primary[400],
    },
    'london-10k': {
      footerBackgroundColor: theme.colors.primary[200],
      footerSplitterColor: theme.colors.primary[100],
      footerSectionBorderColor: theme.colors.primary[100],
      footerSvgColorA: theme.colors.primary[200],
      footerSvgColorB: theme.colors.primary[300],
      footerSvgColorC: theme.colors.primary[300],
      footerSvgColorD: theme.colors.primary[200],
      footerSvgColorE: theme.colors.primary[200],
      footerSvgBorderColor: theme.colors.primary[300],
      footerCopyrightColor: theme.colors.primary[300],
      legalLinks: theme.colors.primary[300],
      statementText: theme.colors.primary[300],
      linklistTitleColor: theme.colors.primary[300],
      linklistItemColor: theme.colors.primary[300],
    },
    'swim-serpentine': {
      footerBackgroundColor: theme.colors.primary[100],
      footerSplitterColor: theme.colors.primary[200],
      footerSectionBorderColor: theme.colors.primary[200],
      footerSvgColorA: theme.colors.secondary[400],
      footerSvgColorB: theme.colors.primary[400],
      footerSvgColorC: theme.colors.primary[400],
      footerSvgColorD: theme.colors.primary[200],
      footerSvgColorE: theme.colors.secondary[400],
      footerSvgBorderColor: theme.colors.primary[400],
      footerCopyrightColor: theme.colors.primary[400],
      legalLinks: theme.colors.primary[400],
      statementText: theme.colors.primary[400],
      linklistTitleColor: theme.colors.primary[400],
      linklistItemColor: theme.colors.primary[400],
    },
    'westminster-mile': {
      footerBackgroundColor: theme.colors.primary[200],
      footerSplitterColor: theme.colors.primary[100],
      footerSectionBorderColor: theme.colors.primary[100],
      footerSvgColorA: theme.colors.primary[200],
      footerSvgColorB: theme.colors.primary[300],
      footerSvgColorC: theme.colors.primary[300],
      footerSvgColorD: theme.colors.primary[200],
      footerSvgColorE: theme.colors.primary[200],
      footerSvgBorderColor: theme.colors.primary[300],
      footerCopyrightColor: theme.colors.primary[300],
      legalLinks: theme.colors.primary[300],
      statementText: theme.colors.primary[300],
      linklistTitleColor: theme.colors.primary[300],
      linklistItemColor: theme.colors.primary[300],
    },
    'ride-london': {
      footerBackgroundColor: theme.colors.primary[100],
      footerSplitterColor: theme.colors.secondary[100],
      footerSectionBorderColor: theme.colors.secondary[100],
      footerSvgColorA: theme.colors.primary[300],
      footerSvgColorB: theme.colors.primary[300],
      footerSvgColorC: theme.colors.primary[400],
      footerSvgColorD: theme.colors.primary[300],
      footerSvgColorE: theme.colors.primary[300],
      footerSvgBorderColor: theme.colors.primary[400],
      footerCopyrightColor: theme.colors.primary[400],
      legalLinks: theme.colors.primary[400],
      statementText: theme.colors.primary[400],
      linklistTitleColor: theme.colors.primary[200],
      linklistItemColor: theme.colors.primary[400],
    },
    'city-race': {
      footerBackgroundColor: theme.colors.primary[300],
      footerSplitterColor: theme.colors.primary[200],
      footerSectionBorderColor: theme.colors.primary[200],
      footerSvgColorA: theme.colors.primary[300],
      footerSvgColorB: theme.colors.primary[300],
      footerSvgColorC: theme.colors.primary[400],
      footerSvgColorD: theme.colors.primary[300],
      footerSvgColorE: theme.colors.primary[300],
      footerSvgBorderColor: theme.colors.primary[400],
      footerCopyrightColor: theme.colors.primary[400],
      legalLinks: theme.colors.primary[400],
      statementText: theme.colors.primary[400],
      linklistTitleColor: theme.colors.primary[500],
      linklistItemColor: theme.colors.primary[400],
    },
    'lm-foundation': {
      footerBackgroundColor: theme.colors.grays[100],
      footerSplitterColor: theme.colors.secondary[400],
      footerSectionBorderColor: theme.colors.primary[200],
      legalLinks: theme.colors.primary[200],

      // footerSvgColorA: theme.colors.primary[300],
      // footerSvgColorB: theme.colors.primary[300],
      // footerSvgColorC: theme.colors.primary[400],
      // footerSvgColorD: theme.colors.primary[300],
      // footerSvgColorE: theme.colors.primary[300],
      // footerSvgBorderColor: theme.colors.primary[400],
      // footerCopyrightColor: theme.colors.primary[400],
      statementText: theme.colors.primary[200],
      linklistTitleColor: theme.colors.primary[200],
      linklistItemColor: theme.colors.primary[200],
    },
  };
  if (website in sites) return sites[website];

  return sites['london-marathon'];
};
