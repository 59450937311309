import styled, { css } from 'styled-components';

const editModeCSS = anchorId => css`
  &:after {
    display: block;
    margin: 10px;
    text-align: center;
    border: 1px solid red;
    content: '${anchorId}';
  }
`;

export const AnchorPointComponent = styled.div`
  position: relative;
  top: ${props => (props.pageEditing ? '0px' : `-${props.theme.website !== 'lm-foundation' ? 52 : 80}px`)};
  display: block;
  width: 100%;
  height: 1px;
  margin: 0 !important;
  padding: 0 !important;
  visibility: ${props => (props.pageEditing ? 'visible' : 'hidden')};

  ${props => props.pageEditing && editModeCSS(props.id)}
`;
