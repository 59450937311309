// Map the values sent by back end to a CTA which is created in buttonVariants (theme/~webiste~/buttonVariants)

export const handleCtaMappingByTheme = (website, color) => {
  const LMECTAColors = {
    golden: 'blackA',
    'winter-sky': 'whiteE',
    'sea-green': 'blackC',
    'blue-ryb': 'whiteB',
    'sponsor-red': 'whiteA',
    'vivid-sky': 'blackE',
  };

  const BigHalfCTAColours = {
    pink: 'blackA',
    white: 'blackB',
    lime: 'blackC',
    green: 'whiteA',
    violet: 'whiteB',
  };

  const London10kCTAColours = {
    pink: 'blackA',
    white: 'whiteA',
  };

  const WestminsterMileCTAColours = {
    pink: 'whiteA',
    white: 'blackA',
  };

  const SwimSerpentineCTAColours = {
    turquoise: 'blackA',
    white: 'blackB',
  };

  const RideLondonCTAColours = {
    blue: 'whiteA',
    pink: 'whiteB',
    white: 'blackA',
  };

  const CityRaceCTAColours = {
    green: 'blackA',
    blue: 'whiteA',
    white: 'blackB',
  };

  const LMFCTAColours = {
    light: 'whiteA',
    dark: 'blackA',
    'reassuring-navy': 'blackA',
    'positive-pink': 'blackA',
    'white-cliffs': 'blackA',
    'extraordinary-orange': 'blackA',
    'genuine-gold': 'blackA',
    'passionate-purple': 'blackA',
    'british-blue': 'blackA',
    'good-luck-green': 'blackA',
  };

  if (website === 'london-marathon' && color in LMECTAColors) {
    return LMECTAColors[color];
  }

  if (website === 'the-big-half' && color in BigHalfCTAColours) {
    return BigHalfCTAColours[color];
  }

  if (website === 'london-10k' && color in London10kCTAColours) {
    return London10kCTAColours[color];
  }

  if (website === 'westminster-mile' && color in WestminsterMileCTAColours) {
    return WestminsterMileCTAColours[color];
  }

  if (website === 'swim-serpentine' && color in SwimSerpentineCTAColours) {
    return SwimSerpentineCTAColours[color];
  }

  if (website === 'ride-london' && color in RideLondonCTAColours) {
    return RideLondonCTAColours[color];
  }

  if (website === 'city-race' && color in CityRaceCTAColours) {
    return CityRaceCTAColours[color];
  }

  if (website === 'lm-foundation' && color in LMFCTAColours) {
    return LMFCTAColours[color];
  }

  return 'blackA';
};
