import { colors } from './colors';

const w = colors.primary[400];
const b = colors.secondary[400];
const primary = colors.primary[300];

// [textColor, backgroundColor]

// Pre hover state
export const buttonVariants = {
  default: [b, primary],
  blackA: [b, primary],
  blackB: [b, w],
  whiteA: [w, primary],
  socialIcon: [w, w], // Footer social links
};

export const buttonHoverVariants = {
  default: [b, colors.primary[200]],
  blackA: [b, colors.primary[200]],
  blackB: [b, colors.grays[500]],
  whiteA: [w, colors.secondary[200]],
  socialIcon: [w, b], // Footer social links
};
