export const mapColourToSiteTheme = (theme) => {
  const { website } = theme;

  const sites = {
    "london-marathon": {
      cookieHeaderBackground: theme.colors.grays[100],
      cookieFooterBackground: theme.colors.grays[100],
      cookieLinkColor: theme.colors.secondary[200],
      globalFocus: theme.colors.primary[100],
      globalBodyColor: theme.colors.grays[500],
      globalH1: theme.colors.primary[200],
      globalH2: theme.colors.primary[200],
      globalH3: theme.colors.primary[200],
      globalH4: theme.colors.primary[200],
      globalH5: theme.colors.primary[200],
      globalH6: theme.colors.primary[200],
      globalFreeTextAnchor: theme.colors.secondary[200],
      globalBlockquoteBorder: theme.colors.secondary[300],
      globalBlockquoteSpanColor: theme.colors.primary[200],
      globalULBorder: theme.colors.secondary[200],
      globalLIBackground: theme.colors.secondary[200],
      globalLIIndentedBackground: theme.colors.secondary[400],
    },
    "the-big-half": {
      cookieHeaderBackground: theme.colors.grays[500],
      cookieFooterBackground: theme.colors.grays[500],
      cookieLinkColor: theme.colors.primary[100],
      globalFocus: theme.colors.primary[100],
      globalBodyColor: theme.colors.secondary[400],
      globalH1: theme.colors.secondary[400],
      globalH2: theme.colors.secondary[400],
      globalH3: theme.colors.secondary[400],
      globalH4: theme.colors.secondary[400],
      globalH5: theme.colors.secondary[400],
      globalH6: theme.colors.secondary[400],
      globalFreeTextAnchor: theme.colors.primary[100],
      globalBlockquoteBorder: theme.colors.primary[100],
      globalBlockquoteSpanColor: theme.colors.secondary[400],
      globalLIBackground: theme.colors.primary[300],
      globalLIIndentedBackground: theme.colors.primary[100],
    },
    "london-10k": {
      cookieHeaderBackground: theme.colors.grays[500],
      cookieFooterBackground: theme.colors.grays[500],
      cookieLinkColor: theme.colors.primary[100],
      cookieCtaBorderColor: theme.colors.primary[100],
      globalFocus: theme.colors.primary[100],
      globalBodyColor: theme.colors.primary[200],
      globalH1: theme.colors.primary[200],
      globalH2: theme.colors.primary[200],
      globalH3: theme.colors.primary[200],
      globalH4: theme.colors.primary[200],
      globalH5: theme.colors.primary[200],
      globalH6: theme.colors.primary[200],
      globalFreeTextAnchor: theme.colors.secondary[100],
      globalBlockquoteBorder: theme.colors.primary[100],
      globalBlockquoteSpanColor: theme.colors.primary[200],
      globalLIBackground: theme.colors.primary[100],
      globalLIIndentedBackground: theme.colors.primary[200],
    },
    "westminster-mile": {
      cookieHeaderBackground: theme.colors.grays[500],
      cookieFooterBackground: theme.colors.grays[500],
      cookieLinkColor: theme.colors.primary[100],
      cookieCtaBorderColor: theme.colors.primary[100],
      globalFocus: theme.colors.primary[100],
      globalBodyColor: theme.colors.primary[200],
      globalH1: theme.colors.primary[200],
      globalH2: theme.colors.primary[200],
      globalH3: theme.colors.primary[200],
      globalH4: theme.colors.primary[200],
      globalH5: theme.colors.primary[200],
      globalH6: theme.colors.primary[200],
      globalFreeTextAnchor: theme.colors.secondary[100],
      globalBlockquoteBorder: theme.colors.primary[100],
      globalBlockquoteSpanColor: theme.colors.primary[200],
      globalLIBackground: theme.colors.primary[100],
      globalLIIndentedBackground: theme.colors.primary[200],
    },
    "swim-serpentine": {
      cookieHeaderBackground: theme.colors.grays[500],
      cookieFooterBackground: theme.colors.grays[500],
      cookieLinkColor: theme.colors.primary[100],
      globalFocus: theme.colors.primary[100],
      globalBodyColor: theme.colors.secondary[400],
      globalH1: theme.colors.secondary[400],
      globalH2: theme.colors.secondary[400],
      globalH3: theme.colors.secondary[400],
      globalH4: theme.colors.secondary[400],
      globalH5: theme.colors.secondary[400],
      globalH6: theme.colors.secondary[400],
      globalFreeTextAnchor: theme.colors.primary[200],
      globalBlockquoteBorder: theme.colors.primary[100],
      globalBlockquoteSpanColor: theme.colors.secondary[400],
      globalLIBackground: theme.colors.primary[200],
      globalLIIndentedBackground: theme.colors.secondary[400],
    },
    "ride-london": {
      cookieHeaderBackground: theme.colors.grays[500],
      cookieFooterBackground: theme.colors.grays[500],
      cookieLinkColor: theme.colors.primary[200],
      globalFocus: theme.colors.primary[100],
      globalBodyColor: theme.colors.primary[300],
      globalH1: theme.colors.primary[300],
      globalH2: theme.colors.primary[300],
      globalH3: theme.colors.primary[300],
      globalH4: theme.colors.primary[300],
      globalH5: theme.colors.primary[300],
      globalH6: theme.colors.primary[300],
      globalFreeTextAnchor: theme.colors.primary[200],
      globalBlockquoteBorder: theme.colors.primary[200],
      globalBlockquoteSpanColor: theme.colors.primary[300],
      globalLIBackground: theme.colors.primary[200],
      globalLIIndentedBackground: theme.colors.secondary[100],
    },
    "city-race": {
      cookieHeaderBackground: theme.colors.grays[500],
      cookieFooterBackground: theme.colors.grays[500],
      cookieLinkColor: theme.colors.primary[200],
      globalFocus: theme.colors.primary[100],
      globalBodyColor: theme.colors.primary[300],
      globalH1: theme.colors.primary[300],
      globalH2: theme.colors.primary[300],
      globalH3: theme.colors.primary[300],
      globalH4: theme.colors.primary[300],
      globalH5: theme.colors.primary[300],
      globalH6: theme.colors.primary[300],
      globalFreeTextAnchor: theme.colors.primary[200],
      globalBlockquoteBorder: theme.colors.primary[500],
      globalBlockquoteSpanColor: theme.colors.primary[300],
      globalLIBackground: theme.colors.primary[200],
      globalLIIndentedBackground: theme.colors.primary[500],
    },
    "lm-foundation": {
      cookieHeaderBackground: theme.colors.grays[100],
      cookieFooterBackground: theme.colors.grays[100],
      cookieLinkColor: theme.colors.secondary[200],
      globalFocus: theme.colors.primary[100],
      globalBodyColor: theme.colors.primary[200],
      globalH1: theme.colors.primary[200],
      globalH2: theme.colors.primary[200],
      globalH3: theme.colors.primary[200],
      globalH4: theme.colors.primary[200],
      globalH5: theme.colors.primary[200],
      globalH6: theme.colors.primary[200],
      globalFreeTextAnchor: theme.colors.secondary[1100],
      globalBlockquoteSpanColor: theme.colors.primary[200],
      globalBlockquoteBorder: theme.colors.secondary[700],
      globalULBorder: theme.colors.secondary[200],
      globalLIBackground: theme.colors.secondary[700],
      globalLIIndentedBackground: theme.colors.secondary[700],
    },
  };
  if (website in sites) return sites[website];

  return sites["london-marathon"];
};
