import React from 'react';
import { getIcon } from '../../utils/icon';
import { ReactComponent as CTAEnd } from '../../../assets/button-end.svg';

export const CTAHTML = (icon, label, socialIcon) => {
  const LMEHTML = CTAType => {
    switch (CTAType.toLowerCase()) {
      case 'a':
        return (
          <>
            {getIcon(icon)}
            <span>{label}</span>
            <CTAEnd />
          </>
        );
      case 'b':
        return (
          <>
            {getIcon(icon)}
            <span>{label}</span>
          </>
        );
      case 'c':
        return socialIcon ? getIcon(socialIcon.toLowerCase()) : null;
    }
  };

  return LMEHTML;
};
