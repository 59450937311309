import { colors } from './colors';

const createLinearGradient = (fromColor, toColor) =>
  `linear-gradient(0deg, ${fromColor} 0%, ${toColor} 100%)`;

export const gradients = {
  'yellow-to-amber': createLinearGradient(colors.secondary[500], colors.secondary[300]),
  'red-to-orange': createLinearGradient(colors.secondary[100], colors.secondary[200]),
  green: createLinearGradient(colors.primary[100], colors.primary[100]),
  'mid-green': createLinearGradient(colors.primary[200], colors.primary[200]),
  turquoise: createLinearGradient(colors.primary[300], colors.primary[300]),
  black: createLinearGradient(colors.secondary[400], colors.secondary[400]),
};
