import React from 'react';
import PropTypes from 'prop-types';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import { EditFrameComponentWrapper } from './EditFrame.styles.js';

const EditFrameProps = {
  itemId: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.string),
  header: PropTypes.string,
  icon: PropTypes.string,
  disabledIcon: PropTypes.string,
  tooltip: PropTypes.string,
  command: PropTypes.string,
  commandDisplayName: PropTypes.string,
  databaseName: PropTypes.string,
  language: PropTypes.string,
  hideIfNotPageEditing: PropTypes.bool,
  sitecoreContext: PropTypes.shape({
    language: PropTypes.string,
    pageEditing: PropTypes.bool,
    route: PropTypes.shape({
      databaseName: PropTypes.string,
    }),
  }),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

const EditFrameWrapper = ({
  itemId,
  fields,
  header,
  icon,
  disabledIcon,
  tooltip,
  command,
  commandDisplayName,
  databaseName,
  language,
  children,
}) => {
  if (!itemId || !fields || fields.length <= 0) {
    return null;
  }

  const itemUri = `sitecore://${databaseName}/${itemId}?lang=${language}`;
  const action = `webedit:fieldeditor(command=${command},fields=${fields.join('|')},id=${itemId})`;

  const commandData = {
    commands: [
      {
        click: `javascript:Sitecore.PageModes.PageEditor.postRequest('${action}',null,false)`,
        header: header,
        icon: icon,
        disabledIcon: disabledIcon,
        isDivider: false,
        tooltip: tooltip || `Edit the following fields: ${fields.join(', ')}`,
        type: null,
      },
    ],
    contextItemUri: itemUri,
    custom: {},
    displayName: commandDisplayName || 'Edit fields',
    expandedDisplayName: '',
  };

  return (
    <EditFrameComponentWrapper
      className="scLooseFrameZone scEnabledChrome"
      sc_item={itemUri}
      sc-part-of="editframe"
    >
      <span className="scChromeData">{JSON.stringify(commandData)}</span>
      {children}
    </EditFrameComponentWrapper>
  );
};

EditFrameWrapper.propTypes = { ...EditFrameProps };

const EditFrame = ({
  sitecoreContext,
  children,
  itemId,
  fields,
  hideIfNotPageEditing,
  databaseName,
  language,
  ...otherProps
}) => {
  const shouldRender = sitecoreContext.pageEditing && itemId && fields && fields.length > 0;

  if (!shouldRender && hideIfNotPageEditing) {
    return null;
  }

  const Wrapper = shouldRender ? EditFrameWrapper : React.Fragment;

  const wrapperProps = shouldRender
    ? {
        itemId,
        fields,
        databaseName: databaseName || sitecoreContext?.route?.databaseName || '',
        language: language || sitecoreContext.language,
        sitecoreContext,
        ...otherProps,
      }
    : {};

  return <Wrapper {...wrapperProps}>{children}</Wrapper>;
};

EditFrame.propTypes = EditFrameProps;
EditFrame.defaultProps = {
  itemId: '',
  fields: [],
  header: 'Edit fields',
  icon: '/temp/iconcache/office/16x16/pencil.png',
  disabledIcon: '/temp/pencil_disabled16x16.png',
  command: '{70C4EED5-D4CD-4D7D-9763-80C42504F5E7}',
};

export default withSitecoreContext()(EditFrame);
