import React from 'react';
import PropTypes from 'prop-types';
import { EmptyComponent } from './Empty.styles.js';

const Empty = props => {
  const { message = null } = props;

  return message && <EmptyComponent>{message}</EmptyComponent>;
};

Empty.propTypes = {
  message: PropTypes.string,
};

export default Empty;
