import styled from 'styled-components';
import { breakpoints } from '../../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from '../colourMap.js';

export const DesktopFiltersWrap = styled.aside`
  display: block;
  width: 300px;
  margin-left: 26px;
  border-right: ${props => `1px solid ${CM(props.theme).desktopFilters.filterWrapBorder}`};

  p {
    &.filterResultsTitle {
      ${props => props.theme.setFont('searchAndResultsFilterResultsTitle')}
      margin-top: 50px;
      margin-bottom: 23px;
    }

    ${props => props.theme.setFont('searchAndResultsFilterTitle')}
    margin: 0;
    padding: 0;
    color: ${props => CM(props.theme).desktopFilters.filterWrapText};
    font-weight: bold;
  }
`;

export const DesktopFilterItems = styled.div`
  top: 50px;
  height: 100vh;

  ul {
    margin: 0 ${props => props.theme.px(10)} 0 0;
    padding: 0;
    list-style: none;

    li:not(:first-child) {
      margin-top: 50px;
    }
  }

  label {
    position: relative;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: flex-end;
    padding: 18px 0 0 20px;
    color: ${props => CM(props.theme).desktopFilters.labelColor};
    font-size: 18px;
    line-height: 1.5;
    cursor: pointer;
    user-select: none;

    &.checked {
      font-weight: bold;
    }

    input {
      position: absolute;
      width: 0;
      height: 0;
      cursor: pointer;
      opacity: 0;
    }

    input:checked ~ .checkmark:after {
      display: block;
    }

    .checkmark {
      position: relative;
      display: table;
      width: 25px;
      height: 25px;
      margin-right: 16px;
      border: 2px solid #000;

      &:after {
        position: absolute;
        top: 2px;
        left: 8px;
        display: none;
        width: 6px;
        height: 15px;
        border: ${props => `solid ${CM(props.theme).desktopFilters.checkmarkColor}`};
        border-width: 0 2px 2px 0;
        transform: rotate(42deg);
        content: '';
      }
    }
  }
`;

export const FilterTitle = styled.p`
  ${props => props.theme.setFont('searchAndResultsFilterTitle')}
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 15px 20px;
  color: ${props => CM(props.theme).desktopFilters.filterTitleColor};
  font-weight: bold;
  font-size: 18px;

  &.filter-title {
    ${breakpoints.tabletLandscape} {
      font-size: 18px;
    }
  }
`;

export const CTAWrap = styled.div`
  margin: 50px 0 150px 10px;

  svg:first-child {
    width: 24px;
    height: auto;
    display: ${({ website }) => website === 'ride-london' && 'none'};

    path:first-child {
      fill: none;
    }
  }
`;

export const Input = styled.input``;
