import styled from 'styled-components';
import { motion } from 'framer-motion';

export const AmbientVideoInnerComponent = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`;

export const Fallback = styled(motion.div)`
  background: url(${props => props.url});
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
`;
