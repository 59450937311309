export const mapColourToSiteTheme = ({ theme = {}, color, gradient }) => {
  const { website } = theme;

  const whiteFeature = color === 'white';
  const whiteGradient = gradient && gradient === 'white';

  const sites = {
    'london-marathon': {
      titleColor: theme.colors.primary[300],
      textColor: theme.colors.primary[300],
      timerBackgroundColor: theme.colors.grays[100],
      previewButtonColor: theme.colors.primary[300],
      nextButtonColor: theme.colors.primary[300],
      nextButtonSvgColor: theme.colors.primary[300],
    },
    'the-big-half': {
      titleColor: theme.colors.primary[400],
      textColor: theme.colors.primary[400],
      timerBackgroundColor: theme.colors.grays[400],
      previewButtonColor: theme.colors.primary[400],
      nextButtonColor: theme.colors.primary[400],
      nextButtonSvgColor: theme.colors.primary[400],
    },
    'london-10k': {
      titleColor: whiteGradient ? theme.colors.primary[200] : theme.colors.primary[300],
      textColor: whiteGradient ? theme.colors.primary[200] : theme.colors.primary[300],
      timerBackgroundColor: whiteFeature ? theme.colors.primary[200] : theme.colors.grays[500],
      previewButtonColor: whiteGradient ? theme.colors.primary[200] : theme.colors.primary[300],
      nextButtonColor: whiteGradient ? theme.colors.primary[200] : theme.colors.primary[300],
      nextButtonSvgColor: whiteGradient ? theme.colors.primary[200] : theme.colors.primary[300],
    },
    'westminster-mile': {
      titleColor: whiteGradient ? theme.colors.primary[200] : theme.colors.primary[300],
      textColor: whiteGradient ? theme.colors.primary[200] : theme.colors.primary[300],
      timerBackgroundColor: whiteFeature ? theme.colors.primary[200] : theme.colors.grays[500],
      previewButtonColor: whiteGradient ? theme.colors.primary[200] : theme.colors.primary[300],
      nextButtonColor: whiteGradient ? theme.colors.primary[200] : theme.colors.primary[300],
      nextButtonSvgColor: whiteGradient ? theme.colors.primary[200] : theme.colors.primary[300],
    },
    'swim-serpentine': {
      titleColor: theme.colors.primary[400],
      textColor: theme.colors.primary[400],
      timerBackgroundColor: theme.colors.grays[400],
      previewButtonColor: theme.colors.primary[400],
      nextButtonColor: theme.colors.primary[400],
      nextButtonSvgColor: theme.colors.primary[400],
    },
    'ride-london': {
      titleColor: theme.colors.primary[400],
      textColor: theme.colors.primary[400],
      timerBackgroundColor: theme.colors.grays[400],
      previewButtonColor: theme.colors.primary[400],
      nextButtonColor: theme.colors.primary[400],
      nextButtonSvgColor: theme.colors.primary[400],
    },
    'city-race': {
      titleColor: theme.colors.primary[400],
      textColor: theme.colors.primary[400],
      timerBackgroundColor: theme.colors.grays[400],
      previewButtonColor: theme.colors.primary[400],
      nextButtonColor: theme.colors.primary[400],
      nextButtonSvgColor: theme.colors.primary[400],
    },
    'lm-foundation': {
      titleColor: theme.colors.primary[300],
      textColor: theme.colors.primary[300],
      timerBackgroundColor: theme.colors.primary[200],
      previewButtonColor: theme.colors.primary[300],
      nextButtonColor: theme.colors.primary[300],
      nextButtonSvgColor: theme.colors.primary[300],
    },
  };
  if (website in sites) return sites[website];

  return sites['london-marathon'];
};
