export const mapColourToSiteTheme = theme => {
  const { website } = theme;

  const sites = {
    'london-marathon': {
      containerBorder: theme.colors.primary[300],
      closeButtonColor: theme.colors.primary[300],
      closeButtonSvgColor: theme.colors.primary[300],
      searchFormColor: theme.colors.primary[300],
      searchFormBorderColor: theme.colors.primary[300],
      searchFormInputColor: theme.colors.primary[300],
      searchFormInputPlaceholderColor: theme.colors.primary[300],
      inputUnderlineColor: theme.colors.primary[100],
      searchButtonSvgColor: theme.colors.primary[300],
      popularSearchesColor: theme.colors.primary[300],
      popularSearchesLinkColor: theme.colors.primary[300],
      searchResultsTextColorA: theme.colors.primary[300],
      searchResultsTextColorB: theme.colors.secondary[100],
      searchResultsHoverColor: theme.colors.primary[300],
    },
    'the-big-half': {
      containerBorder: theme.colors.primary[400],
      closeButtonColor: theme.colors.primary[400],
      closeButtonSvgColor: theme.colors.primary[400],
      searchFormColor: theme.colors.primary[400],
      searchFormBorderColor: theme.colors.primary[400],
      searchFormInputColor: theme.colors.primary[400],
      searchFormInputPlaceholderColor: theme.colors.primary[400],
      inputUnderlineColor: theme.colors.primary[100],
      searchButtonSvgColor: theme.colors.primary[400],
      popularSearchesColor: theme.colors.primary[400],
      popularSearchesLinkColor: theme.colors.primary[400],
      searchResultsTextColorA: theme.colors.primary[400],
      searchResultsTextColorB: theme.colors.primary[100],
      searchResultsHoverColor: theme.colors.secondary[300],
    },
    'london-10k': {
      containerBorder: theme.colors.primary[300],
      closeButtonColor: theme.colors.primary[300],
      closeButtonSvgColor: theme.colors.primary[300],
      searchFormColor: theme.colors.primary[300],
      searchFormBorderColor: theme.colors.primary[300],
      searchFormInputColor: theme.colors.primary[300],
      searchFormInputPlaceholderColor: theme.colors.primary[300],
      inputUnderlineColor: theme.colors.primary[100],
      searchButtonSvgColor: theme.colors.primary[300],
      popularSearchesColor: theme.colors.primary[300],
      popularSearchesLinkColor: theme.colors.primary[300],
      searchResultsTextColorA: theme.colors.primary[300],
      searchResultsTextColorB: theme.colors.primary[100],
      searchResultsHoverColor: theme.colors.primary[300],
    },
    'westminster-mile': {
      containerBorder: theme.colors.primary[300],
      closeButtonColor: theme.colors.primary[300],
      closeButtonSvgColor: theme.colors.primary[300],
      searchFormColor: theme.colors.primary[300],
      searchFormBorderColor: theme.colors.primary[300],
      searchFormInputColor: theme.colors.primary[300],
      searchFormInputPlaceholderColor: theme.colors.primary[300],
      inputUnderlineColor: theme.colors.primary[100],
      searchButtonSvgColor: theme.colors.primary[300],
      popularSearchesColor: theme.colors.primary[300],
      popularSearchesLinkColor: theme.colors.primary[300],
      searchResultsTextColorA: theme.colors.primary[300],
      searchResultsTextColorB: theme.colors.primary[100],
      searchResultsHoverColor: theme.colors.primary[300],
    },
    'swim-serpentine': {
      containerBorder: theme.colors.primary[400],
      closeButtonColor: theme.colors.primary[400],
      closeButtonSvgColor: theme.colors.primary[400],
      searchFormColor: theme.colors.primary[400],
      searchFormBorderColor: theme.colors.primary[400],
      searchFormInputColor: theme.colors.primary[400],
      searchFormInputPlaceholderColor: theme.colors.primary[400],
      inputUnderlineColor: theme.colors.secondary[400],
      searchButtonSvgColor: theme.colors.primary[400],
      popularSearchesColor: theme.colors.primary[400],
      popularSearchesLinkColor: theme.colors.primary[400],
      searchResultsTextColorA: theme.colors.primary[400],
      searchResultsTextColorB: theme.colors.primary[300],
      searchResultsHoverColor: theme.colors.primary[400],
    },
    'ride-london': {
      containerBorder: theme.colors.primary[400],
      closeButtonColor: theme.colors.primary[400],
      closeButtonSvgColor: theme.colors.primary[400],
      searchFormColor: theme.colors.primary[400],
      searchFormBorderColor: theme.colors.primary[400],
      searchFormInputColor: theme.colors.primary[400],
      searchFormInputPlaceholderColor: theme.colors.primary[400],
      inputUnderlineColor: theme.colors.secondary[400],
      searchButtonSvgColor: theme.colors.primary[400],
      popularSearchesColor: theme.colors.primary[400],
      popularSearchesLinkColor: theme.colors.primary[400],
      searchResultsTextColorA: theme.colors.primary[400],
      searchResultsTextColorB: theme.colors.primary[200],
      searchResultsHoverColor: theme.colors.primary[400],
    },
    'city-race': {
      containerBorder: theme.colors.primary[400],
      closeButtonColor: theme.colors.primary[400],
      closeButtonSvgColor: theme.colors.primary[400],
      searchFormColor: theme.colors.primary[400],
      searchFormBorderColor: theme.colors.primary[400],
      searchFormInputColor: theme.colors.primary[400],
      searchFormInputPlaceholderColor: theme.colors.primary[400],
      inputUnderlineColor: theme.colors.primary[500],
      searchButtonSvgColor: theme.colors.primary[400],
      popularSearchesColor: theme.colors.primary[400],
      popularSearchesLinkColor: theme.colors.primary[400],
      searchResultsTextColorA: theme.colors.primary[400],
      searchResultsTextColorB: theme.colors.primary[500], //TODO
      searchResultsHoverColor: theme.colors.primary[400],
    },
    'lm-foundation': {
      containerBorder: theme.colors.primary[300],
      closeButtonColor: theme.colors.primary[300],
      closeButtonSvgColor: theme.colors.primary[300],
      searchFormColor: theme.colors.primary[300],
      searchFormBorderColor: theme.colors.primary[300],
      searchFormInputColor: theme.colors.primary[300],
      searchFormInputPlaceholderColor: theme.colors.primary[300],
      inputUnderlineColor: theme.colors.secondary[400],
      searchButtonSvgColor: theme.colors.primary[300],
      popularSearchesColor: theme.colors.primary[300],
      popularSearchesLinkColor: theme.colors.primary[300],
      searchResultsTextColorA: theme.colors.primary[300],
      searchResultsTextColorB: theme.colors.secondary[100],
      searchResultsHoverColor: theme.colors.primary[300],
    },
  };
  if (website in sites) return sites[website];

  return sites['london-marathon'];
};
