import { colors } from './colors';

const createLinearGradient = (fromColor, toColor) =>
  `linear-gradient(0deg, ${fromColor} 0%, ${toColor} 100%)`;

export const gradients = {
  'pink-to-blue': createLinearGradient(colors.primary[100], colors.primary[200]),
  'blue-to-orange': createLinearGradient(colors.secondary[200], colors.primary[100]),
  blue: createLinearGradient(colors.primary[100], colors.primary[100]),
};
