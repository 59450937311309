import PropTypes from 'prop-types';
import React from 'react';

const PictureSource = {
  srcset: PropTypes.string,
  media: PropTypes.string,
};

const PictureFallback = {
  src: PropTypes.string,
  srcset: PropTypes.string,
  alt: PropTypes.string,
};

const PictureFieldValue = {
  sources: PropTypes.arrayOf(PropTypes.shape(PictureSource)),
  fallback: PropTypes.shape(PictureFallback),
};

const PictureField = {
  value: PropTypes.shape(PictureFieldValue),
  editable: PropTypes.string,
};

const getEditableWrapper = editableMarkup => (
  <span className="sc-image-wrapper" dangerouslySetInnerHTML={{ __html: editableMarkup }} />
);

const Picture = ({ editable, field, className, decorative = false, lazy = true }) => {
  if (!field || (!field.editable && !field.value)) {
    return null;
  }

  if (editable && field.editable) {
    return getEditableWrapper(field.editable);
  }

  if (!field.value.fallback) {
    return null;
  }

  const imgAttributes = {
    src: field.value.fallback.src,
    srcSet: field.value.fallback.srcset,
    alt: decorative ? '' : field.value.fallback.alt,
  };

  if (lazy) {
    imgAttributes.loading = 'lazy';
  }

  return (
    <>
      <picture className={className}>
        {field?.value?.sources?.map((src, i) => (
          <source srcSet={src.srcset} media={src.media} key={i} />
        ))}
        {field?.value?.fallback && <img {...imgAttributes} />}
      </picture>
    </>
  );
};

Picture.propTypes = {
  field: PropTypes.shape(PictureField),
  editable: PropTypes.bool,
  className: PropTypes.string,
  decorative: PropTypes.bool,
  lazy: PropTypes.bool,
};

Picture.defaultProps = {
  editable: true,
};

Picture.displayName = 'Picture';

export default Picture;
