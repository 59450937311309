import React from 'react';
import PropTypes from 'prop-types';
import FaqVotingComponent from 'lmel-components/core/faq-voting';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import { withTranslation } from 'react-i18next';

const FaqVoting = props => {
  return (
    <ErrorBoundary>
      <FaqVotingComponent translate={props.t} {...props} />
    </ErrorBoundary>
  );
};

FaqVoting.propTypes = {
  t: PropTypes.func,
};

export default withTranslation()(FaqVoting);
