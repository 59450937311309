import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { loadScript } from '../utils/script-loader';
import { getIcon } from '../utils/icon';
import { ChatButton, Wrapper, ChatWindow } from './ChatBot.styles';
import { styleOptions } from './ChatBotStyleOptions';
import { breakpointValues } from '../global-styles/breakpoints';
import { isServer } from '@sitecore-jss/sitecore-jss';

const ChatBot = props => {
  const { botUrl } = props;

  const [showBot, setShowBot] = useState(false);
  const [directLine, setDirectLine] = useState();
  const [token, setToken] = useState('');
  const [store, setStore] = useState();
  const [chatBotLib, setChatBotLib] = useState();
  const [windowWidth, setWindowWidth] = useState(0);

  const handleClick = () => {
    setShowBot(!showBot);
  };

  const handleResize = () => {
    if (!isServer()) {
      setWindowWidth(window.innerWidth);
    }
  };

  useEffect(() => {
    if (!isServer()) {
      window.addEventListener('resize', handleResize);
      handleResize();
    }
    return () => {
      if (!isServer()) {
        window.removeEventListener('resize', handleResize);
      }
    };
  }, []);

  useEffect(() => {
    if (!isServer()) {
      const bodySelector = document.getElementsByTagName('body')[0];

      if (showBot && windowWidth <= breakpointValues.mobileLarge) {
        bodySelector.classList.add('body-hidden');
        bodySelector.classList.remove('body-visible');
      } else {
        bodySelector.classList.remove('body-hidden');
        bodySelector.classList.add('body-visible');
      }
    }
  }, [showBot, windowWidth]);

  //Logic to fetch initial token, api link privided by LME
  useMemo(async () => {
    if (!token) {
      const res = await fetch(botUrl);
      const { token: responseToken } = await res.json();
      setToken(responseToken);
    }
  }, []);

  useEffect(() => {
    loadScript('https://cdn.botframework.com/botframework-webchat/latest/webchat.js', 'anonymous', () => {
      if (!isServer()) {
        setChatBotLib(window.WebChat);
      }
    });
  }, []);

  useEffect(() => {
    if (token && chatBotLib) {
      const { createDirectLine } = chatBotLib;
      setDirectLine(createDirectLine({ token }));
    }
  }, [token, chatBotLib]);

  useEffect(() => {
    if (directLine && chatBotLib) {
      const { createStore } = chatBotLib;
      const storeTemp = createStore({}, ({ dispatch }) => next => action => {
        if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
          dispatch({
            meta: {
              method: 'keyboard',
            },
            payload: {
              activity: {
                channelData: {
                  postBack: true,
                },
                name: 'startConversation',
                type: 'event',
              },
            },
            type: 'DIRECT_LINE/POST_ACTIVITY',
          });
        }
        return next(action);
      });
      setStore(storeTemp);
    }
  }, [directLine]);

  useEffect(() => {
    if (showBot && store && chatBotLib) {
      chatBotLib.renderWebChat(
        {
          directLine,
          store,
          styleOptions,
        },
        document.getElementById('chatbot')
      );
    }
  }, [store, showBot]);

  return (
    <>
      <Wrapper>
        <ChatWindow>
          {showBot && (
            <>
              <div className="bot-heading">Ask a question</div>
              <div id="chatbot"></div>
            </>
          )}
        </ChatWindow>
        {store && (
          <ChatButton
            onClick={() => {
              handleClick();
            }}
          >
            {getIcon(showBot ? 'arrow' : 'openChat')}
          </ChatButton>
        )}
      </Wrapper>
    </>
  );
};

ChatBot.propTypes = {
  botUrl: PropTypes.string,
};

export default ChatBot;
