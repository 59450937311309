import styled, { css } from "styled-components";
import { breakpoints } from "../global-styles/breakpoints";
import { mapColourToSiteTheme as CM } from "./colourMap.js";
import {
  LMEIconStyles,
  TheBigHalfIconStyles,
  London10kIconStyles,
  WestminsterMileIconStyles,
  SwimSerpentineIconStyles,
  RideLondonIconStyles,
  CityRaceIconStyles,
} from "./iconStyles.styles";

// Due to the icons being different from a style point of view we need to create each button style per brand.
// When you start take a copy of one of the icons and rename it to the correct brand and tweak styling where needed.
const iconStylesBySite = (props) => {
  const { website } = props;

  switch (website) {
    case "london-marathon":
      return LMEIconStyles;
    case "the-big-half":
      return TheBigHalfIconStyles;
    case "london-10k":
      return London10kIconStyles;
    case "westminster-mile":
      return WestminsterMileIconStyles;
    case "swim-serpentine":
      return SwimSerpentineIconStyles;
    case "ride-london":
      return RideLondonIconStyles;
    case "city-race":
      return CityRaceIconStyles;
    default:
      return LMEIconStyles;
  }
};

const specificSiteStyles = (props) => {
  const { website } = props;

  const sites = {
    "london-marathon": {
      playTextBottomPos: "-30px",
      playTextLeftPos: "unset",
      playTextTransform: "unset",
      playTextFontWeight: "normal",
    },
    "the-big-half": {
      playTextBottomPos: "-125px",
      playTextLeftPos: "-30px",
      playTextTransform: "uppercase",
      playTextFontWeight: "bold",
    },
    "london-10k": {
      playTextBottomPos: "-75px",
      playTextLeftPos: "-50px",
      playTextTransform: "uppercase",
      playTextFontWeight: "bold",
    },
    "westminster-mile": {
      playTextBottomPos: "-75px",
      playTextLeftPos: "-50px",
      playTextTransform: "uppercase",
      playTextFontWeight: "bold",
    },
    "swim-serpentine": {
      playTextBottomPos: "-100px",
      playTextLeftPos: "-50px",
      playTextTransform: "uppercase",
      playTextFontWeight: "bold",
    },
    "ride-london": {
      playTextBottomPos: "-60px",
      playTextLeftPos: "-25px",
      playTextTransform: "unset",
      playTextFontWeight: "bold",
    },
    "city-race": {
      playTextBottomPos: "-40px",
      playTextLeftPos: "-25px",
      playTextTransform: "unset",
      playTextFontWeight: "bold",
    },
  };

  if (website in sites) {
    return sites[website];
  }

  return sites["london-marathon"];
};

export const FullWidthVideoComponent = styled.section`
  width: calc(100% - 51px);
  margin-bottom: ${(props) => props.theme.space(props.spaceMultiplierMobile)};

  &.limitedWidth {
    justify-self: center;

    ${breakpoints.tabletLandscape} {
      width: calc(100% - 122px);
      margin-bottom: ${(props) =>
        props.theme.space(props.spaceMultiplierDesktop)};
    }
  }

  ${breakpoints.tabletLandscape} {
    margin: 0 ${(props) => props.theme.px(61)};
  }

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: 100%;
  }

  .react-player__preview {
    /* Remove poster to replace it with our Picture */
    background-image: none !important;
  }
`;

export const ImageWrapper = styled.div`
  position: relative;
  height: 0;
  padding-top: ${(props) =>
    props.hasBgImg ? "calc(425 / 322 * 100%)" : "0px"};
  overflow: hidden;
  cursor: pointer;

  ${breakpoints.tabletPortrait} {
    padding-top: ${(props) =>
      props.hasBgImg ? "calc(670 / 1243 * 100%)" : "0px"};
  }

  /* Angle cutout in the bottom right of the poster image, only applies to LME */
  &:after {
    position: absolute;
    top: 90%;
    right: -50px;
    display: ${(props) =>
      props.theme.website === "london-marathon" ? "block" : "none"};
    clear: both;
    width: 0;
    height: 0;
    border-right: 50px solid transparent;
    border-bottom: 50px solid #fff;
    border-left: 20px solid transparent;
    content: "";

    ${breakpoints.tabletPortrait} {
      border-bottom: 50px solid #fff;
      border-left: 30px solid transparent;
    }

    ${breakpoints.desktopSmall} {
      border-bottom: 85px solid #fff;
      border-left: 45px solid transparent;
    }
  }

  picture {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }
`;

export const Title = styled.h1`
  ${(props) => props.theme.setFont("videoTitle")}
  margin: 0;
`;

export const SubTitle = styled.h2`
  ${(props) => props.theme.setFont("videoSubTitle")}
  margin: 0;
  font-weight: normal;
`;

export const TitleWrap = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    &:not(:first-child) {
      margin-bottom: ${(props) => props.theme.px(15)};
    }

    ${breakpoints.tabletLandscape} {
      margin-bottom: ${(props) => props.theme.px(15)};
    }
  }

  ${breakpoints.tabletLandscape} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const Icon = styled.button`
  ${(props) => iconStylesBySite(props.theme)}
`;

export const IconWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;

  ${breakpoints.tabletLandscape} {
    width: 120px;
    height: auto;
  }

  ${breakpoints.desktopSmall} {
    width: 171px;
    height: 171px;
  }
`;

export const VideoTitle = styled.h3`
  ${(props) => props.theme.setFont("videoTitleOverlay")}
  position: absolute;
  bottom: ${(props) => props.theme.space()};
  left: ${(props) => props.theme.space()};
  z-index: 1;
  display: block;
  max-width: 75%;
  margin: 0;
  color: ${(props) => CM(props.theme).titleColor};
  ${(props) =>
    props.theme.website !== "lm-foundation"
      ? css`
          font-size: ${(props) => props.theme.px(42)};
          line-height: ${(props) => props.theme.px(48)};
        `
      : ""}

  ${breakpoints.tabletLandscape} {
    position: relative;
    bottom: 0;
    flex: 1;
    ${(props) =>
      props.theme.website !== "lm-foundation"
        ? css`
            font-size: ${(props) => props.theme.px(100)};
            line-height: ${(props) => props.theme.px(90)};
          `
        : ""}
  }

  ${breakpoints.desktopSmall} {
    bottom: ${(props) => props.theme.space()};
    ${(props) =>
      props.theme.website !== "lm-foundation"
        ? css`
            font-size: ${(props) => props.theme.px(150)};
            line-height: ${(props) => props.theme.px(160)};
          `
        : ""}
  }
`;

export const ButtonText = styled.div`
  display: none;

  ${breakpoints.tabletLandscape} {
    position: absolute;
    bottom: ${(props) => specificSiteStyles(props.theme).playTextBottomPos};
    left: ${(props) => specificSiteStyles(props.theme).playTextLeftPos};
    z-index: 99;
    display: block;
    color: ${(props) => CM(props.theme).buttonTextColor};
    text-transform: ${(props) =>
      specificSiteStyles(props.theme).playTextTransform};
    opacity: ${(props) => (props.imageHovered ? "1" : "0")};
    transition: opacity 0.3s ease-out;
  }

  p {
    font-weight: ${(props) =>
      specificSiteStyles(props.theme).playTextFontWeight};
  }
`;

export const OverlayWrap = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 52px;
  z-index: 2;

  ${breakpoints.tabletLandscape} {
    height: auto;
  }
`;

export const TextContrastGradient = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  width: 100%;
  height: 80%;
  z-index: ${({ theme }) =>
    theme.website === "lm-foundation" ? "1" : "unset"};

  background-image: ${({ theme }) =>
    theme.website === "lm-foundation"
      ? `linear-gradient(0deg, ${`${theme.colors.secondary[800]}BF`} 0%, rgba(0, 0, 0, 0) 100%)`
      : `linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%)`};
`;
