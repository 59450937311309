import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const ModalComponent = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  display: ${props => (props.visible ? 'block' : 'none')};
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 16px;
  background-color: ${props => CM(props.theme).backgroundColor};
  box-shadow: 1px 1px 1px black;
  transition: all 0.3s ease-out;
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
`;

export const CloseButton = styled.button`
  position: absolute;
  right: ${props => props.theme.px(20)};
  z-index: 105;
  padding: 0;
  background: transparent;
  border: 0;
  cursor: pointer;

  svg {
    width: 30px;
    height: 30px;

    ${breakpoints.desktopSmall} {
      width: 50px;
      height: 50px;
    }

    path:last-child {
      fill: ${props => CM(props.theme).closeButtonSvgColor};
    }
  }
`;
