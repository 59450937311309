import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  TextBlockComponent,
  AdSlotLayout,
  NoAdsLayout,
  AdContainer,
  CTAComponent,
} from './TextBlock.styles.js';
import { RichText, Text, withSitecoreContext, Placeholder } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { withTheme } from 'styled-components';

const TextBlockLayout = props => {
  const {
    rendering = {},
    rendering: {
      placeholders = {},
      params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
    },
    theme: { website },
  } = props;

  const isMetaData = placeholders?.['jss-mpu-ads']?.map(
    placeholder => placeholder.componentName === 'ArticleMeta'
  );

  return placeholders?.['jss-mpu-ads']?.length ? (
    <AdSlotLayout
      className={`limitedWidth ${isMetaData.includes(true) ? 'has-meta-data' : ''}`}
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      <TextBlock {...props} hasMPU />
      <AdContainer className={`${isMetaData.includes(true) ? 'meta-data' : ''}`}>
        <Placeholder name="jss-mpu-ads" rendering={rendering} />
      </AdContainer>
    </AdSlotLayout>
  ) : (
    <NoAdsLayout
      className="limitedWidth"
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      <TextBlock {...props} website={website} />
    </NoAdsLayout>
  );
};

const TextBlock = props => {
  const { fields: { title = {}, description = {}, cta = {} } = {}, website } = props;

  const textBlockRef = useRef();

  const mapCtaVariant = () => {
    const sites = {
      'london-marathon': 'blackA',
      'the-big-half': 'blackA',
      'london-10k': 'blackA',
      'westminster-mile': 'whiteA',
      'swim-serpentine': 'blackA',
      'ride-london': 'whiteA',
      'city-race': 'blackA',
      'lm-foundation': 'blackA',
    };
    if (website in sites) return sites[website];
    return sites['london-marathon'];
  };

  useEffect(() => {
    if (!isServer()) {
      Array.from(textBlockRef.current.querySelectorAll('li')).forEach(el => {
        if (el.style.textAlign === 'center') {
          el.parentNode.classList.add('centered');
        }
      });
    }
  }, []);

  return (
    <TextBlockComponent ref={textBlockRef} className="freeText">
      {title && <Text field={title} tag="h1" className={`textBlockH1`} />}
      <RichText field={description} />
      {cta?.value?.href && (
        <CTAComponent
          type="A"
          link={cta.value}
          variant={mapCtaVariant()}
          target={cta.value?.linktype === 'external' ? '_blank' : null}
          label={cta.value.text}
          field={cta}
        />
      )}
    </TextBlockComponent>
  );
};

TextBlock.propTypes = {
  fields: PropTypes.object,
  website: PropTypes.string,
};

TextBlockLayout.propTypes = {
  rendering: PropTypes.object,
  spaceMultiplierMobile: PropTypes.string,
  spaceMultiplierDesktop: PropTypes.string,
  theme: PropTypes.object,
};

export default withSitecoreContext()(withTheme(TextBlockLayout));
