import { css } from 'styled-components';
import { mapColourToSiteTheme as CM } from '../colourMap';

const buttonBgColour = props => {
  if (props.type.toLowerCase() === 'b') {
    return 'none';
  }
  if (props.inactive) {
    return CM(props.theme).inactiveBackgroundColor;
  }
  return props.theme.buttonVariants[props.variant][1];
};

const buttonBgHoverColour = props => {
  return `
    span {
      color: ${props.theme.buttonHoverVariants[props.variant][0]};
      background-color: ${props.theme.buttonHoverVariants[props.variant][1]};
    }
  `;
};

const buttonHover = props => {
  if (props.type.toLowerCase() === 'b') {
    return props.inactive
      ? `color: ${CM(props.theme).inactiveHoverColor}`
      : `
      color: ${props.theme.buttonVariants['default'][1]}
      `;
  }
  return props.inactive ? '' : `${buttonBgHoverColour(props)}`;
};

const buttonTextColour = props => {
  if (props.type.toLowerCase() === 'b') {
    if (props.inactive) {
      return CM(props.theme).inactiveTextColor;
    }
    return props.theme.buttonVariants['default'][0];
  } else {
    return props.theme.buttonVariants[props.variant][0];
  }
};

const arrowFillColour = (props, hover = false) => {
  if (props.type.toLowerCase() === 'b') {
    if (props.inactive) {
      return CM(props.theme).inactiveArrowFillColor;
    }
    if (hover) {
      return props.theme.buttonVariants['default'][1];
    } else {
      return props.theme.buttonVariants['default'][0];
    }
  }
  return props.theme.buttonVariants[props.variant][0];
};

export const ButtonStyles = css`
  position: relative;
  display: flex;
  ${props => props.theme.setFont('cta')}
  width: ${props => (props.fullwidth ? '100%' : 'auto')};
  padding: 0;
  color: ${props => buttonTextColour(props)};
  font-weight: 700;
  text-align: left;
  text-decoration: none;
  background: none;
  border: none;
  transform: translateZ(0);
  cursor: ${props => (props.inactive ? 'not-allowed' : 'pointer')};
  transition: filter 0.25s;
  appearance: none;

  span, a {
    display: inline-block;
    width: ${props => (props.fullwidth ? '100%' : 'auto')};
    height: ${props => (props.type.toLowerCase() === 'a' ? `55px` : 'auto')};
    padding: ${props => (props.type.toLowerCase() === 'a' ? '0 37px 0 37px' : '0 3px 0 37px')};
    color: ${props => buttonTextColour(props)};
    line-height: ${props => (props.type.toLowerCase() === 'a' ? `55px` : 'auto')};
    text-align: center;
    text-transform: uppercase;
    text-decoration: none;
    background-color: ${props => buttonBgColour(props)};
    border-radius: 47px;
  }

  svg {
    display: ${props => (props.type.toLowerCase() !== 'a' ? 'block' : 'none')} ;
  }

  &:hover {
    svg:first-child {
      fill: ${props => arrowFillColour(props, true)};
    }
    ${props => buttonHover(props)}
  }
`;
