import styled, { css } from 'styled-components';
import { breakpoints } from '../../../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

// If the text is uppercased then we need to shift the event wrap up slightly to be aligned with the text
const handleBottomPositioningPerSite = props => {
  const { website } = props;

  const sites = {
    'london-marathon': '0',
    'the-big-half': '15px',
    'london-10k': '15px',
    'westminster-mile': '15px',
    'swim-serpentine': '0',
    'ride-london': '0',
    'city-race': '0',
    'lm-foundation': '0',
  };

  if (website in sites) {
    return sites[website];
  }

  return sites['london-marathon'];
};

export const HeadingWrap = styled.div`
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  > p {
    font-size: 16px;
  }
`;

export const ContentWrap = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${breakpoints.tabletLandscape} {
    position: relative;
    width: 100%;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    ${props => props.theme.setFont('articleTitleLarge')}
    margin-bottom: 0;
    color: ${({ theme, gradient }) => CM(theme, gradient).titleColor};

    ${breakpoints.tabletLandscape} {
      font-size: 52px;
      line-height: 57px;
      max-width: ${({ theme: { website } }) => (website === 'lm-foundation' ? '60%' : 'unset')};
    }

    ${breakpoints.desktopSmall} {
      max-width: ${({ theme: { website } }) => (website === 'lm-foundation' ? '65%' : 'unset')};
    }
  }
`;

export const ReadTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  color: ${({ theme, gradient }) => CM(theme, gradient).readTimeColor};
  line-height: 50px;
  text-align: center;
  background: ${props => (props.hasBgImg ? 'rgba(0, 0, 0, 0.47)' : 'transparent')};
  border: ${({ theme, gradient }) => `1px solid ${CM(theme, gradient).borderColor}`};
  border-radius: 50%;
`;

export const EventWrap = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.px(17)};

  ${breakpoints.tabletLandscape} {
    position: absolute;
    right: 0px;
    bottom: ${props => handleBottomPositioningPerSite(props.theme)};
    margin-bottom: 0;
  }

  p {
    margin-right: ${props => props.theme.px(8)};

    ${breakpoints.tabletLandscape} {
      margin-right: 0;
    }
  }

  > span {
    display: block;
    margin-right: ${props => props.theme.px(20)};
  }
`;

export const ReadTimeNumber = styled.span`
  ${props => props.theme.setFont('articleReadTimeNumber')}
  display: block;
  margin-bottom: 3px;
  color: ${({ theme, gradient }) => CM(theme, gradient).readTimeNumberColor};
`;

export const ReadTimeText = styled.p`
  ${props => props.theme.setFont('articleReadTimeText')}
  margin-bottom: 0;
`;

export const EventDateWrapper = styled.span`
  ${props => props.theme.setFont('articleEventDate')}
  display: block;
  color: ${({ theme, gradient }) => CM(theme, gradient).eventColor};
`;

const patternLayout = name => {
  const layout = {
    'twenty-six-degree-mark': {
      desktop: css`
        top: 30%;
        right: 0;
        height: 60%;
        transform: translateY(-50%);
      `,
      tabletLandscape: css`
        top: 30%;
        right: 0;
        height: 60%;
        transform: translateY(-50%);
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 50%;
      `,
    },
    'the-medal-alt': {
      desktop: css`
        top: 0%;
        right: 0;
        height: 65%;
      `,
      tabletLandscape: css`
        top: 0%;
        right: 0;
        height: 65%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 55%;
      `,
    },
    'the-steps': {
      desktop: css`
        top: 0;
        right: 0;
        height: 65%;
        transform: rotate(180deg);
      `,
      tabletLandscape: css`
        top: 0;
        right: 0;
        height: 65%;
        transform: rotate(180deg);
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 50%;
        transform: rotate(180deg);
      `,
    },
    'tower-bridge': {
      desktop: css`
        top: 0;
        right: 0;
        height: 55%;
      `,
      tabletLandscape: css`
        top: 0;
        right: 0;
        height: 55%;
      `,
      mobile: css`
        top: 0;
        right: 0;
        height: 50%;
      `,
    },
    'scattered-circles': {
      desktop: css`
        top: 1%;
        right: 0px;
        height: 65%;
      `,
      tabletLandscape: css`
        top: 1%;
        right: 0px;
        height: 65%;
      `,
      mobile: css`
        top: 0%;
        right: 0px;
        height: 50%;
        transform: rotate(180deg);
      `,
    },
    'diagonal-chevrons': {
      desktop: css`
        top: 0;
        right: 0;
        height: 50%;
      `,
      tabletLandscape: css`
        top: 0;
        right: 0;
        height: 50%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 40%;
      `,
    },
    'film-reel': {
      desktop: css`
        top: 0;
        right: 0;
        height: 60%;
      `,
      tabletLandscape: css`
        top: 0;
        right: 0;
        height: 60%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 50%;
      `,
    },
    'multiple-circles': {
      desktop: css`
        top: 0;
        right: 0;
        height: 60%;
      `,
      tabletLandscape: css`
        top: 0;
        right: 0;
        height: 60%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 40%;
      `,
    },
    'parallel-lines': {
      desktop: css`
        top: 20%;
        right: 0;
        height: 80%;
        transform: rotate(180deg);
      `,
      tabletLandscape: css`
        top: 20%;
        right: 0;
        height: 80%;
        transform: rotate(180deg);
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 40%;
        transform: rotate(180deg);
      `,
    },
    peak: {
      desktop: css`
        top: 0;
        right: 0;
        height: 65%;
      `,
      tabletLandscape: css`
        top: 0;
        right: 0;
        height: 65%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 60%;
      `,
    },
    waves: {
      desktop: css`
        top: 15%;
        right: 0;
        height: 55%;
      `,
      tabletLandscape: css`
        top: 15%;
        right: 0;
        height: 55%;
      `,
      mobile: css`
        top: 15%;
        right: 0%;
        height: 40%;
      `,
    },
    'circle-outlines': {
      desktop: css`
        top: 0;
        right: 0;
        height: 66%;
      `,
      tabletLandscape: css`
        top: 0;
        right: 0;
        height: 66%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 60%;
      `,
    },
    'triangle-outlines': {
      desktop: css`
        top: 0;
        right: 0;
        height: 65%;
      `,
      tabletLandscape: css`
        top: 0;
        right: 0;
        height: 65%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 60%;
      `,
    },
    power: {
      desktop: css`
        top: 0;
        right: 0;
        height: 65%;
      `,
      tabletLandscape: css`
        top: 0;
        right: 0;
        height: 65%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 55%;
      `,
    },
    unity: {
      desktop: css`
        top: 0;
        right: 0;
        height: 65%;
      `,
      tabletLandscape: css`
        top: 0;
        right: 0;
        height: 65%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 55%;
      `,
    },
    'shape-of-water-01': {
      desktop: css`
        top: 0%;
        right: 0%;
        height: 60%;
      `,
      tabletLandscape: css`
        top: 0%;
        right: 0%;
        height: 60%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 50%;
      `,
    },
    'shape-of-water-02': {
      desktop: css`
        top: 0%;
        right: 0%;
        height: 60%;
      `,
      tabletLandscape: css`
        top: 0%;
        right: 0%;
        height: 60%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 50%;
      `,
    },
    'shape-of-water-03': {
      desktop: css`
        top: 0%;
        right: 0%;
        height: 60%;
      `,
      tabletLandscape: css`
        top: 0%;
        right: 0%;
        height: 60%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 55%;
      `,
    },
    'shape-of-water-04': {
      desktop: css`
        top: 0%;
        right: 0%;
        height: 60%;
      `,
      tabletLandscape: css`
        top: 0%;
        right: 0%;
        height: 60%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 50%;
      `,
    },
    frame: {
      desktop: css`
        top: -3%;
        right: -8%;
        height: 106%;
      `,
      tabletLandscape: css`
        top: -3%;
        right: -8%;
        height: 106%;
      `,
      mobile: css`
        top: -2%;
        right: -50px;
        height: 60%;
      `,
    },
    handlebars: {
      desktop: css`
        top: -3%;
        right: 0;
        height: 108%;
      `,
      tabletLandscape: css`
        top: -3%;
        right: 0;
        height: 108%;
      `,
      mobile: css`
        top: -2%;
        right: 0%;
        height: 50%;
      `,
    },
    wheels: {
      desktop: css`
        top: -2%;
        right: -30px;
        height: 107%;
      `,
      tabletLandscape: css`
        top: -2%;
        right: -30px;
        height: 107%;
      `,
      mobile: css`
        top: -1%;
        right: -10px;
        height: 55%;
      `,
    },
    'landscape-outlined': {
      desktop: css`
        top: -1%;
        right: -0.5%;
        height: 103%;
      `,
      tabletLandscape: css`
        top: -1%;
        right: -0.5%;
        height: 103%;
      `,
      mobile: css`
        top: -1%;
        right: -4px;
        height: 60%;
      `,
    },
    'on-your-marks': {
      desktop: css`
        top: 15%;
        right: 5%;
        height: 70%;
      `,
      tabletLandscape: css`
        top: 5%;
        right: 5%;
        height: 55%;
      `,
      mobile: css`
        top: 7.5%;
        right: 7.5%;
        height: 50%;
      `,
    },
    'on-your-marks-alt': {
      desktop: css`
        top: 15%;
        right: 5%;
        height: 70%;
      `,
      tabletLandscape: css`
        top: 5%;
        right: 5%;
        height: 55%;
      `,
      mobile: css`
        top: 7.5%;
        right: 7.5%;
        height: 50%;
      `,
    },
    'get-set': {
      desktop: css`
        top: 7.5%;
        right: 10%;
        height: 80%;
      `,
      tabletLandscape: css`
        top: 7.5%;
        right: 5%;
        height: 55%;
      `,
      mobile: css`
        top: 5%;
        right: 5%;
        height: 50%;
      `,
    },
    go: {
      desktop: css`
        top: -2%;
        right: 10%;
        height: 105%;
      `,
      tabletLandscape: css`
        top: -2%;
        right: 10%;
        height: 105%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 55%;
      `,
    },
    'transformation-full': {
      desktop: css`
        top: -10%;
        right: 0%;
        height: 125%;
      `,
      tabletLandscape: css`
        top: -10%;
        right: 2%;
        height: 125%;
      `,
      mobile: css`
        top: 0%;
        right: 0%;
        height: 70%;
      `,
    },
  };
  if (name in layout) return layout[name];

  return 'twenty-six-degree-mark';
};

export const Pattern = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: ${props => (props.pageEditing ? '50%' : '100%')};
  height: 100%;
  margin-left: ${props => (props.pageEditing ? '50%' : 'unset')};

  svg {
    position: absolute;
    ${props => patternLayout(props.patternName).mobile}
    fill: ${props => props.theme.colors.named[props.patternColor]};

    path,
    g {
      fill: ${props => props.theme.colors.named[props.patternColor]};
    }

    ${breakpoints.tabletLandscape} {
      ${props => patternLayout(props.patternName).tabletLandscape}
    }

    ${breakpoints.desktopSmall} {
      ${props => patternLayout(props.patternName).desktop}
    }
  }
`;

export const TextContrastGradient = styled.div`
  position: absolute;
  bottom: 0px;
  left: 0;
  z-index: ${props => (props.pageEditing ? 0 : 1)};
  width: 100%;
  height: ${props => (props.pageEditing ? '0' : '80%')};
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0) 100%);
  pointer-events: none;
`;
