import React from 'react';
import { getIcon } from '../../utils/icon';

export const CTAHTML = (icon, label, socialIcon) => {
  const WestminsterMileHTML = CTAType => {
    if (CTAType.toLowerCase() === 'c') {
      return socialIcon ? getIcon(socialIcon.toLowerCase()) : null;
    }

    return (
      <>
        {getIcon(icon)}
        <span>{label}</span>
      </>
    );
  };

  return WestminsterMileHTML;
};
