import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../../../tag';
import Picture from '../../../picture';
import { getPattern } from '../../../utils/pattern';
import { format } from 'date-fns';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import {
  HeadingWrap,
  ContentWrap,
  ReadTimeWrapper,
  EventWrap,
  ReadTimeNumber,
  ReadTimeText,
  EventDateWrapper,
  Pattern,
  TextContrastGradient,
} from './VariantThree.styles.js';
import { useIsMobile } from '../../../hooks/useIsMobile';

const VariantThree = props => {
  const isMobile = useIsMobile('tabletPortrait');
  const {
    fields: {
      title = {},
      gradient = {},
      publicationDate: { value: publicationDate = null } = {},
      readingTime: { value: readingTime = null } = {},
      tag: { fields: tag = {} } = {},
      heroImage = {},
    } = {},
    params: { pattern, colour: color = 'golden' },
    sitecoreContext: { pageEditing = false } = {},
    translate = {},
    headingLevel = 3,
    theme: { website },
  } = props;

  const patternName = () => {
    if (pattern === 'the-medal') return 'the-medal-alt';
    if (pattern === 'transformation') return 'transformation-full';

    return pattern;
  };

  const t = key => {
    if (translate && typeof translate === 'function') {
      return translate(`Article_${key}`);
    }
    return key;
  };

  const minText = t('Min');
  const minsText = t('Mins');
  const readingTimeText = parseInt(readingTime, 10) === 1 ? minText : minsText;
  const readingTimeVisibleCondition = website !== 'lm-foundation';

  const hasBgImg = heroImage?.value && Object.keys(heroImage.value).length > 0;

  const showGradient = pageEditing || !hasBgImg ? gradient?.value : null;

  return (
    <>
      {hasBgImg && <Picture field={heroImage} />}
      {hasBgImg && <TextContrastGradient pageEditing={pageEditing} />}
      {pattern && (
        <Pattern patternColor={color} patternName={patternName()} pageEditing={pageEditing}>
          {getPattern(patternName())}
        </Pattern>
      )}
      <HeadingWrap gradient={showGradient}>
        <Tag
          textColour={tag.textColour?.value}
          backgroundColour={tag.colour?.value}
          label={tag.title?.value}
          isPresentationalOnly
        />
      </HeadingWrap>
      <ContentWrap gradient={showGradient}>
        <EventWrap>
          {!isMobile && (
            <>
              {publicationDate && (
                <EventDateWrapper className="article-card-date" gradient={showGradient}>
                  {format(Date.parse(publicationDate), 'dd-MM-yyyy')}
                </EventDateWrapper>
              )}

              {readingTime && readingTimeVisibleCondition && (
                <ReadTime
                  time={readingTime}
                  readingTimeText={readingTimeText}
                  hasBgImg={hasBgImg}
                  gradient={showGradient}
                />
              )}
            </>
          )}
        </EventWrap>
        {title && <Text className="title" field={title} tag={`h${headingLevel}`} />}
      </ContentWrap>
    </>
  );
};

const ReadTime = ({ time, readingTimeText, hasBgImg, gradient }) => (
  <ReadTimeWrapper className="read-time-mobile-hide" hasBgImg={hasBgImg} gradient={gradient}>
    {time && <ReadTimeNumber gradient={gradient}>{time}</ReadTimeNumber>}
    {readingTimeText && <ReadTimeText>{readingTimeText}</ReadTimeText>}
  </ReadTimeWrapper>
);

VariantThree.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.object,
    gradient: PropTypes.object,
    heroTitle: PropTypes.object,
    heroImage: PropTypes.object,
    publicationDate: PropTypes.object,
    readingTime: PropTypes.object,
    tag: PropTypes.object,
  }),
  params: PropTypes.object,
  sitecoreContext: PropTypes.object,
  translate: PropTypes.func,
  forceHover: PropTypes.bool,
  headingLevel: PropTypes.number,
  theme: PropTypes.shape({
    website: PropTypes.string,
  }),
};

ReadTime.propTypes = {
  time: PropTypes.number,
  readingTimeText: PropTypes.string,
  hasBgImg: PropTypes.bool,
};

export default VariantThree;
