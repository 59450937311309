import React from 'react';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import RelatedFaqsComponent from 'lmel-components/core/related-faqs';

const RelatedFaqs = props => {
  return (
    <ErrorBoundary>
      <RelatedFaqsComponent {...props} />
    </ErrorBoundary>
  );
};

export default RelatedFaqs;
