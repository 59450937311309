import styled, { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import { mapColourToSiteTheme as CM } from './colourMap.js';

const mapFooterSvgColorToSite = (theme) => {
  const { website } = theme;

  const sites = {
    'london-marathon': {
      mobile: {
        rect: theme.colors.primary[200],
        path: theme.colors.primary[100],
      },
      desktop: theme.colors.primary[100],
    },
    'the-big-half': {
      mobile: {
        rect: theme.colors.secondary[400],
        path: theme.colors.primary[100],
      },
      desktop: theme.colors.named['pink'],
    },
    'london-10k': {
      mobile: {
        rect: theme.colors.primary[100],
        path: theme.colors.primary[200],
      },
      desktop: theme.colors.named['pink'],
    },
    'swim-serpentine': {
      mobile: {
        rect: theme.colors.named['green'],
        path: theme.colors.named['turquoise'],
      },
      desktop: theme.colors.named['turquoise'],
    },
    'westminster-mile': {
      mobile: {
        rect: theme.colors.primary[200],
        path: theme.colors.primary[100],
      },
      desktop: theme.colors.named['pink'],
    },
    'ride-london': {
      mobile: {
        rect: theme.colors.named['cyan'],
        path: theme.colors.named['blue'],
      },
      desktop: theme.colors.named['cyan'],
    },
    'city-race': {
      mobile: {
        rect: theme.colors.named['green'],
        path: theme.colors.named['black'],
      },
      desktop: theme.colors.named['green'],
    },
    'lm-foundation': {
      mobile: {
        rect: theme.colors.grays[100],
        path: theme.colors.named['sea-green'],
      },
      desktop: theme.colors.named['sea-green'],
    },
  };
  if (website in sites) return sites[website];

  return sites['london-marathon'];
};

const LinkListStyles = css`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(1, 1fr);
  padding-top: ${(props) => props.theme.px(26)};

  ${breakpoints.tabletPortrait} {
    display: flex;
    flex: 0.75;
    flex-direction: column;
    margin-right: ${(props) => props.theme.px(13)};
    padding-top: 0;
    border-top: none;
  }

  ${breakpoints.tabletLandscape} {
    margin-right: ${(props) => props.theme.px(26)};
  }

  ${breakpoints.desktopLarge} {
    flex: 0.5;
  }
`;

export const FooterComponent = styled.footer`
  display: block;
  position: relative;
  padding: ${(props) => props.theme.px(26)};
  background-color: ${(props) => CM(props.theme).footerBackgroundColor};
  visibility: ${(props) => (props.isMounted ? 'visible' : 'hidden')};

  ${breakpoints.tabletPortrait} {
    padding: ${(props) => props.theme.px(26)} ${(props) => props.theme.px(39)};
  }

  ${breakpoints.tabletLandscape} {
    padding: ${(props) => props.theme.px(26)} ${(props) => props.theme.px(78)};
  }

  & * {
    height: 100%;
  }
`;

export const SponsorContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0 0 ${(props) => props.theme.px(13)} 0;
  padding: 0;

  ${breakpoints.tabletPortrait} {
    position: relative;
    justify-content: center;
  }
`;

const SplitterStyles = css`
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: ${(props) => CM(props.theme).footerSplitterColor};
  content: '';
`;

export const SponsorItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => `calc(33% - ${props.theme.px(13)})`};
  min-height: 100px;
  text-indent: initial;

  &:nth-child(3n) {
    margin-right: 0;
  }

  ${breakpoints.tabletPortrait} {
    flex: 1 0 ${(props) => `calc(20% - ${props.theme.px(13)})`};
    flex-grow: 0;
    margin-bottom: 0;

    ${breakpoints.desktopLarge} {
      min-height: 120px;
    }
  }

  &:before {
    content: none;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
`;

export const SectionWrap = styled.div`
  margin-bottom: ${(props) => props.theme.px(26)};
  ${(props) =>
    props.theme.website !== 'lm-foundation'
      ? `border-top: 1px solid ${(props) =>
          CM(props.theme).footerSectionBorderColor};`
      : `flex-wrap: wrap;`}
      
  ${breakpoints.tabletPortrait} {
    display: flex;
    justify-content: space-between;
    margin-top: ${(props) => props.theme.px(39)};
    border-top: none;
  }
`;

export const NoticeImage = styled.img`
  max-width: ${(props) => props.theme.px(200)};
  width: ${(props) => props.theme.px(200)};
  margin-bottom: ${(props) => props.theme.px(16)};
  margin-top: ${(props) => props.theme.px(26)};

  &:hover {
    filter: invert(95%) sepia(1%) saturate(380%) hue-rotate(147deg)
      brightness(311%) contrast(25%);
  }
`;

export const Image = styled.img`
  width: 100%;
  max-width: 120px;
  height: 50px;
  object-fit: contain;

  &:hover {
    filter: invert(95%) sepia(1%) saturate(380%) hue-rotate(147deg)
      brightness(311%) contrast(25%);
  }

  ${breakpoints.tabletPortrait} {
    max-width: 80px;
  }

  ${breakpoints.tabletLandscape} {
    max-width: 100px;
  }

  ${breakpoints.desktopSmall} {
    max-width: 120px;
  }

  ${breakpoints.desktopLarge} {
    max-width: 150px;
  }
`;

export const LinkListWrap = styled.div`
  ${LinkListStyles}

  > *:first-child {
    color: ${(props) => CM(props.theme).linklistTitleColor};
  }

  > *:not(:first-child) {
    color: ${(props) => CM(props.theme).linklistItemColor};
  }
`;

const LinkListTitleStyles = css`
  margin: 0;
  font-weight: bold;
  ${(props) => props.theme.setFont('footerLinks')}
  ${(props) =>
    props.theme.website == 'lm-foundation' ? 'text-transform: uppercase;' : ''}
`;

const LinkUnderLineStyles = css`
  text-decoration: underline;
  text-decoration-skip-ink: none;
  ${(props) =>
    props.theme.website !== 'lm-foundation'
      ? 'text-decoration-style: dotted;'
      : ''}
`;

export const LinkListTitle = styled.p`
  ${LinkListTitleStyles}
`;

export const LinkListTitleLink = styled(Link)`
  ${LinkListTitleStyles}
  text-decoration: none;

  &:hover {
    ${LinkUnderLineStyles}
  }
`;

export const LinkListItem = styled(Link)`
  text-decoration: none;
  ${(props) => props.theme.setFont('footerLinks')}

  &:hover {
    ${LinkUnderLineStyles}
  }
`;

export const SocialMediaWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  ${breakpoints.tabletPortrait} {
    display: grid;
    grid-template-columns: ${(props) =>
      props.shouldStack ? '1fr' : 'repeat(2, 1fr)'};
    margin-top: -10px;
    gap: ${(props) => props.theme.px(13)};
  }

  a {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;

    &:not(:last-child) {
      margin-right: ${(props) => props.theme.px(10)};

      ${breakpoints.tabletPortrait} {
        margin-right: unset;
      }
    }

    ${({ theme }) =>
      theme.website == 'lm-foundation'
        ? css`
            background-color: ${theme.colors.secondary[600]};
            height: 50px;
            width: 50px;
            border-radius: 100%;
            transition: all 0.12s;
            &:hover {
              background-color: ${theme.colors.secondary[700]};
            }

            svg {
              display: block;
              height: 100%;
              width: 100%;
              position: relative;
              left: 2px;

              > :first-child {
                path {
                  fill: none;
                }
              }
            }
          `
        : css`
            svg {
              display: block;
              height: 100%;
              width: 100%;

              ${breakpoints.mobile} {
                width: 70px;
              }

              g {
                path:not(:first-child) {
                  fill: ${(props) => CM(props.theme)?.footerSvgBorderColor};
                }
              }

              &:hover {
                path {
                  fill: ${(props) => CM(props.theme).footerSvgColorA};
                }

                g {
                  fill: ${(props) => CM(props.theme).footerSvgColorB};

                  path {
                    fill: ${(props) => CM(props.theme).footerSvgColorC};
                  }

                  &:not(:first-child) {
                    fill: ${(props) => CM(props.theme).footerSvgColorD};

                    path {
                      fill: ${(props) => CM(props.theme).footerSvgColorE};
                    }
                  }
                }
              }

              &:first-child {
                transform: scale(1);
              }

              &:last-child {
                position: relative;
              }
            }
          `}
  }
`;

export const LegalLinksWrap = styled.div`
  ${LinkListStyles}
  border-top: none;

  ${breakpoints.tabletPortrait} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const LegalLinksInner = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: repeat(1, 1fr);

  ${breakpoints.tabletPortrait} {
    display: flex;
    flex: 2;
    flex-wrap: wrap;
    justify-content: flex-end;
    order: 2;
  }

  a {
    color: ${(props) => CM(props.theme).legalLinks};
    ${breakpoints.tabletPortrait} {
      &:not(:first-child) {
        margin-left: ${(props) => props.theme.px(26)};
      }
    }
  }
`;

export const LegalLink = styled(Link)`
  text-decoration: none;
  ${(props) => props.theme.setFont('footerLegalLinks')}

  &:hover {
    ${LinkUnderLineStyles}
  }
`;

export const CopyrightText = styled.div`
  margin: 0;

  & * {
    color: ${(props) => CM(props.theme).footerCopyrightColor};
    ${(props) => props.theme.setFont('footerLegalLinks')}
  }

  ${breakpoints.tabletPortrait} {
    flex: 1;
    order: 1;
  }
`;

export const PatternContainer = styled.div`
  display: flex;
  height: 10px;
  overflow: hidden;

  svg {
    height: 100%;
    position: relative;
    display: none;
    + svg {
      display: block;

      path {
        fill: ${(props) => mapFooterSvgColorToSite(props.theme).mobile.path};
      }
      > g > path {
        fill: ${(props) => mapFooterSvgColorToSite(props.theme).mobile.rect};
      }
    }
  }

  ${breakpoints.desktopSmall} {
    height: 12px;
    margin-bottom: ${(props) => props.theme.px(26)};
    background-image: none;
    svg {
      display: block;

      path {
        fill: ${(props) => mapFooterSvgColorToSite(props.theme).desktop};
      }
      + svg {
        display: none;
      }
    }
  }

  ${breakpoints.desktopLarge} {
    height: 15px;
  }
`;

export const MobileTextBlock = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${(props) => props.theme.px(26)};
  margin-bottom: ${(props) => props.theme.px(26)};
  justify-content: center;

  ${breakpoints.desktopSmall} { 
    display: none;
  }
`;

export const TextBlock = styled.div`
  display: none;

  ${breakpoints.tabletLandscape} {
    display: flex;
    flex: 1.5;
    padding-right: ${(props) => props.theme.px(78)};
  }

  & * {
    margin-top: 0;
    ${(props) => props.theme.setFont('footerStatement')};
  }

  ${(props) =>
    props.theme.website === 'lm-foundation'
      ? css`
          display: none;
          width: 100%;
          flex-direction: column;
          
          & * {
            margin-top: 0;
            ${(props) => props.theme.setFont('footerStatement')}
          }

          ${breakpoints.desktopSmall} {
            display: flex;
            flex: 1.5;
            padding-right: ${(props) => props.theme.px(78)};
          }

          ${LinkListWrap} + & {
            ${LinkListStyles}
            padding-right: 0;

            ${TextBlockText} {
              ::first-line {
                text-transform: uppercase;
                font-weight: bold;
              }
              ${breakpoints.desktopSmall} {
                max-width: none;
              }

              a {
                text-decoration: none;
                :hover {
                  text-decoration: underline;
                }
              }
            }

            & * {
              ${(props) => props.theme.setFont('footerLinks')}
            }
          }
        `
      : css``}
`;

export const TextBlockText = styled.div`
  color: ${(props) => CM(props.theme).statementText};

  .noticeText {
    font-size: ${(props) => props.theme.px(18)};
    line-height: ${(props) => props.theme.px(26)};

    a {
      font-size: ${(props) => props.theme.px(18)};
      line-height: ${(props) => props.theme.px(26)};
    }
  }

  ${breakpoints.desktopSmall} {
    max-width: 75%;
  }

  a {
    color: ${(props) => CM(props.theme).statementText};
    ${(props) =>
      props.theme.website !== 'lm-foundation'
        ? `text-decoration-style: dotted;`
        : ``}
    text-decoration-skip-ink: none;
  }

  img {
    max-width: 200px;
    display: block;
  }
`;

export const SponsorSplitter = styled.hr`
  ${SplitterStyles}
  border: none;
  position: static;
  height: 0;

  ${breakpoints.tabletLandscape} {
    margin-top: 60px;
    height: 1px;
  }
`;

export const FooterRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.theme.px(16)};
  border-bottom: 1px solid ${(props) => CM(props.theme).footerSplitterColor};
`;
