import styled, { css } from 'styled-components';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const TagStyles = css`
  ${props => props.theme.setFont('tag')}
  display: inline-block;
  margin: 0 ${props => props.theme.space()} 0 0;
  padding: 8px 20px;
  color: ${props =>
    props.textColour ? props.theme.colors.named[props.textColour] : CM(props.theme).textColour};
  text-align: center;
  text-decoration: none;
  background-color: ${props =>
    props.backgroundColour
      ? props.theme.colors.named[props.backgroundColour]
      : CM(props.theme).backgroundColour};
  border-radius: ${props => (props.theme.website == 'lm-foundation' ? '10px' : '20px')};
  cursor: pointer;
`;

export const TagComponent = styled.a`
  ${TagStyles}
`;

export const TagComponentPresentational = styled.p`
  ${TagStyles}
  border: none;
  cursor: default;
`;
