export const mapColourToSiteTheme = theme => {
  const { website } = theme;

  const sites = {
    'london-marathon': {
      headingWrapSplittingBorder: theme.colors.grays[200],
      authorBorderColor: theme.colors.secondary[300],
      nameColor: theme.colors.primary[200],
      articleInfoColor: theme.colors.primary[200],
      bodyWrapSvgColorOne: theme.colors.primary[300],
      bodyWrapSvgColorTwo: theme.colors.primary[200],
      socialShareColor: theme.colors.primary[200],
      tagWrapColor: theme.colors.primary[200],
      tagWrapListItemColor: theme.colors.primary[200],
      tagWrapListItemBorderColor: theme.colors.grays[200],
    },
    'the-big-half': {
      headingWrapSplittingBorder: theme.colors.grays[300],
      authorBorderColor: theme.colors.secondary[300],
      nameColor: theme.colors.secondary[400],
      articleInfoColor: theme.colors.secondary[400],
      bodyWrapSvgColorOne: theme.colors.primary[400],
      bodyWrapSvgColorTwo: theme.colors.secondary[400],
      socialShareColor: theme.colors.secondary[400],
      tagWrapColor: theme.colors.secondary[400],
      tagWrapListItemColor: theme.colors.secondary[400],
      tagWrapListItemBorderColor: theme.colors.grays[300],
    },
    'london-10k': {
      headingWrapSplittingBorder: theme.colors.grays[400],
      authorBorderColor: theme.colors.primary[100],
      nameColor: theme.colors.primary[200],
      articleInfoColor: theme.colors.primary[200],
      bodyWrapSvgColorOne: theme.colors.primary[300],
      bodyWrapSvgColorTwo: theme.colors.primary[200],
      socialShareColor: theme.colors.primary[200],
      tagWrapColor: theme.colors.primary[200],
      tagWrapListItemColor: theme.colors.primary[200],
      tagWrapListItemBorderColor: theme.colors.grays[200],
    },
    'westminster-mile': {
      headingWrapSplittingBorder: theme.colors.grays[400],
      authorBorderColor: theme.colors.primary[100],
      nameColor: theme.colors.primary[200],
      articleInfoColor: theme.colors.primary[200],
      bodyWrapSvgColorOne: theme.colors.primary[300],
      bodyWrapSvgColorTwo: theme.colors.primary[200],
      socialShareColor: theme.colors.primary[200],
      tagWrapColor: theme.colors.primary[200],
      tagWrapListItemColor: theme.colors.primary[200],
      tagWrapListItemBorderColor: theme.colors.grays[200],
    },
    'swim-serpentine': {
      headingWrapSplittingBorder: theme.colors.grays[300],
      authorBorderColor: theme.colors.primary[100],
      nameColor: theme.colors.secondary[400],
      articleInfoColor: theme.colors.secondary[400],
      bodyWrapSvgColorOne: theme.colors.primary[400],
      bodyWrapSvgColorTwo: theme.colors.secondary[400],
      socialShareColor: theme.colors.secondary[400],
      tagWrapColor: theme.colors.secondary[400],
      tagWrapListItemColor: theme.colors.secondary[400],
      tagWrapListItemBorderColor: theme.colors.grays[300],
    },
    'ride-london': {
      headingWrapSplittingBorder: theme.colors.grays[300],
      authorBorderColor: theme.colors.secondary[100],
      nameColor: theme.colors.primary[300],
      articleInfoColor: theme.colors.primary[300],
      bodyWrapSvgColorOne: theme.colors.primary[400],
      bodyWrapSvgColorTwo: theme.colors.primary[300],
      socialShareColor: theme.colors.primary[300],
      tagWrapColor: theme.colors.primary[300],
      tagWrapListItemColor: theme.colors.primary[300],
      tagWrapListItemBorderColor: theme.colors.grays[300],
    },
    'city-race': {
      headingWrapSplittingBorder: theme.colors.grays[300],
      authorBorderColor: theme.colors.primary[200],
      nameColor: theme.colors.primary[300],
      articleInfoColor: theme.colors.primary[300],
      bodyWrapSvgColorOne: theme.colors.primary[400],
      bodyWrapSvgColorTwo: theme.colors.primary[300],
      socialShareColor: theme.colors.primary[300],
      tagWrapColor: theme.colors.primary[300],
      tagWrapListItemColor: theme.colors.primary[300],
      tagWrapListItemBorderColor: theme.colors.grays[300],
    },
  };
  if (website in sites) return sites[website];

  return sites['london-marathon'];
};
