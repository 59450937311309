import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { getIcon } from '../utils/icon';
import { ModalComponent, Backdrop, CloseButton } from './Modal.styles.js';

const Modal = props => {
  const { isOpen, visible, children } = props;

  const handleKeyDown = event => {
    if (event.keyCode === 27) {
      isOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <ModalComponent visible={visible} aria-modal="true">
      <Backdrop />
      <CloseButton onClick={() => isOpen(false)}>{getIcon('clear')}</CloseButton>
      {children}
    </ModalComponent>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.func,
  visible: PropTypes.bool,
  children: PropTypes.object,
};

export default Modal;
