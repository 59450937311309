import { colors } from './colors';

const createLinearGradient = (fromColor, toColor) =>
  `linear-gradient(0deg, ${fromColor} 0%, ${toColor} 100%)`;

export const gradients = {
  'white-to-pink': createLinearGradient(colors.primary[100], colors.primary[300]),
  pink: createLinearGradient(colors.primary[100], colors.primary[100]),
  black: createLinearGradient(colors.primary[200], colors.primary[200]),
  white: createLinearGradient(colors.primary[300], colors.primary[300]),
};
