import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import ArticleMetaComponent from 'lmel-components/core/article-meta';
import { withTranslation } from 'react-i18next';

const ArticleMeta = props => {
  return (
    <ErrorBoundary>
      <ArticleMetaComponent translate={props.t} {...props} />
    </ErrorBoundary>
  );
};

ArticleMeta.propTypes = {
  t: PropTypes.func,
};

export default withTranslation()(ArticleMeta);
