import React from 'react';
import QuoteBlockComponent from 'lmel-components/core/quote-block';
import ErrorBoundary from 'lmel-components/core/error-boundary';

const QuoteBlock = props => {
  return (
    <ErrorBoundary>
      <QuoteBlockComponent {...props} />
    </ErrorBoundary>
  );
};

export default QuoteBlock;
