import React from 'react';
import FeatureComponent from 'lmel-components/core/feature';
import ErrorBoundary from 'lmel-components/core/error-boundary';

const Feature = props => {
  return (
    <ErrorBoundary>
      <FeatureComponent {...props} pattern="circle-outlines" patternColor="pink" />
    </ErrorBoundary>
  );
};

export default Feature;
