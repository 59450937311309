import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const ArticleMeta = styled.div``;

export const Wrapper = styled.div`
  width: 100%;
`;

export const HeadingWrap = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.px(20)};
  padding-bottom: ${props => props.theme.px(20)};

  ${breakpoints.tabletLandscape} {
    flex-wrap: wrap;
    padding-bottom: ${props => props.theme.px(26)};
  }

  &:after {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 1px;
    background-color: ${props => CM(props.theme).headingWrapSplittingBorder};
    content: '';
  }
`;

export const AuthorWrap = styled.div`
  display: flex;
  align-items: center;

  ${breakpoints.tabletLandscape} {
    width: 100%;
  }
`;

export const Author = styled.div`
  position: relative;
  width: 36px;
  height: 33px;
  margin-right: ${props => props.theme.px(13)};
  margin-left: -2px;
  overflow: hidden;
  border: 2px solid;
  border-color: ${props => CM(props.theme).authorBorderColor};
  border-radius: 50%;

  ${breakpoints.tabletLandscape} {
    width: 40px;
    height: 40px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

export const Name = styled.p`
  ${props => props.theme.setFont('authorInfo')}
  margin: 0;
  color: ${props => CM(props.theme).nameColor};
  font-weight: bold;

  span {
    ${props => props.theme.setFont('authorInfo')}
    color: ${props => CM(props.theme).nameColor};
    font-weight: bold;

    ${breakpoints.tabletLandscape} {
      display: none;
    }
  }
`;

export const ArticleInfoWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${breakpoints.tabletLandscape} {
    display: block;
    width: unset;
  }
`;

export const ArticleInfo = styled.div`
  display: flex;

  ${breakpoints.tabletLandscape} {
    margin-top: -5px;
  }

  p {
    ${props => props.theme.setFont('authorInfo')}
    margin: 0;
    color: ${props => CM(props.theme).articleInfoColor};

    &:first-child {
      margin: 0 3px 0 0;
    }
  }

  span {
    ${props => props.theme.setFont('authorInfo')}
    color: ${props => CM(props.theme).articleInfoColor};
    font-weight: bold;
  }
`;

export const BodyWrap = styled.div`
  a {
    display: block;
    svg {
      display: block;
      width: 45px;

      g {
        path {
          fill: ${props => CM(props.theme).bodyWrapSvgColorOne};
        }

        &:not(:first-child) {
          path {
            fill: ${props => CM(props.theme).bodyWrapSvgColorTwo};
          }
        }
      }
      > path {
        fill: ${props => CM(props.theme).bodyWrapSvgColorTwo};
      }
    }
  }
`;

export const SocialShare = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${props => props.theme.px(18)};

  p {
    ${props => props.theme.setFont('authorInfo')}
    margin: 5px 0;
    color: ${props => CM(props.theme).socialShareColor};
    font-weight: bold;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-right: -10px;
    }
  }
`;

export const TagWrap = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: ${props => props.theme.px(18)};

  p {
    ${props => props.theme.setFont('authorInfo')}
    margin: ${props => props.theme.px(3)} 0 0;
    color: ${props => CM(props.theme).tagWrapColor};
    font-weight: bold;
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    margin: -5px 0 0;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: ${props => props.theme.px(10)};
      margin-left: ${props => props.theme.px(13)};

      p {
        ${props => props.theme.setFont('authorInfo')}
        margin: 0;
        color: ${props => CM(props.theme).tagWrapListItemColor};
        font-weight: normal;
        background-color: transparent;
        border: 1px solid ${props => CM(props.theme).tagWrapListItemBorderColor};
      }
    }
  }
`;
