import React from 'react';
import TextBlockComponent from 'lmel-components/core/text-block';
import ErrorBoundary from 'lmel-components/core/error-boundary';

const TextBlock = props => {
  return (
    <ErrorBoundary>
      <TextBlockComponent {...props} />
    </ErrorBoundary>
  );
};

export default TextBlock;
