import React, { useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import ArticleCard from '../article-card/ArticleCard';
import CTA from '../cta/CTA';
import MobileCarousel from '../mobile-carousel/MobileCarousel';
import { useIsMobile } from '../hooks/useIsMobile';
import { getIcon } from '../utils/icon';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import ArticleRowCarousel from './ArticleRowCarousel';
import {
  ArticleListRowComponent,
  ArticleContainer,
  InnerContainer,
  Title,
  TitleContainer,
  SwipeText,
  MobileCTAContainer,
} from './ArticleListRow.styles';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/react-hooks';
const FETCH_ARTICLES = loader('./FetchArticles.graphql');

const mapArticle = article => {
  // Can't get graphql to produce the same structure for tags...
  if (article.fields.tag && article.fields.tag.title) {
    article.fields.tag = {
      fields: {
        title: article.fields.tag.title,
        colour: article.fields.tag.colour,
        textColour: article.fields.tag.textColour,
      },
    };
  }

  return article;
};

const ArticleListRow = props => {
  const {
    fields: {
      title = {},
      cta = {},
      contentType: { value: contentType = '' } = {},
      tags = [],
      resultCount: { value: resultCount = '3' } = {},
      theme = { value: 'grey' },
    } = {},
    translate = {},
    setFilterFn = () => {},
    theme: { website },
  } = props;

  const t = key => {
    if (translate && typeof translate === 'function') {
      return translate(`Article_${key}`);
    }

    return key;
  };

  const swipeText = t('Swipe');

  const isMobile = useIsMobile('tabletLandscape', -1);
  const [articles, setArticles] = useState([]);
  const [fetchArticles, { loading, data }] = useLazyQuery(FETCH_ARTICLES, { fetchPolicy: 'no-cache' });

  useEffect(() => {
    fetchArticles({
      variables: {
        articleType: contentType,
        tags,
        resultCount: parseInt(resultCount, 10),
      },
    });
  }, []);

  useEffect(() => {
    if (!loading) {
      if (!data) {
        return;
      }

      const articleElements = data?.articles?.results?.items || [];

      const articleCards = articleElements.map((article, i) => {
        const mappedArticle = mapArticle(article);
        return (
          <ArticleCard
            variant="b"
            key={i}
            headingLevel={1}
            translate={translate}
            gtm={{ name: 'articleCard', data: ['cardTitle'] }}
            {...mappedArticle}
          />
        );
      });

      setArticles(articleCards);
    }
  }, [data]);

  const handleViewAll = () => {
    if (tags[0]) {
      setFilterFn(tags[0]);
    }
  };

  const mapMobileCtaVariant = () => {
    const sites = {
      'london-marathon': 'whiteE',
      'the-big-half': 'whiteE',
      'london-10k': 'blackA',
      'westminster-mile': 'blackA',
      'swim-serpentine': 'blackA',
      'ride-london': 'whiteB',
      'city-race': 'whiteA',
    };
    if (website in sites) return sites[website];
    return sites['london-marathon'];
  };

  const mapDesktopCtaVariant = () => {
    const sites = {
      'london-marathon': 'whiteA',
      'the-big-half': 'whiteA',
      'london-10k': 'whiteA',
      'westminster-mile': 'blackA',
      'swim-serpentine': 'whiteA',
      'ride-london': 'blackA',
      'city-race': 'blackA',
    };
    if (website in sites) return sites[website];
    return sites['london-marathon'];
  };

  return (
    <ArticleListRowComponent bgColor={theme?.value}>
      <InnerContainer>
        <TitleContainer indent={articles.length > 3}>
          <Title>
            <Text field={title} />
          </Title>
          {!isMobile && cta?.value && (
            <CTA
              type="b"
              variant={mapDesktopCtaVariant()}
              label={cta?.value}
              ariaLabel={cta?.value}
              onClick={handleViewAll}
              className="viewAll"
            />
          )}
        </TitleContainer>
        <ArticleContainer>
          {isMobile ? (
            <MobileCarousel>{articles}</MobileCarousel>
          ) : (
            <ArticleRowCarousel>{articles}</ArticleRowCarousel>
          )}
        </ArticleContainer>
        {isMobile && (
          <SwipeText>
            {getIcon('swipe')} <span>{swipeText}</span>
          </SwipeText>
        )}
        {isMobile && cta?.value && (
          <MobileCTAContainer>
            <CTA
              fullwidth={1}
              variant={mapMobileCtaVariant()}
              label={cta?.value}
              ariaLabel={cta?.value}
              onClick={handleViewAll}
            />
          </MobileCTAContainer>
        )}
      </InnerContainer>
    </ArticleListRowComponent>
  );
};

ArticleListRow.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  translate: PropTypes.func,
  setFilterFn: PropTypes.func,
  theme: PropTypes.object,
};

export default withTheme(ArticleListRow);
