import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  RestrictWidthContainer,
  ImageCarouselComponent,
  ImageContainer,
  ImageBottom,
  Controls,
} from './ImageCarousel.styles.js';
import Picture from '../picture';
import { getIcon } from '../utils/icon';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import { useSwipeable } from 'react-swipeable';
import { isServer } from '@sitecore-jss/sitecore-jss';

const ImageCarousel = props => {
  const {
    fields: { items = [] } = {},
    params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
  } = props;

  const [currentSlide, setCurrentSlide] = useState(0);

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  const gotoSlide = dir => {
    let nextSlide = currentSlide + dir;
    if (nextSlide > items.length - 1) {
      nextSlide = 0;
    } else if (nextSlide === -1) {
      nextSlide = items.length - 1;
    }
    dataLayerEvent('imageCarouselAction', { cardAction: dir < 0 ? 'left' : 'right' });
    setCurrentSlide(nextSlide);
  };

  const handleKeyUp = e => {
    switch (e.key) {
      case 'ArrowLeft':
        gotoSlide(-1);
        break;
      case 'ArrowRight':
        gotoSlide(1);
        break;
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => gotoSlide(1),
    onSwipedRight: () => gotoSlide(-1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <ImageCarouselComponent
      className="limitedWidth"
      tabIndex="0"
      onKeyUp={handleKeyUp}
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      <RestrictWidthContainer>
        <ImageContainer {...swipeHandlers}>
          <Picture field={items[currentSlide]?.fields?.image} />
        </ImageContainer>
        <ImageBottom>
          {items[currentSlide]?.fields?.description && (
            <div aria-live="polite" role="region">
              <Text field={items[currentSlide]?.fields?.description} tag="p" />
            </div>
          )}
          <Controls>
            <button aria-label="Previous image" onClick={() => gotoSlide(-1)}>
              {getIcon('chevronOutline')}
            </button>
            <p>{`${currentSlide + 1} of ${items.length}`}</p>
            <button aria-label="Next image" onClick={() => gotoSlide(1)}>
              {getIcon('chevronOutline')}
            </button>
          </Controls>
        </ImageBottom>
      </RestrictWidthContainer>
    </ImageCarouselComponent>
  );
};

ImageCarousel.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
};

export default ImageCarousel;
