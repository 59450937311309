import { isServer } from "@sitecore-jss/sitecore-jss";
import { Link, RichText, Text } from "@sitecore-jss/sitecore-jss-react";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as DesktopPattern } from "../../assets/patterns/footer-svg/desktop.svg";
import { ReactComponent as MobilePattern } from "../../assets/patterns/footer-svg/mobile.svg";
import CTA from "../cta";
import { useIsMobile } from "../hooks/useIsMobile";
import { useIsDesktop } from "../hooks/useIsDesktop";
import {
  CopyrightText,
  FooterComponent,
  FooterRow,
  Image,
  LegalLink,
  LegalLinksInner,
  LegalLinksWrap,
  LinkListItem,
  LinkListTitle,
  LinkListTitleLink,
  LinkListWrap,
  NoticeImage,
  PatternContainer,
  SectionWrap,
  SocialMediaWrap,
  SponsorItem,
  TextBlock,
  TextBlockText,
  MobileTextBlock,
} from "./Footer.styles.js";

const Footer = ({
  copyright = {},
  legalLinks = [],
  sections = [],
  sponsors = [],
  notice = {},
}) => {
  const [isMounted, setIsMounted] = useState(false);
  const [desktopRowGroups, setDesktopRowGroups] = useState([]);
  const [mobileRowGroups, setMobileRowGroups] = useState([]);
  const statementRef = useRef(null);
  const isMobile = useIsMobile("tabletPortrait", -1);
  const isDesktop = useIsDesktop();
  

  useEffect(() => {
    if (!isServer()) {
      setIsMounted(true);
    }
  }, []);

  useEffect(() => {
    if (!isServer()) {
      const mobileRows = [];
      const desktopRows = [];

      sponsors.forEach((sponsor) => {
        const { desktopRow } = sponsor;
        const { mobileRow } = sponsor;

        if (!desktopRows[desktopRow]) {
          desktopRows[desktopRow] = [];
        }

        desktopRows[desktopRow].push(sponsor);

        if (!mobileRows[mobileRow]) {
          mobileRows[mobileRow] = [];
        }

        mobileRows[mobileRow].push(sponsor);
      });

      setDesktopRowGroups(desktopRows);
      setMobileRowGroups(mobileRows);
    }
  }, [sponsors]);

  useEffect(() => {
    const statementAnchor = statementRef?.current?.querySelectorAll("a");

    if (statementAnchor && !isServer()) {
      [...statementAnchor].forEach((anchor) => {
        anchor.addEventListener("click", (e) => {
          window.dataLayer.push({
            event: "footerStatement",
            clickText: e.target.innerHTML,
            clickURL: e.target.getAttribute("href"),
          });
        });
      });
    }
  }, []);

  return (
    <FooterComponent isMounted={isMounted} id="footer">
      {isMounted && (
        <>
          {/* Desktop Footer Rows - Sponsors */}
          {!isMobile && desktopRowGroups.length
            ? desktopRowGroups.map((row, rowIndex) => (
                <FooterRow key={`desktop-row-group-${rowIndex}`}>
                  {row.map((sponsor, sponsorIndex) => (
                    <SponsorItem
                      key={sponsorIndex}
                      sponsorLength={row.length}
                      desktopSplit={100 / Math.ceil(row.length / 5)}
                    >
                      {sponsor?.link?.href && (
                        <Link
                          field={sponsor?.link}
                          rel="noreferrer"
                          onClick={() => {
                            if (!isServer()) {
                              window.dataLayer.push({
                                event: "footerSponsor",
                                clickURL: sponsor?.link?.href,
                              });
                            }
                          }}
                        >
                          <Image
                            src={sponsor?.logo?.value?.src}
                            alt={sponsor?.logo?.value?.alt}
                          />
                        </Link>
                      )}
                      {!sponsor?.link?.href && (
                        <Image
                          src={sponsor?.logo?.value?.src}
                          alt={sponsor?.logo?.value?.alt}
                        />
                      )}
                    </SponsorItem>
                  ))}
                </FooterRow>
              ))
            : null}
          {/* Mobile Rows - Sponsors */}
          {isMobile && mobileRowGroups.length
            ? mobileRowGroups.map((row, rowIndex) => (
                <FooterRow key={`mobile-row-group-${rowIndex}`}>
                  {row.map((sponsor, sponsorIndex) => (
                    <SponsorItem
                      key={sponsorIndex}
                      sponsorLength={row.length}
                      desktopSplit={100 / Math.ceil(row.length / 5)}
                    >
                      {sponsor?.link?.href && (
                        <Link
                          field={sponsor?.link}
                          rel="noreferrer"
                          onClick={() => {
                            if (!isServer()) {
                              window.dataLayer.push({
                                event: "footerSponsor",
                                clickURL: sponsor?.link?.href,
                              });
                            }
                          }}
                        >
                          <Image
                            src={sponsor?.logo?.value?.src}
                            alt={sponsor?.logo?.value?.alt}
                          />
                        </Link>
                      )}
                      {!sponsor?.link?.href && (
                        <Image
                          src={sponsor?.logo?.value?.src}
                          alt={sponsor?.logo?.value?.alt}
                        />
                      )}
                    </SponsorItem>
                  ))}
                </FooterRow>
              ))
            : null}

          {!isDesktop && notice && (
            <MobileTextBlock>
              <TextBlockText ref={statementRef}>
                <NoticeImage
                  src={notice?.image?.value?.src}
                  alt={notice?.image?.value?.alt}
                />
                <RichText
                  className="noticeText"
                  field={notice?.text}
                  editable={false}
                />
              </TextBlockText>
            </MobileTextBlock>
          )}

          {sections.length ? (
            <SectionWrap>
              {notice && isDesktop && (
                <TextBlock>
                  <TextBlockText ref={statementRef}>
                    <NoticeImage
                      src={notice?.image?.value?.src}
                      alt={notice?.image?.value?.alt}
                    />
                    <RichText
                      className="noticeText"
                      field={notice?.text}
                      editable={false}
                    />
                  </TextBlockText>
                </TextBlock>
              )}
              {sections.map(
                ({ type, title, links, items, text, link }, sectionIndex) => (
                  <React.Fragment key={sectionIndex}>
                    {type === "TextBlock" ? (
                      <TextBlock>
                        <TextBlockText ref={statementRef}>
                          <RichText field={text} editable={false} />
                        </TextBlockText>
                      </TextBlock>
                    ) : null}
                    {type === "LinkList" ? (
                      <LinkListWrap>
                        {link ? (
                          <LinkListTitleLink
                            field={link}
                            onClick={() => {
                              if (!isServer()) {
                                window.dataLayer.push({
                                  event: "footerNav",
                                  clickText: link.text,
                                  clickURL: link.href,
                                });
                              }
                            }}
                          >
                            <Text field={title} editable={false} />
                          </LinkListTitleLink>
                        ) : (
                          <LinkListTitle>
                            <Text field={title} editable={false} />
                          </LinkListTitle>
                        )}

                        {!!links.length &&
                          links.map((link, linkIndex) => (
                            <LinkListItem
                              key={linkIndex}
                              field={link}
                              onClick={() => {
                                if (!isServer()) {
                                  window.dataLayer.push({
                                    event: "footerNav",
                                    clickText: link.text,
                                    clickURL: link.href,
                                  });
                                }
                              }}
                            >
                              {text}
                            </LinkListItem>
                          ))}
                      </LinkListWrap>
                    ) : null}
                    {type === "SocialMediaLinks" && items.length ? (
                      <SocialMediaWrap shouldStack={items?.length < 4}>
                        {items.map(
                          ({ link, icon }, socialMediaIndex) =>
                            link && (
                              <CTA
                                key={socialMediaIndex}
                                type="c"
                                variant="socialIcon"
                                socialIcon={icon?.value}
                                link={link}
                                target={link.target}
                                ariaLabel={link?.text}
                                onClick={() => {
                                  if (!isServer()) {
                                    window.dataLayer.push({
                                      event: "socialVisit",
                                      clickText: link?.text,
                                      clickURL: link.href,
                                    });
                                  }
                                }}
                              />
                            )
                        )}
                      </SocialMediaWrap>
                    ) : null}
                  </React.Fragment>
                )
              )}
            </SectionWrap>
          ) : null}

          <PatternContainer className="pattern-container">
            <DesktopPattern />
            <MobilePattern />
          </PatternContainer>

          {legalLinks.length ? (
            <LegalLinksWrap>
              <LegalLinksInner>
                {legalLinks.map((link, legalLinksIndex) => (
                  <LegalLink
                    key={legalLinksIndex}
                    field={link}
                    onClick={() => {
                      if (!isServer()) {
                        window.dataLayer.push({
                          event: "footerNav",
                          clickText: link.text,
                          clickURL: link.href,
                        });
                      }
                    }}
                  >
                    {link.text}
                  </LegalLink>
                ))}
              </LegalLinksInner>
              {copyright ? (
                <CopyrightText>
                  <Text field={copyright} encode={false} editable={false} />
                </CopyrightText>
              ) : null}
            </LegalLinksWrap>
          ) : null}
        </>
      )}
    </FooterComponent>
  );
};

Footer.propTypes = {
  copyright: PropTypes.object,
  legalLinks: PropTypes.array,
  sections: PropTypes.array,
  sponsors: PropTypes.array,
  notice: PropTypes.object,
};

export default Footer;
