import { createGlobalStyle } from 'styled-components';
import normalize from './@normalize';
import { breakpoints } from './breakpoints';
import { CookieStyles } from './CookieStyles';
import { mapColourToSiteTheme as CM } from './colourMap.js';

const GlobalStyles = createGlobalStyle`
  ${normalize}

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @media (prefers-reduced-motion) {
      scroll-behavior: auto;
    }
  }
  
  *, *:before, *:after {
    box-sizing: inherit;
  }

  /*
    Focus styling if mouse/touch interaction occurs
  */

  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }

  /*
    Standard focus styling (keyboard & other assistive tech)
  */
  .js-focus-visible .focus-visible, *:focus-visible {
    /* globalFocus */
    outline: 2px solid ${props => CM(props.theme).globalFocus};
  }

  .visually-hidden:not(:focus):not(:active) {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    white-space: nowrap;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
  }

  .skipLink {
    position: absolute;
    left: -999999px;
    z-index: 999999;
    opacity: 0.0001;

    &.focus-visible {
      top: 0px;
      left: 0px;
      padding: 20px;
      background-color: white;
      opacity: 1;    
    }
  }

  [inert] {
    cursor: default;
    pointer-events: none;
  }

  [inert], [inert] * {
    user-select: none;
  }

  body {
    /* globalBodyColor */
    color: ${props => CM(props.theme).globalBodyColor};
    ${props => props.theme.setFont('body')}
    font-weight: 300;
    word-wrap: break-word;

    &.body-visible {
      overflow: auto;
    }
    
    &.body-hidden {
      overflow: hidden;
    }
  }

  #header-overlay {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    display: none;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0,0.7);
    pointer-events: none;

    &.visible {
      display: block;
    }
  }

  #root {

    & > main:not(.sc-edit-mode),
    & > main.sc-edit-mode > div,
    & > header {
      display: grid;
      grid-column-gap: ${props => props.theme.px(10)};
      grid-template-columns: repeat(4, 1fr);
      width: 100%;
      
      ${breakpoints.desktopSmall} {
        grid-column-gap: ${props => props.theme.px(16)};
        grid-template-columns: repeat(12, 1fr);
      }
    }

    & > main:not(.sc-edit-mode),
    & > main.sc-edit-mode > div {
      padding: 0;

      > * {
        grid-column: 1 / -1;
      }
    }
  }

  p {
    font-weight: 300;
  }

  strong {
    font-weight: 700;
  }

  h1 {
    ${props => props.theme.setFont('h2')}
    margin: 0 0 ${props => props.theme.space(2)} 0;
    color: ${props => CM(props.theme).globalH1};
  }

  h2 {
    ${props => props.theme.setFont('h3')}
    margin: 0 0 ${props => props.theme.space(2)} 0;
    color: ${props => CM(props.theme).globalH2};
  }

  h3 {
    ${props => props.theme.setFont('h4')}
    margin: 0 0 ${props => props.theme.space()} 0;
    color: ${props => CM(props.theme).globalH3};
  }

  h4 {
    ${props => props.theme.setFont('h5')}
    margin: 0 0 ${props => props.theme.space()} 0;
    color: ${props => CM(props.theme).globalH4};
  }

  h5 {
    ${props => props.theme.setFont('h6')}
    margin: 0 0 ${props => props.theme.space()} 0;
    color: ${props => CM(props.theme).globalH5};
  }

  h6 {
    ${props => props.theme.setFont('h7')}
    margin: 0 0 ${props => props.theme.space()} 0;
    color: ${props => CM(props.theme).globalH6};
  }

  .fakeAnchor {
    position: relative;
    top: -52px;
    display: block;
    width: 100%;
    height: 1px;
    margin: 0 !important;
    padding: 0 !important;
    visibility: hidden;
  }

  .freeText {
    overflow: hidden;

    & > * {
      margin: 0 0 ${props => props.theme.space()} 0;
    }

    a {
      &:link, &:visited, &:active, &:hover {
        color: ${props => CM(props.theme).globalFreeTextAnchor};
        text-decoration: underline;
      }
    }

    img {
      width: 100%;
      height: auto;
    }

    blockquote {
      margin: 0 0 ${props => props.theme.space()} 0;
      padding-left: ${props => props.theme.px(29)};
      border-left: 4px solid ${props => CM(props.theme).globalBlockquoteBorder};

      > span {
        display: block;
        margin: 0;
        color: ${props => CM(props.theme).globalBlockquoteSpanColor};

        &:nth-child(1) {
          ${props => props.theme.setFont('quote')}
          margin-bottom: ${props => props.theme.px(20)};
          font-weight: 700;
        }

        &:nth-child(2) {
          ${props => props.theme.setFont('bodyExtraSmall')}
          font-weight: 700;
        }

        &:nth-child(3) {
          ${props => props.theme.setFont('bodyExtraSmall')}
        }
      }     
    }

    ul {
      margin: 0 0 0 ${props => props.theme.px(17)};
      padding: 0;
      list-style-type: none;
    
      li {
        text-indent: ${props => props.theme.px(-17)};
        &:before {
          position: relative;
          top: ${props => props.theme.px(-1)};
          display: inline-block;
          width: ${props => props.theme.px(props.theme.website === 'lm-foundation' ? 11 : 8)};
          height: ${props => props.theme.px(props.theme.website === 'lm-foundation' ? 11 : 8)};
          margin-right: ${props => props.theme.px(10)};
          background-color: ${({ theme }) => CM(theme).globalLIBackground};
          border: ${props => props.theme.px(2)} solid ${props => CM(props.theme).globalLIBackground};
          border-radius: 50%;
          content: '';
        }

        > ul {
          margin: 0 0 0 ${props => props.theme.px(17)};
          padding: 0;
          list-style-type: none;

          li {
            text-indent: ${props => props.theme.px(-17)};
            &:before {
              position: relative;
              top: ${props => props.theme.px(-2)};
              display: inline-block;
              width: ${props => props.theme.px(props.theme.website == 'lm-foundation' ? 11 : 8)};
              height: ${props => props.theme.px(props.theme.website == 'lm-foundation' ? 11 : 8)};
              margin-right: ${props => props.theme.px(10)};
              background-color: ${({ theme }) => CM(theme).globalLIIndentedBackground};
              border: ${props => props.theme.px(2)} solid ${props =>
  CM(props.theme).globalLIIndentedBackground};
              border-radius: 0%;
              content: '';
            }
          }
        }
      }
    }

    ol {
      margin: 0;
      padding: 0;
      counter-reset: item;

      ${breakpoints.tabletPortrait} { 
        padding: ${props => `0 0 0 ${props.theme.px(35)}`};
      }

      > li {
        display: table;
        counter-increment: item;

        &:before {
          display: table-cell;
          padding-right: 0.6em;
          content: counters(item, ".") ". ";
        }
      }

      li ol > li {
        margin: 0;

        &:before {
          content: counters(item, ".") " ";
        }
      }

      table {
        table-layout: fixed;

        ${breakpoints.desktopSmall} {  
          table-layout: unset;
        }

        tbody {
          display:block;
          width: 100%;
          overflow: auto;
        }

        thead tr  {
          display: block;
        }
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        display: inline;

        &:before {
          content: none;
        }
      }
    }

    h1 {
      ${props => props.theme.setFont('h3')}
    }

    h2 {
      ${props => props.theme.setFont('h4')}
    }

    h3 {
      ${props => props.theme.setFont('textBlockHeading')}
    }

    h4 {
      ${props => props.theme.setFont('h6')}

      ${breakpoints.tabletPortrait} {
        font-family: ${props => props.theme.typography.typefaces.headings} !important;
      }

      ${breakpoints.desktopSmall} {
        font-family: ${props => props.theme.typography.typefaces.headings} !important;
      }

      ${breakpoints.desktopLarge} {
        font-family: ${props => props.theme.typography.typefaces.headings} !important;
      }
    }

    h5 {
      ${props => props.theme.setFont('h7')}

      ${breakpoints.tabletPortrait} {
        font-family: ${props => props.theme.typography.typefaces.headings} !important;
      }

      ${breakpoints.desktopSmall} {
        font-family: ${props => props.theme.typography.typefaces.headings} !important;
      }

      ${breakpoints.desktopLarge} {
        font-family: ${props => props.theme.typography.typefaces.headings} !important;
      }
    }

    h6 {
      ${props => props.theme.setFont('h8')}

      ${breakpoints.tabletPortrait} {
        font-family: ${props => props.theme.typography.typefaces.headings} !important;
      }

      ${breakpoints.desktopSmall} {
        font-family: ${props => props.theme.typography.typefaces.headings} !important;
      }

      ${breakpoints.desktopLarge} {
        font-family: ${props => props.theme.typography.typefaces.headings} !important;
      }
    }

    table {
      width: 100%;
      border: 1px solid black;
      border-collapse: collapse;

      td {
        padding: ${props => props.theme.space(0.5)};
        border: 1px solid black;
      }
    }
  }

  .limitedWidth {
    justify-self: center;
    max-width: 1526px;
  }

  .errorBoundary {
    color: red;
  }

  .pointer-events-disabled {
    pointer-events: none;
  }

  ${CookieStyles}
`;

export default GlobalStyles;
