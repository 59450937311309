export const mapColourToSiteTheme = (theme, gradient) => {
  const { website } = theme;

  const sites = {
    'london-marathon': {
      titleColor: theme.colors.primary[300],
    },
    'the-big-half': {
      titleColor: theme.colors.primary[400],
    },
    'london-10k': {
      titleColor: gradient === 'white' ? theme.colors.primary[200] : theme.colors.primary[300],
    },
    'westminster-mile': {
      titleColor: gradient === 'white' ? theme.colors.primary[200] : theme.colors.primary[300],
    },
    'swim-serpentine': {
      titleColor: theme.colors.primary[400],
    },
    'ride-london': {
      titleColor: theme.colors.primary[400],
    },
    'city-race': {
      titleColor: theme.colors.primary[400],
    },
    'lme-account': {
      titleColor: theme.colors.primary[300],
    },
  };
  if (website in sites) return sites[website];

  return sites['london-marathon'];
};
