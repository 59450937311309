import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';

export const AdSlotWrapper = styled.section`
  height: ${props => props.slotHeight}px;
`;

export const AdSlotComponent = styled.div`
  width: ${props => props.slotWidth}px;
  height: ${props => props.slotHeight}px;
  margin-bottom: ${props => props.theme.space(parseInt(props.spaceMultiplier.mobile, 10))};
  pointer-events: ${props => props.pointerEvents || 'all'};

  ${breakpoints.tabletLandscape} {
    margin-bottom: ${props => props.theme.space(parseInt(props.spaceMultiplier.desktop, 10))};
  }
`;
