import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const VisualCardsComponent = styled.section`
  width: 100%;
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};
  overflow: hidden;

  ${breakpoints.tabletLandscape} {
    width: calc(100% - ${props => props.theme.px(122)});
    margin: 0 ${props => props.theme.px(61)} ${props => props.theme.space(props.spaceMultiplierDesktop)};
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > * {
    margin-bottom: ${props => props.theme.space()};
  }

  h1 {
    margin: 0 ${props => props.theme.space()} ${props => props.theme.space()};
    ${props => props.theme.setFont('visualCardComponentHeading')}

    ${breakpoints.tabletLandscape} {
      margin: 0 0 ${props => props.theme.space()} 0;
    }
  }
`;

export const Controls = styled.nav`
  display: flex;
  align-items: center;

  p {
    ${props => props.theme.setFont('visualCardText')}
    margin: 0px;
    color: ${props => CM(props.theme).controlsText};
  }

  button {
    display: flex;
    margin: 0;
    padding: 20px 8px;
    background: none;
    border: 0;
    cursor: pointer;
    appearance: none;

    svg {
      align-self: center;
      width: 25px;
      fill: ${props => CM(props.theme).controlsButtonFill};
    }

    &:last-of-type {
      svg {
        transform: rotate(180deg);
      }
    }
  }
`;

export const CardContainer = styled.ul`
  width: ${props => props.cardCount * 389 + (props.cardCount + props.theme.space(1, true))}px;
  margin: 0;
  padding: 0;
  overflow: hidden;
  list-style-type: none;

  > div {
    cursor: default;

    &.hasHover {
      cursor: pointer;
    }
  }

  ${breakpoints.tabletLandscape} {
    display: grid;
    grid-gap: ${props => props.theme.space()};
    grid-template-columns: ${props => (props.cardCount < 3 ? '1fr 1fr' : '1fr 1fr 1fr')};
    width: 100%;
  }
`;

export const ScrollContainer = styled.ul`
  display: flex;
  transform: translateX(0px);
  transition-timing-function: ease-out;
  transition-property: transform;
`;

export const Card = styled.li`
  position: relative;
  width: 294px;
  height: 389px;
  margin: 0 ${props => props.theme.space(0.5)};
  overflow: hidden;

  ${breakpoints.tabletLandscape} {
    width: auto;
    height: 0px;
    margin: 0;
    padding-top: calc(149 / 100 * 100%);
  }

  picture, .sc-image-wrapper {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: ${props => (props.pageEditing ? 4 : 1)};
    width: 100%;
    height: 100%;
    transform: scale(1);
    transition: transform 0.3s ease-in;
    pointer-events: none;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 50% 50%;
    }
  }

  h2 {
    ${props => props.theme.setFont('visualCardHeading')}
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 4;
    width: 100%;
    padding: ${props => props.theme.space()};
    color: ${props => CM(props.theme).cardHeading};
    background: linear-gradient(to bottom, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    pointer-events: none;
  }

  div.cardContent {
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 5;
    width: 100%;
    padding: ${props => props.theme.space()};
    background: linear-gradient(to top, rgba(0, 0, 0) 75%, rgba(0, 0, 0, 0) 100%);


    p {
      ${props => props.theme.setFont('visualCardText')}
      color: ${props => CM(props.theme).cardContentText};
    }

    .visualCardLinkContainer {
      display: flex;
      align-items: center;

      a, p {
        ${props => props.theme.setFont('visualCardLink')}
        z-index: 1;
        color: ${props => CM(props.theme).cardLink};
        font-weight: 700;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      svg {
        margin-right: ${props => props.theme.px(5)};
        fill: ${props => CM(props.theme).cardLinkIconFill};
      }
    }
  }

  .hasHover &:hover {
    picture, .sc-image-wrapper {
      transform: scale(1.1);
    }
  }
`;

export const SwipeText = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  ${props => props.theme.setFont('visualCardSwipeMessage')}
  margin-bottom: 0;
  color: ${props => CM(props.theme).swipeText};

  ${breakpoints.tabletLandscape} {
    display: none;
  }

  svg {
    width: 15px;
    margin-right: ${props => props.theme.space(0.5)};
    fill: ${props => CM(props.theme).swipeTextIconFill};
  }
`;
