import { colors } from './colors';

const w = colors.primary[300];
const b = colors.named.offBlack;

// [textColor, backgroundColor]
export const buttonVariants = {
  blackA: [b, w],
  blackB: [b, colors.secondary[600]],
  blackC: [b, colors.grays[100]],
};
