import React from 'react';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';
import PropTypes from 'prop-types';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import SearchHeroComponent from 'lmel-components/core/search-hero';

const SearchHero = props => {
  return (
    <ErrorBoundary>
      <SearchHeroComponent {...props} />
    </ErrorBoundary>
  );
};

SearchHero.propTypes = {
  fields: PropTypes.object,
};

export default withSitecoreContext()(SearchHero);
