import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';

export const AdContainer = styled.div`
  display: flex;
  flex-direction: column;

  > * {
    &:not(:first-child) {
      margin-top: ${props => props.theme.space()};
    }
  }
`;

export const AdSlotLayout = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: calc(100% - 50px);
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};

  ${breakpoints.tabletLandscape} {
    width: calc(100% - 122px);
  }

  > div {
    width: 100%;

    > *:first-child {
      margin-bottom: ${props => props.theme.px(26)};

      ${breakpoints.tabletLandscape} {
        margin: 0 ${props => props.theme.px(61)} 0 0;
      }
    }

    > *:last-child {
      margin: 0 ${props => props.theme.px(25)};

      > * {
        margin-right: auto;
        margin-left: auto;
      }
    }

    ${breakpoints.tabletLandscape} {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      width: 100%;
      margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};

      > *:last-child {
        width: 300px;
        margin: 0;

        > * {
          &:not(:first-child) {
            margin-top: ${props => props.theme.space()};
          }
        }
      }
    }
  }
`;

export const NoAdsLayout = styled.section`
  width: calc(100% - 50px);
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};

  ${breakpoints.tabletLandscape} {
    width: calc(100% - 122px);
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
  }
`;

export const PlaceholderWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.theme.space()};

  ${breakpoints.tabletLandscape} {
    margin-top: 0;
  }

  > * {
    &:not(:first-child) {
      margin-top: ${props => props.theme.space()};
    }
  }
`;

export const ContentWrap = styled.div``;

export const SignpostListComponent = styled.ul`
  grid-column: 1 / -1;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  ${breakpoints.tabletLandscape} {
    display: grid;
    grid-column: 1 / 9;
    width: calc(100% - 361px);
    margin: 0 ${props => props.theme.px(361)} 0 0;
  }

  ${breakpoints.desktopSmall} {
    grid-column: 1 / 10;
  }
`;

export const SignpostListTitle = styled.h2`
  ${props => props.theme.setFont('signpostTitle')}
  width: 100%;
  margin-bottom: ${props => props.theme.px(26)};
`;
