const baseSizes = {
  h1: {
    fontSize: { mobile: 120, tablet: 160, desktopSmall: 200, desktopLarge: 250 },
    lineHeight: { mobile: 96, tablet: 128, desktopSmall: 160, desktopLarge: 250 },
    letterSpacing: { mobile: -5, tablet: -5, desktopSmall: -10, desktopLarge: -10 },
  },
  h2: {
    fontSize: { mobile: 80, tablet: 100, desktopSmall: 120, desktopLarge: 150 },
    lineHeight: { mobile: 72, tablet: 90, desktopSmall: 108, desktopLarge: 150 },
    letterSpacing: { mobile: -1, tablet: -1, desktopSmall: -1, desktopLarge: -1 },
  },
  h3: {
    fontSize: { mobile: 52, tablet: 58, desktopSmall: 64, desktopLarge: 70 },
    lineHeight: { mobile: 57, tablet: 64, desktopSmall: 70, desktopLarge: 84 },
  },
  h4: {
    fontSize: { mobile: 30, tablet: 32, desktopSmall: 36, desktopLarge: 40 },
    lineHeight: { mobile: 39, tablet: 42, desktopSmall: 37, desktopLarge: 52 },
  },
  h5: {
    fontSize: { mobile: 28, tablet: 30, desktopSmall: 32, desktopLarge: 34 },
    lineHeight: { mobile: 45, tablet: 48, desktopSmall: 52, desktopLarge: 54 },
  },
  h6: {
    fontSize: { mobile: 22, tablet: 24, desktopSmall: 26, desktopLarge: 28 },
    lineHeight: { mobile: 35, tablet: 38, desktopSmall: 42, desktopLarge: 45 },
  },
  h7: {
    fontSize: { mobile: 20, tablet: 22, desktopSmall: 24, desktopLarge: 24 },
    lineHeight: { mobile: 32, tablet: 35, desktopSmall: 38, desktopLarge: 38 },
  },
  h7NotResponsive: {
    fontSize: { mobile: 21, tablet: 21, desktopSmall: 21, desktopLarge: 21 },
    lineHeight: { mobile: 29, tablet: 29, desktopSmall: 29, desktopLarge: 29 },
  },
  h8: {
    fontSize: { mobile: 40, tablet: 40, desktopSmall: 40, desktopLarge: 40 },
    lineHeight: { mobile: 46, tablet: 26, desktopSmall: 56, desktopLarge: 56 },
  },
  h9: {
    fontSize: { mobile: 21, tablet: 21, desktopSmall: 30, desktopLarge: 30 },
    lineHeight: { mobile: 29, tablet: 29, desktopSmall: 39, desktopLarge: 39 },
  },
  h10: {
    fontSize: { mobile: 42, tablet: 42, desktopSmall: 150, desktopLarge: 150 },
    lineHeight: { mobile: 48, tablet: 48, desktopSmall: 160, desktopLarge: 160 },
  },
  h11: {
    fontSize: { mobile: 42, tablet: 100, desktopSmall: 100, desktopLarge: 100 },
    lineHeight: { mobile: 43, tablet: 100, desktopSmall: 100, desktopLarge: 100 },
  },
  h12: {
    fontSize: { mobile: 42, tablet: 42, desktopSmall: 21, desktopLarge: 21 },
    lineHeight: { mobile: 42, tablet: 42, desktopSmall: 34, desktopLarge: 34 },
  },
  h13: {
    fontSize: { mobile: 30, tablet: 30, desktopSmall: 40, desktopLarge: 40 },
    lineHeight: { mobile: 38, tablet: 38, desktopSmall: 52, desktopLarge: 60 },
  },
  h14: {
    fontSize: { mobile: 70, tablet: 70, desktopSmall: 120, desktopLarge: 150 },
    lineHeight: { mobile: 29, tablet: 29, desktopSmall: 108, desktopLarge: 150 },
    letterSpacing: { mobile: 2, tablet: 2, desktopSmall: 2, desktopLarge: 2 },
  },
  h15: {
    fontSize: { mobile: 70, tablet: 70, desktopSmall: 54, desktopLarge: 54 },
    lineHeight: { mobile: 29, tablet: 29, desktopSmall: 59, desktopLarge: 59 },
    letterSpacing: { mobile: 2, tablet: 2, desktopSmall: 2, desktopLarge: 2 },
  },
  h16: {
    fontSize: { mobile: 21, tablet: 21, desktopSmall: 40, desktopLarge: 40 },
    lineHeight: { mobile: 29, tablet: 29, desktopSmall: 56, desktopLarge: 56 },
  },
  h17: {
    fontSize: { mobile: 30, tablet: 30, desktopSmall: 52, desktopLarge: 52 },
    lineHeight: { mobile: 39, tablet: 39, desktopSmall: 57, desktopLarge: 57 },
  },
  h18: {
    fontSize: { mobile: 30, tablet: 30, desktopSmall: 30, desktopLarge: 30 },
    lineHeight: { mobile: 32, tablet: 32, desktopSmall: 32, desktopLarge: 32 },
  },
  h19: {
    fontSize: { mobile: 37, tablet: 45, desktopSmall: 85, desktopLarge: 100 },
    lineHeight: { mobile: 47, tablet: 55, desktopSmall: 85, desktopLarge: 100 },
  },
  h20: {
    fontSize: { mobile: 42, tablet: 42, desktopSmall: 100, desktopLarge: 130 },
    lineHeight: { mobile: 42, tablet: 42, desktopSmall: 100, desktopLarge: 130 },
  },
  body1: {
    fontSize: { mobile: 18, tablet: 19, desktopSmall: 21, desktopLarge: 21 },
    lineHeight: { mobile: 29, tablet: 30, desktopSmall: 34, desktopLarge: 34 },
  },
  body1NotResponsive: {
    fontSize: { mobile: 18, tablet: 18, desktopSmall: 18, desktopLarge: 18 },
    lineHeight: { mobile: 26, tablet: 26, desktopSmall: 26, desktopLarge: 26 },
  },
  body2: {
    fontSize: { mobile: 16, tablet: 18, desktopSmall: 21, desktopLarge: 21 },
    lineHeight: { mobile: 26, tablet: 29, desktopSmall: 29, desktopLarge: 29 },
  },
  body2NotResponsive: {
    fontSize: { mobile: 16, tablet: 16, desktopSmall: 16, desktopLarge: 16 },
    lineHeight: { mobile: 22, tablet: 22, desktopSmall: 22, desktopLarge: 22 },
  },
  body3: {
    fontSize: { mobile: 14, tablet: 16, desktopSmall: 18, desktopLarge: 18 },
    lineHeight: { mobile: 22, tablet: 26, desktopSmall: 29, desktopLarge: 29 },
  },
  body4: {
    fontSize: { mobile: 14, tablet: 14, desktopSmall: 14, desktopLarge: 14 },
    lineHeight: { mobile: 29, tablet: 29, desktopSmall: 29, desktopLarge: 29 },
  },
  body5: {
    fontSize: { mobile: 16, tablet: 16, desktopSmall: 18, desktopLarge: 18 },
    lineHeight: { mobile: 16, tablet: 16, desktopSmall: 18, desktopLarge: 18 },
  },
  body6: {
    fontSize: { mobile: 10, tablet: 10, desktopSmall: 12, desktopLarge: 12 },
    lineHeight: { mobile: 18, tablet: 18, desktopSmall: 22, desktopLarge: 22 },
    letterSpacing: { mobile: 2, tablet: 2, desktopSmall: 2, desktopLarge: 2 },
  },
  body7NotResponsive: {
    fontSize: { mobile: 12, tablet: 12, desktopSmall: 12, desktopLarge: 12 },
    lineHeight: { mobile: 12, tablet: 12, desktopSmall: 12, desktopLarge: 12 },
  },
  body8: {
    fontSize: { mobile: 18, tablet: 18, desktopSmall: 30, desktopLarge: 30 },
    lineHeight: { mobile: 26, tablet: 26, desktopSmall: 39, desktopLarge: 39 },
  },
  body9: {
    fontSize: { mobile: 12, tablet: 12, desktopSmall: 16, desktopLarge: 16 },
    lineHeight: { mobile: 22, tablet: 22, desktopSmall: 22, desktopLarge: 22 },
  },
  body10: {
    fontSize: { mobile: 12, tablet: 12, desktopSmall: 14, desktopLarge: 14 },
    lineHeight: { mobile: 22, tablet: 22, desktopSmall: 22, desktopLarge: 22 },
  },
  body11: {
    fontSize: { mobile: 21, tablet: 21, desktopSmall: 52, desktopLarge: 52 },
    lineHeight: { mobile: 29, tablet: 29, desktopSmall: 29, desktopLarge: 29 },
  },
  body12: {
    fontSize: { mobile: 16, tablet: 28, desktopSmall: 42, desktopLarge: 52 },
    lineHeight: { mobile: 23, tablet: 35, desktopSmall: 55, desktopLarge: 65 },
  },
};

export const typography = {
  typefaces: {
    body: 'proxima-nova, sans-serif;',
    headings: 'itc-avant-garde-gothic-pro, sans-serif;',
  },
  styles: {
    h1: {
      ...baseSizes.h1,
      type: 'headings',
    },
    h2: {
      ...baseSizes.h2,
      type: 'headings',
    },
    h3: {
      ...baseSizes.h3,
      type: 'headings',
    },
    h4: {
      ...baseSizes.h4,
      type: 'headings',
    },
    h5: {
      ...baseSizes.h5,
      type: 'body',
    },
    h6: {
      ...baseSizes.h6,
      type: 'body',
    },
    h7: {
      ...baseSizes.h7,
      type: 'body',
    },
    h8: {
      ...baseSizes.body1,
      type: 'body',
    },
    h9: {
      ...baseSizes.body2,
      type: 'body',
    },
    body: {
      ...baseSizes.body1,
      type: 'body',
    },
    bodySmall: {
      ...baseSizes.body2,
      type: 'body',
    },
    bodyExtraSmall: {
      ...baseSizes.body3,
      type: 'body',
    },
    carouselSmallText: {
      ...baseSizes.body2,
      type: 'headings',
    },
    carouselStateText: {
      ...baseSizes.body5,
    },
    signpostTitle: {
      ...baseSizes.h7NotResponsive,
      type: 'headings',
    },
    signpostHeading: {
      ...baseSizes.body1,
      type: 'headings',
    },
    signpostBody: {
      ...baseSizes.body1NotResponsive,
      type: 'body',
    },
    navigation: {
      ...baseSizes.body6,
      type: 'headings',
    },
    featureHeading: {
      ...baseSizes.h8,
      type: 'headings',
    },
    featureBody: {
      ...baseSizes.body1NotResponsive,
      type: 'body',
    },
    featureLink: {
      ...baseSizes.body1NotResponsive,
      type: 'body',
    },
    footerLinks: {
      ...baseSizes.body1NotResponsive,
      type: 'body',
    },
    footerLegalLinks: {
      ...baseSizes.body4,
      type: 'body',
    },
    footerStatement: {
      ...baseSizes.h7,
      type: 'body',
    },
    accordion: {
      ...baseSizes.body1,
      type: 'body',
    },
    accordionActive: {
      ...baseSizes.body2,
      type: 'headings',
    },
    accordionText: {
      ...baseSizes.body2NotResponsive,
      type: 'body',
    },
    accordionHeader: {
      ...baseSizes.h9,
      type: 'headings',
    },
    utilityBar: {
      ...baseSizes.body7NotResponsive,
      type: 'body',
    },
    navCardLink: {
      ...baseSizes.body4,
      type: 'body',
    },
    quote: {
      ...baseSizes.body8,
      type: 'headings',
    },
    cta: {
      ...baseSizes.body3,
    },
    tag: {
      ...baseSizes.body1NotResponsive,
    },
    videoTitle: {
      ...baseSizes.body2,
      type: 'headings',
    },
    videoSubTitle: {
      ...baseSizes.body2,
    },
    videoTitleOverlay: {
      ...baseSizes.h10,
      type: 'headings',
    },
    imageGalleryHeading: {
      ...baseSizes.h11,
      type: 'headings',
    },
    searchOverlayCloseButton: {
      ...baseSizes.body2NotResponsive,
    },
    searchOverlayLabelText: {
      ...baseSizes.body9,
    },
    searchOverlayPopularSearchTitle: {
      ...baseSizes.body10,
    },
    searchOverlayPopularSearchItem: {
      ...baseSizes.body3,
      type: 'headings',
    },
    searchOverlayResults: {
      ...baseSizes.body1NotResponsive,
      type: 'headings',
    },
    imageCarouselText: {
      ...baseSizes.body2NotResponsive,
    },
    visualCardComponentHeading: {
      ...baseSizes.h12,
      type: 'headings',
    },
    visualCardHeading: {
      ...baseSizes.h13,
      type: 'headings',
    },
    visualCardText: {
      ...baseSizes.body1NotResponsive,
    },
    visualCardLink: {
      ...baseSizes.body1NotResponsive,
      type: 'headings',
    },
    visualCardSwipeMessage: {
      ...baseSizes.body4,
    },
    countdownClockTitle: {
      ...baseSizes.body2,
      type: 'headings',
    },
    countdownClockTitleSmall: {
      ...baseSizes.body2NotResponsive,
      type: 'headings',
    },
    countdownClockSubTitle: {
      ...baseSizes.body3,
    },
    countdownClockSubTitleSmall: {
      ...baseSizes.body4,
    },
    countdownClockTimer: {
      ...baseSizes.h14,
    },
    countdownClockTimerSmall: {
      ...baseSizes.h15,
    },
    countdownClockTimerLabel: {
      ...baseSizes.body3,
    },
    countdownClockTimerLabelSmall: {
      ...baseSizes.body2NotResponsive,
    },
    countdownClockCTA: {
      ...baseSizes.body1,
      type: 'headings',
    },
    breadcrumbs: {
      ...baseSizes.body4,
    },
    textBlockHeading: {
      ...baseSizes.h18,
      type: 'headings',
    },
    categoryFilterButton: {
      ...baseSizes.body1NotResponsive,
    },
    categoryFilterText: {
      ...baseSizes.body4,
      type: 'headings',
    },
    authorInfo: {
      ...baseSizes.body4,
    },
    articleTitleSmall: {
      ...baseSizes.h16,
      type: 'headings',
    },
    articleTitleLarge: {
      ...baseSizes.h17,
      type: 'headings',
    },
    articleTitle: {
      ...baseSizes.body1,
      type: 'headings',
    },
    articleReadTimeNumber: {
      ...baseSizes.body1NotResponsive,
      lineHeight: { mobile: 0, tablet: 0, desktopSmall: 0, desktopLarge: 0 },
    },
    articleReadTimeText: {
      ...baseSizes.body4,
      lineHeight: { mobile: 0, tablet: 0, desktopSmall: 0, desktopLarge: 0 },
    },
    articleEventDate: {
      ...baseSizes.body2NotResponsive,
    },
    loadMore: {
      ...baseSizes.body2NotResponsive,
    },
    searchHeroInput: {
      ...baseSizes.body11,
      type: 'headings',
    },
    searchHeroIntro: {
      ...baseSizes.body4,
    },
    searchResultHeading: {
      ...baseSizes.body2NotResponsive,
      type: 'headings',
    },
    articleListRowHeading: {
      ...baseSizes.body2,
      type: 'headings',
    },
    searchAndResultsFilterTitle: {
      ...baseSizes.body3,
      type: 'headings',
    },
    searchAndResultsIntroSubHeadingText: {
      ...baseSizes.body4,
    },
    searchAndResultsIntroHeadingText: {
      ...baseSizes.body4,
    },
    searchAndResultsFilterResultsTitle: {
      ...baseSizes.body2,
      type: 'headings',
    },
    faqVotingQuestion: {
      ...baseSizes.body2,
      type: 'headings',
    },
    faqVotingConfirmedText: {
      ...baseSizes.body4,
    },
    searchAndResultsIntroSearchedTerm: {
      ...baseSizes.h5,
      type: 'headings',
    },
    searchOverlayInput: {
      ...baseSizes.body12,
      type: 'headings',
    },
    heroBasicHeading: {
      ...baseSizes.h19,
      type: 'headings',
    },
    heroImpactHeading: {
      ...baseSizes.h20,
      type: 'headings',
    },
  },
};
