import React, { useEffect, useState, useRef } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import {
  SearchOverlayContainer,
  CloseButton,
  SearchButton,
  SearchContainer,
  SearchForm,
  PopularSearches,
  LayerImage,
  SearchResults,
  SearchTextUnderline,
  HiddenSearchTerm,
  Pattern,
} from './SearchOverlay.styles';
import { getIcon } from '../../utils/icon';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { Link } from '@sitecore-jss/sitecore-jss-react';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/react-hooks';
import debounce from 'debounce';
import Tag from '../../tag';
import { getPattern } from '../../utils/pattern.js';

const PERFORM_SEARCH = loader('./PerformSearch.graphql');

const SearchOverlay = React.forwardRef((props, ref) => {
  const {
    toggleFn,
    layerImage,
    searchPlaceholder,
    searchTitle,
    popularSearches,
    popularSearchesTitle,
    closeText,
    searchUrl = '/search',
    pattern,
    colour,
    className,
    id,
    theme: { website },
  } = props;

  const hiddenSearchRef = useRef();
  const searchInputRef = useRef();
  const [searchResults, setSearchResults] = useState([]);
  const [underlineWidth, setUnderlineWidth] = useState(0);
  const [doSearch, { loading, data }] = useLazyQuery(PERFORM_SEARCH, { fetchPolicy: 'no-cache' });

  const patternName = () => {
    if (pattern.value === 'the-medal') return 'the-medal-thin';
    if (pattern.value === 'transformation') return 'transformation-full';

    return pattern.value;
  };

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  const calculateUnderlineWidth = text => {
    const el = hiddenSearchRef?.current;
    if (el) {
      el.innerText = text;
      return el.getBoundingClientRect().width;
    }
  };

  const search = searchTerm => {
    doSearch({ variables: { terms: searchTerm } });
  };

  const searchDebounced = debounce(search, 300);

  const handleSearchInputChange = e => {
    const value = e.target.value.trim();
    setUnderlineWidth(calculateUnderlineWidth(value));
    if (value?.length > 2) {
      searchDebounced(value);
    } else {
      setSearchResults([]);
    }
  };

  const highlightSearchTerms = inputString => {
    const searchInputValue = searchInputRef?.current?.value.trim();
    const searchWords = searchInputValue.split(' ').join('|');
    const regex = new RegExp(`\\b(?=\\w*(${searchWords}))\\w+\\b`, 'gi');
    const matches = inputString.match(regex);
    return inputString
      .split(/\b/)
      .reduce((acc, word) => {
        if (matches?.findIndex(w => w.toLowerCase() === word.toLowerCase()) === 0) {
          acc.push(`<span>${word}</span>`);
          matches.shift();
        } else {
          acc.push(word);
        }
        return acc;
      }, [])
      .join('');
  };

  const handleSubmit = e => {
    e.preventDefault();
    const searchString = searchInputRef?.current?.value?.trim();
    if (searchString && !isServer()) {
      const q = encodeURIComponent(searchString).replace(/%20(\D)?/g, '+$1');
      window.location.replace(`${searchUrl}?q=${q}`);
    }
  };

  const handleResize = () => {
    const inputValue = searchInputRef?.current?.value?.trim();
    setUnderlineWidth(calculateUnderlineWidth(inputValue));
  };

  useEffect(() => {
    if (!isServer()) {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }
  }, []);

  useEffect(() => {
    if (!loading) {
      const searchString = searchInputRef?.current?.value?.trim();
      if (searchString.length > 2) {
        setSearchResults(data?.search?.results?.items || []);
      } else {
        setSearchResults([]);
      }
    }
  }, [data]);

  if (typeof className === 'string' && className.split(' ').includes('visible') && !isServer()) {
    setTimeout(() => {
      searchInputRef?.current?.focus();
    }, 250);
  } else if (!isServer()) {
    setTimeout(() => {
      searchInputRef?.current?.blur();
    }, 250);
  }

  const handleGTMResultClick = value => {
    dataLayerEvent('searchLink', { clickText: value });
  };

  const handleGradientBySite = () => {
    const sites = {
      'london-marathon': 'winter-blues',
      'the-big-half': 'violet',
      'london-10k': 'black',
      'westminster-mile': 'black',
      'swim-serpentine': 'green',
      'ride-london': 'blue',
      'city-race': 'black',
      'lm-foundation': 'blue',
    };

    if (website in sites) {
      return sites[website];
    }

    return sites['london-marathon'];
  };

  return (
    <SearchOverlayContainer id={id} ref={ref} gradient={handleGradientBySite()} className={className}>
      <HiddenSearchTerm ref={hiddenSearchRef} aria-hidden="true"></HiddenSearchTerm>
      <CloseButton aria-label={closeText?.value || 'Close'} onClick={toggleFn}>
        {getIcon('clear')}
        {closeText?.value || 'Close'}
      </CloseButton>
      <SearchContainer>
        <SearchForm onSubmit={handleSubmit}>
          <label htmlFor="searchOverlayInput">{searchTitle?.value}</label>
          <input
            ref={searchInputRef}
            id="searchOverlayInput"
            placeholder={searchPlaceholder?.value}
            type="text"
            onChange={handleSearchInputChange}
            spellCheck="false"
            autoComplete="off"
          />
          <SearchButton type="submit">{getIcon('arrowLong')}</SearchButton>
          <SearchTextUnderline underlineWidth={underlineWidth} />
        </SearchForm>
        {!searchResults.length ? (
          <PopularSearches>
            <p id="popularSearchesTitle">{popularSearchesTitle?.value}</p>
            <ul aria-labelledby="popularSearchesTitle">
              {popularSearches?.map((el, i) => (
                <li key={i} onClick={() => handleGTMResultClick(el?.text)}>
                  <Link field={el} />
                </li>
              ))}
            </ul>
          </PopularSearches>
        ) : (
          <SearchResults>
            {searchResults.map((result, i) => (
              <li key={i} onClick={() => handleGTMResultClick(result?.title)}>
                <Tag
                  isPresentationalOnly
                  label={result?.contentTypeTitle}
                  textColour={result?.contentTypeTextColour}
                  backgroundColour={result?.contentTypeColour}
                  className="searchTag"
                />
                <Link field={{ href: result?.url, title: result?.title }}>
                  <p dangerouslySetInnerHTML={{ __html: highlightSearchTerms(result?.title) }}></p>
                </Link>
              </li>
            ))}
          </SearchResults>
        )}
      </SearchContainer>

      {pattern?.value && (
        <Pattern patternName={patternName()} patternColor={colour?.value || 'golden'}>
          {getPattern(patternName())}
        </Pattern>
      )}
      {layerImage?.value?.src && <LayerImage src={layerImage?.value?.src} />}
    </SearchOverlayContainer>
  );
});

SearchOverlay.displayName = 'SearchOverlay';

SearchOverlay.propTypes = {
  toggleFn: PropTypes.func,
  searchTitle: PropTypes.object,
  searchPlaceholder: PropTypes.object,
  layerImage: PropTypes.object,
  popularSearchesTitle: PropTypes.object,
  popularSearches: PropTypes.array,
  closeText: PropTypes.object,
  searchUrl: PropTypes.string,
  className: PropTypes.string,
  id: PropTypes.string,
  pattern: PropTypes.object,
  colour: PropTypes.object,
  theme: PropTypes.object,
};

export default withTheme(SearchOverlay);
