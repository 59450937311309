import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';

export const VideoEmbedComponent = styled.section`
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};
  padding: ${props => (props.pageEditing ? '20px' : 0)};

  &.limitedWidth {
    width: calc(100% - 52px);

    ${breakpoints.tabletLandscape} {
      width: calc(100% - 122px);
      margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
    }
  }
`;

export const VideoInner = styled.div`
  ${breakpoints.tabletLandscape} {
    max-width: ${props => props.theme.px(900)};
  }
`;

export const VideoWrapper = styled.div`
  position: relative;
  height: 0;
  padding-top: 56.25%;
  overflow: hidden;

  .react-player {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;
