const colorNames = {
  blue: '#060549',
  pink: '#E71984',
  black: '#000000',
  white: '#ffffff',
  cyan: '#009fe3',
  orange: '#ff7c21',
  purple: '#ff00ff',
  yellow: '#ffed00',
  lime: '#c5d300',
  jet: '#3c3c3b',
  sonicSilver: '#706f6f',
  silver: '#c7c7c6',
  platinum: '#e3e3e3',
  cultured: '#F5F5F5',
};

export const colors = {
  primary: {
    100: colorNames.blue,
    200: colorNames.pink,
    300: colorNames.black,
    400: colorNames.white,
  },
  secondary: {
    100: colorNames.cyan,
    200: colorNames.orange,
    300: colorNames.purple,
    400: colorNames.yellow,
  },
  grays: {
    100: colorNames.jet,
    200: colorNames.sonicSilver,
    300: colorNames.silver,
    400: colorNames.platinum,
    500: colorNames.cultured,
  },
  named: {
    blue: colorNames.blue,
    pink: colorNames.pink,
    black: colorNames.black,
    white: colorNames.white,
    cyan: colorNames.cyan,
    orange: colorNames.orange,
    purple: colorNames.purple,
    yellow: colorNames.yellow,
    lime: colorNames.lime,
    jet: colorNames.jet,
    'sonic-silver': colorNames.sonicSilver,
    silver: colorNames.silver,
    platinum: colorNames.platinum,
    cultured: colorNames.cultured,
  },
};
