import { useEffect, useState } from 'react';
import { breakpointValues } from '../global-styles/breakpoints';
import { isServer } from '@sitecore-jss/sitecore-jss';

export const useIsDesktop = (breakpoint = 'tabletLandscape', offset = 0) => {
  const mql = !isServer() ? window.matchMedia(`(min-width: ${breakpointValues[breakpoint] + offset}px)`) : '';

  const [isMobile, setIsMobile] = useState(mql?.matches);

  const handleMediaQuery = e => {
    if (e.matches) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    if (!isServer()) {
      mql.addListener(handleMediaQuery);

      return () => {
        mql.removeListener(handleMediaQuery);
      };
    }
  }, []);

  return isMobile;
};
