import React from 'react';
import PropTypes from 'prop-types';
import ErrorBoundary from 'lmel-components/core/error-boundary';
import ShowcaseHeroComponent from 'lmel-components/core/showcase-hero';
import { withTranslation } from 'react-i18next';
import { withSitecoreContext } from '@sitecore-jss/sitecore-jss-react';

const ShowcaseHero = props => {
  const { sitecoreContext } = props;

  return (
    <ErrorBoundary>
      <ShowcaseHeroComponent translate={props.t} {...props} sitecoreContext={sitecoreContext} />
    </ErrorBoundary>
  );
};

ShowcaseHero.propTypes = {
  sitecoreContext: PropTypes.object,
  t: PropTypes.func,
};

export default withSitecoreContext()(withTranslation()(ShowcaseHero));
