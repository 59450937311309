export const mapColourToSiteTheme = theme => {
  const sites = {
    'london-marathon': {
      borderColor: theme.colors.secondary[300],
      textColor: theme.colors.primary[200],
    },
    'the-big-half': {
      borderColor: theme.colors.primary[100],
      textColor: theme.colors.secondary[400],
    },
    'london-10k': {
      borderColor: theme.colors.primary[100],
      textColor: theme.colors.primary[200],
    },
    'westminster-mile': {
      borderColor: theme.colors.primary[100],
      textColor: theme.colors.primary[200],
    },
    'swim-serpentine': {
      borderColor: theme.colors.primary[200],
      textColor: theme.colors.secondary[400],
    },
    'ride-london': {
      borderColor: theme.colors.primary[200],
      textColor: theme.colors.primary[300],
    },
    'city-race': {
      borderColor: theme.colors.primary[500],
      textColor: theme.colors.primary[300],
    },
    'lm-foundation': {
      borderColor: theme.colors.secondary[700],
      textColor: theme.colors.primary[200],
    },
  };
  if (theme.website in sites) return sites[theme.website];
  return sites['london-marathon'];
};
