import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  MobileFiltersWrap,
  MobileFilterHeader,
  MobileFilterHeaderInner,
  MobileFilterContainer,
  MobileFilterItems,
  MobileFilterHeaderWrap,
  IconWrap,
  Accordion,
  AccordionTitle,
  AccordionItems,
  CTAWrap,
} from './MobileFilters.styles';
import { getIcon } from '../../utils/icon.js';
import CTA from '../../cta';
import { withTheme } from 'styled-components';
import { handleCtaMappingByTheme } from '../../cta/ctaMapper';

const MobileFilters = React.forwardRef((props, ref) => {
  const {
    checkedItems,
    setCheckedItems,
    generateFilters,
    filtersTitle,
    clearAllText,
    appliedText,
    showResultsText,
    ctaTheme,
    theme: { website },
  } = props;

  const [filtersOpen, setFiltersOpen] = useState(false);
  const filtersRef = useRef(null);

  const handleOpenFilter = () => {
    setFiltersOpen(true);

    if (filtersRef.current) {
      setTimeout(() => {
        filtersRef.current.classList.add('animate-in');
      });
    }
  };

  const handleCloseFilter = () => {
    setFiltersOpen(false);
  };

  const handleApplyFilters = () => {
    setFiltersOpen(false);
  };

  const handleClearAll = () => {
    setCheckedItems([]);
    setFiltersOpen(false);
  };

  const handleChange = event => {
    const filterName = event.target.dataset.name.toLowerCase();
    const filterValue = event.target.name.toLowerCase();

    if (!event.target.checked) {
      const findIndex = checkedItems.findIndex(
        item => item.name.toLowerCase() === filterName && item.value.toLowerCase() === filterValue
      );

      const duplicateCheckedItemsArray = [...checkedItems];
      duplicateCheckedItemsArray.splice(findIndex, 1);

      setCheckedItems(duplicateCheckedItemsArray);
    } else {
      setCheckedItems([
        ...checkedItems,
        {
          name: filterName,
          value: filterValue,
        },
      ]);
    }
  };

  useEffect(() => {
    if (ref.current && !filtersOpen) {
      ref.current.addEventListener('click', handleOpenFilter);
    }
  }, [filtersOpen]);

  return (
    <>
      <MobileFilterHeader ref={ref} className="filter-header">
        <MobileFilterHeaderInner>
          <MobileFilterHeaderWrap>
            <IconWrap>
              {getIcon('filter')}
              <p>{filtersTitle}</p>
            </IconWrap>
            {checkedItems.length ? (
              <span>
                ({checkedItems.length} {appliedText})
              </span>
            ) : null}
          </MobileFilterHeaderWrap>
          <button onClick={() => handleOpenFilter()}>{getIcon('arrowLong')}</button>
        </MobileFilterHeaderInner>
      </MobileFilterHeader>

      <MobileFiltersWrap ref={filtersRef} filtersOpen={filtersOpen}>
        <MobileFilterHeader>
          {filtersOpen ? (
            <>
              <button onClick={() => handleCloseFilter()}>{getIcon('clear')}</button>
              <MobileFilterHeaderInner filtersOpen={filtersOpen}>
                <p>{filtersTitle}</p>
                {checkedItems.length ? (
                  <button onClick={() => handleClearAll()}>
                    {getIcon('clear')} <span>{clearAllText}</span>
                  </button>
                ) : null}
              </MobileFilterHeaderInner>
            </>
          ) : null}
        </MobileFilterHeader>
        {filtersOpen && (
          <MobileFilterContainer>
            <MobileFilterItems>
              <ul>
                {generateFilters().map((filter, index) => (
                  <li key={index}>
                    <MobileFilterItem
                      filters={filter}
                      filtersOpen={filtersOpen}
                      checkedItems={checkedItems}
                      setCheckedItems={setCheckedItems}
                      handleChange={handleChange}
                    />
                  </li>
                ))}
              </ul>
            </MobileFilterItems>
            <CTAWrap>
              <CTA
                label={showResultsText}
                variant={handleCtaMappingByTheme(website, ctaTheme)}
                className="apply-filters-cta"
                onClick={() => handleApplyFilters()}
              />
            </CTAWrap>
          </MobileFilterContainer>
        )}
      </MobileFiltersWrap>
    </>
  );
});

const MobileFilterItem = ({ filters, checkedItems, handleChange }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [setHeight, setHeightState] = useState('100%');
  const contentRef = useRef(null);

  function toggleAccordion() {
    setIsOpen(!isOpen);
    if (contentRef.current) {
      setHeightState(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');
    }
  }

  useEffect(() => {
    if (contentRef.current) {
      setHeightState(`${contentRef.current.scrollHeight}px`);
    }
  }, []);

  return (
    <Accordion>
      {filters.title && (
        <AccordionTitle isOpen={isOpen} onClick={toggleAccordion}>
          {filters.title} {getIcon('chevronOutline')}
        </AccordionTitle>
      )}

      {filters.filter.values.length > 0 &&
        filters.filter.values.map((item, index) => (
          <AccordionItems ref={contentRef} key={index} style={{ maxHeight: `${setHeight}` }}>
            <label
              key={item.key}
              className={
                checkedItems.some(e => e.value.toLowerCase() === item.value.toLowerCase()) ? 'checked' : ''
              }
            >
              {item.name}
              <input
                type="checkbox"
                name={item.value}
                checked={checkedItems.some(e => e.value.toLowerCase() === item.value.toLowerCase())}
                onChange={handleChange}
                data-name={filters.filter.name}
                className="filter-checkbox"
              />
              <span className="checkmark"></span>
            </label>
          </AccordionItems>
        ))}
    </Accordion>
  );
};

MobileFilters.displayName = 'MobileFilters';

MobileFilters.propTypes = {
  filtersOpen: PropTypes.func,
  setFiltersOpen: PropTypes.func,
  checkedItems: PropTypes.array,
  setCheckedItems: PropTypes.func,
  generateFilters: PropTypes.func,
  filtersTitle: PropTypes.string,
  clearAllText: PropTypes.string,
  appliedText: PropTypes.string,
  showResultsText: PropTypes.string,
  ctaTheme: PropTypes.string,
  theme: PropTypes.object,
};

MobileFilterItem.propTypes = {
  filtersOpen: PropTypes.bool,
  setCheckedItems: PropTypes.func,
  checkedItems: PropTypes.array,
  handleChange: PropTypes.func,
  filters: PropTypes.object,
};

export default withTheme(MobileFilters);
