import React, { useState, useRef, useEffect } from 'react';
import { withTheme } from 'styled-components';
import PropTypes from 'prop-types';
import EditFrame from '../edit-frame';
import Empty from '../empty';
import { withSitecoreContext, Placeholder, Text, RichText } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { getIcon } from '../utils/icon';
import {
  AccordionListComponent,
  Title,
  Accordion,
  AccordionWrap,
  Content,
  ButtonHeader,
  Icon,
  ContentWrap,
  CTAWrap,
  AdSlotLayout,
  TextWrap,
  PlaceholderWrap,
} from './AccordionList.styles.js';
import { useIsMobile } from '../hooks/useIsMobile';
import CTA from '../cta';

const AccordionLayout = props => {
  const {
    rendering = {},
    rendering: {
      placeholders = {},
      params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' } = {},
    },
  } = props;

  return placeholders?.['jss-mpu-ads']?.length ? (
    <AdSlotLayout
      className="limitedWidth"
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      <AccordionList {...props} />
      <PlaceholderWrap>
        <Placeholder name="jss-mpu-ads" rendering={rendering} />
      </PlaceholderWrap>
    </AdSlotLayout>
  ) : (
    <AccordionList
      className="limitedWidth"
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
      {...props}
    />
  );
};

const AccordionList = props => {
  const [activeIndex, setActiveIndex] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);
  const isMobile = useIsMobile('tabletPortrait');
  const contentRef = useRef(null);

  const {
    fields: { items = [], link = {} } = {},
    sitecoreContext: { pageEditing = false } = {},
    className = '',
    spaceMultiplierMobile = '1',
    spaceMultiplierDesktop = '1',
    theme: { website },
  } = props;
  const uniqueTitleId = `title-${new Date().getTime()}`;

  const hasItems = items && items.length > 0;

  const refs = [];
  items.forEach(() => {
    refs.push(useRef());
  });

  const handleAccordionClick = e => {
    const elParent = e.target.parentNode;

    const accordionContent = elParent.querySelector('.accordionContent');
    if (accordionContent) {
      setContentHeight(accordionContent.getBoundingClientRect().height);
    } else {
      setContentHeight('auto');
    }
  };

  const mapCtaVariant = () => {
    const sites = {
      'london-marathon': 'blackA',
      'the-big-half': 'blackA',
      'london-10k': 'blackA',
      'westminster-mile': 'whiteA',
      'swim-serpentine': 'blackA',
      'ride-london': 'whiteA',
      'city-race': 'blackA',
    };
    if (website in sites) return sites[website];
    return sites['london-marathon'];
  };

  useEffect(() => {
    const accordionItemHeight = refs
      .filter(element => element.current !== undefined)
      .reduce((acc, el) => {
        const height = el.current.getBoundingClientRect().height;
        return height > acc ? height : acc;
      }, 0);

    refs.forEach(
      el =>
        el.current !== undefined && (el.current.style.height = isMobile ? 'auto' : `${accordionItemHeight}px`)
    );
  }, []);

  return (
    <AccordionListComponent
      className={className}
      aria-labelledby={uniqueTitleId}
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      <Title id={uniqueTitleId}>{props?.fields?.title && <Text field={props?.fields?.title} />}</Title>
      <Accordion>
        {hasItems &&
          items.map((item, index) => {
            const activeOpenAccordion = activeIndex === index;
            return (
              <EditFrame
                itemId={item.id}
                header="Edit Accordion"
                commandDisplayName="Accordion"
                fields={['title', 'body']}
                key={index}
              >
                <AccordionWrap active={activeOpenAccordion}>
                  <ButtonHeader
                    active={activeOpenAccordion}
                    aria-expanded={activeOpenAccordion}
                    onClick={e => {
                      setActiveIndex(activeIndex === index ? false : index);
                      handleAccordionClick(e);

                      if (!isServer()) {
                        window.dataLayer.push({
                          event: activeOpenAccordion ? 'accordionClose' : 'accordionOpen',
                          faqTitle: item.fields?.title?.value,
                        });
                      }
                    }}
                    ref={refs[index]}
                    disabled={pageEditing}
                  >
                    <TextWrap>
                      <Text field={item.fields?.title} />
                    </TextWrap>
                    <Icon active={activeOpenAccordion}>
                      {activeOpenAccordion ? getIcon('remove') : getIcon('add')}
                    </Icon>
                  </ButtonHeader>
                  <Content
                    contentHeight={contentHeight}
                    active={activeOpenAccordion}
                    className={`freeText ${activeOpenAccordion || pageEditing ? 'active' : ''}`}
                  >
                    <ContentWrap ref={contentRef} className="accordionContent">
                      <RichText field={item.fields?.body} />
                    </ContentWrap>
                  </Content>
                </AccordionWrap>
              </EditFrame>
            );
          })}
        {pageEditing && !hasItems && <Empty message="No items specified" />}
      </Accordion>

      {link?.value?.href && (
        <CTAWrap>
          <CTA
            type="A"
            variant={mapCtaVariant()}
            link={link.value}
            target={link.value.linktype === 'external' ? '_blank' : null}
            label={link.value.text}
            isEE={pageEditing}
            field={link}
            className="accordion-list-cta"
          />
        </CTAWrap>
      )}
    </AccordionListComponent>
  );
};

AccordionLayout.propTypes = {
  rendering: PropTypes.object,
};

AccordionList.propTypes = {
  visibleAds: PropTypes.bool,
  props: PropTypes.object,
  fields: PropTypes.object,
  sitecoreContext: PropTypes.object,
  className: PropTypes.string,
  spaceMultiplierMobile: PropTypes.string,
  spaceMultiplierDesktop: PropTypes.string,
  theme: PropTypes.object,
  website: PropTypes.string,
};

export default withSitecoreContext()(withTheme(AccordionLayout));
