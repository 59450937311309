import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const AccordionListComponent = styled.section`
  &.limitedWidth {
    width: calc(100% - 52px);
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};

    ${breakpoints.tabletLandscape} {
      width: calc(100% - 122px);
      margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};
    }
  }

  ${breakpoints.tabletLandscape} {
    position: relative;
  }
`;

export const Accordion = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  list-style: none;

  ${breakpoints.tabletLandscape} {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: ${props => props.theme.space()};
    margin-bottom: 0;
  }
`;

export const AdSlotLayout = styled.div`
  width: calc(100% - 52px);
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};

  > *:last-child {
    display: flex;
    justify-content: center;
    width: 100%;

    ${breakpoints.tabletLandscape} {
      justify-content: flex-start;
    }

    > * {
      display: flex;
      justify-content: center;

      &:not(:first-child) {
        margin-top: ${props => props.theme.space()};
      }

      ${breakpoints.tabletLandscape} {
        justify-content: flex-end;
      }
    }
  }

  ${breakpoints.tabletLandscape} {
    position: relative;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    width: calc(100% - 122px);
    margin-bottom: ${props => props.theme.space(props.spaceMultiplierDesktop)};

    > *:first-child {
      grid-column: 1 / 7;
      margin: 0 ${props => props.theme.px(8)} 0 0;
    }

    > *:not(:first-child) {
      grid-column: 9 / -1;
      margin: 0;
    }

    ${Accordion} {
      flex-direction: column;

      ${breakpoints.tabletLandscape} {
        li {
          width: 100%;
          margin-right: 0;

          &:nth-last-child(2) {
            margin-bottom: ${props => props.theme.space()};
          }
        }
      }
    }
  }
`;

export const PlaceholderWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Title = styled.h1`
  ${props => props.theme.setFont('accordionHeader')}
  margin-bottom: ${props => props.theme.space()};
  color: ${props => CM(props.theme).textColor};
  font-weight: bold;

  ${breakpoints.tabletLandscape} { 
    margin-bottom: ${props => props.theme.space(0.5)};
  }
`;

export const AccordionWrap = styled.li`
  margin-bottom: ${props => props.theme.space()};
  background-color: ${props =>
    props.active ? CM(props.theme).activeAccordionBackgroud : CM(props.theme).accordionBackground};
  border: ${props => (props.active ? 'none' : `1px solid ${CM(props.theme).borderColor}`)};

  &:hover {
    background-color: ${props => CM(props.theme).activeAccordionBackgroud};
  }

  &:last-child {
    margin-bottom: 0;
  }

  ${breakpoints.tabletLandscape} {
    width: calc(50% - 8px);
    margin-right: ${props => props.theme.px(16)};
    padding-bottom: ${props => (props.active ? `${props.theme.px(20)}` : 0)};

    &:nth-of-type(even) {
      margin-right: 0;
    }

    &:last-child,
    :nth-last-child(2) {
      margin-bottom: 0;
    }
  }
`;

export const ButtonHeader = styled.button`
  ${props => (props.active ? props.theme.setFont('accordionActive') : props.theme.setFont('accordion'))}
  position: relative;
  display: flex;
  align-items: flex-start;
  width: 100%;
  /* Override inline style */
  height: ${props => props.active && 'auto'} !important;
  padding: ${props => props.theme.space(0.5)} ${props => props.theme.space()};
  font-weight: ${props => (props.active ? 'bold' : 'normal')};
  text-align: left;
  background: transparent;
  border: none;
  cursor: pointer;
`;

export const TextWrap = styled.span`
  display: block;
  max-width: 90%;
  color: ${props => CM(props.theme).textColor};
  pointer-events: none;

  ${breakpoints.tabletLandscape} {
    max-width: 75%;
  }
`;

export const Icon = styled.div`
  position: absolute;
  right: ${props => props.theme.space(0.5)};
  display: flex;
  pointer-events: none;

  svg {
    path {
      &:last-child {
        fill: ${props =>
          props.active ? props => CM(props.theme).iconActiveColor : props => CM(props.theme).iconColor};
      }
    }
  }
`;

export const Content = styled.div`
  height: 100%;
  max-height: 0;
  padding: 0px;
  overflow: hidden;
  transition: max-height 0.3s ease-out;
  will-change: max-height;

  ${breakpoints.tabletLandscape} {
    max-width: 90%;
    margin: 0;
  }

  > * {
      ${props => props.theme.setFont('accordionText')}
      padding: 0 ${props => props.theme.space()};

      p {
        margin-top: 0;
      }
    }

  &.active {
    max-height: ${props => `${props.contentHeight === 'auto' ? 'auto' : `${props.contentHeight + 20}px`}`};
    ${breakpoints.tabletLandscape} { 
      max-height: ${props => `${props.contentHeight === 'auto' ? 'auto' : `${props.contentHeight}px`}`};
    }
  }
`;

export const ContentWrap = styled.div``;

export const CTAWrap = styled.div`
  margin-top: ${props => props.theme.space()};

  ${breakpoints.tabletLandscape} {
    position: absolute;
    top: -13px;
    right: 0;
    margin: 0;
  }

  .accordion-list-cta {
    width: 100%;

    ${breakpoints.tabletLandscape} {
      width: unset;
    }

    span {
      width: ${props => (props.theme.website === 'london-marathon' ? 'calc(100% - 55px)' : '100%')};

      ${breakpoints.tabletLandscape} {
        width: auto;
      }
    }
  }
`;
