import styled from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';

export const SearchAndResultsComponent = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${breakpoints.tabletLandscape} {
    flex-direction: unset;
    margin-top: ${props => props.theme.px(50)};
  }
`;

export const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: -2ex;
  margin-bottom: 50px;
  padding-top: 2ex;

  ul {
    display: flex;
    flex-direction: column;
    margin: 0 26px;
    padding: 0;
    list-style: none;

    ${breakpoints.tabletLandscape} {
      margin: 0 61px;
    }

    > li {
      margin-bottom: 26px;
      &.article-card {
        height: 380px;

        ${breakpoints.tabletLandscape} {
          height: 481px;
        }
      }
    }
  }
`;

export const LoadMoreBtn = styled.button`
  ${props => props.theme.setFont('loadMore')}
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 55px;
  margin-bottom: 84px;
  font-weight: bold;
  background: transparent;
  border: none;
  cursor: pointer;

  svg {
    width: 24px;
    margin-right: 10px;
  }
`;
