import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { isServer } from '@sitecore-jss/sitecore-jss';
import {
  FilterComponent,
  FilterText,
  FilterComponentWrapper,
  Filter,
  Text,
} from './CategoryFilter.styles.js';

const CategoryFilter = React.forwardRef((props, ref) => {
  const {
    className,
    filterFn = null,
    filterLabels = [],
    t,
    selectedIndex = 0,
    applyStickyBorder = false,
  } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [isHeaderSticky, setIsHeaderSticky] = useState(false);
  const [listRefWidth, setListRefWidth] = useState(0);
  const listRef = useRef(null);
  const listItemsRef = useRef([]);
  const hasRunRef = useRef(false);

  const scrollToView = (index) => {
    if (listItemsRef.current[index]) {
      const listElement = listItemsRef.current[index];
      // Get the left position minus the page left margin to position it at the start
      ref.current.scrollLeft = listElement.offsetLeft - 26;
    }
  };

  function handleOnClick(index, label) {
    setActiveIndex(index);
    filterFn?.(index);

    // Sets the selected filter in the query string
    if ('URLSearchParams' in window) {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('filter', label.toLowerCase());
      let queryStr = searchParams.toString();
      queryStr = queryStr.replace(/\+/g, '%20');
      const newRelativePathQuery = window.location.pathname + '?' + queryStr;
      history.pushState(null, '', newRelativePathQuery);
    }
  }

  if (activeIndex !== selectedIndex) {
    setActiveIndex(selectedIndex);
  }

  if (!filterLabels.length) {
    return null;
  }

  const handleOrientationChange = () => {
    // fix for devices that don't handle sticky elements properly
    // forces a repaint
    if (ref?.current) {
      ref.current.style.position = 'static';
      setTimeout(() => {
        ref.current.style.position = 'sticky';
      }, 10);
    }
  };

  useEffect(() => {
    if (!isServer()) {
      window.addEventListener('orientationchange', handleOrientationChange);
    }

    return () => {
      if (!isServer()) {
        window.removeEventListener(
          'orientationchange',
          handleOrientationChange
        );
      }
    };
  }, []);

  useEffect(() => {
    if (applyStickyBorder) {
      const cachedRef = ref.current,
        observer = new IntersectionObserver(
          ([e]) => setIsHeaderSticky(e.intersectionRatio < 1),
          {
            threshold: [1],
          }
        );

      observer.observe(cachedRef);

      return () => {
        observer.unobserve(cachedRef);
      };
    }
  }, []);

  useEffect(() => {
    if (listRef.current) {
      setListRefWidth(listRef.current.getBoundingClientRect().width);
    }
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const presetFilter = searchParams.get('filter');

    if (!presetFilter) hasRunRef.current = true;

    if (activeIndex && !hasRunRef.current) {
      scrollToView(activeIndex);
      hasRunRef.current = true;
    }
  }, [activeIndex]);

  return (
    <FilterComponent
      ref={ref}
      id={className}
      className={`${className} ${isHeaderSticky ? 'sticky' : ''}`}
      listRefWidth={listRefWidth}
    >
      <FilterText>
        <Text>{t('FilterTitle')}</Text>
      </FilterText>
      <FilterComponentWrapper ref={listRef}>
        {filterLabels.map((label, index) => (
          <li
            key={index}
            data-filter-id={index}
            ref={(el) => (listItemsRef.current[index] = el)}
          >
            <Filter
              onClick={() => handleOnClick(index, label)}
              active={index === activeIndex}
            >
              {label}
            </Filter>
          </li>
        ))}
      </FilterComponentWrapper>
    </FilterComponent>
  );
});

CategoryFilter.displayName = 'CategoryFilter';

CategoryFilter.propTypes = {
  className: PropTypes.string,
  filterFn: PropTypes.func,
  filterLabels: PropTypes.array,
  t: PropTypes.func,
  selectedIndex: PropTypes.number,
  applyStickyBorder: PropTypes.bool,
};

export default CategoryFilter;
