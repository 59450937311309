import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { DesktopFiltersWrap, DesktopFilterItems, FilterTitle, CTAWrap } from './DesktopFilters.styles';
import CTA from '../../cta';
import { withTheme } from 'styled-components';
import { handleCtaMappingByTheme } from '../../cta/ctaMapper';

const DesktopFilters = ({
  checkedItems,
  setCheckedItems,
  generateFilters,
  filtersTitle,
  clearAllText,
  ctaTheme,
  theme: { website },
}) => {
  const filtersRef = useRef(null);

  const handleClearAll = () => {
    setCheckedItems([]);
  };

  return (
    <DesktopFiltersWrap ref={filtersRef}>
      <DesktopFilterItems>
        {filtersTitle && <p className="filterResultsTitle">{filtersTitle}</p>}
        <ul>
          {generateFilters().map((filter, index) => (
            <li key={index}>
              <DesktopFilterItem
                filters={filter}
                checkedItems={checkedItems}
                setCheckedItems={setCheckedItems}
              />
            </li>
          ))}
        </ul>
        {checkedItems.length ? (
          <CTAWrap website={website}>
            <CTA
              label={clearAllText}
              variant={handleCtaMappingByTheme(website, ctaTheme)}
              className="apply-filters-cta"
              icon="clear"
              onClick={() => handleClearAll()}
            />
          </CTAWrap>
        ) : null}
      </DesktopFilterItems>
    </DesktopFiltersWrap>
  );
};

const DesktopFilterItem = ({ setCheckedItems, checkedItems, filters }) => {
  const handleChange = event => {
    const filterName = event.target.dataset.name.toLowerCase();
    const filterValue = event.target.name.toLowerCase();

    if (!event.target.checked) {
      const findIndex = checkedItems.findIndex(
        item => item.name.toLowerCase() === filterName && item.value.toLowerCase() === filterValue
      );

      const duplicateCheckedItemsArray = [...checkedItems];
      duplicateCheckedItemsArray.splice(findIndex, 1);

      setCheckedItems(duplicateCheckedItemsArray);
    } else {
      setCheckedItems([
        ...checkedItems,
        {
          name: filterName,
          value: filterValue,
        },
      ]);
    }
  };

  return (
    filters.filter.values.length > 0 && (
      <>
        {filters.title && <FilterTitle className="filter-title">{filters.title}</FilterTitle>}
        {filters.filter.values.map((item, index) => (
          <label
            key={index}
            className={`${
              checkedItems.some(e => e.value.toLowerCase() === item.value.toLowerCase()) ? 'checked' : ''
            }`}
          >
            {item.name}
            <input
              type="checkbox"
              name={item.value}
              checked={checkedItems.some(e => e.value.toLowerCase() === item.value.toLowerCase())}
              onChange={handleChange}
              data-name={filters.filter.name}
            />
            <span className="checkmark"></span>
          </label>
        ))}
      </>
    )
  );
};

DesktopFilters.propTypes = {
  checkedItems: PropTypes.array,
  setCheckedItems: PropTypes.func,
  generateFilters: PropTypes.func,
  filtersTitle: PropTypes.string,
  clearAllText: PropTypes.string,
  ctaTheme: PropTypes.string,
  theme: PropTypes.object,
};

DesktopFilterItem.propTypes = {
  setCheckedItems: PropTypes.func,
  checkedItems: PropTypes.array,
  filters: PropTypes.object,
};

export default withTheme(DesktopFilters);
