const colorNames = {
  pink: '#f41c5e',
  orange: '#e84e1b',
  aqua: '#00a09a',
  white: '#ffffff',
  lime: '#95c11e',
  green: '#007e78',
  violet: '#29235c',
  black: '#000000',
  jet: '#3c3c3b',
  sonicSilver: '#706f6f',
  silver: '#c7c7c6',
  platinum: '#e3e3e3',
  cultured: '#f2f2f2',
};

export const colors = {
  primary: {
    100: colorNames.pink,
    200: colorNames.orange,
    300: colorNames.aqua,
    400: colorNames.white,
  },
  secondary: {
    100: colorNames.lime,
    200: colorNames.green,
    300: colorNames.violet,
    400: colorNames.black,
  },
  grays: {
    100: colorNames.jet,
    200: colorNames.sonicSilver,
    300: colorNames.silver,
    400: colorNames.platinum,
    500: colorNames.cultured,
    600: colorNames.white,
  },
  named: {
    pink: colorNames.pink,
    orange: colorNames.orange,
    aqua: colorNames.aqua,
    white: colorNames.white,
    lime: colorNames.lime,
    green: colorNames.green,
    violet: colorNames.violet,
    black: colorNames.black,
  },
};
