import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../../../tag';
import Picture from '../../../picture';
import { format } from 'date-fns';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import {
  HeadingWrap,
  ContentWrap,
  ReadTimeWrapper,
  EventWrap,
  ReadTimeNumber,
  ReadTimeText,
  EventDateWrapper,
  TextContrastGradient,
  Gradient,
} from './VariantTwo.styles';
import { useIsMobile } from '../../../hooks/useIsMobile';

const VariantTwo = props => {
  const isMobile = useIsMobile('tabletPortrait');
  const {
    fields: {
      title = {},
      cardImage = {},
      publicationDate: { value: publicationDate = null } = {},
      readingTime: { value: readingTime = null } = {},
      tag = {},
      gradient = {},
      secondaryImage = {},
    } = {},
    headingLevel = 3,
    sitecoreContext: { pageEditing = false } = {},
    translate = {},
    forceHover = false,
    theme: { website },
  } = props;
  const hasBgImg = secondaryImage?.value && Object.keys(secondaryImage.value).length > 0;
  const hasCardImage = cardImage?.value && Object.keys(cardImage.value).length > 0;

  const showGradient = pageEditing || (!hasBgImg && !hasCardImage) ? gradient?.value : null;

  const hasTag = tag?.fields && Object.keys(tag.fields).length > 0;

  const t = key => {
    if (translate && typeof translate === 'function') {
      return translate(`Article_${key}`);
    }
    return key;
  };

  const minText = t('Min');
  const minsText = t('Mins');
  const readingTimeVisibleCondition = website !== 'lm-foundation';
  const readingTimeText = parseInt(readingTime, 10) === 1 ? minText : minsText;

  return (
    <>
      {gradient && !hasBgImg && <Gradient gradient={showGradient} />}
      {hasBgImg && <Picture field={secondaryImage} />}
      {hasCardImage && (
        <img
          className={`card-image ${(cardImage.value.src || forceHover) && 'hasHover'}`}
          src={cardImage.value.src}
          alt={cardImage.value.alt}
        />
      )}
      {(hasBgImg || hasCardImage) && (
        <TextContrastGradient className="text-contrast-gradient" pageEditing={pageEditing} />
      )}
      <HeadingWrap hasTag={hasTag} className="pointer-events-disabled" gradient={showGradient}>
        <Tag
          textColour={tag?.fields?.textColour?.value}
          backgroundColour={tag?.fields?.colour?.value}
          label={tag?.fields?.title?.value}
          isPresentationalOnly
        />
        {isMobile && readingTime && readingTimeVisibleCondition && (
          <ReadTime
            time={readingTime}
            readingTimeText={readingTimeText}
            hasBgImg={hasBgImg}
            gradient={showGradient}
          />
        )}
      </HeadingWrap>
      <ContentWrap
        className="pointer-events-disabled"
        gradient={showGradient}
        readingTimeVisibleCondition={readingTimeVisibleCondition}
      >
        <EventWrap>
          {readingTime && readingTimeVisibleCondition && (
            <ReadTime
              time={readingTime}
              readingTimeText={readingTimeText}
              hasBgImg={hasBgImg}
              gradient={showGradient}
            />
          )}
        </EventWrap>
        {title && <Text className="title" field={title} tag={`h${headingLevel}`} />}
        {publicationDate && (
          <EventDateWrapper className="article-card-date" gradient={showGradient}>
            {format(Date.parse(publicationDate), 'dd-MM-yyyy')}
          </EventDateWrapper>
        )}
      </ContentWrap>
    </>
  );
};

const ReadTime = ({ time, readingTimeText, hasBgImg, gradient }) => (
  <ReadTimeWrapper hasBgImg={hasBgImg} className="read-time-wrapper" gradient={gradient}>
    {time && <ReadTimeNumber gradient={gradient}>{time}</ReadTimeNumber>}
    {readingTimeText && <ReadTimeText>{readingTimeText}</ReadTimeText>}
  </ReadTimeWrapper>
);

VariantTwo.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.object,
    gradient: PropTypes.object,
    heroTitle: PropTypes.object,
    cardImage: PropTypes.object,
    publicationDate: PropTypes.object,
    readingTime: PropTypes.object,
    tag: PropTypes.object,
  }),
  params: PropTypes.object,
  sitecoreContext: PropTypes.object,
  translate: PropTypes.func,
  forceHover: PropTypes.bool,
  headingLevel: PropTypes.number,
  theme: PropTypes.shape({
    website: PropTypes.string,
  }),
};

ReadTime.propTypes = {
  time: PropTypes.number,
  readingTimeText: PropTypes.string,
  hasBgImg: PropTypes.bool,
  gradient: PropTypes.string,
};

export default VariantTwo;
