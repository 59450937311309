import React from 'react';
import { getIcon } from '../../utils/icon';

export const CTAHTML = (icon, label, socialIcon) => {
  const RideLondonHTML = CTAType => {
    if (CTAType.toLowerCase() === 'c') {
      return socialIcon ? getIcon(socialIcon.toLowerCase()) : null;
    }

    return (
      <>
        <span>
          {label} {getIcon(icon)}
        </span>
      </>
    );
  };

  return RideLondonHTML;
};
