import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { AnchorMenuComponent, AnchorLink, AnchorLinksContainer } from './AnchorMenu.styles.js';
import { useIsMobile } from '../hooks/useIsMobile';
import { useSwipeable } from 'react-swipeable';
import { isServer } from '@sitecore-jss/sitecore-jss';

const IgnoreReactRouterAnchorTag = props => {
  const go = e => {
    e.preventDefault();
    const scrollToEl = document.getElementById(props.anchor);
    scrollToEl?.scrollIntoView();
  };

  return (
    <a href="" onClick={go}>
      {props.text}
    </a>
  );
};

IgnoreReactRouterAnchorTag.propTypes = {
  anchor: PropTypes.string,
  text: PropTypes.string,
};

const AnchorMenu = props => {
  const {
    fields: { links: { value: anchorLinks = [] } } = {},
    params: { spaceMultiplierMobile = '1', spaceMultiplierDesktop = '1' },
  } = props;

  const isMobile = useIsMobile('desktopLarge');
  const [currentAnchorPoint, setCurrentAnchorPoint] = useState(null);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [mobileOverflow, setMobileOverflow] = useState(false);

  const anchorLinkListContainerRef = useRef();
  const outerRef = useRef();
  const anchorRefs = anchorLinks?.reduce((acc, el) => {
    acc[el?.anchor] = useRef();
    return acc;
  }, {});

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  const onEnterScreen = elements => {
    elements.forEach(el => {
      if (el.isIntersecting) {
        const targetId = el.target.id;
        setCurrentAnchorPoint(targetId);
      }
    });
  };

  const onEnterScreenFake = elements => {
    elements.forEach(el => {
      if (el.isIntersecting) {
        setCurrentAnchorPoint(null);
      }
    });
  };

  const onSwipe = dir => {
    const nextIndex = scrollIndex + dir;
    if (nextIndex >= 0 && nextIndex < anchorLinks.length) {
      setScrollIndex(nextIndex);
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => onSwipe(1),
    onSwipedRight: () => onSwipe(-1),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
    trackTouch: true,
  });

  const refPassthrough = el => {
    // call useSwipeable ref prop with el
    swipeHandlers.ref(el);
    // set myRef el so you can access it yourself
    outerRef.current = el;
  };

  const handleAnchorClick = (link, index) => {
    dataLayerEvent('navAnchor', {
      clickText: link?.text,
      clickURL: link?.href,
    });

    setScrollIndex(index);
  };

  const handleResize = () => {
    if (!isServer()) {
      const anchorLinkListPadding = 122;
      const overflow =
        anchorLinkListContainerRef.current.scrollWidth > window.innerWidth - anchorLinkListPadding;
      setMobileOverflow(overflow);
    }
  };

  useEffect(() => {
    if (!mobileOverflow) {
      anchorLinkListContainerRef.current.style.transform = `translateX(0px)`;
    }
  }, [mobileOverflow]);

  useEffect(() => {
    const anchorRef = anchorRefs?.[anchorLinks[scrollIndex]?.anchor];
    if (isMobile && anchorRef && mobileOverflow) {
      const xScrollAmount = 0 - anchorRef.current.offsetLeft;
      anchorLinkListContainerRef.current.style.transform = `translateX(${xScrollAmount}px)`;
    }
  }, [scrollIndex]);

  useEffect(() => {
    if (isMobile && anchorRefs[currentAnchorPoint] && mobileOverflow) {
      const xScrollAmount = 0 - anchorRefs[currentAnchorPoint].current.offsetLeft;
      anchorLinkListContainerRef.current.style.transform = `translateX(${xScrollAmount}px)`;
    }
  }, [currentAnchorPoint]);

  useEffect(() => {
    const observer = new IntersectionObserver(onEnterScreen, {
      root: null,
      rootMargin: '0px 0px -90% 0px',
      threshold: [0, 1],
    });

    const observerFake = new IntersectionObserver(onEnterScreenFake, {
      root: null,
      rootMargin: '0px 0px 0px 0px',
      threshold: [0, 1],
    });

    const fakeAnchor = document.createElement('div');
    fakeAnchor.classList.add('fakeAnchor');
    outerRef.current.parentNode.insertBefore(fakeAnchor, outerRef.current);
    observerFake.observe(fakeAnchor);

    Array.from(document.querySelectorAll('.anchorPoint')).forEach(el => {
      observer.observe(el);
    });

    if (!isServer()) {
      window.addEventListener('resize', handleResize);
      handleResize();
    }

    return () => {
      window.removeEventListener('resize', handleResize);
      fakeAnchor?.parentNode?.removeChild(fakeAnchor);
    };
  }, []);

  return (
    <AnchorMenuComponent
      className="limitedWidth"
      id="secondary-nav"
      {...swipeHandlers}
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
      ref={refPassthrough}
      aria-label="Secondary Navigation"
    >
      <AnchorLinksContainer ref={anchorLinkListContainerRef} mobileOverflow={mobileOverflow}>
        {anchorLinks.map((link, i) => (
          <AnchorLink
            key={i}
            ref={anchorRefs[link?.anchor]}
            inView={currentAnchorPoint === link?.anchor ? 1 : 0}
            onClick={() => handleAnchorClick(link, i)}
          >
            <IgnoreReactRouterAnchorTag text={link.text} anchor={link.anchor} />
          </AnchorLink>
        ))}
      </AnchorLinksContainer>
    </AnchorMenuComponent>
  );
};

AnchorMenu.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
};

export default AnchorMenu;
