import React from 'react';
import PropTypes from 'prop-types';
import Tag from '../../../tag';
import Picture from '../../../picture';
import { format, isValid } from 'date-fns';
import { Text } from '@sitecore-jss/sitecore-jss-react';
import {
  HeadingWrap,
  ContentWrap,
  ReadTimeWrapper,
  EventWrap,
  ReadTimeNumber,
  ReadTimeText,
  EventDateWrapper,
  TextContrastGradient,
  Gradient,
} from './VariantFour.styles.js';
import { useIsMobile } from '../../../hooks/useIsMobile';

const VariantFour = props => {
  const isMobile = useIsMobile('tabletPortrait');
  const {
    fields: {
      title = {},
      publicationDate = {},
      readingTime = {},
      tag = {},
      heroImage = {},
      gradient = null,
    } = {},
    sitecoreContext: { pageEditing = false } = {},
    translate = {},
    headingLevel = 3,
    theme: { website },
  } = props;

  const t = key => {
    if (translate && typeof translate === 'function') {
      return translate(`Article_${key}`);
    }
    return key;
  };

  const minText = t('Min');
  const minsText = t('Mins');
  const readingTimeText = parseInt(readingTime, 10) === 1 ? minText : minsText;
  const readingTimeVisibleCondition = website !== 'lm-foundation';

  let hasBgImg = heroImage?.value && Object.keys(heroImage.value).length > 0;
  if (hasBgImg) {
    hasBgImg = !!heroImage.value.sources;
  }

  const showGradient = pageEditing || !hasBgImg ? gradient : null;

  const date = Date.parse(publicationDate);

  return (
    <>
      {gradient && !hasBgImg && <Gradient gradient={showGradient} />}
      {hasBgImg && <Picture editable={false} field={heroImage} />}
      {hasBgImg && <TextContrastGradient pageEditing={pageEditing} />}
      <HeadingWrap gradient={showGradient}>
        <Tag
          textColour={tag?.textColour}
          backgroundColour={tag?.colour}
          label={tag?.title}
          isPresentationalOnly
        />
        {isMobile && readingTime && readingTimeVisibleCondition && (
          <ReadTime
            hasBgImg={hasBgImg}
            time={readingTime}
            readingTimeText={readingTimeText}
            gradient={showGradient}
          />
        )}
      </HeadingWrap>
      <ContentWrap gradient={showGradient} readingTimeVisibleCondition={readingTimeVisibleCondition}>
        {title && <Text field={title.value ? title : { value: title }} tag={`h${headingLevel}`} />}
        {date && isValid(date) && (
          <EventDateWrapper className="article-card-date" gradient={showGradient}>
            {format(date, 'dd-MM-yyyy')}
          </EventDateWrapper>
        )}
        {!isMobile && readingTime && readingTimeVisibleCondition && (
          <EventWrap>
            <ReadTime
              hasBgImg={hasBgImg}
              time={readingTime}
              readingTimeText={readingTimeText}
              gradient={showGradient}
            />
          </EventWrap>
        )}
      </ContentWrap>
    </>
  );
};

const ReadTime = ({ time, readingTimeText, hasBgImg, gradient }) => (
  <ReadTimeWrapper hasBgImg={hasBgImg} gradient={gradient}>
    {time && <ReadTimeNumber gradient={gradient}>{time}</ReadTimeNumber>}
    {readingTimeText && <ReadTimeText>{readingTimeText}</ReadTimeText>}
  </ReadTimeWrapper>
);

VariantFour.propTypes = {
  fields: PropTypes.shape({
    title: PropTypes.any,
    gradient: PropTypes.string,
    heroTitle: PropTypes.object,
    heroImage: PropTypes.object,
    publicationDate: PropTypes.string,
    readingTime: PropTypes.string,
    tag: PropTypes.object,
  }),
  params: PropTypes.object,
  sitecoreContext: PropTypes.object,
  translate: PropTypes.func,
  forceHover: PropTypes.bool,
  headingLevel: PropTypes.number,
};

ReadTime.propTypes = {
  time: PropTypes.string,
  readingTimeText: PropTypes.string,
  hasBgImg: PropTypes.bool,
  gradient: PropTypes.string,
};

export default VariantFour;
