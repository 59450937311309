import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Image } from '@sitecore-jss/sitecore-jss-react';
import { isServer } from '@sitecore-jss/sitecore-jss';
import { format } from 'date-fns';
import { getIcon } from '../utils/icon';
import {
  ArticleMeta,
  Wrapper,
  HeadingWrap,
  AuthorWrap,
  Author,
  Name,
  ArticleInfoWrap,
  ArticleInfo,
  BodyWrap,
  SocialShare,
  TagWrap,
} from './ArticleMeta.styles.js';
import Tag from '../tag';
import { withTheme } from 'styled-components';

const ArticleMetaComponent = props => {
  const {
    fields: {
      author = {},
      publicationDate: { value: publicationDate = null } = {},
      readingTime: { value: readingTime = null } = {},
      tags = [],
    } = {},
    theme: { website },
    translate = {},
  } = props;

  const [pageUrl, setPageUrl] = useState('');

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  const gtmEvent = socialChannel => {
    if (!isServer()) {
      dataLayerEvent('socialShare', {
        socialChannel,
        pagePath: window.location.pathname,
      });
    }
  };

  useEffect(() => {
    setPageUrl(window.location.origin + window.location.pathname);
  }, []);

  const t = key => {
    if (translate && typeof translate === 'function') {
      return translate(`ArticleMeta_${key}`);
    }
    return key;
  };

  const minsText = t('Mins');
  const shareArticleText = t('Share');
  const tagsText = t('Tags');

  const commonLinkProps = {
    target: '_blank',
    rel: 'noreferrer',
  };

  const hasAuthor = author?.fields && Object.keys(author?.fields).length > 0;

  const hasAuthorImage =
    hasAuthor &&
    author?.fields.profileImage?.value &&
    Object.keys(author?.fields.profileImage?.value).length > 0;

  const hasAuthorName =
    hasAuthor && author?.fields.authorName?.value && Object.keys(author?.fields.authorName?.value).length > 0;

  return (
    <ArticleMeta>
      <Wrapper>
        <HeadingWrap>
          {hasAuthorImage && (
            <Author>
              <Image field={author.fields.profileImage} />
            </Author>
          )}
          <ArticleInfoWrap>
            <AuthorWrap>{hasAuthorName && <Name>{author.fields.authorName.value}</Name>}</AuthorWrap>
            {website != 'lm-foundation' && (
              <ArticleInfo>
                {publicationDate && (
                  <p>
                    {format(Date.parse(publicationDate), 'MMM d, yyyy')} <span>&middot;</span>
                  </p>
                )}
                {readingTime && (
                  <p>
                    {readingTime} {minsText}
                  </p>
                )}
              </ArticleInfo>
            )}
          </ArticleInfoWrap>
        </HeadingWrap>
        <BodyWrap>
          {pageUrl && (
            <SocialShare>
              <p>{shareArticleText}</p>
              <ul>
                <li>
                  <a
                    onClick={() => gtmEvent('facebook')}
                    href={`https://www.facebook.com/sharer/sharer.php?u=${pageUrl}`}
                    aria-label="Share article on Facebook"
                    {...commonLinkProps}
                  >
                    {getIcon('facebook')}
                  </a>
                </li>
                <li>
                  <a
                    onClick={() => gtmEvent('twitter')}
                    href={`https://twitter.com/intent/tweet?url=${pageUrl}`}
                    aria-label="Share article on Twitter"
                    {...commonLinkProps}
                  >
                    {getIcon('twitter')}
                  </a>
                </li>
              </ul>
            </SocialShare>
          )}
          <TagWrap>
            <p>{tagsText}</p>
            <ul>
              {tags.map((tag, index) => (
                <li key={index}>
                  <Tag
                    textColour={tag.fields?.textColour?.value}
                    backgroundColor={tag.fields?.backgroundColor?.value}
                    label={tag.fields?.title?.value}
                    isPresentationalOnly
                  />
                </li>
              ))}
            </ul>
          </TagWrap>
        </BodyWrap>
      </Wrapper>
    </ArticleMeta>
  );
};

ArticleMetaComponent.propTypes = {
  fields: PropTypes.shape({
    author: PropTypes.shape({
      fields: PropTypes.shape({
        authorName: PropTypes.object,
        profileImage: PropTypes.object,
      }),
    }),
    publicationDate: PropTypes.object,
    readingTime: PropTypes.object,
    tags: PropTypes.array,
  }),
  theme: PropTypes.object,
  translate: PropTypes.func,
};

export default withTheme(ArticleMetaComponent);
