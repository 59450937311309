import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { getIcon } from '../utils/icon';
import {
  ArticleRowCarouselContainer,
  CarouselButton,
  CarouselContainer,
  CarouselOuter,
  CarouselItem,
  CarouselNavigation,
} from './ArticleRowCarousel.styles';

const ArticleRowCarousel = props => {
  const { children } = props;
  const itemsPerRow = 3;
  const [currentPage, setCurrentPage] = useState(0);

  const generateCarouselPages = carouselItems => {
    const carouselPages = [];
    let currentIndex = -1;
    carouselItems.forEach((carouselItem, i) => {
      if (i % itemsPerRow === 0) {
        currentIndex += 1;
        if (!carouselPages[currentIndex]) {
          carouselPages[currentIndex] = [];
        }
      }
      carouselPages[currentIndex].push(carouselItems[i]);
    });

    return carouselPages;
  };

  const carouselPages = generateCarouselPages(children);
  const noCarousel = carouselPages.length <= 1;

  const changePage = direction => {
    const totalPages = carouselPages.length - 1;
    let nextPage = currentPage + direction;
    if (nextPage === -1) {
      nextPage = totalPages;
    } else if (nextPage > totalPages) {
      nextPage = 0;
    }
    setCurrentPage(nextPage);
  };

  return (
    <ArticleRowCarouselContainer>
      <CarouselOuter>
        {!noCarousel && (
          <CarouselButton onClick={() => changePage(-1)} aria-label="Previous slide">
            {getIcon('chevronOutline')}
          </CarouselButton>
        )}
        <CarouselContainer noCarousel={noCarousel}>
          {carouselPages[currentPage]?.map((carouselPage, i) => (
            <CarouselItem noCarousel={noCarousel} key={i}>
              {carouselPage}
            </CarouselItem>
          ))}
        </CarouselContainer>
        {!noCarousel && (
          <CarouselButton aria-label="Next slide" flipped onClick={() => changePage(1)}>
            {getIcon('chevronOutline')}
          </CarouselButton>
        )}
      </CarouselOuter>
      {!noCarousel && (
        <CarouselNavigation>
          {carouselPages.map((page, i) => (
            <li key={i}>
              <button
                onClick={() => setCurrentPage(i)}
                aria-label={`Go to slide number ${i + 1}`}
                className={i === currentPage ? 'selected' : null}
              ></button>
            </li>
          ))}
        </CarouselNavigation>
      )}
    </ArticleRowCarouselContainer>
  );
};

ArticleRowCarousel.propTypes = {
  children: PropTypes.node,
};

export default ArticleRowCarousel;
