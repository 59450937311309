import styled, { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { TagComponentPresentational } from '../tag/Tag.styles';
import { mapColourToSiteTheme as CM } from './colourMap.js';

export const SearchHeroComponent = styled.form`
  position: relative;
  height: 160px;
  margin-bottom: ${props => props.theme.space(props.spaceMultiplierMobile)};
  background-image: ${props => props.theme.gradients[props.gradient]};

  ${breakpoints.tabletPortrait} {
    height: 380px;
  }

  ${breakpoints.tabletLandscape} {
    display: flex;
    height: 389px;
    margin: 0 ${props => props.theme.px(26)} ${props => props.theme.space(props.spaceMultiplierDesktop)};
  }
`;

export const SearchWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 100%;
  margin: ${props => `0 ${props.theme.px(26)}`};

  ${breakpoints.tabletLandscape} {
    width: 60%;
  }

  p {
    ${props => props.theme.setFont('searchHeroIntro')}
    width: 100%;
    font-weight: bold;
  }

  > p {
    color: ${props => CM(props.theme).introText};
  }
`;

export const InputWrapper = styled.div`
  position: relative;
  width: 100%;
  border-bottom: ${props => `1px solid ${CM(props.theme).searchInputBorderBottom}`};

  &:after {
    position: absolute;
    bottom: -1px;
    left: 0;
    width: ${props => props.inputWidth}px;
    max-width: 100%;
    height: 1px;
    background-color: ${props => CM(props.theme, props.gradient).inputUnderline};
    transition: width 0.1s ease-in-out;
    content: '';
  }

  input {
    ${props => props.theme.setFont('searchHeroInput')}
    position: relative;
    z-index: 2;
    width: 100%;
    max-width: 90%;
    padding-bottom: ${props => props.theme.px(3)};
    padding-left: 0px;
    color: ${props => CM(props.theme).inputText};
    font-weight: bold;
    background: transparent;
    border: none;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: ${props => CM(props.theme).inputPlaceholderText};
      opacity: 0.7;
    }

    ${breakpoints.tabletPortrait} {
      padding-bottom: ${props => props.theme.px(20)};
    }
  }

  button {
    position: absolute;
    right: -4px;
    height: 100%;
    padding: 0;
    background: transparent;
    border: none;

    ${breakpoints.tabletLandscape} {
      right: 0;
    }

    svg {
      path {
        fill: ${props => CM(props.theme).searchButtonFill};
      }
    }
  }

  > span {
    ${props => props.theme.setFont('searchHeroInput')}
    position: absolute;
    display: block;
    width: auto;
    height: auto;
    font-weight: bold;
    white-space: nowrap;
    visibility: hidden;
  }
`;

export const ImageWrapper = styled.div`
  display: none;

  ${breakpoints.tabletLandscape} {
    display: block;
  }
`;

const patternLayout = name => {
  const layout = {
    'twenty-six-degree-mark': {
      desktop: css`
        top: 50%;
        right: ${props => props.theme.space(3)};
        height: 60%;
        transform: translateY(-50%);
      `,
    },
    'the-medal-thin': {
      desktop: css`
        top: 0%;
        right: 0;
        height: 100%;
      `,
    },
    'the-steps': {
      desktop: css`
        top: 0%;
        right: 0;
        height: 65%;
        transform: rotate(180deg);
      `,
    },
    'tower-bridge': {
      desktop: css`
        top: 0;
        right: 0;
        height: 100%;
      `,
    },
    'scattered-circles': {
      desktop: css`
        top: 10%;
        right: 0;
        height: 80%;
      `,
    },
    'diagonal-chevrons': {
      desktop: css`
        top: 10%;
        right: 0;
        height: 80%;
      `,
    },
    'film-reel': {
      desktop: css`
        top: 10%;
        right: 0;
        height: 80%;
      `,
    },
    'multiple-circles': {
      desktop: css`
        top: 10%;
        right: 2%;
        height: 70%;
      `,
    },
    'parallel-lines': {
      desktop: css`
        top: 5%;
        right: 5%;
        height: 90%;
      `,
    },
    peak: {
      desktop: css`
        top: 20%;
        right: 0%;
        height: 80%;
      `,
    },
    waves: {
      desktop: css`
        top: 10%;
        right: 0%;
        height: 80%;
      `,
    },
    'circle-outlines': {
      desktop: css`
        top: 0;
        right: 0;
        height: 80%;
      `,
    },
    'triangle-outlines': {
      desktop: css`
        top: 0;
        right: 0;
        height: 80%;
      `,
    },
    power: {
      desktop: css`
        top: 0;
        right: 0;
        height: 100%;
      `,
    },
    unity: {
      desktop: css`
        top: 0;
        right: 0;
        height: 100%;
      `,
    },
    'shape-of-water-01': {
      desktop: css`
        top: -10%;
        right: -2%;
        height: 120%;
      `,
    },
    'shape-of-water-02': {
      desktop: css`
        top: -5%;
        right: 0;
        height: 110%;
      `,
    },
    'shape-of-water-03': {
      desktop: css`
        top: -5%;
        right: -1%;
        height: 120%;
        transform: rotate(180deg);
      `,
    },
    'shape-of-water-04': {
      desktop: css`
        top: -5%;
        right: -2%;
        height: 110%;
      `,
    },
    frame: {
      desktop: css`
        top: -3%;
        right: -4.5%;
        height: 106%;
      `,
    },
    handlebars: {
      desktop: css`
        top: -3%;
        right: 0;
        height: 108%;
      `,
    },
    wheels: {
      desktop: css`
        top: -2%;
        right: -1%;
        height: 107%;
      `,
    },
    'landscape-outlined': {
      desktop: css`
        top: -1%;
        right: -50px;
        height: 103%;
      `,
      desktopMedium: css`
        top: -1%;
        right: -0.5%;
        height: 103%;
      `,
    },
    'on-your-marks': {
      desktop: css`
        top: 10%;
        right: 2%;
        height: 80%;
      `,
    },
    'on-your-marks-alt': {
      desktop: css`
        top: 10%;
        right: 2%;
        height: 80%;
      `,
    },
    'get-set': {
      desktop: css`
        top: 10%;
        right: 3%;
        height: 80%;
      `,
    },
    go: {
      desktop: css`
        top: -2%;
        right: 2%;
        height: 105%;
      `,
    },
    'transformation-full': {
      desktop: css`
        top: -12%;
        right: -50px;
        height: 130%;
      `,
      desktopMedium: css`
        top: -12%;
        right: 0px;
        height: 130%;
      `,
    },
  };

  if (name in layout) return layout[name];

  return 'twenty-six-degree-mark';
};

export const Pattern = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 1;
  width: ${props => (props.pageEditing ? '50%' : '100%')};
  height: 100%;
  margin-left: ${props => (props.pageEditing ? '50%' : 'unset')};
  overflow: hidden;

  svg {
    ${breakpoints.tabletLandscape} {
      position: absolute;
      ${props => patternLayout(props.patternName)?.desktop}
    }

    ${breakpoints.desktopMedium} {
      ${props => patternLayout(props.patternName)?.desktopMedium}
    }

    path {
      fill: ${props => props.theme.colors.named[props.patternColor]};
    }
  }
`;

export const ResultWrapper = styled.div`
  position: absolute;
  bottom: -${props => props.resultsHeight + 3}px;
  left: 0;
  z-index: 100;
  width: 100%;
  padding: 7px;
  background-color: ${props => CM(props.theme).resultsWrapperBackground};
  opacity: ${props => (props.resultsHeight === 0 ? 0 : 1)};

  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      &:not(:first-child) {
        margin-top: ${props => props.theme.px(4)};

        ${breakpoints.tabletLandscape} {
          margin-top: ${props => props.theme.px(7)};
        }
      }
    }
  }
`;

export const LayerImage = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: ${props => (props.pageEditing ? 0 : 1)};
  width: ${props => (props.pageEditing ? '50%' : '100%')};
  height: 100%;
  margin-left: ${props => (props.pageEditing ? '50%' : 'unset')};

  img {
    position: absolute;
    right: 0;
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
  }
`;

export const ResultContainer = styled.a`
  position: relative;
  display: block;
  height: 100%;
  padding: 13px 14px 20px;
  text-decoration: none;
  background-color: ${props => CM(props.theme).resultsContainerBackground};

  &:hover {
    svg {
      transform: translateX(7px);
      transition: transform 0.3s ease-out;
    }
  }

  ${breakpoints.tabletLandscape} {
    display: flex;
    align-items: center;
    padding: 20px 30px 20px 20px;
  }

  ${TagComponentPresentational} {
    width: auto;
    margin-bottom: ${props => props.theme.px(12)};
    white-space: nowrap;
    cursor: pointer;

    ${breakpoints.tabletLandscape} {
      margin-bottom: 0;
    }
  }

  svg {
    display: none;

    ${breakpoints.tabletLandscape} {
      position: absolute;
      right: 0;
      display: block;
      width: 30px;
      margin-right: ${props => props.theme.px(20)};

      path {
        fill: ${props => CM(props.theme).resultsIconFill};
      }
    }
  }
`;

export const ResultsTitle = styled.p`
  && {
    ${props => props.theme.setFont('searchResultHeading')}
    margin-top: 0;
    margin-right: ${props => props.theme.px(30)};
    margin-bottom: 0;
    color: ${props => CM(props.theme).resultsTitleText};

    span {
      color: ${props => CM(props.theme).resultsTitleHighlight};
    }

    ${breakpoints.desktopSmall} {
      margin-left: 0;
      line-height: ${props => props.theme.px(29)};
    }
  }
`;
