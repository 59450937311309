import React from 'react';
import PropTypes from 'prop-types';
import { TagComponent, TagComponentPresentational } from './Tag.styles.js';

const Tag = React.forwardRef((props, ref) => {
  const {
    label = null,
    link = {},
    isPresentationalOnly = false,
    textColour = null,
    backgroundColour = null,
    className = null,
  } = props;

  const commonProps = {
    textColour,
    backgroundColour,
    ref,
    className,
  };

  return (
    label && (
      <>
        {isPresentationalOnly ? (
          <TagComponentPresentational {...commonProps}>{label}</TagComponentPresentational>
        ) : (
          <TagComponent rel="tag" href={link?.href} {...commonProps}>
            {label}
          </TagComponent>
        )}
      </>
    )
  );
});

Tag.displayName = 'Tag';

Tag.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
  link: PropTypes.object,
  isPresentationalOnly: PropTypes.bool,
  textColour: PropTypes.string,
  backgroundColour: PropTypes.string,
  className: PropTypes.string,
};

export default Tag;
