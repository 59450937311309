const colorNames = {
  sponsorPink: '#e41165',
  black: '#000000',
  offBlack: '#00144B',
  white: '#ffffff',
  golden: '#ffd810',
  blueRYB: '#0f37ff',
  vividSky: '#00cff8',
  winterSky: '#e41165',
  seaGreen: '#00ffc5',
  aquaBlue: '#32ffff',
  cultured: '#f5f5f5',
  platinum: '#e1e1e1',
  grayX: '#b9b9b9',
  spanishGray: '#919191',
  davysGray: '#555555',
  jet: '#373737',
  bubblePink: '#FF34A1',
  summerOrange: '#FFC834',
  dashingPurple: '#5B34FF',
  britishBlue: '#34A9FF',
};

export const colors = {
  primary: {
    100: colorNames.sponsorPink,
    200: colorNames.black,
    300: colorNames.white,
  },
  secondary: {
    100: colorNames.golden,
    200: colorNames.blueRYB,
    300: colorNames.vividSky,
    400: colorNames.winterSky,
    500: colorNames.seaGreen,
    600: colorNames.aquaBlue,
    700: colorNames.bubblePink,
    800: colorNames.summerOrange,
    900: colorNames.dashingPurple,
    1000: colorNames.britishBlue,
  },
  grays: {
    100: colorNames.cultured,
    200: colorNames.platinum,
    300: colorNames.grayX,
    400: colorNames.spanishGray,
    500: colorNames.davysGray,
    600: colorNames.jet,
  },
  named: {
    white: colorNames.white,
    black: colorNames.black,
    offBlack: colorNames.offBlack,
    'blue-ryb': colorNames.blueRYB,
    golden: colorNames.golden,
    'sea-green': colorNames.seaGreen,
    'sponsor-red': colorNames.sponsorPink,
    'vivid-sky': colorNames.vividSky,
    'winter-sky': colorNames.winterSky,
  },
};
