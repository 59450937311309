export const spacer = (spaceSize, baseUnits, baseSize) => (count = 1, noUnits = false) => {
  const pixelValue = spaceSize * count;
  switch (baseUnits) {
    case 'rem':
      return noUnits ? pixelValue / baseSize : `${pixelValue / baseSize}rem`;
    case 'px':
    default:
      return noUnits ? pixelValue : `${pixelValue}px`;
  }
};
