export const breakpointValues = {
  mobile: 375,
  mobileLarge: 414,
  tabletPortrait: 768,
  tabletLandscape: 1024,
  desktopSmall: 1280,
  desktopMedium: 1366,
  desktopLarge: 1666,
  desktopExtraLarge: 1920,
};

export const breakpoints = {
  mobile: `@media screen and (min-width: ${breakpointValues.mobile}px)`,
  mobileLarge: `@media screen and (min-width: ${breakpointValues.mobileLarge}px)`,
  tabletPortrait: `@media screen and (min-width: ${breakpointValues.tabletPortrait}px)`,
  tabletLandscape: `@media screen and (min-width: ${breakpointValues.tabletLandscape}px)`,
  desktopSmall: `@media screen and (min-width: ${breakpointValues.desktopSmall}px)`,
  desktopMedium: `@media screen and (min-width: ${breakpointValues.desktopMedium}px)`,
  desktopLarge: `@media screen and (min-width: ${breakpointValues.desktopLarge}px)`,
  desktopExtraLarge: `@media screen and (min-width: ${breakpointValues.desktopExtraLarge}px)`,
};
