import styled, { css } from 'styled-components';
import { breakpoints } from '../global-styles/breakpoints';
import { mapColourToSiteTheme as CM } from './colourMap.js';

const adEditingCSS = css`
  width: 480px;
  height: 60px;
`;

export const HeaderAdsComponent = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
  background-color: ${props => CM(props.theme).backgroundColor};
  position: relative;
  z-index: 100;

  &.hidden {
    display: none;
  }
`;

export const AdContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: calc(100% - 70px);
  overflow: hidden;

  ${breakpoints.tabletPortrait} {
    max-width: calc(100% - 25px);
  }

  > div {
    z-index: 101;
    margin-right: 10px;

    ${props => (props.editing ? adEditingCSS : null)}

    &:first-child {
      margin-bottom: 10px;

      ${breakpoints.tabletLandscape} {
        margin-bottom: 0px;
      }
    }
  }

  ${breakpoints.tabletLandscape} {
    flex-direction: row;
  }
`;

export const CloseContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -20px;
  padding-left: 15px;
  max-width: 16px;

  ${breakpoints.mobileLarge} {
    margin-right: -30px;
    padding-left: 0;
    max-width: 16px;
  }

  ${breakpoints.tabletPortrait} {
    margin-right: unset;
    padding-left: unset;
    max-width: 16px;
  }

  svg {
    width: 16px;
    cursor: pointer;
    fill: ${props => CM(props.theme).closeSvgColor};
  }
`;
