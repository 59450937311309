import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import ArticleCard from '../article-card/ArticleCard';
import {
  ArticleListComponent,
  FeaturedArticleComponent,
  FilteredResultsGrid,
  LoadMore,
  NoResults,
} from './ArticleList.styles.js';
import ArticleListRow from './ArticleListRow';
import CategoryFilter from '../category-filter';
import EditFrame from '../edit-frame';
import { getIcon } from '../utils/icon';
import { loader } from 'graphql.macro';
import { useLazyQuery } from '@apollo/react-hooks';
import { useIsMobile } from '../hooks/useIsMobile';
import InfiniteScroll from 'react-infinite-scroller';
import { isServer } from '@sitecore-jss/sitecore-jss';

const FETCH_ARTICLES = loader('./FetchArticles.graphql');

const ArticleList = (props) => {
  const {
    fields: { rows = [], filters = [], contentType = {} } = {},
    translate = {},
    params: { spaceMultiplierDesktop = '1', spaceMultiplierMobile = '1' },
  } = props;

  const t = (key) => {
    if (translate && typeof translate === 'function') {
      return translate(`ArticleList_${key}`);
    }
    return key;
  };

  const filterResultsOffsetRef = useRef();
  const categoryFilterRef = useRef();

  const highlightsLabel = t('HighlightsFilter');
  const allFilterLabel = t('AllFilter');
  const loadMoreLabel = t('LoadMore');
  const noResultsLabel = t('NoResults');

  const filterLabels = filters.map((filter) => filter?.title?.value);
  filterLabels.unshift(highlightsLabel, allFilterLabel);

  const filterIds = filters.map((filter) => filter?.id);
  filterIds.unshift('', '');

  const isMobile = useIsMobile();
  const resultsPerRequest = isMobile ? 8 : 12;
  const [selectedFilterIndex, setSelectedFilterIndex] = useState(0);
  const [currentFilterPage, setCurrentFilterPage] = useState(0);
  const [totalFilterResults, setTotalFilterResults] = useState(0);
  const [noResults, setNoResults] = useState(false);
  const [articleList, setArticleList] = useState([]);
  const [isPreselectedFilter, setIsPreselectedFilter] = useState(true);

  const [fetchArticles, { loading, data }] = useLazyQuery(FETCH_ARTICLES, {
    fetchPolicy: 'no-cache',
  });

  const dataLayerEvent = (eventName, variables = {}) => {
    if (!isServer()) {
      window.dataLayer.push({ event: eventName, ...variables });
    }
  };

  const handleFilterChange = (index) => {
    if (index === selectedFilterIndex) {
      return;
    }
    dataLayerEvent('blogFilter', {
      clickText: filterLabels[index],
    });
    setIsPreselectedFilter(false);
    setNoResults(false);
    setSelectedFilterIndex(index);
    setCurrentFilterPage(0);
    setArticleList([]);
  };

  const setFilter = (tag) => {
    const index = filterIds.indexOf(tag);
    if (index > -1) {
      setSelectedFilterIndex(index);
    }
  };

  useEffect(() => {
    if (selectedFilterIndex > 0) {
      fetchArticles({
        variables: {
          articleType: contentType?.value || '',
          tags: [filterIds[selectedFilterIndex]],
          resultCount: resultsPerRequest,
          resultStartIndex: (currentFilterPage * resultsPerRequest).toString(),
        },
      });
    }
  }, [selectedFilterIndex, currentFilterPage]);

  useEffect(() => {
    if (!loading) {
      if (data?.articles?.results?.items?.length) {
        setNoResults(false);
        setArticleList([...articleList, ...data.articles.results.items]);
      } else {
        setNoResults(true);
        setArticleList([]);
      }
      setTotalFilterResults(data?.articles?.results?.totalCount || 0);
    }
  }, [data]);

  useEffect(() => {
    if (articleList.length && currentFilterPage === 0) {
      const height =
        categoryFilterRef?.current?.getBoundingClientRect().height || 0;
      if (filterResultsOffsetRef?.current) {
        filterResultsOffsetRef.current.style.top = `-${height}px`;

        if (!isPreselectedFilter)
          filterResultsOffsetRef.current.scrollIntoView();
      }
    }
  }, [articleList]);

  useEffect(() => {
    if (!isServer()) {
      // Retrieve the selected filter in the query string and set it on load
      const searchParams = new URLSearchParams(window.location.search);
      const presetFilter = searchParams.get('filter');

      if (presetFilter) {
        const findFilterIndex = filterLabels.findIndex(
          (filterLabel) => filterLabel.toLowerCase() === presetFilter
        );

        setSelectedFilterIndex(findFilterIndex);
        setIsPreselectedFilter(true);
      }
    }
  }, []);

  const handleLoadMore = () => {
    setCurrentFilterPage(currentFilterPage + 1);
  };

  const showLoadMore = () => {
    if (
      Math.ceil(totalFilterResults / resultsPerRequest) - 1 >
      currentFilterPage
    ) {
      return true;
    }
  };

  const formatTags = (article) => {
    article.fields.tag = {
      fields: {
        textColour: { value: article?.fields?.tag?.textColour?.value },
        colour: { value: article?.fields?.tag?.colour?.value },
        title: { value: article?.fields?.tag?.title?.value },
      },
    };

    return article;
  };

  return (
    <ArticleListComponent
      spaceMultiplierMobile={spaceMultiplierMobile}
      spaceMultiplierDesktop={spaceMultiplierDesktop}
    >
      {filterLabels.length > 1 && (
        <CategoryFilter
          className="article-list-filter"
          filterFn={handleFilterChange}
          filterLabels={filterLabels}
          ref={categoryFilterRef}
          t={t}
          selectedIndex={selectedFilterIndex}
        />
      )}
      <div>
        {selectedFilterIndex === 0 &&
          rows.map((row, i) => {
            return (
              <React.Fragment key={i}>
                {row?.fields?.article && (
                  <FeaturedArticleComponent>
                    <div>
                      <ArticleCard
                        className="article-card article-card-featured"
                        translate={translate}
                        variant="a"
                        headingLevel={1}
                        forceHover
                        gtm={{ name: 'featuredArticle', data: ['cardTitle'] }}
                        {...row?.fields?.article}
                      />
                    </div>
                  </FeaturedArticleComponent>
                )}
                {row?.fields?.title && (
                  <EditFrame
                    itemId={row.id}
                    header="Edit Row"
                    commandDisplayName="Row"
                    fields={[
                      'title',
                      'cta',
                      'contentType',
                      'tags',
                      'resultCount',
                      'theme',
                    ]}
                    key={i}
                  >
                    <ArticleListRow
                      {...row}
                      translate={translate}
                      setFilterFn={setFilter}
                    />
                  </EditFrame>
                )}
              </React.Fragment>
            );
          })}
        {selectedFilterIndex > 0 && articleList?.length > 0 && !loading && (
          <InfiniteScroll
            pageStart={0}
            loadMore={handleLoadMore}
            hasMore={showLoadMore()}
            threshold={0}
            initialLoad={false}
          >
            <FilteredResultsGrid>
              <div className="offset" ref={filterResultsOffsetRef}></div>
              {articleList.map(formatTags).map((article, i) => (
                <ArticleCard
                  key={i}
                  className="article-card"
                  variant="b"
                  headingLevel={1}
                  {...article}
                />
              ))}

              {showLoadMore() && (
                <LoadMore onClick={handleLoadMore}>
                  {getIcon('refresh')} {loadMoreLabel}
                </LoadMore>
              )}
            </FilteredResultsGrid>
          </InfiniteScroll>
        )}
        {selectedFilterIndex > 0 && noResults && (
          <NoResults>{noResultsLabel}</NoResults>
        )}
      </div>
    </ArticleListComponent>
  );
};

ArticleList.propTypes = {
  fields: PropTypes.object,
  params: PropTypes.object,
  translate: PropTypes.func,
};

export default ArticleList;
