import React from 'react';
import AnchorMenuComponent from 'lmel-components/core/anchor-menu';
import ErrorBoundary from 'lmel-components/core/error-boundary';

const AnchorMenu = props => {
  return (
    <ErrorBoundary>
      <AnchorMenuComponent {...props} />
    </ErrorBoundary>
  );
};

export default AnchorMenu;
