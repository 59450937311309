import { css } from 'styled-components';
import { mapColourToSiteTheme as CM } from '../colourMap';

const buttonBgColour = props => {
  if (props.type.toLowerCase() === 'b') {
    return 'none';
  }
  if (props.inactive) {
    return CM(props.theme).inactiveBackgroundColor;
  }
  return props.theme.buttonVariants[props.variant][1];
};

const buttonHover = props => {
  if (props.type.toLowerCase() === 'b') {
    return props.inactive
      ? `color: ${CM(props.theme).inactiveHoverColor}`
      : `
      color: ${props.theme.buttonHoverVariants['blackB'][1]}
      `;
  }

  return props.inactive
    ? ''
    : css`
        span {
          color: ${props.theme.buttonHoverVariants[props.variant][0]};
          background-color: ${props.theme.buttonHoverVariants[props.variant][1]};
        }
      `;
};

const buttonTextColour = props => {
  if (props.type.toLowerCase() === 'b') {
    if (props.inactive) {
      return CM(props.theme).inactiveTextColor;
    }
    return props.theme.buttonVariants['blackB'][0];
  } else {
    return props.theme.buttonVariants[props.variant][0];
  }
};

const arrowFillColour = (props, hover = false) => {
  if (props.type.toLowerCase() === 'b') {
    if (props.inactive) {
      return CM(props.theme).inactiveArrowFillColor;
    }
    if (hover) {
      return props.theme.buttonHoverVariants['blackB'][1];
    } else {
      return props.theme.buttonVariants['blackB'][0];
    }
  }
  return props.theme.buttonVariants[props.variant][0];
};

export const ButtonStyles = css`
  position: relative;
  display: flex;
  ${props => props.theme.setFont('cta')}
  width: ${props => (props.fullwidth ? '100%' : 'auto')};
  padding: 0;
  color: ${props => buttonTextColour(props)};
  font-weight: 700;
  text-align: left;
  text-decoration: none;
  background: none;
  border: none;
  transform: translateZ(0);
  cursor: ${props => (props.inactive ? 'not-allowed' : 'pointer')};
  transition: all 0.12s;
  appearance: none;

  span,
  a {
    &:not(.icon) {
      display: inline-block;
      min-width: 120px;
      text-align: center;
      text-decoration: none;
      border-radius: 10px;
      transition: all 0.12s;
      width: ${props => (props.fullwidth ? '100%' : 'auto')};
      height: ${props => (props.type.toLowerCase() === 'a' ? `50px` : 'auto')};
      line-height: ${props => (props.type.toLowerCase() === 'a' ? `50px` : 'auto')};
      padding: ${props => (props.type.toLowerCase() === 'a' ? '0 27px 0 27px' : '0 3px 0 37px')};

      color: ${props => buttonTextColour(props)};
      background-color: ${props => buttonBgColour(props)};
      &:hover {
        ${props => buttonHover(props)};
      }

      svg {
        display: ${props => (props.type.toLowerCase() !== 'a' ? 'inline-block' : 'none')};
      }
    }
  }

  .icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 50px;
    border-radius: 100%;
    background-color: ${props => buttonBgColour(props)};
  }

  svg {
    fill: ${props => buttonTextColour(props)};
  }

  ${props =>
    props.type.toLowerCase() === 'b'
      ? css`
          :not(.icon) svg {
            top: 7px;
            position: relative;
          }
        `
      : ''}

  &:hover {
    svg:first-child {
      fill: ${props => arrowFillColour(props, true)};
    }
    ${props => buttonHover(props)}
  }
`;
